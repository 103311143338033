import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { Zeego_server_url, Zeego_AppId } from "../../config/config_local";
import { AndroidPermissions } from "@ionic-native/android-permissions";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";

import { removeOngoingMeeting } from "../../redux/actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { call } from "../../config/axios";
import { IoClose } from "react-icons/io5";
import { isPlatform } from "@ionic/react";
import {
  FaMicrophone,
  FaVideo,
  FaVideoSlash,
  FaMicrophoneSlash,
} from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { IoIosMore } from "react-icons/io";
import { MdCallEnd, MdPerson } from "react-icons/md";
import { IonModal } from "@ionic/react";
import { IoCloseSharp } from "react-icons/io5";
import ReactDOMServer from "react-dom/server";
import { LuSendHorizonal } from "react-icons/lu";
import toast from "react-hot-toast";
import {
  openrequestJoin,
  sendRequestJoin,
  OpenRequest2ndMethod,
  OpenRequest3rdMethod
} from "../../utils/WebSocket";
import {
  GET_ALL_CLIENTS,
  GET_LIVE_MEETING,
  SIGNAL_RECORDING,
  UPDATE_MEETING,
} from "../../config/endpoints";
import "./styles.css";
import { Images } from "../../images";
import WantToJoinPopup from "../../pages/video-call-matchposition/WantToJoinPopup";

const GroupCall = (props) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const sendText = useRef(null);
  const videoRefs = useRef([]);
  const localstreamRef = useRef(null);
  const zg = useRef(null);
  const localView = useRef(null);
  const history = useHistory();
  const [isJoined, setIsJoined] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [videoElements, setVideoElements] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [videoElementIds, setVideoElementIds] = useState([]);
  const [muteAudio, setMuteAudio] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [localVideoClass, setLocalVideoClass] = useState("remote-video");
  const [remoteVideoClass, setremoteVideoClass] = useState("remote-video");
  const [joinList, setJoinList] = useState([]);
  const [remoteStreamId, setRemoteStreamId] = useState(null);
  const [showWaitingList, setShowWaitingList] = useState(false);
  const [chatPopupOpen, setChatPopupOpen] = useState(false);
  const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
  const [popupmsg, setPopupmsg] = useState("");
  const [ParticpentRequest, setParticpentRequest] = useState(false);
  const [callEndPopup, setCallEndPopup] = useState(false);
  let userName = "Default User";
  let callType = "audio-video";

  const requestMicrophonePermission = async () => {
    if (isPlatform("ios")) {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Microphone permission granted");
      } catch (err) {
        console.error("Microphone permission not granted", err);
      }
    }
  };

  const meetingObject = useSelector((state) => state.data?.ongoingMeeting);
    userName = meetingObject.user_name;
    callType = meetingObject.videoType;
  const [openWantToJoinPopup, setOpenWantToJoinPopup] = useState(false);
  const capitalizedUserName = userName.charAt(0).toUpperCase();
  const RoomId = props.id;
  const register_Id = isAdminCreated
    ? localStorage.getItem("register_id")
    : localStorage.getItem("creator_id");
  const userID = localStorage.getItem("register_id");
  const streamID = Array(9)
    .fill()
    .map(() => ((Math.random() * 36) | 0).toString(36))
    .join("");

  useEffect(() => {
    if (isAdminCreated) {
      const initZegoExpressEngine = async () => {
        const App_Id = Zeego_AppId;
        const Server_url = Zeego_server_url;
        zg.current = new ZegoExpressEngine(App_Id, Server_url);
        initEvent();
        createRoom();
      };
      initZegoExpressEngine();
    }
  }, []);

  const onRequestRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    console.log("onRequestRecievemsg", msg);
    msg.to_user_id = msg.to_user_id.endsWith('up') ? msg.to_user_id.slice(0, -2) : msg.to_user_id;
    if (!isAdminCreated) {
      console.log("not admin");
      if (msg.from_user_id === register_Id && msg.message === "accept") {
        console.log("accept", msg);

        setWaiting(false);
        createRoom();
      } else if (msg.from_user_id === register_Id && msg.message === "reject") {
        console.log("reject", msg);
        dropCall('');
      }
    }

    if (isAdminCreated) {
      if (msg.to_user_id === register_Id) {
        setOpenWantToJoinPopup(true);
        setPopupmsg(msg);
        setTimeout(() => {
          setOpenWantToJoinPopup(false);
        }, 5000);
        setJoinList((prevList) => [...prevList, msg]);
      }
    }
  };
  const showWaiting = () => {
    setShowWaitingList(!showWaitingList);
  };

  useEffect(() => {
    const videoContainer = document.querySelector(".video-container");

    const hasVideoElements = videoElementIds.length > 0;
    console.log("hasVideoElements", hasVideoElements);
    if (videoElementIds.length < 1) {
      setLocalVideoClass("local-stream-video");
    } else if (videoElementIds.length === 1) {
      setLocalVideoClass("small-video");
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("medium-video");
          element.classList.add("remote-video");
        }
      });
    } else if (videoElementIds.length > 1) {
      setLocalVideoClass("medium-video");
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("remote-video");
          element.classList.add("medium-video");
        }
      });

      if (videoContainer) {
        const videoElements = videoContainer.querySelectorAll("video");

        videoElements.forEach((videoElement) => {
          videoElement.classList.remove("main-video");
          //videoElement.classList.add('medium-video');
        });
      }
    }
  }, [videoElementIds]);
  const addVideoElementId = (id) => {
    setVideoElementIds((prevIds) => [...prevIds, id]);
  };

  const removeVideoElementId = (idToRemove) => {
    setVideoElementIds((prevIds) => prevIds.filter((id) => id !== idToRemove));
  };

  useEffect(() => {
    props.userJoin(userList);
  }, [userList]);

  useEffect(() => {
    openrequestJoin({ onmessage: onRequestRecieve })
      .then(() => {
        if (!isAdminCreated && waiting) {
          const paramObj = {
            meeting_id: RoomId,
            register_id: register_Id,
            type: "send_request",
          };
          // callTokenApi(paramObj, "send_request");
          sendRequestJoin("waiting", register_Id,"",RoomId);
          const timeoutId = setTimeout(() => {
            setParticpentRequest(true);
          }, 10000);
          return () => clearTimeout(timeoutId);
        }
      })
      .catch((error) => {
        console.error("Error opening WebSocket connection:", error);
      });
  }, []);

  const initEvent = () => {
    zg.current.on(
      "roomStateUpdate",
      (roomID, state, errorCode, extendedData) => {
        if (state === "DISCONNECTED") {
          console.log("DISCONNECTED");
        }
        if (state === "CONNECTING") {
          console.log("CONNECTING");
        }
        if (state === "CONNECTED") {
          console.log("CONNECTED");
        }
      }
    );
    zg.current.on("IMRecvBroadcastMessage", (roomID, chatData) => {
      console.log("IMRecvBroadcastMessage", roomID, chatData);
      let message = {
        ID: "zego" + chatData[0].fromUser.userID + chatData[0].sendTime,
        name: chatData[0].fromUser.userName,
        time: moment(chatData[0].sendTime).format("hh:mm A"),
        content: chatData[0].message,
        type: "received",
      };
      setMessageList((prevMessages) => [...prevMessages, message]);
    });

    zg.current.on("roomUserUpdate", (roomID, updateType, userList) => {
      console.log("roomUserUpdate", roomID);
      if (updateType === "ADD") {
        console.log("roomUserUpdate", userList);
      } else if (updateType == "DELETE") {
        console.log("roomUserUpdate", userList);
      }
    });
    zg.current.on("publisherStateUpdate", (result) => {
      //alert('publisherStateUpdate',result.streamID, result.state)
    });

    zg.current.on("remoteCameraStatusUpdate", (streamID, status) => {
      if (callType === "audio-video") {
        const divElement = document.getElementById(streamID);
        if (divElement) {
          let iconElement;
          let videoElement = divElement.querySelector("video");
          if (status === "MUTE") {
            iconElement = document.createElement("span");
            iconElement.className = "icon-dynamic";
            var faVideoElement = React.createElement(FaVideoSlash);
            var htmlString = ReactDOMServer.renderToString(faVideoElement);
            iconElement.innerHTML = htmlString;
            const belParameterElement =
              divElement.querySelector(".bel-parameter");
            const text = belParameterElement.textContent.trim();
            const capitalizedText = text.charAt(0).toUpperCase();
            videoElement.style.display = "none";
            divElement.classList.add("mute-active");
            const circleOuter = document.createElement("div");
            circleOuter.className = "circle-outer";

            const circleMuted = document.createElement("div");
            circleMuted.className = "circle-muted";

            const textCircle = document.createElement("span");
            textCircle.className = "text-circle";
            textCircle.textContent = capitalizedText;

            circleMuted.appendChild(textCircle);
            circleOuter.appendChild(circleMuted);

            divElement.appendChild(circleOuter);
          } else {
            iconElement = document.createElement("span");
            iconElement.className = "icon-dynamic";
            var faVideoElement = React.createElement(FaVideo);
            var htmlString = ReactDOMServer.renderToString(faVideoElement);
            iconElement.innerHTML = htmlString;
            videoElement.style.display = "block";
            divElement.classList.remove("mute-active");
            const circleOuter = divElement.querySelector(".circle-outer");
            if (circleOuter) {
              circleOuter.remove();
            }
          }

          const mutVidDiv = divElement.querySelector(".mut-vid");
          if (mutVidDiv) {
            mutVidDiv.innerHTML = "";
            mutVidDiv.appendChild(iconElement);
          }
        }
      }
    });
    zg.current.on("remoteMicStatusUpdate", (streamID, status) => {
      console.log("remoteMicStatusUpdate", streamID, status);
      const divElement = document.getElementById(streamID);
      if (divElement) {
        let iconElement;
        if (status === "MUTE") {
          iconElement = document.createElement("span");
          iconElement.className = "icon-dynamic";
          var faVideoElement = React.createElement(FaMicrophoneSlash);
          var htmlString = ReactDOMServer.renderToString(faVideoElement);
          iconElement.innerHTML = htmlString;
        } else {
          iconElement = document.createElement("span");
          iconElement.className = "icon-dynamic";
          var faVideoElement = React.createElement(FaMicrophone);
          var htmlString = ReactDOMServer.renderToString(faVideoElement);
          iconElement.innerHTML = htmlString;
        }

        const mutVidDiv = divElement.querySelector(".mut-aud");
        if (mutVidDiv) {
          mutVidDiv.innerHTML = "";
          mutVidDiv.appendChild(iconElement);
        }
      }
    });
    zg.current.on("videoMuteState", (result) => {
      //alert('videoMuteState', result)
    });
    zg.current.on("playerStateUpdate", (result) => {
      var state = result["state"];
      var streamID = result["streamID"];
      var errorCode = result["errorCode"];
      var extendedData = result["extendedData"];
      // alert('player state update', state)
    });
    zg.current.on(
      "roomStreamUpdate",
      async (roomID, updateType, streamList, extendedData) => {
        if (updateType == "ADD") {
          console.log("roomStreamUpdate", streamList);
          streamList = streamList.filter(
            (stream) => stream.user.userID !== userID
          );
          if (callType === "hide") {
            if (!isAdminCreated) {
              streamList = streamList.filter(
                (stream) => stream.user.userID === register_Id
              );
            }
          }
          const newvideoElement = streamList.map((stream, index) => {
            const newRef = React.createRef();
            addVideoElementId(stream.streamID);
            (async () => {
              try {
                const remoteStream = await zg.current.startPlayingStream(
                  stream.streamID
                );
                const data = {
                  roomID: roomID,
                  updateType: updateType,
                  streamId: stream.streamID,
                  stream: remoteStream,
                  user: stream.user,
                  userID: stream.user.userID,
                  userName: stream.user.userName,
                  micMute: false,
                  camMute: false,
                };
                setUserList((prevUsers) => [...prevUsers, data]);

                let className =
                  videoElementIds.length < 1
                    ? "remote-video-1"
                    : "medium-video";

                setremoteVideoClass(className);
                const videoElement = newRef.current;
                if (videoElement && remoteStream) {
                  videoElement.srcObject = remoteStream;
                  videoElement.muted = false;
                  videoElement.className = "main-video";
                }
                props.userJoin(userList);
              } catch (error) {
                console.error("Error starting to play the stream:", error);
              }
            })();
            return (
              <div id={stream.streamID} className={remoteVideoClass}>
                <span className="bel-parameter">
                  
                  <MdPerson /> {stream.user.userName}
                </span>
                {callType !== "audio-video" && (
                  <div className="circle-outer">
                    <div className="circle-muted">
                      <span className="text-circle">
                        {stream.user.userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
                <div id={stream.user.id} className="aud-vid-mute">
                  <div className={`mut-aud`}>
                    <FaMicrophone />
                  </div>

                  {callType === "audio-video" && (
                    <div className={`mut-vid`}>
                      <FaVideo />
                    </div>
                  )}
                </div>
                {callType === "audio-video" ? (
                  <video key={index} ref={newRef} autoPlay playsInline />
                ) : (
                  <audio key={index} ref={newRef} autoPlay />
                )}
              </div>
            );
          });
          setVideoElements((prevElements) => [
            ...prevElements,
            ...newvideoElement,
          ]);
        } else if (updateType == "DELETE") {
          streamList.map(async (stream, index) => {
            await zg.current.stopPlayingStream(stream.streamID);
            setUserList((prevUsers) =>
              prevUsers.filter((user) => user.streamId !== stream.streamID)
            );
            props.userJoin(userList);
            const videoElement = document.getElementById(stream.streamID);
            if (videoElement) {
              videoElement.parentNode.removeChild(videoElement);
            }
            removeVideoElementId(stream.streamID);
          });
        }
      }
    );
    zg.current.on(
      "roomStateChanged",
      async (roomID, reason, streamList, extendedData) => {
        console.log("roomStateChanged", reason);
        if (reason == "LOGINING") {
        } else if (reason == "LOGINED") {
        } else if (reason == "LOGIN_FAILED") {
        } else if (reason == "RECONNECTING") {
          // Reconnecting.
        } else if (reason == "RECONNECTED") {
          // Reconnection successful.
        } else if (reason == "RECONNECT_FAILED") {
          // Reconnection failed.
        } else if (reason == "KICKOUT") {
          // Kicked out of the room.
          toast.error("You have been kicked out of the room");
          await zg.current.logoutRoom(RoomId);
          await zg.current.stopPublishingStream(localStream);
          await zg.current.destroyStream(localstreamRef.current);
          dispatch(removeOngoingMeeting());
          setIsJoined(false);
          history.push("/call-management");
          window.location.reload();
        } else if (reason == "LOGOUT") {
          // Logout successful.
        } else if (reason == "LOGOUT_FAILED") {
          // Logout failed.
        }
      }
    );
    zg.current.on("soundLevelUpdate", (streamList) => {
      console.log("soundLevelUpdate", streamList);
      streamList.forEach((stream) => {
        if (stream.type === "push" && !muteAudio) {
          var soundLevelElement = document.getElementById("local-circle");
          if (soundLevelElement) {
            var soundLevel = Math.round(stream.soundLevel);
            var boxShadowSize = soundLevel * 10 + "px";
            soundLevelElement.style.boxShadow = `0px 0px ${boxShadowSize} 0px #1475e3`;
            setTimeout(function () {
              soundLevelElement.style.boxShadow = "none";
            }, 2000);
          }

          var streamDiv = document.getElementById("local-video");

          if (streamDiv && stream.soundLevel > 0) {
            streamDiv.style.border = "3px solid #1475e3";
            streamDiv.style.boxShadow = "0px 0px 10px 0px blue";
            setTimeout(function () {
              streamDiv.style.boxShadow = "none";
              streamDiv.style.border = "none";
            }, 3000);
          }
        }
        if (stream.type === "pull") {
          var soundLevelElement = document.getElementById(stream.streamID);
          if (soundLevelElement) {
            var circleMutedElement =
              soundLevelElement.querySelector(".circle-muted");
            if (circleMutedElement) {
              var soundLevel = Math.round(stream.soundLevel);
              var boxShadowSize = soundLevel * 10 + "px";
              circleMutedElement.style.boxShadow = `0px 0px ${boxShadowSize} 0px #1475e3`;
              setTimeout(function () {
                circleMutedElement.style.boxShadow = "none";
              }, 2000);
            }
          }
          var streamDiv = document.getElementById(stream.streamID);

          if (streamDiv && stream.soundLevel > 0) {
            streamDiv.style.border = "3px solid  #1475e3";
            streamDiv.style.boxShadow = "0px 0px 10px 0px #1475e3";
            setTimeout(function () {
              streamDiv.style.boxShadow = "none";
              streamDiv.style.border = "none";
            }, 2000);
          }
        }
      });
    });
  };
  useEffect(() => {
    if (props.mute) {
      console.log("muteVideo", props.mute);
      muteRemoteCam(props.mute.streamId, props.mute.mute);
    }
  }, [props.mute]);

  const muteRemoteCam = async (streamID, mute) => {
    try {
      await zg.current.mutePlayStreamVideo(streamID, mute);
    } catch (error) {
      console.error("Error mute remote stream:", error);
    }
  };

  const callTokenApi = (paramsObj, flag) => {
    if (isAdminCreated) {
      paramsObj["userType"] = "admin";
    } else {
      paramsObj["userType"] = "user";
      paramsObj["userId"] = localStorage.getItem("register_id");
    }
    return call(SIGNAL_RECORDING, paramsObj)
      .then((res) => {
        console.log(res, "api response");
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const createRoom = async () => {
    try {
      let data = "";
      if (!isAdminCreated) {
        const App_Id = Zeego_AppId;
        const Server_url = Zeego_server_url;
        zg.current = new ZegoExpressEngine(App_Id, Server_url);
        initEvent();
      }
      const paramObj = {
        meeting_id: RoomId,
        register_id: register_Id,
        type: "generate_token",
      };
      data = await callTokenApi(paramObj, "generate_token");
      if (
        !AndroidPermissions.hasPermission(AndroidPermissions.PERMISSION.CAMERA)
      ) {
        await AndroidPermissions.requestPermission(
          AndroidPermissions.PERMISSION.CAMERA
        );
      }
      if (
        !AndroidPermissions.hasPermission(
          AndroidPermissions.PERMISSION.RECORD_AUDIO
        )
      ) {
        await AndroidPermissions.requestPermission(
          AndroidPermissions.PERMISSION.RECORD_AUDIO
        );
      }

      requestMicrophonePermission();

      if (isAdminCreated) {
        data.waitingList.map((res) => {
          if (res.participant_id !== register_Id) {
            const JoinData = {
              account_role: res.account_role,
              from_user_id: res.participant_id,
              from_user_name: res.from_user_name,
              message: "accept",
              to_user_id: register_Id,
            };
            setJoinList((prevList) => [...prevList, JoinData]);
          }
        });
      }
      if (
        !AndroidPermissions.hasPermission(
          AndroidPermissions.PERMISSION.RECORD_AUDIO
        )
      ) {
        await AndroidPermissions.requestPermission(
          AndroidPermissions.PERMISSION.RECORD_AUDIO
        );
      }

      requestMicrophonePermission();

      if (isAdminCreated) {
        data.waitingList.map((res) => {
          if (res.participant_id !== register_Id) {
            const JoinData = {
              account_role: res.account_role,
              from_user_id: res.participant_id,
              from_user_name: res.from_user_name,
              message: "accept",
              to_user_id: register_Id,
            };
            setJoinList((prevList) => [...prevList, JoinData]);
          }
        });
      }
      console.log("data", data);
      const result = await zg.current.loginRoom(
        RoomId,
        data.token,
        { userID, userName },
        { userUpdate: true }
      );
      console.log("result", result);
      const deviceInfo = await zg.current.enumDevices();
      console.log("deviceInfo", deviceInfo);
      const recordParamsObj = {
        meeting_id: RoomId,
        register_id: localStorage.getItem("register_id"),
        type: "startRecord",
      };
      if (isAdminCreated) {
        await callTokenApi(recordParamsObj, "startRecord");
      }
      if (!localstreamRef.current) {
        setLocalStream(streamID);
        if (callType === "audio-video") {
          localstreamRef.current = await zg.current.createZegoStream({
            camera: {
              audioInput: deviceInfo.microphones[0].deviceID,
              videoInput: deviceInfo.cameras[0].deviceID,
              video: true,
              audio: true,
            },
          });
          localstreamRef.current.playVideo(
            document.querySelector("#local-video")
          );
        } else {
          localstreamRef.current = await zg.current.createZegoStream({
            camera: { audio: true, video: false },
          });
          //localstreamRef.current.playAudio();
        }

        zg.current.startPublishingStream(streamID, localstreamRef.current);
        zg.current.setSoundLevelDelegate(true, 1000);

        //localstreamRef.current.stopCaptureVideo();
        //  const videoElement = videoRef.current;
        // if (videoElement && localstreamRef.current) {
        //     videoElement.srcObject = localstreamRef.current;
        //     videoRef.current.muted = false;
        // }
        // localView.current = zg.current.createLocalStreamView(local);
        // console.log('local-view', localView.current);

        // localView.current.playVideo("local-video", {
        //     enableAutoplayDialog: true,
        // });
      }

      setRemoteStreamId(streamID);
    } catch (error) {
      const stopRecordParamsObj = {
        meeting_id: RoomId,
        type: "stopRecord",
        register_id: localStorage.getItem("register_id"),
        //remainingTime: remainingMinutes > 0 ? time : "0:0",
      };
      if (isAdminCreated) {
        await callTokenApi(stopRecordParamsObj, "stopRecord");
      }
      console.error("Error creating room:", error);
    }
  };
  const sendMessage = async () => {
    const text = sendText.current.value;
    if (text) {
      try {
        const isSent = await zg.current.sendBroadcastMessage(RoomId, text);
        sendText.current.value = "";
        const message = {
          ID: "zego" + userID + Date.now(),
          name: userName,
          time: moment().format("hh:mm A"),
          content: text,
          type: "sent",
        };
        setMessageList((prevMessages) => [...prevMessages, message]);
        console.log(">>> sendMsg success,", isSent);
      } catch (error) {
        console.log(">>> sendMsg, error:", error);
      }
    }
  };
  const closeWaiting = () => {
    setShowWaitingList(false);
  };


  const dropCall = async (after) => {
    try {
      await zg.current.logoutRoom(RoomId);
      await zg.current.stopPublishingStream(localStream);
      await zg.current.destroyStream(localstreamRef.current);
      dispatch(removeOngoingMeeting());
      setIsJoined(false);
      history.push("/call-management");

      if (isAdminCreated) {
        const totalMinutesStr = localStorage.getItem("totalMinutes");
        const totalMinutes = totalMinutesStr ? parseInt(totalMinutesStr) : 0;
        const startTimeStr = localStorage.getItem("startTime");
        const startTime = new Date(startTimeStr).getTime();
        const currentTime = new Date().getTime();
        const elapsedTimeMs = currentTime - startTime;
        const elapsedTimeMinutes = elapsedTimeMs / (1000 * 60);
        let remainingMinutes = totalMinutes - elapsedTimeMinutes;
        remainingMinutes = Math.max(0, remainingMinutes);
        const hours = Math.floor(remainingMinutes / 60);
        const minutes = Math.floor(remainingMinutes % 60);
        const time = `${hours}:${minutes}`;
        
          const stopRecordParamsObj = {
          meeting_id: RoomId,
          type: "stopRecord",
          register_id: localStorage.getItem("register_id"),
          remainingTime: remainingMinutes > 0 ? time : "0:0",
          after: after != null && after != '' ? true : ''
          };
          await callTokenApi(stopRecordParamsObj, "stopRecord");
        
        
        localStorage.removeItem("isAdminMeeting");
        localStorage.removeItem("totalMinutes");
        localStorage.removeItem("startTime");
        localStorage.removeItem("meeting_id");
        localStorage.removeItem("minutesRemaining");
        console.log('stopRecordParamsObj',stopRecordParamsObj)
      }
      
      window.location.reload();
    } catch (error) {
      console.error("Error dropping the call:", error);
      dispatch(removeOngoingMeeting());
      setIsJoined(false);
      history.push("/call-management");
      window.location.reload();
    }
  };

  const openChat = () => {
    var footer = document.getElementById("callFooter");
    var videoGroup = document.getElementById("videoGroup");
    var chatBox = document.getElementById("chatBox");
    var chat = document.getElementById("chat-btn");
    if (chatBox.style.display === "block") {
      chatBox.style.display = "none";
      chat.classList.remove("active-chat");
      setChatPopupOpen(false);
    } else {
      chatBox.style.display = "block";
      chat.classList.add("active-chat");
      setChatPopupOpen(true);
    }
    footer.style.height = chatBox.style.display === "block" ? "13vh" : "13vh";
    videoGroup.style.height =
      chatBox.style.display === "block" ? "70vh" : "70vh";
  };
  const muteMicro = () => {
    if (localStream) {
      if (callType === "audio-video") {
        zg.current.muteMicrophone(!muteAudio);
        setMuteAudio(!muteAudio);
      } else {
        zg.current.muteMicrophone(!muteAudio);

        setMuteAudio(!muteAudio);
      }
    }
  };
  const muteCam = async () => {
    if (localstreamRef.current) {
      const deviceInfo = await zg.current.enumDevices();

      try {
        if (muteVideo) {
          zg.current.mutePublishStreamVideo(localstreamRef.current, false);
        } else {
          //const videoTrack = localstreamRef.current.getVideoTracks()[0];
          //videoTrack.enabled = !videoTrack.enabled;
          ///localstreamRef.current.stopCaptureVideo();
          zg.current.mutePublishStreamVideo(localstreamRef.current, true);
        }
      } catch (error) {
        console.error("Error unmute video:", error);
      }

      setMuteVideo(!muteVideo);
    }
  };
  const sendJoinRequest = (res, joinuser_id) => {
    const paramObj = {
      meeting_id: RoomId,
      register_id: register_Id,
      type: "remove_request",
      userType: "user",
      userId: joinuser_id,
    };
    // callTokenApi(paramObj, "remove_request");
     sendRequestJoin(res, joinuser_id,"",RoomId);
    const updateJoin = joinList.filter((j) => j.from_user_id !== joinuser_id);
    setJoinList(updateJoin);
  };
  const handleOpenRequest = async () => {
  const newItems = await OpenRequest2ndMethod(register_Id, RoomId);
  
  if (newItems && newItems.length > 0) {
    setJoinList((prevList) => {
      const uniqueItems = newItems.filter(newItem => 
        !prevList.some(existingItem => existingItem.from_user_id === newItem.from_user_id)
      );
      return [...prevList, ...uniqueItems];
    });
  }
};

const handleOpenRequestForParticipants = async () => {
  const newItems = await OpenRequest3rdMethod(RoomId);
    
  if (newItems && newItems.length > 0) {
        const acceptedItems = newItems.filter(item => item.accepted === 1);

    if (acceptedItems.length > 0) {
      setParticpentRequest(false);
      
      if(waiting === true){
            createRoom()
        }
        setWaiting(false);
      console.log('create Room')
    } else {
      
      console.log("Waiting for accepted participants...");
    }
      
  }else{
    console.log('Drop Call')
        dropCall();
  }
};


  useEffect(() => {
  
  if (!isAdminCreated && ParticpentRequest === true) {
    const intervalId = setInterval(() => {
      handleOpenRequestForParticipants();
    }, 5000);

    
    return () => clearInterval(intervalId);
  }
}, [isAdminCreated, ParticpentRequest]); 

 useEffect(() => {
  if (isAdminCreated) {
    const intervalId = setInterval(() => {
      handleOpenRequest();
    }, 5000);

    return () => clearInterval(intervalId);
  } 
}, [isAdminCreated, register_Id, RoomId]);

  const handleacceptClick = () => {
    setOpenWantToJoinPopup(false);
    sendJoinRequest("accept", popupmsg.from_user_id);
    setOpenWantToJoinPopup(false);
  };

  const handlerejectClick = () => {
    setOpenWantToJoinPopup(false);
    sendJoinRequest("reject", popupmsg.from_user_id);
    setOpenWantToJoinPopup(false);
  };

  const changeClasses = () => {
    if (videoElementIds.length === 1) {
      let localVideoClassvar =
        localVideoClass === "small-video" ? "remote-video" : "small-video";
      setLocalVideoClass(localVideoClassvar);
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("medium-video");
          element.classList.remove(
            localVideoClassvar === "remote-video"
              ? "remote-video"
              : "small-video-remote"
          );
          element.classList.add(
            localVideoClassvar === "remote-video"
              ? "small-video-remote"
              : "remote-video"
          );
        }
      });
    }
  };

  return (
    <>
      <div className="room-page">
        {waiting && !isAdminCreated ? (
          <>
            <div className="waiting-img">
              <img src={Images.callWaiting} alt="" />
              <p className="text-white">Waiting for Joining..</p>
            </div>
          </>
        ) : (
          <>
            <div id="videoGroup" className="video-group">
              <div className="video-container">
                <div
                  id="local-video"
                  onClick={() => changeClasses()}
                  className={`${localVideoClass} ${
                    muteVideo ? "mute-active" : "mute-inactive"
                  } ${callType != "audio-video" ? "audio-active" : ""} `}
                >
                  <span className="yel-parameter">
                    
                    <MdPerson /> {userName} (you)
                  </span>
                  {muteVideo || callType != "audio-video" ? (
                    <div className="circle-outer">
                      <div id="local-circle" className="circle-muted">
                        <span className="text-circle">
                          {capitalizedUserName}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="aud-vid-mute">
                    <div
                      className={`mut-aud ${muteVideo ? "mute-active" : ""}`}
                    >
                      {muteAudio ? <FaMicrophoneSlash /> : <FaMicrophone />}
                    </div>
                    {callType === "audio-video" ? (
                      <div
                        className={`mut-vid ${muteAudio ? "mute-active" : ""}`}
                      >
                        {muteVideo ? <FaVideoSlash /> : <FaVideo />}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* <video   id="local-video" ref={videoRef} className={localVideoClass} autoPlay playsInline/> */}
                {videoElements}

                {/* {remoteStream && remoteStream.length > 0 && (
                                remoteStream.map((stream, index) => (


                                    <>

                                    <video ref={videoRef} className='remote-video' autoPlay playsInline muted={stream.isMuted} />

                                    </>
                                ))
                           )} */}
              </div>
            </div>
          </>
        )}

        <div id="callFooter">
          {showWaitingList && (
            <div className="accept-approval">
              <div className="head-joining">
                <h4>Waiting to be admitted - {joinList.length} </h4>
                <IoClose onClick={() => closeWaiting()} />
              </div>
              {joinList.map((join, index) => (
                <div key={index} className="join-approval">
                  <div className="join-approval-box">
                    <div className="join-approval-img">
                      <img
                        src={Images.CallingProfileImage}
                        className="img-approval-list"
                        alt=""
                      />
                    </div>
                    <div className="join-approval-text">
                      <span>{join.from_user_name}</span>
                      <p>{join.account_role}</p>
                    </div>
                  </div>
                  <div className="join-approval-btn">
                    <button
                      className="btn-accept"
                      onClick={() =>
                        sendJoinRequest("reject", join.from_user_id)
                      }
                    >
                      Reject
                    </button>
                    <button
                      className="btn-reject"
                      onClick={() =>
                        sendJoinRequest("accept", join.from_user_id)
                      }
                    >
                      Admit
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            className={` ${
              !isAdminCreated && callType !== "audio-video"
                ? "footer-video-user"
                : "footer-video"
            }`}
          >
            {callType === "audio-video" ? (
              <>
                <button className="btn-foot mute" onClick={() => muteMicro()}>
                  {muteAudio ? <FaMicrophoneSlash /> : <FaMicrophone />}
                </button>
                <button className="btn-foot video" onClick={() => muteCam()}>
                  {muteVideo ? <FaVideoSlash /> : <FaVideo />}
                </button>
              </>
            ) : (
              <>
                {isAdminCreated && (
                  <button className="btn-foot mute" onClick={() => muteMicro()}>
                    {muteAudio ? <FaMicrophoneSlash /> : <FaMicrophone />}
                  </button>
                )}
              </>
            )}

            {isAdminCreated && (
              <button
                className="btn-foot add-part"
                onClick={() => showWaiting()}
              >
                <span className="count-join">{joinList.length}</span>
                {!showWaitingList ? (
                  <img src={Images.userManagerWhite} className="user-manage" />
                ) : (
                  <>
                    <img
                      src={Images.userManageryellow}
                      className="user-manage"
                    />
                  </>
                )}
              </button>
            )}
            {!waiting || isAdminCreated ? (
              <button
                id="chat-btn"
                className="btn-foot chat"
                onClick={() => openChat()}
              >
                <BsChatDots />
              </button>
            ) : (
              <></>
            )}
            {callType !== "audio-video" && !isAdminCreated && (
              <button
                className="btn-foot mute audio-only"
                onClick={() => muteMicro()}
              >
                {muteAudio ? <FaMicrophoneSlash /> : <FaMicrophone />}
              </button>
            )}
            {isAdminCreated && (
              <button className="btn-foot more">
                <IoIosMore />
              </button>
            )}
            <button
              className="drop-call"
              // onClick={() => dropCall()}
              onClick={() => setCallEndPopup(true)}
            >
              <MdCallEnd />
            </button>
            <IonModal isOpen={callEndPopup} className="statememt-popup-main">
              <center>
                <div className="font-25">
                  Are you sure you want end the call?
                </div>
                <div className="font-18 p-8 br-5 green-bg-clr mt-2rem" onClick={() => dropCall('after')}>
                  Re-join after
                  <br /> some time...
                </div>
                <div className="font-18 flex-space-between mt-20">
                  <div
                    className="w-48 p-8 br-5 blue-bg"
                    onClick={() => setCallEndPopup(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="w-48 p-8 br-5 red-bg-clr"
                    onClick={() => dropCall('')}
                  >
                    End Call
                  </div>
                </div>
              </center>
            </IonModal>
          </div>
          <div id="chatBox">
            <IonModal isOpen={chatPopupOpen} className="chat-popup">
              <div className="font-14 flex-space-between">
                <h2>CHAT</h2>
                <IoCloseSharp
                  className="chat-send-icon"
                  onClick={() => setChatPopupOpen(false)}
                />
              </div>
              <div className="chats">
                <div className="chat-body">
                  {messageList.map((message) =>
                    message.type === "sent" ? (
                      <div className="chat-message">
                        <div className="message-right">
                          <div className="message-text">
                            <span className="message-user">{message.name}</span>
                            {message.content}
                            <span className="message-time">{message.time}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="chat-message">
                        <div className="message-left">
                          <span className="message-user">{message.name}</span>
                          <div className="message-text">
                            {message.content}
                            <span className="message-time">{message.time}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="w-100 flex-space-between mt-10">
                <input ref={sendText} type="text" className="send-text-area1" />
                <LuSendHorizonal
                  className="chat-send-icon"
                  onClick={() => sendMessage()}
                />
              </div>
            </IonModal>
          </div>
          {/* <div id="chatBox" class="chat-box">
            <div className="chats">
              <div className="chat-body">
                {messageList.map((message) =>
                  message.type === "sent" ? (
                    <div className="chat-message">
                      <div className="message-right">
                        <div className="message-text">
                          <span className="message-user">{message.name}</span>
                          {message.content}
                          <span className="message-time">{message.time}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="chat-message">
                      <div className="message-left">
                        <span className="message-user">{message.name}</span>
                        <div className="message-text">
                          {message.content}
                          <span className="message-time">{message.time}</span>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="action-send">
              <input ref={sendText} type="text" className="send-input" />
              <LuSendHorizonal onClick={() => sendMessage()} />
            </div>
          </div> */}
        </div>
      </div>
      <WantToJoinPopup
        openWantToJoinPopup={openWantToJoinPopup}
        msg={popupmsg}
        setacceptClick={handleacceptClick}
        settRejectClick={handlerejectClick}
        setWantToJoinPopup={setOpenWantToJoinPopup}
      />
    </>
  );
};

export default GroupCall;
