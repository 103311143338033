import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import {
  FANCY_ENTRY_DATA,
  GET_OFFLINE_CLIENTS,
  UPDATE_FANCY_ENTRY,
  GET_FANCY_ENTRY_DATA,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { IonText } from "@ionic/react";
import Select from "react-select";
import MatchEntryCreatePopup from "../matchEntry/MatchEntryCreatePopup";

function FancyEntryInputs({
  selectedMatch,
  registered_match_id,
  selectedMatchEntry,
  setStatus,
  inningsHeading,
  setSelectedMatchEntry,
  setMatchOver,
  getRiskRunningData,
  getFancyProfitLoss,
  profitLossData = {},
  profitLossDataInnings
}) {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [oversOpen, setOversOpen] = useState(false);
  const [oversHeading, setOversHeading] = useState("");
  const [teamOpen, setTeamOpen] = useState(false);
  const [teamHeading, setTeamHeading] = useState("");
  const [yesOrNoOpen, setYesOrNoOpen] = useState(false);
  const [yesOrNoHeading, setYesOrNoHeading] = useState("");
  const [fancySubmitPopup, setFancySubmitPopup] = useState(false);
  const [runs, setRuns] = useState("");
  const [existingUsers, setExistingUsers] = useState([]);
  const [amount, setAmount] = useState("");
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  let selectedInnigs = inningsHeading;
  const [selectedOptions, setSelectedOptions] = useState();

  const resetFields = () => {
    setAmount("");
    setRuns("");
    setOversHeading("");
    setTeamHeading("");
    setYesOrNoHeading("");
  };
  const getMatchEntryDetails = async () => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: registered_match_id,
      register_id,
    })
      .then((res) => {
        
        setData(res?.data?.data?.Items);
        console.log(res?.data?.data?.Items,'fancy')
      })
      .catch((err) => {
        console.log(err);
      });
  };
    useEffect(()=>{
    getMatchEntryDetails()
  },[registered_match_id])

  const handleMatchSubmitPopup = async () => {
    if (
      !amount ||
      !teamHeading ||
      !runs ||
      !oversHeading ||
      !yesOrNoHeading ||
      !selectedOptions?.value
    ) {
      return setError("Please enter required fields");
    }

    const existingEntry = data?.find(
    (entry) =>
      entry.innings === selectedInnigs === "first" ? "1" : "2" &&
      entry.team !== teamHeading
  );
  console.log(existingEntry,'existingEntry')
  if (existingEntry) {
    return setError(`Innings ${selectedInnigs} is already set with other team !`);
  }

    setIsProcessing(true);
    setError("");
    await call(FANCY_ENTRY_DATA, {
      ...selectedMatch,
      register_id,
      client_id: selectedOptions?.value,
      client_name: selectedOptions?.label,
      amount: +amount,
      rate: 1,
      yN: yesOrNoHeading,
      registered_match_id,
      account_role,
      team: teamHeading,
      runs: runs,
      innings: selectedInnigs === "first" ? "1" : "2",
      over: oversHeading,
    })
      .then((res) => {
        setError("");
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          resetFields();
          setStatus((prev) => !prev);
          setFancySubmitPopup(true);
          setTimeout(() => {
            setFancySubmitPopup(false);
          }, 1000);
          getRiskRunningData();
          getFancyProfitLoss();
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wne't wrong`);
        console.log(err);
      });
  };
  const handleMatchEntryUpdate = async () => {
    if (
      !amount ||
      !teamHeading ||
      !runs ||
      !oversHeading ||
      !yesOrNoHeading ||
      !selectedOptions?.value
    ) {
      return setError("Please enter required fields");
    }
    setIsProcessing(true);
    setError("");
    await call(UPDATE_FANCY_ENTRY, {
      ...selectedMatch,
      ...selectedMatchEntry,
      register_id,
      client_id: selectedOptions?.value,
      client_name: selectedOptions?.label,
      amount: amount,
      rate: 1,
      yN: yesOrNoHeading,
      registered_match_id,
      account_role,
      team: teamHeading,
      runs: runs,
      innings: selectedInnigs === "first" ? "1" : "2",
      over: oversHeading,
      fancy_entry_id: selectedMatchEntry?.fancy_entry_id,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          setStatus((prev) => !prev);
          setFancySubmitPopup(true);
          setSelectedMatchEntry("");
          getRiskRunningData();
          getFancyProfitLoss();
          resetFields();
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wen't wrong`);
        console.log(err);
      });
  };
  const toggleOversOpen = () => {
    setOversOpen(!oversOpen);
    setTeamOpen(false);
    setYesOrNoOpen(false);
  };
  const toggleTeamOpen = () => {
    setTeamOpen(!teamOpen);
    setOversOpen(false);
    setYesOrNoOpen(false);
  };

  const toggleYesOrNoOpen = () => {
    setYesOrNoOpen(!yesOrNoOpen);
    setOversOpen(false);
    setTeamOpen(false);
  };
  const handleOversHeading = (content) => {
    setOversHeading(content);
    setMatchOver(content);
    setOversOpen(false);
    setTeamOpen(false);
    setYesOrNoOpen(false);
  };
  const handleTeamHeading = (content) => {
    setTeamHeading(content);
    setOversOpen(false);
    setTeamOpen(false);
    setYesOrNoOpen(false);
  };

  const handleYesOrNoHeading = (content) => {
    setYesOrNoHeading(content);
    setOversOpen(false);
    setTeamOpen(false);
    setYesOrNoOpen(false);
  };

  useEffect(() => {
    if (selectedMatchEntry) {
    setSelectedOptions({
        label: selectedMatchEntry?.client_name,
        value: selectedMatchEntry?.client_id,
      });
    setAmount(selectedMatchEntry?.amount);
    setRuns(selectedMatchEntry?.runs);
    setOversHeading(selectedMatchEntry?.over);
    setTeamHeading(selectedMatchEntry?.team);
    setYesOrNoHeading(selectedMatchEntry?.yN);
    selectedInnigs = selectedMatchEntry.innings;
    }
  }, [selectedMatchEntry]);

  const getAllClientsData = async () => {
    call(GET_OFFLINE_CLIENTS, {
      register_id,
      account_role,
    })
      .then((res) => {
        if (res.status === 200) {
          let temp = res?.data?.data;
          setExistingUsers(temp);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllClientsData();
    };

    fetchData();
  }, []);

  const optionList = existingUsers
    ?.filter((item) => item?.active == true || item?.active == "true")
    ?.map((item) => {
      return {
        value: item?.client_id,
        label: item?.client_name,
      };
    });
  const handleSelect = (data) => {
    setSelectedOptions(data);
  };


   const checkOverExists = (innings, over) => {
  return innings?.some(inning => Object.keys(inning).includes(`${over}`));
};

const renderOvers = (overs, innings) => {
  return overs
    .filter(over => !checkOverExists(innings, over))
    .map((over, index) => (
      <div
        key={index}
        className="fancy-first-inn custom-box-shadow flex-center mt-5"
        onClick={() => handleOversHeading(over)}
      >
        {over}
      </div>
    ));
};

  return (
    <div>
      {console.log("selectedMatchEntry", selectedMatch)}
      <div className="flex-space-between mt-10 mb-10">
        <div className="w-60 flex-space-between">
          <input
            className="align-center pl-10 fancy-inputs custom-box-shadow w-43"
            placeholder="Rate"
            type="number"
            maxLength={3}
            value={1}
            disabled
          />
          <div className="align-center pl-5 fancy-inputs custom-box-shadow flex-column fancy-overs-position">
            <div className="flex-space-between w-100">
              <input
                className="align-center w-60 p-3 all-none"
                placeholder="Over"
                type="number"
                name="oversHeading"
                id="oversHeading"
                value={oversHeading || ""}
                maxLength={2}
                onChange={(e) => setOversHeading(e.target.value)}
              />
              <BiChevronDown
                className="fancy-down-arrow"
                onClick={() => toggleOversOpen()}
              />
            </div>
            {oversOpen ? (
              <div className="flex-space-around fancy-innings-div custom-box-shadow w-100 mt-10 fancy-overs-margin">
                  {selectedInnigs === "first"
  ? renderOvers(selectedMatch?.game_object?.first_innings_fancy_overs, profitLossDataInnings?.innings1)
  : renderOvers(selectedMatch?.game_object?.second_innings_fancy_overs, profitLossDataInnings?.innings2)}
                {/* {selectedInnigs === "first"
                  ? selectedMatch?.game_object?.first_innings_fancy_overs
                      ?.filter(
                        (i) =>
                          Object.keys(profitLossData).length === 0 ||
                          !Object.keys(profitLossData)?.includes(`${i}`)
                      )
                      ?.map((over, index) => {
                        return (
                          <div
                            key={index}
                            className="mt-5 fancy-first-inn custom-box-shadow flex-center"
                            onClick={() => handleOversHeading(over)}
                          >
                            {over}
                          </div>
                        );
                      })
                  : selectedMatch?.game_object?.second_innings_fancy_overs
                      ?.filter(
                        (i) =>
                          Object.keys(profitLossData).length === 0 ||
                          !Object.keys(profitLossData)?.includes(`${i}`)
                      )
                      ?.map((over, index) => {
                        return (
                          <div
                            key={index}
                            className="mt-5 fancy-first-inn custom-box-shadow flex-center"
                            onClick={() => handleOversHeading(over)}
                          >
                            {over}
                          </div>
                        );
                      })} */}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="align-center pl-10 fancy-inputs custom-box-shadow flex-column fancy-team-position"
          onClick={() => toggleTeamOpen()}
        >
          <div className="flex-space-between w-100">
            {teamHeading || <div>Enter Team</div>}
            <div>
              <BiChevronDown className="fancy-down-arrow" />
            </div>
          </div>

          {teamOpen ? (
            <div className="flex-space-around fancy-teams-div custom-box-shadow mt-10 fancy-team-margin">
              <div
                className="fancy-teamname custom-box-shadow flex-center"
                onClick={() => handleTeamHeading(selectedMatch?.team1)}
              >
                {selectedMatch?.team1}
              </div>
              <div
                className="fancy-teamname custom-box-shadow flex-center mt-5"
                onClick={() => handleTeamHeading(selectedMatch?.team2)}
              >
                {selectedMatch?.team2}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex-space-between mt-10 mb-10">
        <div className="w-60 flex-space-between">
          <input
            className="align-center pl-10 fancy-inputs custom-box-shadow w-43"
            placeholder="Runs"
            type="number"
            name="runs"
            id="runs"
            value={runs || ""}
            onChange={(e) => setRuns(e.target.value)}
          />
          <div
            className="align-center pl-10 fancy-inputs custom-box-shadow w-43 mr-15 flex-column"
            placeholder="Y/N"
          >
            <div
              className={
                yesOrNoHeading === "Y"
                  ? "clr-green flex-space-between w-100 pt-5"
                  : yesOrNoHeading === "N"
                  ? "clr-pink flex-space-between w-100 pt-5"
                  : "flex-space-between w-100 pt-5"
              }
              onClick={() => toggleYesOrNoOpen()}
            >
              {yesOrNoHeading || <div>Y/N</div>}
              <div>
                <BiChevronDown className="fancy-down-arrow" />
              </div>
            </div>
            {yesOrNoOpen ? (
              <div className="flex-space-around fancy-teams-div custom-box-shadow mt-10 fancy-yes-no-margin">
                <div
                  className="clr-green fancy-yes-no custom-box-shadow flex-center"
                  onClick={() => handleYesOrNoHeading("Y")}
                >
                  Y
                </div>
                <div
                  className="clr-pink fancy-yes-no custom-box-shadow flex-center mt-5"
                  onClick={() => handleYesOrNoHeading("N")}
                >
                  N
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <input
          className="align-center pl-10 fancy-inputs custom-box-shadow w-40"
          placeholder="Amount"
          type="number"
          name="amount"
          id="amount"
          value={amount || ""}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div>
        <Select
          className="w-100 mt-10 client-name-select"
          classNamePrefix="react-select"
          options={optionList}
          placeholder="Select Client Name..."
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
        />
      </div>
      <button
        className="fancy-submit-btn fancy-inputs button-box-shadow flex-center w-100"
        onClick={() =>
          Object.keys(selectedMatchEntry).length === 0
            ? handleMatchSubmitPopup()
            : handleMatchEntryUpdate()
        }
        disabled={!selectedMatch?.match_id || isProcessing}
      >
        {isProcessing
          ? "Processing..."
          : Object.keys(selectedMatchEntry).length === 0
          ? "Submit"
          : "Update"}
      </button>
      {error && (
        <IonText color="danger" className="ion-text-center">
          {error}
        </IonText>
      )}
      <MatchEntryCreatePopup
        openPopup={fancySubmitPopup}
        setclosePopup={setFancySubmitPopup}
        displayData={
          Object.keys(selectedMatchEntry).length === 0
            ? "Fancy entry added"
            : "fancy entry updated"
        }
      />
    </div>
  );
}

export default FancyEntryInputs;
