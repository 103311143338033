import { useEffect, useState } from "react";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import EditPopup from "../popups/EditPopup";
import {
  DELETE_FANCY_ENTRY,
  GET_FANCY_ENTRY_DATA,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { FaMinus } from "react-icons/fa6";
import FancyEditPopup from "./FancyEditPopup";
import FancyDeletePopup from "./FancyDeletePopup";
import moment from "moment";
function FancyBetHistory(props) {
  const {
    seriesType,
    selectedMatch,
    matchAccountData,
    setSelectedMatchEntry,
    status,
    setStatus,
    profitLossDataInnings
  } = props;
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletPopup] = useState(false);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [matchEntryData, setMatchEntryData] = useState([]);
  const register_id = localStorage?.getItem("register_id");

  const handleOpenEditPopup = (DATA) => {
    setMatchEntryData(DATA);
    setSelectedMatchEntry(DATA);
    setEditPopup(true);
  };
  const handleOpenDeletePopup = (matchId) => {
    setSelectedId(matchId);
    setDeletPopup(true);
  };

  const handleDeleteFancy = async () => {
    await call(DELETE_FANCY_ENTRY, {
      fancy_entry_id: selectedId,
      register_id,
    })
      .then((res) => {
        setSelectedId("");
        setStatus((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const getMatchEntryDetails = async () => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: matchAccountData?.registered_match_id,
      register_id,
    })
      .then((res) => {
        
        setData(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkOverExists = (innings, over) => {
    console.log(innings, over)
      let Ing = innings == 1 ? profitLossDataInnings?.innings1 : profitLossDataInnings?.innings2
  return Ing.some(Ing => Object.keys(Ing).includes(`${over}`));
};

  useEffect(() => {
    getMatchEntryDetails();
  }, [matchAccountData?.registered_match_id, status]);

  console.log(data, "===>data");

  return (
    <div className="meetings-container w-92">
      <div className="custom-box-shadow  fancy-meetings-details-div mt-15">
        <div className="flex-row flex-space-between">
          <div className=" custom-box-shadow fancy-upcoming-meetings-div font-14 w-60 p-10 mar-5">
            <b>Bet</b> History
          </div>
        </div>
        <div className="hr-line mt-5"></div>
        <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
          <div className="fancy-match-history-button custom-box-shadow flex-center font-10">
            Bet Place
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Over-Y/N
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Name
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Amount
          </div>
        </div>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data &&
            data?.length > 0 &&
            data
              ?.filter((i) => i.fancy_status !== "Y")
              ?.map((fancy, index) => {
                return (
                  <div key={index} className="flex-row">
                    <div className="flex-space-between mt-5 pl-10 pr-10">
                      <div
                        className={
                          fancy.record_status === "updated"
                            ? "match-entry-edit-color meetings-time w-25 font-2 padding-tb"
                            : fancy.record_status === "delete"
                            ? "match-entry-delete-color meetings-time w-25 font-2 padding-tb"
                            : "meetings-time w-25 font-2 padding-tb"
                        }
                      >
                        <div>
                          <div>
                            S.No:
                            {fancy?.old_s_no === fancy?.s_no
                              ? fancy?.s_no
                              : `${fancy?.s_no}/${fancy?.old_s_no}`}
                          </div>
                          <div>{fancy?.team}</div>
                          <div>Rate {fancy?.rate}</div>
                          <div>
                            {moment(fancy?.givenTimeStamp).format("DD-MM-YYYY")}
                          </div>
                          <div>
                            {moment(fancy?.givenTimeStamp).format("hh:mm:ss A")}
                          </div>
                        </div>
                      </div>
                      <div className="flex-column flex-center font-12 w-22">
                        <div> {fancy?.over} Overs</div>
                        <div> {fancy?.innings} Innings</div>
                        <div
                          className={
                            fancy?.yN === "Y"
                              ? "clr-green mt-25"
                              : "clr-pink mt-25"
                          }
                        >
                          <span>{fancy?.runs}</span>
                          <span className="ml-10">{fancy?.yN}</span>
                        </div>
                      </div>
                      <div className="font-12 w-15 text-center mb-40">
                        {fancy?.client_name}
                      </div>
                      <div className="flex-column flex-center w-20">
                        <div className="font-12 yellow-clr text-center mt-5">
                          {fancy?.amount}
                        </div>
                        {/* {fancy?.record_status === "active" && (
                          <div className="flex-space-between mt-20">
                            <MdModeEditOutline
                              className="edit-delete-btn green-clr mt-5"
                              onClick={() => handleOpenEditPopup(fancy)}
                            />
                            <MdDeleteOutline
                              className="edit-delete-btn red-clr mt-5"
                              onClick={() =>
                                handleOpenDeletePopup(fancy?.fancy_entry_id)
                              }
                            />
                          </div>
                        )} */}

                        {fancy?.record_status === "active" && (
                          <>
                        {profitLossDataInnings != null && profitLossDataInnings != undefined && checkOverExists(fancy?.innings, fancy?.over) ?
                          (
                            <div className="font-12 yellow-clr text-center mt-5">
                              Declared
                            </div>
                          ) : (
                            <>
                              <div className="flex-space-between mt-20">
                            <MdModeEditOutline
                              className="edit-delete-btn green-clr mt-5"
                              onClick={() => handleOpenEditPopup(fancy)}
                            />
                            <MdDeleteOutline
                              className="edit-delete-btn red-clr mt-5"
                              onClick={() =>
                                handleOpenDeletePopup(fancy?.fancy_entry_id)
                              }
                            />
                          </div>
                            
                            </>
                          )
                        
                        }
                        </>
                        )}

                        {fancy?.record_status === "updated" && (
                                <div className="flex-space-between mt-20">
                                  <FaMinus className="edit-delete-btn yellow-clr mt-5" />
                                  <FaMinus className="edit-delete-btn yellow-clr mt-5" />
                                </div>
                              )}
                              {fancy?.record_status === "deleted" && (
                                <div className="flex-space-between mt-20">
                                  <FaMinus className="edit-delete-btn yellow-clr mt-5" />
                                  <FaMinus className="edit-delete-btn yellow-clr mt-5" />
                                </div>
                              )}
                        
                      </div>
                    </div>
                    <div className="hr-line mt-5"></div>
                  </div>
                );
              })}
        </div>
      </div>
      <FancyEditPopup
        openPopup={editPopup}
        setclosePopup={setEditPopup}
        displayData="Are You Sure You Want To Edit This Entry"
        data={matchEntryData}
        setSelectedMatchEntry={setSelectedMatchEntry}
      />
      <FancyDeletePopup
        openPopup={deletePopup}
        setclosePopup={setDeletPopup}
        displayData="Are You Sure You Want To Delete This Entry"
        deletedId={selectedId}
        handleDeleteFancy={handleDeleteFancy}
        setSelectedId={setSelectedId}
      />
    </div>
  );
}

export default FancyBetHistory;