import { useState } from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import MatchEntryInputs from "./MatchEntryInputs";
import MatchResultInputs from "./MatchResultInputs";
import { useHistory } from "react-router";
function FancyHeader({
  selectedMatch,
  matchAccountData,
  selectedMatchEntry,
  setStatus,
  getMatchPositionData,
  setAfterDeclare,
}) {
  const history = useHistory();
  const [matchEntryInputs, setMatchEntryInputs] = useState(true);
  const [matchResultInputs, setMatchResultInputs] = useState(false);
  const handleMatchEntryOpen = () => {
    setMatchEntryInputs(true);
    setMatchResultInputs(false);
  };
  const handleMatchResultOpen = () => {
    setMatchResultInputs(true);
    setMatchEntryInputs(false);
  };

  return (
    <div className="match-scroll-div fancy-header-div mt-10">
      <div className="flex-space-between mb-10">
        <div className="w-70 fancy-meetings-button custom-box-shadow flex-center flex-column">
          <div>
            {selectedMatch
              ? `${selectedMatch?.date} - ${selectedMatch?.time}`
              : "Date & Time"}
          </div>
          <div>
            {selectedMatch?.series_name || "Series Name"} -
            {selectedMatch?.gender || "Gender"}
          </div>
        </div>
        <div className="clr-green fancy-meetings-button custom-box-shadow flex-center w-25">
          {selectedMatch?.match_name || "Match Name"}
        </div>
      </div>
      <hr className="horizantal-line" />
      <div className="flex-space-between mt-10 mb-10">
        <div className="w-60 flex-space-between">
          <div
            onClick={() => handleMatchEntryOpen()}
            className={`${
              !matchEntryInputs
                ? "fancy-button custom-box-shadow flex-center"
                : "active-fancy-result-btn flex-center"
            }`}
          >
            Match Entry
          </div>

          <div
            onClick={() => handleMatchResultOpen()}
            className={`${
              !matchResultInputs
                ? "fancy-button custom-box-shadow flex-center"
                : "active-fancy-result-btn flex-center"
            }`}
          >
            Match Result
          </div>
        </div>
        {selectedMatch?.game_object?.match_type !== "testMatch" && <div
          className="fancy-button custom-box-shadow flex-center w-100px"
          onClick={() => history.push("/fancyentry")}
        >
          Fancy Entry
          <BiRightArrowCircle className="right-arrow-icon ml-5" />
        </div>}
      </div>
      {matchEntryInputs && (
        <MatchEntryInputs
          selectedMatch={selectedMatch}
          registered_match_id={matchAccountData?.registered_match_id}
          selectedMatchEntry={selectedMatchEntry}
          setStatus={setStatus}
          getMatchPositionData={getMatchPositionData}
        />
      )}
      {matchResultInputs && (
        <MatchResultInputs
          registered_match_id={matchAccountData?.registered_match_id}
          selectedMatch={selectedMatch}
          selectedMatchEntry={selectedMatchEntry}
          setAfterDeclare={setAfterDeclare}
        />
      )}
    </div>
  );
}
export default FancyHeader;
