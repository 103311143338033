import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Images } from "../../images";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineClockCircle, AiOutlineDown } from "react-icons/ai";
import { PiPencilLine } from "react-icons/pi";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import { call } from "../../config/axios";
import { CREATE_OFFLINE_MATCH, UPDATE_MATCH } from "../../config/endpoints";
import moment from "moment";

function MatchCreation({
  setStatus,
  matchDetails,
  matchEditStatus,
  setMatchEditStatus,
  setMatchEditSuccess,
  matchEditSuccess,
}) {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [data, setData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [firstInnings, setFirstInnigs] = useState("");
  const [secondInnings, setSecondInnings] = useState("");
  const [matchCreationSubmitPopup, setMatchCreationSubmitPopup] =
    useState(false);
  const [matchtypeDropdown, setMatchtypeDropdown] = useState(false);
  const [matchTypeSelect, setMatchTypeSelect] = useState("");
  const [sportsNameSelected, setSportsNameSelected] = useState("");
  const [showSelelctDropdown, setShowSelelctDropdown] = useState(false);
  const [gender, setGender] = useState("Gender");
  const [showGenderDropdown, setShowGenderDropdown] = useState(false);

  const matchVenue = [
    {
      place: "Enter Series Name",
      iocn: Images.Stadium,
      size: "12",
      name: "series_name",
      id: "series_name",
      value: data?.sport_name || "",
    },
    {
      place: "Match Place",
      iocn: Images.MatchPlace,
      size: "6",
      name: "match_place",
      id: "match_place",
      value: data?.match_place || "",
    },
    {
      place: "Stadium",
      iocn: Images.Stadium,
      size: "6",
      name: "stadium",
      id: "stadium",
      value: data?.stadium || "",
    },
  ];
  const teamSelect = ["team1", "team2"];
  const sportNameList = ["Cricket", "Football", "Tennis", "Kabaddi"];
  const matchType = [
    { name: "T10", first: [5, 10], second: [5, 10] },
    { name: "T20", first: [6, 10, 15, 20], second: [6, 10, 15, 20] },
    {
      name: "ODI",
      first: [10, 20, 30, 40, 50],
      second: [10, 20, 30, 40, 50],
    },
    { name: "testMatch", first: ["No Fancy"], second: ["No Fancy"] },
  ];

  const handleMatchType = () => {
    setMatchtypeDropdown((prev) => !prev);
  };
  const handleMatchTypeSelect = (value) => {
    setMatchTypeSelect(value);
    setMatchtypeDropdown(false);
  };

  const handleSelectedSportName = (value) => {
    setSportsNameSelected(value);
    setShowSelelctDropdown(false);
  };

  const handleSelectDropdown = () => {
    setShowSelelctDropdown((prev) => !prev);
  };

  const handleGender = (item) => {
    setGender(item);
    setShowGenderDropdown(false);
  };
  const handleSelectGender = () => {
    setShowGenderDropdown((prev) => !prev);
  };

  const handleResetFields = () => {
    setData({});
    setSportsNameSelected("");
    setMatchTypeSelect("");
    setFirstInnigs("");
    setSecondInnings("");
  };

  const handleUpdateMatch = async () => {
    if (
      !data?.team1 ||
      !data?.team2 ||
      !sportsNameSelected ||
      !gender ||
      !matchTypeSelect?.name ||
      !matchTypeSelect?.first ||
      !matchTypeSelect?.second ||
      !data?.series_name ||
      !data?.match_place ||
      !data?.stadium ||
      !data?.time ||
      !data?.date
    ) {
      return setError("Please enter required fields");
    }
    setIsProcessing(true);
    setError("");
    await call(UPDATE_MATCH, {
      register_id,
      account_role,
      match_id: matchDetails?.match_id,
      team1: data?.team1,
      team2: data?.team2,
      match_name: data?.match,
      series_name: data?.series_name,
      match_place: data?.match_place,
      gender: gender === "Female" ? "F" : "M",
      stadium: data?.stadium,
      date: moment(data?.date).format("DD-MM-YYYY"),
      time: data?.time,
      sport_name: sportsNameSelected,
      game_object: {
        first_innings_fancy_overs: firstInnings?.length
          ? firstInnings?.split(",")
          : matchTypeSelect?.first,
        second_innings_fancy_overs: secondInnings?.length
          ? secondInnings?.split(",")
          : matchTypeSelect?.second,
        match_type: matchTypeSelect?.name,
      },
    })
      .then((res) => {
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          setStatus((prev) => !prev);
          setMatchCreationSubmitPopup(true);
          setMatchEditStatus(false);
          setTimeout(() => {
            setMatchCreationSubmitPopup(false);
            setMatchEditSuccess(false);
          }, 1000);
          handleResetFields();
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(err?.message ? err.message : `something wen't wrong`);
        console.log(err);
      });
  };

  const handleMacthSubmit = async () => {
    if (
      !data?.team1 ||
      !data?.team2 ||
      !sportsNameSelected ||
      !gender ||
      !matchTypeSelect?.name ||
      !matchTypeSelect?.first ||
      !matchTypeSelect?.second ||
      !data?.series_name ||
      !data?.match_place ||
      !data?.stadium ||
      !data?.time ||
      !data?.date
    ) {
      console.log("Validation Check Failed", {
          team1: data?.team1,
          team2: data?.team2,
          sportsNameSelected,
          gender,
          matchTypeSelect,
          series_name: data?.series_name,
          match_place: data?.match_place,
          stadium: data?.stadium,
          time: data?.time,
          date: data?.date
        });
      return setError("Please enter required fields");
    }
    setIsProcessing(true);
    setError("");
    await call(CREATE_OFFLINE_MATCH, {
      register_id,
      account_role,
      match_id: matchDetails?.match_id,
      series_name: data?.series_name,
      match_place: data?.match_place,
      stadium: data?.stadium,
      time: data?.time,
      date: data?.date,
      // date: moment(data?.date).format("DD-MM-YYYY"),
      team1: data?.team1,
      team2: data?.team2,
      gender: gender === "Female" ? "F" : "M",
      sport_name: sportsNameSelected,
      game_object: {
        first_innings_fancy_overs: firstInnings?.length
          ? firstInnings?.split(",")
          : matchTypeSelect?.first,
        second_innings_fancy_overs: secondInnings?.length
          ? secondInnings?.split(",")
          : matchTypeSelect?.second,
        match_type: matchTypeSelect?.name,
      },
    })
      .then((res) => {
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          setStatus((prev) => !prev);
          setMatchCreationSubmitPopup(true);
          setTimeout(() => {
            setMatchCreationSubmitPopup(false);
          }, 1000);
          handleResetFields();
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(err?.message ? err.message : `something wen't wrong`);
        console.log(err);
      });
  };
  const handleChange = (e) => {
    setError("");
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getFancyOvers = (i) => {
    return matchTypeSelect[i];
  };

  useEffect(() => {
    if (matchEditStatus) {
      setData({ ...data, ...matchDetails });
      setMatchTypeSelect({
        name: matchDetails?.game_object?.match_type,
        first: matchDetails?.game_object?.first_innings_fancy_overs || [],
        second: matchDetails?.game_object?.second_innings_fancy_overs || [],
      });
      setSportsNameSelected(matchDetails?.sport_name);
      handleMatchTypeSelect({
        name: matchDetails?.game_object?.match_type,
        first: matchDetails?.game_object?.first_innings_fancy_overs || [],
        second: matchDetails?.game_object?.second_innings_fancy_overs || [],
      });
      setGender(matchDetails?.gender === "F" ? "Female" : "Male");
    }
  }, [matchEditStatus]);

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        <IonCol size="12" className="flex-space-between">
          <div className="connect-device-header custom-box-shadow">
            Match Management/Creation
          </div>
        </IonCol>
        <div className="w-100 upcoming-table flex-space-between">
          <IonCol size="4">
            <div className="upcoming-table-heading flex-center custom-box-shadow">
              Series Name
            </div>
          </IonCol>
          <IonCol size="3.3">
            <div
              className="upcoming-table-heading flex-center custom-box-shadow"
              onClick={() => handleSelectGender()}
            >
              {gender}
              <AiOutlineDown className="ml-10" />
            </div>
            {showGenderDropdown && (
              <div className="upcoming-dropdown w-100">
                <div
                  className="upcoming-table-heading flex-center custom-box-shadow w-100  m-5"
                  onClick={() => handleGender("Male")}
                >
                  Male
                </div>
                <div
                  className="upcoming-table-heading flex-center custom-box-shadow w-100 m-5"
                  onClick={() => handleGender("Female")}
                >
                  Female
                </div>
              </div>
            )}
          </IonCol>
        </div>
        {matchVenue?.map((macth, index) => {
          return (
            <IonCol key={index} size={macth.size}>
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  placeholder={macth?.place}
                  name={macth?.name}
                  value={data[macth.name] || ""}
                  onChange={(e) => handleChange(e)}
                ></input>
                {index !== 0 ? (
                  <img
                    className="play-delete-icon"
                    src={macth.iocn}
                    alt="Play"
                  />
                ) : (
                  ""
                )}
              </div>
            </IonCol>
          );
        })}
        <IonCol size="12">
          <div
            className="series-input flex-space-between custom-box-shadow"
            onClick={() => handleSelectDropdown()}
          >
            <div>{sportsNameSelected ? sportsNameSelected : "Sports Name"}</div>
            <RiArrowDropDownLine className="dropdown-icon" />
          </div>
          {showSelelctDropdown && (
            <div className="select-team-dropdown w-50 position-right font-12">
              {sportNameList.map((value, index) => (
                <div
                  key={index}
                  className="h-40 flex-center mb-5"
                  onClick={() => handleSelectedSportName(value)}
                >
                  {value}
                </div>
              ))}
            </div>
          )}
        </IonCol>
        {teamSelect.map((item, index) => {
          return (
            <IonCol key={index} size="6" className="team-dropdown-col">
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-80 match-input"
                  placeholder="Enter Team Name"
                  name={item}
                  value={data[item] || ""}
                  onChange={(e) => handleChange(e)}
                ></input>
              </div>
            </IonCol>
          );
        })}
        <IonCol size="5">
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-100 match-input"
              placeholder="Date"
              type="date"
              name="date"
              value={data["date"] || ""}
              onChange={(e) => handleChange(e)}
            />
            <FaRegCalendarAlt className="clock-icon" />
          </div>
        </IonCol>
        <IonCol size="4">
          <div className="series-input flex-center custom-box-shadow">
            <input
              id="appt-time"
              type="time"
              className="flex-center w-80 match-input margin-negitive-15px"
              placeholder="Time"
              name="time"
              value={data["time"] || ""}
              onChange={(e) => handleChange(e)}
            />
            <AiOutlineClockCircle className="clock-icon" />
          </div>
        </IonCol>
        <IonCol size="3" className="h-50">
          <div className="series-input flex-center custom-box-shadow">
            <div className="flex-center w-80 match-input">
              {matchTypeSelect?.name || "Match type"}
            </div>
            <RiArrowDropDownLine
              className="dropdown-icon"
              onClick={() => handleMatchType()}
            />
          </div>
          {matchtypeDropdown && (
            <div className="select-team-dropdown">
              {matchType?.map((value, index) => (
                <div
                  key={index}
                  className="h-40 flex-center mb-5 font-12"
                  onClick={() => handleMatchTypeSelect(value)}
                >
                  {value?.name}
                </div>
              ))}
            </div>
          )}
        </IonCol>
        <IonCol>
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-80 match-input"
              placeholder="1st Inn"
              defaultValue={getFancyOvers("first") || ""}
              onChange={(e) => {
                setFirstInnigs(e.target.value);
              }}
            ></input>
            <PiPencilLine className="clock-icon" />
          </div>
        </IonCol>
        <IonCol>
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-80 match-input"
              placeholder="2nd Inn"
              defaultValue={getFancyOvers("second") || ""}
              onChange={(e) => {
                setSecondInnings(e.target.value);
              }}
            ></input>
            <PiPencilLine className="clock-icon" />
          </div>
        </IonCol>
        <IonCol size="12">
          <button
            type="submit"
            className="submit-button w-100 mb-10"
            onClick={() =>
              matchEditStatus ? handleUpdateMatch() : handleMacthSubmit()
            }
            disabled={isProcessing}
          >
            {isProcessing
              ? "Processing..."
              : matchEditStatus
              ? "Update"
              : "Submit"}
          </button>
          {error && (
            <IonText color="danger" className="ion-text-center">
              {error}
            </IonText>
          )}
        </IonCol>
      </IonRow>
      <FancyThankYouPopup
        openPopup={matchCreationSubmitPopup}
        setclosePopup={setMatchCreationSubmitPopup}
        setMatchEditSuccess={setMatchEditSuccess}
        displayData={`match ${
          matchEditSuccess ? "updated" : "created"
        } successfully`}
      />
    </IonGrid>
  );
}

export default MatchCreation;
