import Table from "../../common/Table";
import PaymentSettlment from "./PaymentSettlement";
import { useState } from "react";
import { Images } from "../../images";
import SettledPayment from "./SettledPayment";
import SuccessfulSubmit from "./SuccessfulSubmit";
import "./style.css";
import { call } from "../../config/axios";
import { OFFLINE_PAYMENT_SETTLEMENT } from "../../config/endpoints";
import moment from "moment";

const SettlementData = (props) => {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [error, setError] = useState("");
  const [amountToBeSetteled, setAmountToBeSetteled] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [showPaymentSettlementModal, setShowPaymentSettlementModal] =
    useState(false);
  const [showSettledPaymentModal, setShowSettledPaymentModal] = useState(false);
  const [showSuccesfullPaymentModal, setShowSuccessfullPaymentModal] =
    useState(false);
  const [clientId, setClientId] = useState([]);
  const [offlineSettlePayload, setOfflineSettlePayload] = useState({});

  const openPaymentSettlementModal = (data) => {
    setClientId(data.client_id);
    setShowPaymentSettlementModal(true);
  };

  const openSettledPaymentModal = () => {
    setShowSettledPaymentModal(true);
  };
  const openSuccessfullPaymentModal = () => {
    setShowSuccessfullPaymentModal(true);
  };

  const handleSettlement = async () => {
    setIsProcessing(true);
    await call(OFFLINE_PAYMENT_SETTLEMENT, {
      ...offlineSettlePayload,
      register_id,
      settledDate: moment(new Date()).format("DD-MM-YYYY"),
      settledTime: moment(new Date()).format("hh:mm:ss A"),
    })
      .then((res) => {
        setIsProcessing(false);
        setShowSettledPaymentModal(false);
        if (res?.data?.error === "true") {
          setError(res?.data?.message);
        } else {
          setShowPaymentSettlementModal(false);
          openSuccessfullPaymentModal();
          props.setSettlementStatus((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setIsProcessing(false);
      });
  };

  const columns = [
    {
      header: "client_name",
      name: (
        <div>
          <div>C Name</div>
          <div>Role/position</div>
        </div>
      ),
    },
    { header: "total_amount", name: "Amount" },
    { header: "settled_amount", name: "Credit/Debit" },
    { header: "pending_amount", name: "Balance" },
  ];
  const data = JSON.parse(JSON.stringify(props?.settlementData))?.map((itm) => {
    return {
      ...itm,
      settled_amount: (
        <div className={itm?.total_amount >= 0 ? "red-clr " : "approved-color"}>
          {itm?.total_amount >= 0
            ? itm?.settled_amount
              ? -itm?.settled_amount?.toFixed(2)
              : 0
            : itm?.settled_amount
            ? itm?.settled_amount?.toFixed(2)
            : 0}
        </div>
      ),
      settlement: itm,
      total_amount: (
        <div className={itm?.total_amount >= 0 ? "approved-color" : "red-clr"}>
          {itm?.total_amount ? itm?.total_amount?.toFixed(2) : 0}
        </div>
      ),
      pending_amount: (
        <div
          className={itm?.pending_amount >= 0 ? "approved-color" : "red-clr"}
        >
          {itm?.pending_amount ? itm?.pending_amount?.toFixed(2) : 0}
        </div>
      ),
      client_name: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>{itm.client_name}</div>
          <div className="flex-center">
            <div>Refere -</div>
            <div className="settlement-icon ml-5 flex-center">
              <img
                src={Images.paymentSettlement}
                alt="payment-settlement"
                className="description-icon"
                onClick={() => openPaymentSettlementModal(itm)}
              />
            </div>
          </div>
        </div>
      ),
    };
  });

  const allClientsData =
    props?.settlementData &&
    props?.settlementData?.length > 0 &&
    props?.settlementData?.map((obj) => {
      return {
        client_id: obj?.client_id,
        client_name: obj?.client_name,
        referral_name: obj?.referral_name,
        pending_amount: obj?.pending_amount,
      };
    });

  return (
    <div className="scrolling-settlement">
      <Table columns={columns} data={data} />
      <PaymentSettlment
        showModal={showPaymentSettlementModal}
        setShowModal={setShowPaymentSettlementModal}
        handleOpenNewModal={openSettledPaymentModal}
        clientId={clientId}
        setOfflineSettlePayload={setOfflineSettlePayload}
        offlineSettlePayload={offlineSettlePayload}
        allClientsData={allClientsData}
        setAmountToBeSetteled={setAmountToBeSetteled}
        isProcessing={isProcessing}
        error={error}
        setError={setError}
      />
      {showSettledPaymentModal && (
        <SettledPayment
          showModal={showSettledPaymentModal}
          setShowModal={setShowSettledPaymentModal}
          handleSettlement={handleSettlement}
          error={error}
          heading={`Settled payment ${allClientsData?.[0]?.client_name} are you sure?`}
          btnObj={{
            btn1: {
              name: isProcessing ? "Processing..." : "Yes",
            },
            btn2: {
              name: "No",
            },
          }}
        />
      )}
      <SuccessfulSubmit
        showModal={showSuccesfullPaymentModal}
        setShowModal={setShowSuccessfullPaymentModal}
        setShowSettledPaymentModal={setShowSettledPaymentModal}
      />
    </div>
  );
};
export default SettlementData;
