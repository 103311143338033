import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa";
import { add, remove } from "ionicons/icons";
import { Images } from "../../images";
import { FiMinus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { IoCloudUpload } from "react-icons/io5";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";

const BuyPackage = ({ buyPackage, setBuyPackage }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [proceed, setproceed] = useState(false);
  const [successUpdatePopup, setSuccessUpdatePopup] = useState(false);
  const handleQuantityChange = (delta) => {
    setQuantity((prev) => Math.max(1, prev + delta));
  };

  const handleCloseBuyerPopup = () => {
    setBuyPackage(false);
    setSuccessUpdatePopup(true);
  };
  const days = [
    {
      LiveStream: "Live Stream",
      duration: "30 days",
      img: Images.Coin,
      amount: "Amount",
      amountPrice: 4000,
      discount: "Discoint",
      discountPrice: 1000,
    },
    {
      LiveStream: "Live Stream",
      duration: "90 days",
      img: Images.SilverMedal,
      amount: "Amount",
      amountPrice: 12000,
      discount: "Discoint",
      discountPrice: 1000,
    },
    {
      LiveStream: "Live Stream",
      duration: "180 days",
      img: Images.GoldMedal,
      amount: "Amount",
      amountPrice: 24000,
      discount: "Discoint",
      discountPrice: 1000,
    },
  ];
  const packagesDate = [
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
    {
      live: "Live Stream",
      pkg: "Buying Pkgs x 10",
    },
  ];
  const TotalAmount = [
    {
      live: "Sub total",
      pkg: 120000,
    },
    {
      live: "Total Discount",
      pkg: 30000,
    },
    {
      live: "Total Amount",
      pkg: 90000,
    },
  ];

  return (
    <>
      {proceed === false && (
        <IonModal isOpen={buyPackage} className="buypackage-popup">
          <div onClick={() => setBuyPackage(false)} className="flex-flex-end">
            <IoCloseSharp onClick={() => setproceed(false)}  />
          </div>

          <h5 className="text-center">Buy Packages</h5>
          <div className="text-center">
            Srinivas - <span className="clr-orng">super Admin</span>
          </div>
          <div className="clr-bg action-btn p-10 br-10 mt-10 d-flex flex-space-between">
            Stock <FaAngleDown />
          </div>
          {days.map((item, index) => (
            <div className="buyPackageContainer br-10 mt-10 p-3">
              <IonRow>
                <IonCol size="4" className="clr-green">
                  {item.LiveStream}
                </IonCol>
                <IonCol size="4">{item.amount}</IonCol>
                <IonCol size="4">{item.discount}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">{item.duration}</IonCol>
                <IonCol size="4">{item.amountPrice}</IonCol>
                <IonCol size="4">{item.discountPrice}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4">
                  <img
                    src={item.img}
                    alt="Gold icon"
                    className="packageicon-buy"
                  />
                </IonCol>
                <IonCol size="4"></IonCol>
                <IonCol size="4" className="btn-shadow br-10">
                  <div className="d-flex flex-center flex-space-between">
                    <div
                      fill="clear"
                      onClick={() => handleQuantityChange(-1)}
                      disabled={quantity === 1}
                      className="quantity-btn"
                    >
                      <FiMinus />
                    </div>
                    <span className="quantity-value">{quantity}</span>
                    <div
                      fill="clear"
                      onClick={() => handleQuantityChange(1)}
                      className="quantity-btn"
                    >
                      <IoMdAdd />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </div>
          ))}

          <IonRow className="m-5">
            <IonCol size="4">Total Amount</IonCol>
            <IonCol size="2"></IonCol>
            <IonCol
              size="6"
              className="proceed-btn br-10 text-center clr-green"
              onClick={() => setproceed(true)}
            >
              Proceed
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">6000</IonCol>
          </IonRow>
        </IonModal>
      )}
      {proceed === true && (
        <IonModal isOpen={buyPackage} className="buypackage-popup">
          <div onClick={() => setBuyPackage(false)} className="flex-flex-end">
            <IoCloseSharp />
          </div>

          <h5 className="text-center">Package Details</h5>
          <div className="buyPackageContainer br-10 p-5">
            {packagesDate.map((item, index) => (
              <div className="flex-space-between mt-10 white-border">
                <div>
                  <span className="clr-green">{item.live}</span> - 30 Days
                </div>
                <div>{item.pkg}</div>
              </div>
            ))}
          </div>
          <div className="buyPackageContainer br-10 mt-10  p-5">
            {TotalAmount.map((item, index) => (
              <div className="flex-space-between mt-10 ">
                <div>
                  <span className="clr-green">{item.live}</span> - 30 Days
                </div>
                <div>{item.pkg}</div>
              </div>
            ))}
          </div>
          <div className="payment-gateway mt-10 br-10 flex-center clr-green">
            Select Payment Gateway
          </div>
          <div className="payment-gateway mt-10 br-10 flex-space-between p-5 ">
            NEFT / RTGS <FaChevronDown />
          </div>
          <div className="payment-gateway mt-5  br-10  p-3 ">
           
            <IonRow>
              <IonCol size="6" className="clr-white" >
                Name:Jayantha
              </IonCol>
              <IonCol size="6">Ifsc:SBIN000111</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6" >
                A/C No:12345678
              </IonCol>
              <IonCol size="6">Bank:SBI</IonCol>
            </IonRow>
          </div>
          <div className="payment-gateway mt-10 br-10 flex-space-between p-5 ">
            Upload Screenshot <IoCloudUpload  className="pink-blue-tex action-btn-shadow"/>
          </div>
          <div className="payment-gateway mt-10 br-10 flex-center clr-green " onClick={handleCloseBuyerPopup}>
            Pay
          </div>
        </IonModal>
      )}
       <FancyThankYouPopup
        openPopup={successUpdatePopup}
        setclosePopup={setSuccessUpdatePopup}
        displayData={"Purchased of Your Package Succesfully"}
      />
    </>
    
  );
};

export default BuyPackage;
