import { IonCol, IonGrid, IonRow, useIonToast } from "@ionic/react";
import { MdContentCopy } from "react-icons/md";
import { Images } from "../../images";
import "./styles.css";

function AdminScoreBoard() {
  const [present] = useIonToast();
  const presentToast = ({ message, color = "success" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };
  const handleCopyWebsiteText = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText("https://texchmaster.app/#/");
    presentToast("Copied To Clipboard");
  };
  const OVER_DETAILS = [
    {
      runsForBall: 6,
    },
    {
      runsForBall: 3,
    },
    {
      runsForBall: 2,
    },
    {
      runsForBall: 4,
    },
    {
      runsForBall: 1,
    },
    {
      runsForBall: 6,
    },
  ];
  const BOWLER_STATS = [
    {
      bowlerName: "Mitchell",
      overs: 5,
      maidens: 0,
      runs: 50,
      wickets: 2,
      economy: 5.55,
    },
    {
      bowlerName: "Brett Lee",
      overs: 2,
      maidens: 1,
      runs: 12,
      wickets: 0,
      economy: 6.05,
    },
  ];
  const BATTER_STATS = [
    {
      batterName: "Sachin",
      runs: 100,
      balls: 50,
      fours: 10,
      sixes: 10,
      strikeRate: 200,
    },
    {
      batterName: "Rohit",
      runs: 50,
      balls: 25,
      fours: 5,
      sixes: 5,
      strikeRate: 200,
    },
  ];
  return (
    <div className="" style={{marginTop:"1rem"}}>
    <div className="custom-box-shadow br-10 p-5 cstm-score-calling">
      <IonGrid>
        <IonRow>
          <IonCol size="2">
            <div className="flex-center button-box-shadow br-15 font-14 green-clr fw-600 text-center h-35px">
              <b> Live</b>
            </div>
          </IonCol>
          <IonCol size="2">
            <div className="flex-center custom-box-shadow br-15 font-14 text-center h-35px">
              Info
            </div>
          </IonCol>
          <IonCol size="8">
            <div className="flex-space-between custom-box-shadow br-15 h-35px">
              <IonCol size="3">
                <div className="font-14">Over:5</div>
              </IonCol>
              <IonCol
                size="9"
                className="flex-space-around font-14 overflow-scroll scroll-none"
              >
                {OVER_DETAILS?.map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.runsForBall === 4 || item.runsForBall === 6
                        ? "flex-center button-box-shadow over-runs font-14 green-bg-clr"
                        : "flex-center button-box-shadow over-runs font-14"
                    }
                  >
                    {item.runsForBall}
                  </div>
                ))}
              </IonCol>
            </div>
          </IonCol>
        </IonRow>
        <div className="custom-box-shadow br-15 mt-10 h-15vh">
          <IonRow className="p-3">
            <IonCol size="4">
              <div className="font-14">Bowler</div>
            </IonCol>
            <IonCol size="8">
              <div className="flex-space-between">
                <div className="font-14">O</div>
                <div className="font-14">M</div>
                <div className="font-14">R</div>
                <div className="font-14">W</div>
                <div className="font-14">ECO</div>
              </div>
            </IonCol>
          </IonRow>
          <hr className="horizantal-line" />
          {BOWLER_STATS?.map((item, index) => (
            <IonRow className="p-3" key={index}>
              <IonCol size="4">
                <div className="font-14 align-center">
                  {item.bowlerName}
                  <img
                    className="ml-5"
                    src={Images.CricketBall}
                    alt="Cricket_Ball"
                  />
                </div>
              </IonCol>
              <IonCol size="8">
                <div className="flex-space-between">
                  <div className="font-14">{item.overs}</div>
                  <div className="font-14">{item.maidens}</div>
                  <div className="font-14">{item.runs}</div>
                  <div className="font-14">{item.wickets}</div>
                  <div className="font-14">{item.economy}</div>
                </div>
              </IonCol>
            </IonRow>
          ))}
        </div>
        <div className="custom-box-shadow br-15 mt-10">
          <div className="match-day-text font-14 p-3 w-35 mt-10">
            <b>Match Of The Day</b>
          </div>
          <div>
            <IonRow>
              <IonCol size="2.5">
                <div className="flex-center">
                  <div className="team-logo flex-center">
                    <img
                      className="team-image"
                      src={Images.MIImage}
                      alt="MI_Logo"
                    />
                  </div>
                </div>
              </IonCol>
              <IonCol size="2.5" className="flex">
                <div className="flex-column align-start-jcc font-14 fw-600">
                  <div className="gold-clr">00/00</div>
                  <div>Over 0.0</div>
                </div>
              </IonCol>
              <IonCol size="2" className="flex">
                <b className="flex-center font-25">VS</b>
              </IonCol>
              <IonCol size="2.5" className="flex">
                <div className="flex-column align-end-jcc font-14 fw-600">
                  <div className="gold-clr">185/6</div>
                  <div>Over 20</div>
                </div>
              </IonCol>
              <IonCol size="2.5">
                <div className="flex-center font-14">
                  <div className="team-logo flex-center">
                    <img
                      className="team-image"
                      src={Images.CSKImage}
                      alt="CSK_Logo"
                    />
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="flex-space-between">
              <IonCol size="2.5" className="flex-center">
                <div className="font-14">IND</div>
              </IonCol>
              <IonCol size="2.5" className="flex-center">
                <div className="font-14">PAK</div>
              </IonCol>
            </IonRow>
            <hr className="horizantal-line" />
            <IonRow className="flex-space-between">
              <IonCol size="6" className="align-start-jcc flex-column">
                <div className="font-14 mt-5">CRR: 0.0</div>
                <div className="font-14 mt-5">RRR: 9.3</div>
              </IonCol>
              <IonCol size="6" className="align-end-jcc flex-column">
                <div className="w-80 font-14 mt-5 text-end">
                  CSK need 186 runs in 120 Balls
                </div>
              </IonCol>
            </IonRow>
          </div>
        </div>
        <div className="font-14 p-5">Most Reliable Online ID</div>
        <div className="custom-box-shadow p-5 flex-space-between br-15">
          <div className="gold-clr font-14 fw-600">
            https://texchmaster.app/#/
          </div>
          <div className="font-22" onClick={(e) => handleCopyWebsiteText(e)}>
            <MdContentCopy className="flex" />
          </div>
        </div>
        <div className="custom-box-shadow br-15 p-5 mt-10">
          <IonRow>
            <IonCol size="6">
              <div className="w-90 flex-center flex-column">
                <div className="w-100 flex-space-between">
                  <div className="flex-center w-45 green-bg-clr font-14 p-5 br-15 btn-border">
                    100
                  </div>
                  <div className="flex-center w-45 red-bg-clr font-14 p-5 br-15 btn-border">
                    100
                  </div>
                </div>
                <div className="font-14 mt-5">Dono (Both)</div>
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="flex-center">
                <div className="w-65 flex-center six-box-shadow br-15 p-5 font-18 fw-600">
                  SIX
                </div>
                <div className="flex-center six-number">6</div>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <div className="custom-box-shadow flex-center flex-column br-15 p-10 mt-5">
          <div className="red-bg-clr w-100 flex-space-between br-15 p-5 font-14">
            <div className="w-30 flex-space-between">
              <div>50</div>
              <div>NO</div>
            </div>
            <div className="w-30 flex-space-between">
              <div>BALLS</div>
              <div>50</div>
            </div>
          </div>
          <div className="green-bg-clr w-100 flex-space-between br-15 p-5 mt-5 font-14">
            <div className="w-30 flex-space-between">
              <div>50</div>
              <div>YES</div>
            </div>
            <div className="w-30 flex-space-between">
              <div>RUNS</div>
              <div>50</div>
            </div>
          </div>
          <div className="flex-center flex-column six-over-number">
            <div>06</div>
            <div className="font-14 fw-600">Over</div>
          </div>
        </div>
        <div className="custom-box-shadow br-15 mt-10 h-15vh">
          <IonRow className="p-3">
            <IonCol size="4">
              <div className="font-14">Batsman</div>
            </IonCol>
            <IonCol size="8">
              <div className="flex-space-between">
                <div className="font-14">R</div>
                <div className="font-14">B</div>
                <div className="font-14">4S</div>
                <div className="font-14">6S</div>
                <div className="font-14">SR</div>
              </div>
            </IonCol>
          </IonRow>
          <hr className="horizantal-line" />
          {BATTER_STATS?.map((item, index) => (
            <IonRow className="p-3" key={index}>
              <IonCol size="4">
                <div className="font-14 align-center">
                  {item.batterName}
                  <img
                    className="ml-5"
                    src={Images.CricketBat}
                    alt="Cricket_Bat"
                  />
                </div>
              </IonCol>
              <IonCol size="8">
                <div className="flex-space-between">
                  <div className="font-14">{item.runs}</div>
                  <div className="font-14">{item.balls}</div>
                  <div className="font-14">{item.fours}</div>
                  <div className="font-14">{item.sixes}</div>
                  <div className="font-14">{item.strikeRate}</div>
                </div>
              </IonCol>
            </IonRow>
          ))}
        </div>
      </IonGrid>
    </div>
    </div>
  );
}

export default AdminScoreBoard;
