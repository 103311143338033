import React from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
function ClientCommRunsPopup(props) {
  const { showClientCommRunsPopup, setShowClientCommRunsPopup } = props;
  const handleClientCommRunsPopupClose = () => {
    setShowClientCommRunsPopup(false);
  };
  const clientCommRunsTable = [
    {
      title: "Runs",
    },
    {
      title: "Client Share",
    },
    {
      title: "Rf Share",
    },
    {
      title: "--",
    },
  ];
  const clientCommRunsData = [
    {
      Runs: "44 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
    {
      Runs: "42 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },

    {
      Runs: "-44 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
    {
      Runs: "-42 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
    {
      Runs: "08 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
    {
      Runs: "05 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
    {
      Runs: "03 Runs",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "--",
    },
  ];
  return (
    <div className="border-colour">
      <IonModal
        className="package-popup-main p-0"
        isOpen={showClientCommRunsPopup}
      >
        
        <div className="flex-center">
          <div className="w-50 p-10 font-14">Client Comm's</div>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={handleClientCommRunsPopupClose}
          >
            <RxCross1 />
          </div>
        </div>
        <div>
          <div className="flex-space-between flex-row cutom-box-shadow table-title-holder font-size-12 w-100 h-30p mb-10">
            {clientCommRunsTable.map((shareData, index) => (
              <div key={index} className="w-30 flex-center">
                <div>{shareData.title}</div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll h-20 ml-10 mr-5">
            {clientCommRunsData.map((sdata, index) => (
              <div key={index} className="flex-row">
                <div className="flex-space-between mt-5 w-100">
                  <div className="meetings-time w-30 flex-center  font-2 padding-tb">
                    {sdata.Runs}
                  </div>
                  <div className="font-size-10 flex-center  w-30 clr-green">
                    {sdata.clientshare}
                  </div>
                  <div className="font-size-10  flex-center w-30 clr-green">
                    {sdata.rfshare}
                  </div>
                  <div className="font-size-10 flex-center clr-green  w-30">
                    {sdata.ulshare}
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="pl-10 pr-10">
          <div className="flex-space-between flex-row total-count-container w-100 h-30p mb-10">
            <div className="font-14 ">Total</div>
            <div className="approved-color">1000000.00</div>
            <div className="approved-color">1000000.00</div>
            <div className="approved-color">500000.00</div>
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default ClientCommRunsPopup;
