import { BsInstagram, BsWhatsapp } from "react-icons/bs";

const ShareButtons = ({ data }) => {
  const handleInstagramShare = () => {
    const instagramShareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
      `${data}`
    )}`;
    window.open(instagramShareUrl, "_blank");
  };

  const handleWhatsAppShare = () => {
    const whatsappShareUrl = `whatsapp://send?text=${encodeURIComponent(
      `${data}`
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  return (
    <div className="w-100 flex-space-between mt-10 mb-10">
      <div className="flex-center w-45 fw-600 custom-box-shadow p-10 br-10 font-14 h-40">
        <BsInstagram
          className="pencil-clr-icon"
          onClick={handleInstagramShare}
        />
      </div>
      <div className="flex-center w-45 fw-600 custom-box-shadow p-10 br-10 font-14 h-40">
        <BsWhatsapp className="green-clr" onClick={handleWhatsAppShare} />
      </div>
    </div>
  );
};

export default ShareButtons;
