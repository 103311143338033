import { useEffect, useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { FaCalendarDays } from "react-icons/fa6";
import { GoArrowSwitch } from "react-icons/go";
import { BsTrophy } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { IonImg } from "@ionic/react";
import MatchFinacialStatement from "./MatchFinacialStatement";
import { useHistory } from "react-router";
import EditPopup from "../popups/EditPopup";
import {
  GET_FINANCIAL_STATEMENT_BY_DATE,
  GET_OFFLINE_CLIENTS,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";

function AdminStatement() {
  const history = useHistory();
  const [existingUsers, setExistingUsers] = useState([]);
  const [financialStatementData, setFinancialStatementData] = useState([]);
  const [statementPayload, setStatementPayload] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const statementList = ["Statement"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMatchfinance, setShowMatchfinance] = useState(true);
  const handleStatementSelect = (value) => {
    setActiveIndex(value);
  };
  const [showStatementData, SetShowStatementData] = useState(false);

  const listOfMatches = ["demo", "demo", "demo", "demo"];
  const listOfFancys = ["demo", "demo", "demo", "demo"];

  const [matchListDropdown, setMatchListDropdown] = useState(false);
  const [showmatchListDropdown, setShowMatchListDropdown] = useState(false);
  const [showClientDropDown, setShowClientDropDown] = useState(false);
  const [selectedMatchName, setSelectedMatchName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const handleMatchesListDropdown = () => {
    setShowMatchListDropdown((prev) => !prev);
  };
  const handleMatchesListSelect = (value) => {
    setSelectedMatchName(value);
    setStatementPayload({ ...statementPayload, match_name: value });
    // setMatchListDropdown(value);
    setShowMatchListDropdown(false);
  };
  const [fancyListDropdown, setFancyListDropdown] = useState(false);
  const [showFancyListDropdown, setShowFancyListDropdown] = useState("");

  const handleFancyssListDropdown = () => {
    setShowFancyListDropdown((prev) => !prev);
  };
  const handleFancysListSelect = (value) => {
    setFancyListDropdown(value);
    setShowFancyListDropdown(false);
  };
  const [editPopup, setEditPopup] = useState(false);
  const handleOpenEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const tableRowData = [
    {
      title: "Series",
    },
    {
      title: "Team Name",
    },
    {
      title: "Win Team",
    },
    {
      title: "P/L",
    },
  ];

  const handleClientDropDown = () => {
    setShowClientDropDown((prev) => !prev);
  };

  const handleChange = (e) => {
    setStatementPayload({
      ...statementPayload,
      [e.target.name]: e.target.value,
    });
  };
  const getStatementData = async () => {
    setIsProcessing(true);
    await call(GET_FINANCIAL_STATEMENT_BY_DATE, {
      register_id,
      account_role,
      ...statementPayload,
    })
      .then((res) => {
        setIsProcessing(false);
        setFinancialStatementData(
          res?.data?.data?.filter((item) => item?.match_declared === "Y")
        );
      })
      .catch((err) => {
        setIsProcessing(false);
        throw err;
      });
  };

  const getAllClientsData = async () => {
    call(GET_OFFLINE_CLIENTS, {
      register_id,
      account_role,
    })
      .then((res) => {
        setExistingUsers(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  let totalMatchResultData = 0;

  const statementData =
    financialStatementData &&
    financialStatementData?.length > 0 &&
    financialStatementData?.map((match) => {
      totalMatchResultData = financialStatementData.reduce(
        (acc, obj) => acc + (obj.totalAmount?.totalLossOrProfit || 0),
        0
      );

      return {
        series: (
          <div>
            {match?.series_name}
            <br />
            {match?.stadium}
            <br />
            {match?.match_place}
            <br />
            {moment(match?.matchTimeStamp).format("DD-MM-YYYY")}
            <br />
            <span> {moment(match?.matchTimeStamp).format("hh:mm:ss A")}</span>
          </div>
        ),
        teamName: match?.match_name,
        winTeam: match?.winTeam,
        porL: match?.totalAmount?.totalLossOrProfit,
        matchId: match?.registered_match_id,
        match_name: match?.match_name,
        matchTimeStamp: match?.matchTimeStamp,
      };
    });

  useEffect(() => {
    getAllClientsData();
    getStatementData();
  }, []);

  return (
    <div>
      <MeetingsHeader heading={"Admin Statement"} />
      <div className="main-container position-relative h-85vh pb-10">
        <div className="custom-box-shadow  meetings-details-div  p-10 mt-20">
          <div className="flex-row flex-start mt-5 mb-5">
            {statementList.map((value, index) => {
              return (
                <div
                  key={index}
                  className={
                    activeIndex === index
                      ? "custom-meetings-button custom-box-shadow approved-color font-size-14 flex-center mr-10 ml-10 fw-600 mb-10"
                      : "custom-meeetings-inactive-button custom-box-shadow font-size-10  flex-center ml-10 mr-10 fw-600 mb-10"
                  }
                  onClick={() => handleStatementSelect(index)}
                >
                  {value}
                </div>
              );
            })}
          </div>
          <div className="hr-line mt-5"></div>

          <div className="flex-row flex-space-between relative-position mt-10 w-100">
            <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow h-30p p-20 mt-5">
              <input
                required
                type="date"
                className="flex-center w-80 match-input"
                name="startDate"
                id="startDate"
                value={statementPayload["startDate"] || ""}
                onChange={(e) => handleChange(e)}
              />
              <FaCalendarDays className="font-size-20 pink-clr" />
            </div>
            <GoArrowSwitch className="double-arrow-icon-match" />
            <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow  h-30p p-20 mt-5">
              <input
                required
                type="date"
                className="flex-center w-80 match-input"
                name="endDate"
                id="endDate"
                value={statementPayload["endDate"] || ""}
                onChange={(e) => handleChange(e)}
              />
              <FaCalendarDays className="font-size-20 pink-clr" />
            </div>
          </div>
          <div>
            <div className="wrap-div">
              <div className="flex-row flex-space-between mt-10 w-100">
                <div className="flex-space-between custom-box-shadow font-12 match-statement-div  p-20">
                  <input
                    required
                    type="text"
                    placeholder="Series Name"
                    className="flex-center w-80 match-input"
                    name="series_name"
                    id="series_name"
                    value={statementPayload["series_name"] || ""}
                    onChange={(e) => handleChange(e)}
                  />
                  <BsTrophy className="font-size-20 pink-clr" />
                </div>

                <div className="flex-column w-vw-40 mt-10">
                  <div
                    className="upgrade-popup-box-container custom-box-shadow font-12 flex-space-between mb-10"
                    onClick={() => handleMatchesListDropdown()}
                  >
                    <div className="pl-10">
                      {selectedMatchName ? selectedMatchName : "Match Name"}
                    </div>
                    {showmatchListDropdown ? (
                      <RiArrowDropUpLine style={{ fontSize: "40px" }} />
                    ) : (
                      <RiArrowDropDownLine style={{ fontSize: "40px" }} />
                    )}
                  </div>
                </div>
              </div>
              {showmatchListDropdown && (
                <div className="select-team-dropdown matches-dropdown w-30-vw font-12">
                  {financialStatementData &&
                    financialStatementData?.length > 0 &&
                    financialStatementData
                      ?.filter((i) => i.match_declared === "Y")
                      ?.map(({ match_name }, index) => {
                        return (
                          <div
                            className="h-30p flex-center mb-5 "
                            key={index}
                            onClick={() => handleMatchesListSelect(match_name)}
                          >
                            {match_name}
                          </div>
                        );
                      })}
                </div>
              )}
            </div>
            {activeIndex === 0 && (
              <div className="wrap-div">
                <div className="flex-row flex-space-between w-100">
                  <div
                    className="upgrade-popup-box-container custom-box-shadow font-12 flex-space-between w-vw-40"
                    onClick={() => handleFancyssListDropdown()}
                  >
                    <div className="pl-10">
                      {fancyListDropdown ? fancyListDropdown : "Fancy"}
                    </div>
                    {showFancyListDropdown ? (
                      <RiArrowDropUpLine style={{ fontSize: "40px" }} />
                    ) : (
                      <RiArrowDropDownLine style={{ fontSize: "40px" }} />
                    )}
                  </div>

                  <div className="flex-column w-vw-40 mt-10">
                    <div
                      className="upgrade-popup-box-container custom-box-shadow font-12 flex-space-between mb-10"
                      onClick={() => handleClientDropDown()}
                    >
                      <div className="pl-10">
                        {statementPayload?.client_name
                          ? statementPayload?.client_name
                          : "Client Name"}
                      </div>
                      {showClientDropDown ? (
                        <RiArrowDropUpLine style={{ fontSize: "40px" }} />
                      ) : (
                        <RiArrowDropDownLine style={{ fontSize: "40px" }} />
                      )}
                    </div>
                  </div>
                </div>
                {showClientDropDown && (
                  <div className="select-team-dropdown matches-dropdown w-30-vw font-12">
                    {existingUsers &&
                      existingUsers?.length > 0 &&
                      existingUsers?.map(
                        ({ client_name, client_id }, index) => {
                          return (
                            <div
                              className="h-30p flex-center mb-5 "
                              key={index}
                              onClick={() => {
                                setStatementPayload({
                                  ...statementPayload,
                                  client_id: client_id,
                                  client_name: client_name,
                                });
                                handleClientDropDown();
                              }}
                            >
                              {client_name} - {account_role}
                            </div>
                          );
                        }
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            className="settelment-button button-box-shadow w-100 font-12 mt-20 mb-10"
            disabled={isProcessing}
            onClick={() => {
              getStatementData();
            }}
          >
            {isProcessing ? "Fetching..." : "Verify"}
          </button>
        </div>
        {/* {showMatchfinance ? (
          <MatchFinacialStatement financialStatementData={financialStatementData} />
        ) : ( */}
        <div className="custom-box-shadow  meetings-details-div mt-10">
          <div className="flex-row flex-space-between p-10">
            <div className=" custom-box-shadow upcoming-meetings-div flex-start  font-16 w-60 p-10">
              Statement History
            </div>
            {statementPayload?.client_name && (
              <div className="seeall-button h-30p w-30 p-5 flex-center custom-box-shadow w-25 font-10">
                {statementPayload?.client_name}
              </div>
            )}
          </div>
          <div className="hr-line mt-5" />
          <div className="d-flex ">
            {tableRowData.map((rowData, index) => (
              <div key={index} className="flex-center mt-5 w-100 pr-5 pl-5">
                <div className="match-history-button flex-center custom-box-shadow font-10">
                  {rowData.title}
                </div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll ml-10 mr-5">
            {statementData?.length > 0 &&
              statementData?.map((data, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5 pl-10 pr-10">
                    <div className="meetings-time w-25 font-2 padding-tb">
                      {data.series}
                    </div>
                    <div className="font-12 w-22">{data.teamName}</div>
                    <div className=" w-15 flex-space-between flex-column">
                      <div className="font-12 flex-center">{data.winTeam}</div>
                      <IonImg
                        className="w-40p h-30p flex-center mt-20"
                        src={process.env.PUBLIC_URL + "./assets/click_here.png"}
                        onClick={() =>
                          history.push(
                            `/statement-popup/${data?.matchId}/${data?.match_name}/${data?.matchTimeStamp}/${data?.winTeam}`
                          )
                        }
                      />
                    </div>

                    <div className="flex-column flex-center w-20">
                      <div
                        className={`font-12 ${
                          data.porL >= 0 ? "approved-color" : "red-clr"
                        }`}
                      >
                        {data.porL}
                      </div>
                      <MdModeEditOutline
                        className="edit-history mt-10"
                        onClick={() => handleOpenEditPopup()}
                      />
                    </div>
                  </div>
                  <div className="hr-line mt-5"></div>
                </div>
              ))}
          </div>
          <div className="flex-space-between flex-row total-count-container">
            <div className="font-14">Total</div>
            <div
              className={`font-12 ${
                totalMatchResultData >= 0 ? "approved-color" : "red-clr"
              }`}
            >
              {totalMatchResultData}
            </div>
          </div>
        </div>
      </div>
      <EditPopup
        openPopup={editPopup}
        setclosePopup={setEditPopup}
        displayData={"Are You Sure You Want To Edit This Entry"}
      />
    </div>
  );
}

export default AdminStatement;
