import { IonCol, IonGrid, IonModal, IonRow, IonText } from "@ionic/react";
import { useState } from "react";
import EditUserPopUp from "./EditUserPopUp";
import { call } from "../../config/axios";
import {
  ACTIVE_INACTIVE_OFFLINE_USERS,
  DELETE_OFFLINE_CLIENT,
} from "../../config/endpoints";
import { Images } from "../../images";
import { RxCross1 } from "react-icons/rx";

function ExistingUsers({
  existingClients,
  getClientDetails,
  setEditStatus,
  fetchingStatus,
  setFetchingStatus,
  setEditPopUp,
  editPopUp,
  setAddClientStatus,
}) {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const matchesStatus = [
    {
      heading: "UserName",
    },
    {
      heading: "Share",
    },
    {
      heading: "M+F Com",
    },
    {
      heading: "Role Fancy com",
    },
    {
      heading: "Action",
    },
  ];
  const deleteBlockSelection = ["Active", "Delete", "Block"];
  const [dropdownIndex, setDropdownIndex] = useState("");
  const [showDeleteDropdown, setShowDeleteDropdown] = useState();
  const [deleteOrBlock, setDeleteOrBlock] = useState("Delete");
  const ActionButtons = ["Edit", deleteOrBlock];
  const [selectedClientId, setSelectedClientId] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [block, setBlock] = useState("UB");
  const [err, setErr] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [blockUnBlockPopup, setBlockUnBlockPopup] = useState(false);

  const handleDeletePopupOpen = () => {
    setDeletePopup((prev) => !prev);
  };

  const handleDeleteBlock = () => {
    call(DELETE_OFFLINE_CLIENT, { register_id, client_id: selectedId })
      .then((res) => {
        handleDeletePopupOpen();
        setAddClientStatus((prev) => !prev);
      })
      .catch((err) => {
        setErr("something went`t wrong");
        console.log(err);
      });
  };

  const handleBlockUnBlock = async () => {
    await call(ACTIVE_INACTIVE_OFFLINE_USERS, {
      register_id: selectedUser?.register_id,
      client_id: selectedUser?.client_id,
      active: selectedUser?.active === "false" ? "true" : "false",
    })
      .then((res) => {
        handleBlockUnBlockPopupOpen();
        setAddClientStatus((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        handleBlockUnBlockPopupOpen();
      });

    // if (block === "B") {
    //   setBlock("UB");
    // } else {
    //   setBlock("B");
    // }
  };
  const handleBlockUnBlockPopupOpen = () => {
    setBlockUnBlockPopup(false);
  };

  const DeleteUserPopUp = () => {
    return (
      <IonModal className="statememt-popup-main" isOpen={deletePopup}>
        <center>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={() => handleDeletePopupOpen()}
          >
            <RxCross1 />
          </div>
          <div>
            <img
              className="fancy-declare-image"
              src={Images.ThumbsUpImage}
              alt="Declare_Man_Img"
            />
          </div>
          <div className="w-75 fancy-declare-text mt-10">
            Are sure you want Delete?.
          </div>
          <div className="flex-center w-100">
            <div
              className="mt-15 flex-space-around w-100"
              onClick={() => {
                handleDeleteBlock();
              }}
            >
              <button className="w-80 button-box-shadow fancy-declare-button green-clr">
                YES
              </button>
            </div>
            <div
              className="mt-15 flex-space-around w-100"
              onClick={() => handleDeletePopupOpen()}
            >
              <button className="w-80 button-box-shadow fancy-declare-button red-clr">
                NO
              </button>
            </div>
          </div>
          {err && (
            <IonText color="danger" className="ion-text-center">
              {err}
            </IonText>
          )}
        </center>
      </IonModal>
    );
  };

  const BlockUnBlockUserPopUp = () => {
    return (
      <IonModal className="statememt-popup-main" isOpen={blockUnBlockPopup}>
        <center>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={() => handleBlockUnBlockPopupOpen()}
          >
            <RxCross1 />
          </div>
          <div>
            <img
              className="fancy-declare-image"
              src={Images.ThumbsUpImage}
              alt="Declare_Man_Img"
            />
          </div>
          <div className="w-75 fancy-declare-text mt-10">
            Are sure you want change the status?.
          </div>
          <div className="flex-center w-100">
            <div
              className="mt-15 flex-space-around w-100"
              onClick={() => {
                handleBlockUnBlock();
              }}
            >
              <button className="w-80 button-box-shadow fancy-declare-button green-clr">
                YES
              </button>
            </div>
            <div
              className="mt-15 flex-space-around w-100"
              onClick={() => handleBlockUnBlockPopupOpen()}
            >
              <button className="w-80 button-box-shadow fancy-declare-button red-clr">
                NO
              </button>
            </div>
          </div>
          {err && (
            <IonText color="danger" className="ion-text-center">
              {err}
            </IonText>
          )}
        </center>
      </IonModal>
    );
  };
  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        <IonCol size="12" className="flex-space-between">
          <div className="connect-device-header custom-box-shadow">
            Existing Users
          </div>
        </IonCol>
        <div className="flex-space-between upcoming-table w-100">
          {matchesStatus?.length > 0 &&
            matchesStatus?.map((value, index) => (
              <IonCol key={index}>
                <div className="connect-device-seemore custom-box-shadow">
                  {value?.heading}
                </div>
              </IonCol>
            ))}
        </div>
        <div className="exist-user-container w-100">
          {existingClients?.length > 0 &&
            existingClients?.filter((j) => j.user_status !== "deleted") &&
            existingClients?.map((client, i) => {
              return (
                <div key={i} className="d-flex upcoming-table">
                  <IonCol size="4" className="users-details-list">
                    <div>{client?.client_name}</div>
                    <div>Agent</div>
                    <div>
                      Alias
                      <span className="clr-green">
                        -{client?.alias_name || ""}
                      </span>
                    </div>
                    <div>
                      REf-
                      <span className="clr-green">
                        {client?.referral_name || ""}
                      </span>
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="clr-white">{client?.client_share || 0}</div>
                    <br />
                    <div className="clr-white">
                      {client?.referral_share || 0}
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="clr-white">
                      {client?.match_race_comm || 0}
                    </div>
                    <br />
                    <div className="clr-white">
                      {client?.referral_comm || 0}
                    </div>
                  </IonCol>
                  <IonCol>
                    <div className="clr-white">{client?.fancy_comm || 0}</div>
                    <br />
                    <div className="clr-white">
                      {client?.fancy_refferal_comm || ""}
                    </div>
                  </IonCol>
                  <IonCol class="d-flex" size="3">
                    <div
                      className="table-button clr-green mr-5 p-5"
                      onClick={() => {
                        handleDeletePopupOpen();
                        setSelectedId(client?.client_id);
                      }}
                    >
                      D
                    </div>
                    <div
                      className={`table-button mr-5 p-5 ${
                        client?.active === "false" ? "clr-red" : "clr-green"
                      }`}
                      onClick={() => {
                        setBlockUnBlockPopup(true);
                        setSelectedUser(client);
                      }}
                    >
                      B
                    </div>
                    <div
                      className="table-button clr-blue p-5"
                      onClick={() => {
                        setEditPopUp(true);
                        setSelectedClientId(client?.client_id);
                      }}
                    >
                      E
                    </div>
                  </IonCol>
                </div>
              );
            })}
        </div>
      </IonRow>
      <EditUserPopUp
        getClientDetails={getClientDetails}
        setEditStatus={setEditStatus}
        selectedClientId={selectedClientId}
        fetchingStatus={fetchingStatus}
        setFetchingStatus={setFetchingStatus}
        editPopUp={editPopUp}
        setEditPopUp={setEditPopUp}
      />
      <DeleteUserPopUp />
      {blockUnBlockPopup && <BlockUnBlockUserPopUp />}
    </IonGrid>
  );
}

export default ExistingUsers;
