import React, { useState } from "react";
import { BsArrowDown } from "react-icons/bs";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import ClientShareRunsPopup from "./ClientShareRunsPopup";
import ClientCommRunsPopup from "./ClientCommRunsPopup";

function RiskRunningPL() {
  const tableMatchRowData = [
    {
      title: (
        <div className="flex-column flex-center">
          <div>Runs</div>
          <div>Amount</div>
        </div>
      ),
    },
    {
      title: "C - Position",
    },
    {
      title: "Rf - Position",
    },
    {
      title: "Urs Position",
    },
  ];
  const matchDetails = [
    {
      run: "-44 Runs",
      amount: "10000000.00",
      cnet: "10000000.00",
      rfnet: "10000000.00",
      pl: "50000000.00",
    },
    {
      run: "-43 Runs",
      amount: "10000000.00",
      cnet: "10000000.00",
      rfnet: "10000000.00",
      pl: "50000000.00",
    },
    {
      run: "42 Runs",
      amount: "10000000.00",
      cnet: "10000000.00",
      rfnet: "10000000.00",
      pl: "50000000.00",
    },
    {
      run: "41 Runs",
      amount: "10000000.00",
      cnet: "10000000.00",
      rfnet: "10000000.00",
      pl: "50000000.00",
    },
    {
      run: "40 Runs",
      amount: "10000000.00",
      cnet: "10000000.00",
      rfnet: "10000000.00",
      pl: "50000000.00",
    },
  ];
  const [openCommModal, setOpenCommModal] = useState(false);
  const handleCommsList = () => {
    setOpenCommModal((prev) => !prev);
  };
  const [openShareModal, setOpenShareModal] = useState(false);
  const handleSharesList = () => {
    setOpenShareModal((prev) => !prev);
  };
  return (
    <div className="mt-10 relative-position">
      <div className="custom-box-shadow m-none overflow-none meetings-details-div">
        <div className="flex-row flex-space-between p-10">
          <div className=" custom-box-shadow upcoming-meetings-div flex-start font-14 w-50 p-5">
            Risk Running Position P/L
          </div>
          <div
            className="seeall-button h-30p w-20 p-5  flex-center custom-box-shadow font-10"
            onClick={() => handleSharesList()}
          >
            Comm's
            <RiArrowDropDownLine style={{ fontSize: "20px" }} />
          </div>

          <div
            className="seeall-button h-30p w-20 p-5 flex-center custom-box-shadow font-10"
            onClick={() => handleCommsList()}
          >
            Shares
            <RiArrowDropDownLine style={{ fontSize: "20px" }} />
          </div>
        </div>

        <div className="hr-line mt-5" />
        <div className="d-flex">
          {tableMatchRowData.map((matchData, index) => (
            <div key={index} className="flex-center mt-5 pr-5 pl-5 w-25 br-10">
              <div className="match-history-button flex-center custom-box-shadow font-10">
                {matchData.title}
              </div>
            </div>
          ))}
        </div>
        <div className="hr-line mt-10 "></div>
        <div className="upcoming-meet-scroll h-25vh ml-10 mr-5">
          {matchDetails.map((fancydata, index) => (
            <div key={index} className="flex-row">
              <div className="flex-space-between pl-10 pr-10 w-100  align-end">
                <div className="meetings-time padding-tb">
                  <div className="flex-column flex-space-between ">
                    <div className="font-10 align-baseline">
                      {fancydata.run}
                    </div>
                    <div className="font-size-10 clr-green">
                      {fancydata.amount}
                    </div>
                  </div>
                </div>
                <div className="font-size-10 clr-green flex-center">
                  {fancydata.cnet}
                </div>
                <div className="font-size-10 clr-green flex-center">
                  {fancydata.rfnet}
                </div>
                <div className="font-size-10 flex-center clr-green">
                  {fancydata.pl}
                </div>
              </div>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
        </div>
        <div className="d-flex flex-row p-5">
          <div className="flex-space-between flex-row total-count-container h-7 br-10 w-75 mr-5 align-center">
            <div className="flex-column">
              <div className="font-14">Total</div>
              <div className="approved-color">10000000.00</div>
            </div>
            <div className="approved-color">10000000.00</div>
            <div className="approved-color">10000000.00</div>
          </div>
          <div className="flex-space-between flex-row total-count-container h-7 br-10 w-25">
            <div className="approved-color">50000000.00</div>
          </div>
        </div>
        <div className="d-flex flex-row flex-space-around mb-20">
          <div className="flex-space-between flex-row total-count-container h-30p br-10 w-45">
            <div className="font-10">UL Position</div>
            <div className="approved-color">10000000.00</div>
          </div>
          <div className="flex-space-between flex-row total-count-container h-30p br-10 w-45">
            <div className="font-10">Position</div>
            <div className="approved-color font-size-12">50000000.00</div>
          </div>
        </div>
      </div>
      <ClientShareRunsPopup
        showClientShreRunsPopup={openCommModal}
        setShowClientShareRunsPopup={setOpenCommModal}
      />
      <ClientCommRunsPopup
        showClientCommRunsPopup={openShareModal}
        setShowClientCommRunsPopup={setOpenShareModal}
      />
    </div>
  );
}

export default RiskRunningPL;
