import { IonModal, IonText } from "@ionic/react";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { call } from "../../config/axios";
import { CREATE_REFFERAL } from "../../config/endpoints";

function CreateReferralPopup(props) {
  const { createReferralPopup, handleReferralPopupClose, setRefStatus } = props;
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [refferalName, setRefferalName] = useState("");
  const [phn, setPhn] = useState("");
  const [location, setLocation] = useState("");
  const [err, setErr] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAddRefferal = async () => {
    if (refferalName === "" || !refferalName) {
      return setErr("Please enter ref name");
    }
    setErr("");
    setIsProcessing(true);
    await call(CREATE_REFFERAL, {
      register_id,
      referral_name: refferalName,
      phone_no: phn,
      location: location,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.status === 200) {
          setErr("");
          setIsProcessing(false);
          setRefStatus((prev) => !prev);
          handleReferralPopupClose();
          setLocation("");
          setPhn("");
          setRefferalName("");
        } else {
          setErr(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setErr(err?.message ? err?.message : `something wen't wrong`);
      });
  };

  return (
    <IonModal
      className="w-70 create-referral-popup"
      isOpen={createReferralPopup}
    >
      <div className="align-center flex-column">
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handleReferralPopupClose}
        >
          <IoCloseSharp />
        </div>
        <div className="w-100">
          <div className="reports-heading flex-center mb-15">
            Create New Referral
          </div>
          <input
            className="w-100 align-center custom-box-shadow font-12 p-10 br-10 mt-10 h-40 outline-none"
            placeholder="Name"
            name="referral_name"
            id="referral_name"
            type="text"
            value={refferalName || ""}
            onChange={(e) => setRefferalName(e.target.value)}
          />
          <input
            className="w-100 align-center custom-box-shadow font-12 p-10 br-10 mt-10 h-40 outline-none"
            placeholder="Phone"
            name="phone_no"
            id="phone_no"
            type="number"
            value={phn || ""}
            onChange={(e) => setPhn(e.target.value)}
          />
          <input
            className="w-100 align-center custom-box-shadow font-12 p-10 br-10 mt-10 h-40 outline-none"
            placeholder="Location"
            name="location"
            id="location"
            type="text"
            value={location || ""}
            onChange={(e) => setLocation(e.target.value)}
          />
          <div className="flex-space-between mt-10">
            <button
              className="edit-btn-clr flex-center w-100 fw-600 button-box-shadow p-10 br-10 font-14 h-40"
              disabled={isProcessing}
              onClick={() => handleAddRefferal()}
            >
              {isProcessing ? "Proccessing..." : "Add"}
            </button>
          </div>
        </div>
        {err && (
          <IonText color="danger" className="ion-text-center">
            {err}
          </IonText>
        )}
      </div>
    </IonModal>
  );
}

export default CreateReferralPopup;
