import { IonImg, IonModal, useIonToast } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { Images } from "../../images";
import { AiOutlineCopy, AiOutlineShareAlt } from "react-icons/ai";
import { useSelector } from "react-redux";

function RegistrationSuccessful(props) {
  const { registationPopup, setRegistationPopup } = props;
  const registeredUserInfo =
    useSelector((State) => State.common.register_user_info) || {};
  const handleCloseRegistration = () => {
    setRegistationPopup(false);
  };
  const [present] = useIonToast();
  const presentToast = ({ message, color = "success" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };

  const referralData =
    "Role:" +
    registeredUserInfo?.role +
    ",User Name:" +
    registeredUserInfo?.user_name +
    ",Password:" +
    registeredUserInfo?.password +
    ",Website:" +
    "www.admin.we2call.com";

  const handleCopyText = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(referralData);
    presentToast({ message: "Copied Succesfully!" });
  };

  const handleWhatsAppShare = () => {
    const whatsappShareUrl = `whatsapp://send?text=${encodeURIComponent(
      `${referralData}`
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const CreatedDetails = [
    {
      createName: "Role",
      createDetails: registeredUserInfo.role,
    },
    {
      createName: "Name",
      createDetails: registeredUserInfo.user_name,
    },
    {
      createName: "Password",
      createDetails: registeredUserInfo.password,
    },
    {
      createName: "Link",
      createDetails: "www.admin.we2call.com",
    },
  ];
  return (
    <IonModal isOpen={registationPopup} className="statememt-popup-main">
      <div className="flex-flex-end mb-10">
        <IoMdClose
          className="reports-heading"
          onClick={() => handleCloseRegistration()}
        />
      </div>
      <div className="flex-space-between mb-10">
        <span className="reports-heading">Admin Details</span>
        <IonImg className="popup-img" src={Images.We2call} />
      </div>
      {CreatedDetails.map((item, index) => {
        return (
          <div key={index} className="d-flex upcoming-table ml-20 h-40">
            <div className="popup-text">{item.createName} </div>
            <div className="ml-5 clr-brown font-14">: {item.createDetails}</div>
          </div>
        );
      })}
      <div className="upcoming-table flex-space-between pt-10">
        <div
          className="series-input flex-center w-45 font-16"
          onClick={() => handleWhatsAppShare()}
        >
          WhatsApp
          <AiOutlineShareAlt className="share-icon ml-5" />
        </div>
        <div
          className="series-input flex-center w-45 font-16"
          onClick={(e) => handleCopyText(e)}
        >
          Copy
          <AiOutlineCopy className="share-icon ml-5" />
        </div>
      </div>
    </IonModal>
  );
}

export default RegistrationSuccessful;
