import React from "react";
import { IonImg, IonModal } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

function ThankYouPopup(props) {
  const { showThankYouPopup, setShowThankYouPopup } = props;
  const handleThankyouPopupClose = () => {
    setShowThankYouPopup(false);

  };
  return (
    <IonModal isOpen={showThankYouPopup} className="upgrade-popup-main">
      <div>
        <div className="flex-space-between mb-10 relative-position">
          <IoMdClose
            className="reports-heading absolute-position-top-right"
            onClick={() => handleThankyouPopupClose()}
          />
        </div>

        <div className="flex-column">
          <div className="flex-center">
            
            <div className="flex-center  thankyou-checkmark mt-10 mb-5">
              <FaCheck />
            </div>
          </div>

          <div className="flex-center mb-5 font-size-20 fw-600">Thank You</div>
          <div className="flex-center mb-5 font-16">
            Your Account Add Successfully
          </div>
          <div className="font-12 flex-center mb-5 mt-5 flex-column">
            Your request is now being processed.
            <div>We will let you know once the Transaction is marked</div>
            <div> as complete from master/admin and amount will </div>
            credited to your account.
          </div>
        </div>
        <button
          className="confirm-upgrade-popup-button  button-box-shadow flex-center mt-15 w-100"
          onClick={() => handleThankyouPopupClose()}
        >
          Okay
        </button>
      </div>
    </IonModal>
  );
}

export default ThankYouPopup;
