import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ImageZoom = ({src}) => {
  return (
    <div>
      <Zoom>
        <img
          src={src}
          alt="Zoomable"
          style={{ width: '300px', height: 'auto' }}
        />
      </Zoom>
    </div>
  );
};

export default ImageZoom;
