import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaRegUser, FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { MdPayment } from "react-icons/md";
import UPIDetails from "./UPIDetails";
import { useState } from "react";
import { BiListCheck } from "react-icons/bi";

function AddUsersListPage(props) {
  const {
    setYourDetailsComponent,
    setPaymentDetailsComponent,
    setPaymentConfirmation,
  } = props;
  const [removeMember, setRemovemember] = useState(true);
  const handleRemovMember = (index) => {
    setRemovemember(!removeMember);
  };

  const tableHead = [
    {
      header: "Sno",
      field: "sno",
    },
    {
      header: "Place",
      field: "place",
    },
    {
      header: "Tour",
      field: "tour",
    },
    {
      header: "Strength",
      field: "strength",
    },
    {
      header: "Amount",
      field: "amount",
    },
  ];

  const tableContent = [
    {
      sno: "1",
      place: "Goa",
      tour: "Casino",
      strength: "5",
      amount: "200000",
    },
    {
      sno: "2",
      place: "Dubai",
      tour: "Cricket",
      strength: "7",
      amount: "400000",
    },
    {
      sno: "3",
      place: "Goa",
      tour: "pocker",
      strength: "7",
      amount: "400000",
    },
  ];

  const handleBuyMorePopups = () => {
    setYourDetailsComponent(true);
  };

  const handleProcede = () => {
    setPaymentConfirmation(true);
    setPaymentDetailsComponent(false);
  };

  return (
    <IonGrid>
      <div className="meetings-container w-92">
        <div className="w-100 flex-space-between mt-20">
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaRegUser className="green-clr font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <BiListCheck className="green-clr font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaCheck className="font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaCheck className="font-20" />
          </div>
        </div>
        <div className="w-100 flex-space-between mt-5">
          <div className="font-10">Your Details</div>
          <div className="font-10">Added List</div>
          <div className="font-10">Payment</div>
          <div className="font-10">Complete</div>
        </div>
        <div className="w-100 custom-box-shadow mt-20 details-container">
          <div className="font-16 p-10">
            <b>Added </b> List
          </div>
          <hr className="horizantal-line" />
          <div className="w-92 custom-box-shadow members-container mt-10 mb-10">
            <div className="w-100 flex-space-between p-8">
              <div className="font-14">Number of Members</div>
              <div>3</div>
            </div>
            <hr className="horizantal-line" />
            <table className="account-settlement-summary account-settlement-summary-data">
              <thead>
                <tr>
                  {tableHead.map((item, index) => {
                    return (
                      <th key={index} className="table-heading">
                        {item.header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableContent.map((rowItem, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {tableHead.map((item, index) => {
                        return <td>{rowItem[item.field]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <hr className="offers-hr-line " />
            <hr className="horizantal-line mt-5 mb-5" />
            <IonRow className="mt-10">
              <IonCol size="4" className="font-10">
                Package Amount
              </IonCol>
              <IonCol size="2" className="font-10">
                10,00000
              </IonCol>
              <IonCol size="4" className="font-10">
                Total Members
              </IonCol>
              <IonCol size="2" className="font-10">
                19
              </IonCol>
            </IonRow>
            <hr className="horizantal-line mt-5 mb-5" />
            <IonRow className="flex-space-between mt-5 mb-10">
              <IonCol size="4" className="font-10">
                Total Amount to Pay
              </IonCol>
              <IonCol size="2" className="font-10">
                10,00000
              </IonCol>
            </IonRow>
          </div>
          <IonRow>
            <IonCol>
              <button
                className="w-92 mt-5 button-box-shadow green-clr font-16 br-10 h-44px ml-5per"
                onClick={() => handleBuyMorePopups()}
              >
                + Buy More
              </button>
            </IonCol>
            <IonCol>
              <button
                className="w-92 mt-5 button-box-shadow green-clr font-16 br-10 h-44px ml-5per"
                onClick={() => handleProcede()}
              >
                Proceed Pay
              </button>
            </IonCol>
          </IonRow>
        </div>
      </div>
    </IonGrid>
  );
}

export default AddUsersListPage;
