import React, { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { BsArrowDown } from "react-icons/bs";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import FancyResultPL from "./FancyResultPL";
import FancyResultOverPL from "./FancyResultOverPL";
import RiskRunningPL from "./RiskRunningPL";
import { useHistory } from "react-router";
import { BiRightArrowCircle } from "react-icons/bi";

function MatchShareRisk() {
  const tableMatchRowData = [
    {
      title: "Match Name",
    },
    {
      title: "Name",
    },
    {
      title: "SRL",
    },
  ];
  const matchDetails = [
    {
      match: "india vs SriLanka",
      matchMode: " T20 World Cup",
      venue: "Ahamedabad Stadium",
      date: "12-07-2023",
      time: "16:16:00 PM",
      user: (
        <div className="flex-start flex-column">
          <div>Srinivas</div>
          <div>2346</div>
        </div>
      ),
      role: "Agent",
      price: "5000",
    },
    {
      match: "india vs SriLanka",
      matchMode: " T20 World Cup",
      venue: "Ahamedabad Stadium",
      date: "12-07-2023",
      time: "16:16:00 PM",
      user: (
        <div className="flex-start flex-column">
          <div>Srinivas</div>
          <div>2346</div>
        </div>
      ),
      role: "Super-master",
      price: "5000",
    },
    {
      match: "india vs SriLanka",
      matchMode: " T20 World Cup",
      venue: "Ahamedabad Stadium",
      date: "12-07-2023",
      time: "16:16:00 PM",
      user: (
        <div className="flex-start flex-column">
          <div>Srinivas</div>
          <div>2346</div>
        </div>
      ),
      role: "Sub-Admin",
      price: "5000",
    },
    {
      match: "india vs SriLanka",
      matchMode: " T20 World Cup",
      venue: "Ahamedabad Stadium",
      date: "12-07-2023",
      time: "16:16:00 PM",
      user: (
        <div className="flex-start flex-column">
          <div>Srinivas</div>
          <div>2346</div>
        </div>
      ),
      role: "Admin",
      price: "5000",
    },
  ];
  const [showFancyResult, setShowFancyResult] = useState(false);
  const handleDownArrow = () => {
    setShowFancyResult((prev) => !prev);
  };
  const history = useHistory();
  return (
    <div>
      <MeetingsHeader heading="Share Risk Live Match" />
      <div className="main-container p-10 h-85vh">
        <div className="custom-box-shadow m-none overflow-none meetings-details-div">
          <div className="flex-row flex-space-between p-10">
            <div className=" custom-box-shadow upcoming-meetings-div flex-start font-16 w-60 p-10">
              Share Risk - Live Fancy
            </div>
            <div
              className="seeall-button h-30p w-30 p-5 flex-center custom-box-shadow font-10"
              onClick={() => history.push("/share-fancy-risk")}
            >
              Match Risk
              <BiRightArrowCircle
                style={{ fontSize: "20px" }}
                className="ml-10"
              />
            </div>
          </div>
          <div className="hr-line mt-5" />
          <div className="d-flex">
            {tableMatchRowData.map((matchData, index) => (
              <div key={index} className="flex-center mt-5 pr-5 pl-5 w-25">
                <div className="match-history-button flex-center custom-box-shadow font-10">
                  {matchData.title}
                </div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10 "></div>
          <div className="upcoming-meet-scroll  ml-10 mr-5">
            {matchDetails.map((matchdata, index) => (
              <div key={index} className="flex-row">
                <div className="flex-space-between mt-5 pl-10 pr-10 w-100">
                  <div className="meetings-time w-30 font-2 padding-tb ">
                    <div className="flex-row ">
                      <div className="font-10">{matchdata.match}</div>
                      <div className="font-10">{matchdata.matchMode}</div>
                      <div className="font-10">{matchdata.venue}</div>
                      <div className="font-10">{matchdata.date}</div>
                      <div className="font-10">{matchdata.time}</div>
                    </div>
                  </div>

                  <div className="font-12 w-30 flex-column flex-center">
                    
                    <div className="font-10 flex-center">{matchdata.user}</div>
                    <div className="font-10 flex-center mt-20">
                      {matchdata.role}
                    </div>
                  </div>
                  <div className="font-size-12 w-30 flex-center clr-green">
                    {matchdata.price}
                  </div>
                  <div className="w-25 flex-center">
                    
                    <BsArrowDown
                      className="font-size-20 custom-box-shadow clr-pink br-50 p-3"
                      onClick={() => handleDownArrow()}
                    />
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
          </div>
          <div className="flex-space-between flex-row total-count-container">
            <div className="font-14">Total</div>
            <div className="approved-color">1000000.00</div>
          </div>
        </div>
        {showFancyResult && (
          <>
            
            <FancyResultPL />
            <FancyResultOverPL />
            <RiskRunningPL />
          </>
        )}
      </div>
    </div>
  );
}

export default MatchShareRisk;
