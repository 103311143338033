import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import PackageTransaction from "../pages/packages-accounts/PackageTransaction";

function AddUserManagement() {
  const userManagement = ["Add Users"];
  const [showUrTransaction, setShowUrTransaction] = useState(false);
  const handleYourTransaction = () => {
    setShowUrTransaction((prev) => !prev);
  };

  return (
    <div>
      <IonGrid>
        <IonRow className="connect-device mrl-10 h-110px" id="mt-10">
          <div className="connect-device-header custom-box-shadow m-5">
            User Management/Creation
          </div>
          <div className="w-100 flex-space-between upcoming-table d-flex">
            {userManagement.map((item, index) => {
              return (
                <IonCol key={index} size="4">
                  <div className="series-input flex-center custom-box-shadow type-of-user">
                    <div className="flex-center w-80 match-input font-12">
                      {item}
                    </div>
                    <AiOutlinePlus className="clock-icon" />
                  </div>
                </IonCol>
              );
            })}
            <IonCol size="4">
              <div
                className="series-input flex-center custom-box-shadow w-100"
                onClick={() => handleYourTransaction()}
              >
                <div className="flex-center w-100 match-input pl-10 font-12">
                  Upgrade Your Package
                </div>
              </div>
            </IonCol>
          </div>
        </IonRow>
      </IonGrid>
      {showUrTransaction && <PackageTransaction />}
    </div>
  );
}

export default AddUserManagement;
