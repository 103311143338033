import Table from "../../common/Table";
import { useEffect, useState } from "react";
import "./style.css";
import moment from "moment";

const MatchFancyRLComm = ({ data, induvisualClientStatus, clientName }) => {
  const [active, setActive] = useState(1);
  const matchFancyRLCommColumns = [
    {
      header: "cNameMatchPL",
      name: (
        <div>
          <div>MatchName</div>
          <div>Date</div>
          <div>Amount</div>
          {/* <div>C Name</div> */}
          {/* <div>Match P/L</div> */}
        </div>
      ),
    },
    { header: "fancyPL", name: "Fancy P/L" },
    { header: "mfComm", name: "M+F Comm" },
    { header: "roleComm", name: "Rolling Comm" },
    // { header: "fancyComm", name: "Fancy Comm" },
    { header: "masterProfitloss", name: "M+F+C /CRC" },
  ];
  const commissionNetColumns = [
    {
      header: "cNameCShare",
      name: (
        <div>
          <div>C Name</div>
          <div>/C Share</div>
        </div>
      ),
    },
    { header: "commGross", name: "C Gross" },
    { header: "matchComm", name: "Match Comm" },
    { header: "commNetPL", name: "C Net P/L" },
  ];
  const firstReferralNetColums = [
    {
      header: "cNameMatchPL",
      name: (
        <div>
          <div>Match Name</div>
          <div>Date</div>
          <div>Amount</div>
        </div>
      ),
    },
    { header: "fancyPL", name: "Fancy P/L" },
    // { header: "refrralFancyComm", name: "Rf-Fa-Comm" },
    // { header: "masterProfitloss", name: "Total" },
    { header: "mfComm", name: "M+F Comm" },
    { header: "roleComm", name: "Roling Comm" },
    { header: "masterProfitloss", name: "M+F+RF /RF.RC" },
  ];
  const secondReferralNetColumns = [
    {
      header: "cNameMatchPL",
      name: (
        <div>
          <div>C Name</div>
          <div>Rf Share</div>
        </div>
      ),
    },
    { header: "gross", name: "Gross" },
    { header: "matchComm", name: "Match Comm" },
    { header: "masterProfitloss", name: "Rf Net P/L" },
  ];
  const uLShareColumns = [
    {
      header: "cNameTotalNet",
      name: (
        <div>
          <div>C Name</div>
          <div>Total Net</div>
        </div>
      ),
    },
    { header: "ulShare", name: "UL Share" },
    { header: "emptyData", name: "--" },
    { header: "masterProfitloss", name: "Total P/L" },
  ];
  const matchFancyRLCommData =
    data?.length > 0 &&
    data?.map((report) => ({
      cNameMatchPL: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>{report?.client_name}</div>
          <div>{report?.amount}</div>
        </div>
      ),
      fancyPL: parseFloat(report?.fancyPl || 0),
      fancyComm: parseFloat(report?.fancyComission || 0),
      masterProfitloss: report?.totalLossOrProfit || 0,
    }));

  const commissionNetdata = [
    {
      cNameCShare: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>Sri65</div>
          <div>1000000</div>
        </div>
      ),
      commGross: "1000000.00",
      matchComm: "1000000.00",
      commNetPL: "1000000.00",
    },
    {
      cNameCShare: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>Sri65</div>
          <div>1000000</div>
        </div>
      ),
      commGross: "1000000.00",
      matchComm: "1000000.00",
      commNetPL: "1000000.00",
    },
    {
      cNameCShare: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>Sri65</div>
          <div>1000000</div>
        </div>
      ),
      commGross: "1000000.00",
      matchComm: "1000000.00",
      commNetPL: "1000000.00",
    },
    {
      cNameCShare: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>Sri65</div>
          <div>1000000</div>
        </div>
      ),
      commGross: "1000000.00",
      matchComm: "1000000.00",
      commNetPL: "1000000.00",
    },
  ];

  let netCommissionTotal = 0,
    netRefTotal = 0;

  const firstReferralNetData =
    data &&
    data?.length > 0 &&
    data?.map((client) => {
      const netCommission = +client?.fancyEntryResult?.referralComission
        ? +client?.referralComission - +client?.referralFancyComission
        : client?.referralComission;
      const netRef =
        netCommission + +client?.fancyEntryResult?.referralComission;

      netCommissionTotal += netCommission;
      netRefTotal += netRef;
      return {
        cNameMatchPL: (
          <div className="client-name-role-container mb-5 mt-5">
            <div>{`${client?.team1} vs ${client?.team2}`}</div>
            <div
              className={
                +client?.matchEntryResult?.amount >= 0
                  ? "approved-color"
                  : "red-clr"
              }
            >
              {client?.matchEntryResult?.amount}
            </div>
            <div>{moment(+client?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        fancyPL: (
          <div
            className={
              +client?.fancyEntryResult?.amount >= 0
                ? "approved-color"
                : "red-clr"
            }
          >
            {+client?.fancyEntryResult?.amount}
          </div>
        ),
        mfComm: (
          <div className={+netCommission >= 0 ? "approved-color" : "red-clr"}>
            {netCommission}
          </div>
        ),
        roleComm: (
          <div
            className={
              +client?.fancyEntryResult?.referralComission >= 0
                ? "approved-color"
                : "red-clr"
            }
          >
            {client?.fancyEntryResult?.referralComission}
          </div>
        ),
        masterProfitloss: (
          <div className={+netRef >= 0 ? "approved-color" : "red-clr"}>
            {netRef}
          </div>
        ),
      };
    });

  const secondReferralNetData = [
    {
      cNameMatchPL: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>Sri65</div>
          <div>10000</div>
        </div>
      ),
      gross: "1000000.00",
      matchComm: "1000000.00",
      masterProfitloss: "1000000.00",
    },
  ];
  const uLShareData =
    data?.length > 0 &&
    data?.map((report) => ({
      cNameTotalNet: (
        <div className="client-name-role-container mb-5 mt-5">
          <div>{report?.client_name}</div>
          <div>{report?.amount}</div>
        </div>
      ),
      ulShare: parseFloat(report?.fancyPl || 0),
      emptyData: parseFloat(report?.fancyComission || 0),
      masterProfitloss: report?.totalLossOrProfit || 0,
    }));

  const cNetButton = (
    <div>
      <button className="common-active-css active-css font-14">
        Total - C Share = C Gross - M Comm = C Net
      </button>
      <div className="hr-line"></div>
    </div>
  );
  const rfNetButton = (
    <div>
      <button className="common-active-css active-css font-14">
        Total - Rf Share = Rf Gross - M Comm = Rf Net
      </button>
      <div className="hr-line"></div>
    </div>
  );

  const [firstTableColumns, setFirstTableColumns] = useState(
    matchFancyRLCommColumns
  );
  const [secondTableColumns, setSecondTableColumns] =
    useState(commissionNetColumns);
  const totalFancyPl =
    data &&
    data?.length > 0 &&
    data?.reduce((acc, obj) => acc + (+obj?.fancyEntryResult?.amount || 0), 0);
  const totalMfComm =
    data &&
    data?.length > 0 &&
    data?.reduce(
      (acc, obj) =>
        acc +
        (+(+obj?.fancyEntryResult?.clientCommission
          ? +obj?.clientComission - +obj?.clientFancyComission
          : obj?.clientComission) || 0),
      0
    );
  const totalRoleComm =
    data &&
    data?.length > 0 &&
    data?.reduce(
      (acc, obj) => acc + (+obj?.fancyEntryResult?.clientCommission || 0),
      0
    );
  const totalMasterPL =
    data &&
    data?.length > 0 &&
    data?.reduce(
      (acc, obj) =>
        acc +
        (+parseFloat(+obj?.amount || 0) +
          parseFloat(+obj?.clientComission || 0) || 0),
      0
    );

  const induvisualClientData =
    data &&
    data?.length > 0 &&
    data?.map((client) => {
      const netCommission = +client?.fancyEntryResult?.clientCommission
        ? +client?.clientComission - +client?.clientFancyComission
        : client?.clientComission;
      const totalAmountAfterCommission =
        parseFloat(+client?.amount || 0) +
        parseFloat(+client?.clientComission || 0);
      return {
        cNameMatchPL: (
          <div className="client-name-role-container mb-5 mt-5">
            <div>{`${client?.team1} vs ${client?.team2}`}</div>
            <div
              className={
                +client?.matchEntryResult?.amount >= 0
                  ? "approved-color"
                  : "red-clr"
              }
            >
              {client?.matchEntryResult?.amount}
            </div>
            <div>{moment(+client?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        fancyPL: (
          <div
            className={
              +client?.fancyEntryResult?.amount >= 0
                ? "approved-color"
                : "red-clr"
            }
          >
            {+client?.fancyEntryResult?.amount}
          </div>
        ),
        mfComm: (
          <div className={+netCommission >= 0 ? "approved-color" : "red-clr"}>
            {+netCommission}
          </div>
        ),
        roleComm: (
          <div
            className={
              +client?.fancyEntryResult?.clientCommission >= 0
                ? "approved-color"
                : "red-clr"
            }
          >
            {client?.fancyEntryResult?.clientCommission}
          </div>
        ),
        masterProfitloss: (
          <div
            className={
              +totalAmountAfterCommission >= 0 ? "approved-color" : "red-clr"
            }
          >
            {totalAmountAfterCommission}
          </div>
        ),
      };
    });

  const [firstTableData, setFirstTableData] = useState(induvisualClientData);
  const [secondTableData, setSecondTableData] = useState(commissionNetdata);
  const [titleButtons, setTitleButtons] = useState(cNetButton);
  const handleMFRCTotal = () => {
    setActive(1);
    setFirstTableColumns(matchFancyRLCommColumns);
    setFirstTableData(induvisualClientData);
    setSecondTableColumns(commissionNetColumns);
    setSecondTableData(commissionNetdata);
    setTitleButtons(cNetButton);
  };
  const handleRfNet = () => {
    setActive(2);
    setFirstTableColumns(firstReferralNetColums);
    setFirstTableData(firstReferralNetData);
    setSecondTableColumns(secondReferralNetColumns);
    setSecondTableData(secondReferralNetData);
    setTitleButtons(rfNetButton);
  };
  const handleUlShare = () => {
    setActive(3);
    setFirstTableColumns(uLShareColumns);
    setFirstTableData(uLShareData);
    setSecondTableColumns([]);
    setSecondTableData([]);
    setTitleButtons("");
  };

  const firstTableButtons = (
    <div>
      <div className="font-14 fw-600 ml-10">Name: {clientName}</div>
      <button
        className={`common-active-css ${
          active === 1 ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleMFRCTotal(1)}
      >
        M+R+M Comm & F Comm/Rolling Comm
      </button>
      <button
        className={`common-active-css ${
          active === 2 ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleRfNet(2)}
      >
        RF Net
      </button>
      {/* <button
        className={`common-active-css ${
          active === 3 ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleUlShare(3)}
      >
        UL Share
      </button> */}
      <div className="hr-line"></div>
    </div>
  );

  useEffect(() => {
    handleMFRCTotal();
  }, [induvisualClientStatus]);

  const totalRFNet = netCommissionTotal + netRefTotal;

  return (
    <div>
      <div className="mt-10 mb-10 scroll-onepage">
        <Table
          columns={firstTableColumns}
          data={firstTableData}
          buttons={firstTableButtons}
        />
      </div>
      {active === 1 && (
        <table className="w-100 account-summary-main-container mt-5">
          <tfoot>
            <tr>
              <th>Total</th>
              <th className={totalFancyPl >= 0 ? "green-clr" : "red-clr"}>
                {totalFancyPl ? totalFancyPl?.toFixed(2) : 0}
              </th>
              <th className={totalMfComm >= 0 ? "green-clr" : "red-clr"}>
                {totalMfComm ? totalMfComm?.toFixed(2) : 0}
              </th>
              <th className={totalRoleComm >= 0 ? "green-clr" : "red-clr"}>
                {totalRoleComm ? totalRoleComm?.toFixed(2) : 0}
              </th>
              <th className={totalMasterPL >= 0 ? "green-clr" : "red-clr"}>
                {totalMasterPL ? totalMasterPL?.toFixed(2) : 0}
              </th>
            </tr>
          </tfoot>
        </table>
      )}
      {active === 2 && (
        <>
          <table className="w-100 account-summary-main-container mt-5">
            <tfoot>
              <tr>
                <th>Total</th>
                <th className={totalFancyPl >= 0 ? "green-clr" : "red-clr"}>
                  {totalFancyPl ? totalFancyPl?.toFixed(2) : 0}
                </th>
                <th
                  className={netCommissionTotal >= 0 ? "green-clr" : "red-clr"}
                >
                  {netCommissionTotal ? netCommissionTotal?.toFixed(2) : 0}
                </th>
                <th className={totalRoleComm >= 0 ? "green-clr" : "red-clr"}>
                  {totalRoleComm ? totalRoleComm?.toFixed(2) : 0}
                </th>
                <th className={netRefTotal >= 0 ? "green-clr" : "red-clr"}>
                  {netRefTotal ? netRefTotal?.toFixed(2) : 0}
                </th>
              </tr>
            </tfoot>
          </table>
          <table className="w-100 account-summary-main-container mt-5">
            <tfoot>
              <tr>
                <th>Total RF Nets</th>
                <th className={totalRFNet >= 0 ? "green-clr" : "red-clr"}>
                  {totalRFNet}
                </th>
              </tr>
              <tr>
                <th>All Total</th>
                <th className={totalMasterPL >= 0 ? "green-clr" : "red-clr"}>
                  {totalMasterPL ? totalMasterPL?.toFixed(2) : 0}
                </th>
              </tr>
            </tfoot>
          </table>
        </>
      )}

      {/* <Table
        columns={secondTableColumns}
        data={secondTableData}
        buttons={titleButtons}
      /> */}
    </div>
  );
};

export default MatchFancyRLComm;
