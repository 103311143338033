import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { call } from "../../config/axios";
import { GET_ALL_CLIENTS, GET_ALL_MEETINGS } from "../../config/endpoints";
import HomeTable from "../../common/HomeTable";
import { useHistory } from "react-router";
import { isLoggedIn } from "../../utils/helpers";

function LiveUpcomingMeetings() {
  const register_id = localStorage.getItem("register_id");
  const [allAdmins, setAllAdmins] = useState([]);
  const [liveMeetings, setLiveMeetings] = useState([]);
  const token = isLoggedIn();
  const history = useHistory();

  const scheduledColumns = [
    { field: "admin" },
    { field: "eventName" },
    { field: "meetingUserData" },
    { field: "action" },
  ];

  const scheduledColumnHeaders = [
    { header: "Admin" },
    { header: "Event Name" },
    { header: "User" },
    { header: "Action" },
  ];

  const fetchLiveMeetings = async () => {
    try {
      const payload = { register_id };
      const response = await call(GET_ALL_MEETINGS, payload);
      setLiveMeetings(response?.data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllAdmins = async () => {
    try {
      const payload = { register_id };
      const response = await call(GET_ALL_CLIENTS, payload);
      const admins =
        response?.data?.data?.filter((admin) => admin.active) || [];
      setAllAdmins(
        admins.map((admin) => ({
          user_name: admin.user_name,
          register_id: admin.register_id,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchLiveMeetings();
      fetchAllAdmins();
    }
  }, [token]);

  const handleJoinRoom = (roomId) => {
    // history.push(`/group-call/${roomId}`);
    const newTab = window.open(`/upjoin/${roomId}`, '_blank');
      if (newTab) {
          newTab.focus();
      }
  };

  const currentTime = Date.now();
  const twentyFourHoursInMs = 24 * 60 * 60 * 1000;

  const meetingData = liveMeetings
    ?.filter((meeting) => meeting.p_id !== register_id)
    ?.map((meeting) => ({
      ...meeting,
      timestamp: new Date(`${meeting.date} ${meeting.time}`).getTime(),
    }))
    ?.filter((meeting) => currentTime - meeting.timestamp < twentyFourHoursInMs)
    ?.sort((a, b) => b.timestamp - a.timestamp)
    ?.map((meeting) => {
      const meetingUserData = allAdmins.filter((admin) =>
        meeting.meetingUserIds?.includes(admin.register_id)
      );
      return {
        ...meeting,
        admin: meeting.createdBy,
        eventName: (
          <div>
            {meeting.match_name}
            <br />
            {meeting.event_name}
            <br />
            {meeting.date}
            <br />
            {meeting.time}
          </div>
        ),
        meetingUserData:
          meetingUserData.length > 0
            ? `${meetingUserData[0].user_name}${
                meetingUserData.length > 1
                  ? ` + ${meetingUserData.length - 1}`
                  : ""
              }`
            : "",
        action: (
          <div onClick={() => handleJoinRoom(meeting.meeting_id)}>JOIN</div>
        ),
      };
    });

  return (
    <IonGrid>
      <IonRow className="connect-device custom-box-shadow mrl-10">
        <IonCol size="11.4" className="flex-space-between">
          <div className="connect-device-header custom-box-shadow">
            Live/Upcoming Meetings
          </div>
          <div
            className="connect-device-seemore custom-box-shadow"
            onClick={() => history.push("/call-management")}
          >
            See All
          </div>
        </IonCol>
        <div className="flex-space-between w-100 table-heading p-5 live-pb">
          {scheduledColumnHeaders.map((column, index) => (
            <div key={index} className="text-center ml-10 mr-10">
              {column.header}
            </div>
          ))}
        </div>
        <div className="scroll-table">
          <HomeTable data={meetingData || []} columns={scheduledColumns} />
        </div>
      </IonRow>
    </IonGrid>
  );
}

export default LiveUpcomingMeetings;
