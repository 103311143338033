import "./styles.css";
import Summary from "./Summary";
import Mangement from "./Mangement";
import CallManagement from "./CallManagement";
import ConnectDevices from "./ConnectDevices";
import LiveUpcomingMeetings from "./LiveUpcomingMeetings";
import Alerts from "./Alerts";
import { isLoggedIn } from "../../utils/helpers";
import LoginPopup from "../signup/LoginPopup";

function HomeContent() {
  let token = isLoggedIn();
  return (
    <div className="we-2-call-main-section">
      <CallManagement />
      <Mangement />
      <LiveUpcomingMeetings />
      <Summary />
      <Alerts />
      <ConnectDevices />
      {!token && <LoginPopup />}
    </div>
  );
}

export default HomeContent;
