import { IonModal } from "@ionic/react";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { MdOutlineContentCopy } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";
import { useHistory } from "react-router";

function RegistrationSuccessfulPopup(props) {
  const history = useHistory();
  const { registrationSuccessfull, handleRegistrationSuccessfullPopupClose } =
    props;
  const USER_DETAILS = [
    {
      heading: "Role",
      content: "User",
    },
    {
      heading: "User Name",
      content: "Jayanta674839",
    },
    {
      heading: "Password",
      content: "Jayanta6748",
    },
  ];
  return (
    <IonModal
      className="w-80 registration-popup"
      isOpen={registrationSuccessfull}
    >
      <div className="align-center flex-column custom-box-shadow registration-div">
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handleRegistrationSuccessfullPopupClose}
        >
          <RxCross1 />
        </div>
        <div>
          <img
            className="registration-image"
            src={Images.MobilePhone}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-90">
          <div className="terms-conditions-text mt-15">
            Registration Successful!
          </div>
          {USER_DETAILS?.map((value, index) => (
            <div
              key={index}
              className="flex-space-between custom-box-shadow registration-user-div mt-15 font-14"
            >
              <div>{value.heading}</div>
              <div>{value.content}</div>
            </div>
          ))}
        </div>
        <div className="w-90 mt-10 font-12 ml-20">www.admin.we2call.com</div>
        <div className="flex-space-around flex-column w-90">
          <div className="w-100 flex-space-between">
            <button className="flex-space-evenly w-45 custom-box-shadow fancy-declare-button mt-15 font-14">
              <FaRegShareFromSquare className="green-clr" /> Share
            </button>
            <button className="flex-space-evenly w-45 custom-box-shadow fancy-declare-button mt-15 font-14">
              <MdOutlineContentCopy className="green-clr" /> Copy
            </button>
          </div>
          <div className="w-100" onClick={() => history.push("/")}>
            <button className="w-100 button-box-shadow fancy-declare-button mt-15 font-14 green-clr">
              Go To Home
            </button>
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default RegistrationSuccessfulPopup;
