import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";

function FancyCommRiskPopup(props) {
  const {
    fancyCommRiskPopup,
    handleFancyCommRiskPopupClose,
    data,
    totalRiskRunningResultData,
  } = props;

  return (
    <IonGrid className="fancy-commission-popup-pos meetings-container w-75 match-share-popup">
      <div className="custom-box-shadow  fancy-meetings-popups-div">
        <div
          className="flex-flex-end fancy-close-icon"
          onClick={() => handleFancyCommRiskPopupClose()}
        >
          <RxCross2 />
        </div>
        <IonRow className="flex-space-between w-100 pr-5 pl-5">
          <IonCol
            size="4"
            className="br-15 custom-box-shadow flex-center flex-column font-10"
          >
            <div>Runs</div>
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            C-FC/RC
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            Rf-fc/rc
          </IonCol>
        </IonRow>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data.map((fancy, index) => (
            <div key={index} className="flex-row">
              <IonRow className="flex-space-between w-100 align-end mt-5 pl-10 pr-10">
                <IonCol size="3" className="meetings-time font-2">
                  <div>{fancy?.key} Runs</div>
                </IonCol>
                <IonCol
                  size="3"
                  className={
                    fancy?.clientComm >= 0
                      ? "green-clr font-12 w-20"
                      : "red-clr font-12 w-20"
                  }
                >
                  {fancy?.clientComm ? fancy?.clientComm?.toFixed(2) : 0}
                </IonCol>
                <IonCol
                  size="3"
                  className={
                    fancy?.rfComm >= 0
                      ? "green-clr font-12 w-20"
                      : "red-clr font-12 w-20"
                  }
                >
                  {fancy?.rfComm ? fancy?.rfComm?.toFixed(2) : 0}
                </IonCol>
              </IonRow>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
        </div>
        {/* <IonRow className="flex-space-between align-end mt-5 pl-10 pr-10 fancy-total-row">
          <IonCol size="4" className="meetings-time font-2">
            <div className="font-14 fw-600">Total</div>
          </IonCol>
          <IonCol
            size="3.5"
            className={
              totalRiskRunningResultData?.clientComm >= 0
                ? "green-clr font-12 w-25 text-center"
                : "red-clr font-12 w-25 text-center"
            }
          >
            <div
              className={
                totalRiskRunningResultData?.clientComm >= 0
                  ? "green-clr"
                  : "red-clr"
              }
            >
              {totalRiskRunningResultData?.clientComm
                ? totalRiskRunningResultData?.clientComm?.toFixed(2)
                : 0}
            </div>
          </IonCol>
          <IonCol
            size="3"
            className={
              totalRiskRunningResultData?.rfComm >= 0
                ? "green-clr font-12 w-25"
                : "red-clr font-12 w-25"
            }
          >
            {totalRiskRunningResultData?.rfComm
              ? totalRiskRunningResultData?.rfComm?.toFixed(2)
              : 0}
          </IonCol>
        </IonRow> */}
      </div>
    </IonGrid>
  );
}

export default FancyCommRiskPopup;
