import { IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import { BsArrowRight } from "react-icons/bs";
import { Images } from "../../images";
import { useHistory } from "react-router";

function Mangement() {
  const history = useHistory();
  const handleManagement = () => {
    history.push("/sport-management");
  };
  const handleUserManagement = () => {
    history.push("/user-management");
  };
  return (
    <IonGrid className="pb-0">
      <IonRow className="d-flex">
        <IonCol size="6" className="d-flex">
          <div
            className="upcoming-meetings-container"
            // onClick={() => handleMeetings()}
          >
            <IonImg
              className="user-management-image"
              src={Images.UserManagement}
            />
            <div className="we-2-call-description ml-10">
              <div>User</div>
              <div className="bold-text we-2-call-text">Management</div>
              <div className="bold-text we-2-meeting-text">
                Create your new <br /> users profile
              </div>
              <div className="we-2-call-icon mt-5 flex-center">
                <BsArrowRight className="d-flex" onClick={() => handleUserManagement()} />
              </div>
            </div>
          </div>
        </IonCol>
        <IonCol size="6" className="d-flex">
          <div
            className="completed-meetings-container"
            // onClick={() => handleMeetings("completedMeetings")}
          >
            <IonImg
              className="sports-management-image"
              src={Images.SportsManagement}
            />
            <div className="we-2-call-description ml-10">
              <div>Sports</div>
              <div className="bold-text we-2-call-text">Management</div>
              <div className="bold-text we-2-meeting-text">
                Create your match
                <br /> schedule meetings
              </div>
              <div
                className="we-2-call-icon mt-5 flex-center"
                onClick={() => handleManagement()}
              >
                <BsArrowRight className="d-flex" />
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default Mangement;
