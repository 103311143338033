import { IonImg, IonTitle, IonIcon, IonModal, IonText } from "@ionic/react";
import moment from "moment";
import { closeOutline } from "ionicons/icons";
import { Images } from "../../images";
import { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";

const PaymentSettlement = (props) => {
  const {
    showModal,
    setShowModal,
    handleOpenNewModal,
    clientId,
    allClientsData = [],
    setOfflineSettlePayload,
    offlineSettlePayload,
    setAmountToBeSetteled,
    isProcessing,
    setError,
    error,
  } = props;
  const [showPaymentType, setShowPaymentType] = useState(false);
  const [paymentType, setPaymentType] = useState("Payment Mode");
  const selectedObj =
    allClientsData &&
    allClientsData?.length > 0 &&
    allClientsData.filter((obj) => obj.client_id == clientId)?.[0];
  const [settlementObj, setSettlementObj] = useState({});
  const onSubmitBtnClick = () => {
    setOfflineSettlePayload({ ...offlineSettlePayload, ...settlementObj });
    handleOpenNewModal();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
  };
  const paymentTypes = [
    { name: "PhonePe", value: "Phonepay" },
    { name: "G Pay", value: "G pay" },
  ];
  const onPaymentType = () => {
    setShowPaymentType((prev) => !prev);
  };
  const handlePaymentType = (type) => {
    setPaymentType(type);
    setSettlementObj({
      ...settlementObj,
      payment_type: type?.value,
    });
  };
  const onInputChange = (e) => {
    setSettlementObj({
      ...settlementObj,
      [e.target.name]: Number(e.target.value),
    });
  };
  useEffect(() => {
    setSettlementObj(selectedObj);
  }, [selectedObj]);

  return (
    <IonModal
      isOpen={showModal}
      backdropDismiss={true}
      className="payment-settlement-modal p-0 payment-settlement-container custom-box-shadow"
    >
      {/* <div className="payment-settlement-container custom-box-shadow"> */}
      <IonIcon
        icon={closeOutline}
        slot="middle"
        className="close-icon"
        onClick={() => handleCloseModal()}
      />

      <div className="flex-center mb-10">
        <IonImg className="logoImage" src={Images.handIllus} alt="hand-illus" />
      </div>
      <IonTitle className="payment-settlement-heading mb-10">
        Payment Settlement
      </IonTitle>
      <div className="flex-space-evenly w-100 mb-10">
        <button className="account-summary-headings custom-box-shadow w-40 font-10">
          Date: {moment().format("DD-MM-YYYY")}
        </button>
        <button className="account-summary-headings custom-box-shadow w-40 font-10">
          Time: {moment().format("hh:mm:ss A")}
        </button>
      </div>

      <div
        placeholder="Client Name"
        name="client_id"
        className="payment-settlement-client-selection d-flex custom-box-shadow mb-10 pl-10"
      >
        {settlementObj?.client_name}
      </div>
      <div className="flex-space-between  w-100 mb-10">
        <button className="account-summary-headings custom-box-shadow w-48 h-40 border-radius-10 text-align-left pl-10">
          {settlementObj?.pending_amount}
        </button>
        <button className="account-summary-headings custom-box-shadow w-48 h-40 border-radius-10 text-align-left pl-10">
          {settlementObj?.pending_amount -
            (settlementObj?.pending_amount >= 0
              ? settlementObj?.settled_amount || 0
              : -settlementObj?.settled_amount || 0)}
        </button>
      </div>
      <div className="flex-space-between w-100 mb-10">
        <div
          className="payment-settlement-client-selection custom-box-shadow w-48 h-40 flex-space-between position-relative"
          name="payment_type"
          value={settlementObj?.payment_type}
          onClick={() => onPaymentType()}
        >
          {paymentType?.name || "Select..."}
          {showPaymentType && (
            <div className="admin-reports-dropdown dropdown-position w-100 h-100 font-12">
              {paymentTypes.map((type, index) => {
                return (
                  <div
                    key={index}
                    className={`h-40 flex-center mb-5`}
                    onClick={() => handlePaymentType(type)}
                  >
                    {type?.name}
                  </div>
                );
              })}
            </div>
          )}
          <RiArrowDropDownLine className="dropdown-icon" />
        </div>
        <input
          className="custom-box-shadow h-40 br-10 font-12 w-48 enter-amount-input"
          type="number"
          id="clientName"
          placeholder="Enter Amount"
          name="settled_amount"
          value={settlementObj?.settled_amount}
          onChange={(e) => {
            onInputChange(e);
          }}
        />
      </div>
      <button
        className="account-summary-title-container clr-green w-100 h-40 mt-20"
        onClick={() => onSubmitBtnClick()}
      >
        {isProcessing ? "Processging..." : "Submit"}
      </button>
      {error && (
        <IonText color="danger" className="ion-text-center">
          {error}
        </IonText>
      )}
    </IonModal>
  );
};

export default PaymentSettlement;
