import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaChevronDown } from "react-icons/fa";
import { useHistory } from "react-router";
import "react-widgets/styles.css";
import DropdownList from "react-widgets/DropdownList";
import { useEffect, useState } from "react";
import {
  GET_INDUVISUAL_MATCH_REPORT,
  TOP_WINNERS_LOOSERS,
  SUMMARY_DATA,
} from "../../config/endpoints";
import { call } from "../../config/axios";

function Summary() {
  const history = useHistory();
  const register_id = localStorage?.getItem("register_id");
  const [topWinnersData, setTopWinnersData] = useState([]);
  const [topLoosersData, setTopLoosersData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [topWinners, setTopWinners] = useState([]);
  const [topLoosers, setTopLoosers] = useState([]);
  const [showlooserDropdown, setShowlooserDropdown] = useState(false);
  const [selectLoser, setSelectLoser] = useState("Top Loosers");
  const [selectPeriod, setSelectPeriod] = useState(false);
  const [selectPeriodTime, setSelectPeriodTime] = useState("all");
  const [selectPeriodLoser, setSelectPeriodLoser] = useState(false);
  const [selectPeriodTimeLoser, setSelectPeriodTimeLoser] = useState("all");

  const selectPeriodDate = [
    { name: "Today", value: "today" },
    { name: "This Week", value: "last7days" },
    { name: "This Month", value: "thismonth" },
  ];

  const handleSummaryButton = () => {
    history.push("/existing-users");
  };
  const handleTopLosers = () => {
    setShowlooserDropdown((prev) => !prev);
  };
  const handleSelectLoser = (item) => {
    setSelectLoser(item);
    setShowlooserDropdown(false);
  };
  
  const handleOpenPeriod = async (item) => {
  setSelectPeriod((prev) => !prev);
  setSelectPeriodTime(item);

  console.log('summaryData', summaryData);
  console.log(item);

  await getAllSummaryData(item);
};

  const handleOpenPeriodLoser = (item) => {
    setSelectPeriodLoser((prev) => !prev);
    setSelectPeriodTimeLoser(item);
  };
  const getIndivisualMatchReport = async () => {
    await call(GET_INDUVISUAL_MATCH_REPORT, {
      register_id,
    })
      .then((res) => {
        setTopWinnersData(res?.data?.data?.topWinners);
        setTopLoosersData(res?.data?.data?.topLosers);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIndivisualMatchReport();
  }, []);

  const getAllWinners = async () => {
    await call(TOP_WINNERS_LOOSERS, {
      register_id: register_id,
      duration: selectPeriodTimeLoser,
      amountfilter: null,
      topclient: "winner",
    })
      .then((res) => {
        let result = res?.data;
        setTopWinners(result);
      })
      .catch((err) => console.log(err));
  };

  const getAllLoosers = async () => {
    await call(TOP_WINNERS_LOOSERS, {
      register_id: register_id,
      duration: selectPeriodTimeLoser,
      amountfilter: null,
      topclient: "looser",
    })
      .then((res) => {
        let result = res?.data;
        setTopLoosers(result);
      })
      .catch((err) => console.log(err));
  };
  const getAllSummaryData = async (timeRange) => {
  try {
    const res = await call(SUMMARY_DATA, { register_id, timeRange });
    setSummaryData(res?.data?.data);
  } catch (err) {
    console.log(err);
  }
};

  const summaryArray = [summaryData];

  useEffect(() => {
    getAllWinners();
    getAllLoosers();
  }, [selectPeriodTimeLoser]);

  useEffect(() => {
    getAllSummaryData('all');
  }, []);

  return (
    <IonGrid>
      <IonRow className="flex-space-between mrl-10">
        <IonCol size="5.8" className="summary">
          <div
            className="summary-content mb-10 font-weight-600"
            onClick={() => handleSummaryButton()}
          >
            Summary
          </div>
          <div className="w-100 h-45px">
            <div
              className="summary-content w-50 mb-10 font-12 flex-space-between"
              onClick={() => handleOpenPeriod()}
            >
              {selectPeriodTime ? selectPeriodTime : "Select"}
              <FaChevronDown className="ml-10 font-14" />
            </div>
            {selectPeriod && (
              <div className="dropdown-container2 p-5">
                {selectPeriodDate.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="summary-content mb-5 font-12 flex-space-between"
                      onClick={() => handleOpenPeriod(item?.value)}
                    >
                      {item?.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {summaryArray?.length > 0 &&
            summaryArray?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="summary-content flex-space-between mb-5 font-12">
                    <div>Total Bets</div>
                    <div>{item?.TotalBets}</div>
                  </div>
                  <div className="summary-content flex-space-between mb-5 font-12">
                    <div>Active Agents</div>
                    <div>{item?.activeAgents}</div>
                  </div>
                  <div className="summary-content flex-space-between mb-5 font-12">
                    <div>Active Users</div>
                    <div>{item?.activeUsers}</div>
                  </div>
                  <div className="summary-content flex-space-between mb-5 font-size-12">
                    <div>Profit Loss</div>
                    <div
                      className={`${
                        item?.totalLossOrProfit >= 0 ? "clr-green" : "clr-red"
                      }`}
                    >
                      {item?.totalLossOrProfit?.toFixed(2)}
                    </div>
                  </div>
                </div>
              );
            })}
        </IonCol>
        <IonCol size="5.8" className="top-losers flex-row">
          <div className="h-50 w-100">
            <div
              className="flex-space-between top-losers-content mb-10 font-weight-600"
              onClick={() => handleTopLosers()}
            >
              {selectLoser}
              <FaChevronDown className="font-16" />
            </div>
            {showlooserDropdown && (
              <div className="dropdown-container">
                <div
                  className="top-losers-content mb-10 font-weight-600"
                  onClick={() => handleSelectLoser("Top Loosers")}
                >
                  Top Loosers
                </div>
                <div
                  className="top-losers-content mb-10 font-weight-600"
                  onClick={() => handleSelectLoser("Top Winners")}
                >
                  Top Winners
                </div>
              </div>
            )}
          </div>
          <div className="w-100 h-45px">
            <div
              className="top-losers-content w-50 mb-10 font-12 flex-space-between"
              onClick={() => handleOpenPeriodLoser()}
            >
              {selectPeriodTimeLoser ? selectPeriodTimeLoser : "Select"}
              <FaChevronDown className="ml-10 font-14" />
            </div>
            {selectPeriodLoser && (
              <div className="dropdown-container-looser p-5">
                {selectPeriodDate?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="top-losers-content mb-5 font-12 flex-space-between"
                      onClick={() => handleOpenPeriodLoser(item?.value)}
                    >
                      {item?.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div>
            <DropdownList
              className="clr-blue mb-10 font-12"
              defaultValue="Select Package"
              data={[
                "25,000",
                "50,000",
                "75,000",
                "1,00,000",
                "1,50,000",
                "2,00,000",
                "5,00,,000",
                "10,000,00",
              ]}
            />
          </div>
          <div className="w-100 loser-list-scroll">
            {selectLoser === "Top Winners" &&
              topWinners?.length > 0 &&
              topWinners?.map((summary, index) => {
                return (
                  <div
                    key={index}
                    className="top-losers-content flex-space-between mb-5 font-12"
                  >
                    <div>{summary.username}</div>
                    <div className="clr-red fw-600">{summary.amount}</div>
                  </div>
                );
              })}
            {selectLoser === "Top Loosers" &&
              topLoosers?.length > 0 &&
              topLoosers?.map((summary, index) => {
                return (
                  <div
                    key={index}
                    className="top-losers-content flex-space-between mb-5 font-12"
                  >
                    <div>{summary.username}</div>
                    <div className="clr-green fw-600">{summary.amount}</div>
                  </div>
                );
              })}
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default Summary;
