import { MdModeEditOutline } from "react-icons/md";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { useEffect, useState } from "react";
import AddPaymentPopup from "../packages-accounts/AddPaymentPopup";
import { GET_ALL_PAYMENT_GATEWAYS } from "../../config/endpoints";
import { call } from "../../config/axios";

function PaymentGateWayList() {
  const register_id = localStorage.getItem("register_id");
  const [paymentGatewayPopup, setPaymentGatewayPopup] = useState(false);
  const [allPaymentGateway, setAllPaymentGateway] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState({});
  const [status, setStatus] = useState(false);

  const handleOpenPaymentGateway = (arugment) => {
    setPaymentGatewayPopup(true);
    setSelectedGateway(arugment);
  };

  const getAllPaymentData = async () => {
    await call(GET_ALL_PAYMENT_GATEWAYS, { register_id })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setAllPaymentGateway(res?.data?.data);
          console.log(res?.data?.data)
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllPaymentData();
  }, [status]);

  const neftPaymentData =
    allPaymentGateway?.length > 0 &&
    allPaymentGateway?.find((obj) => obj?.pg_upi === "neft");

  const phonePayData =
    allPaymentGateway?.length > 0 &&
    allPaymentGateway?.find((obj) => obj?.pg_upi === "phonepe");

  const paytmPmentData =
    allPaymentGateway?.length > 0 &&
    allPaymentGateway?.find((obj) => obj?.pg_upi === "paytm");

  const gPayPaymentData =
    allPaymentGateway?.length > 0 &&
    allPaymentGateway?.find((obj) => obj?.pg_upi === "gpay");

  const qrCodePaymenInfo =
    allPaymentGateway?.length > 0 &&
    allPaymentGateway?.find((obj) => obj?.pg_upi === "qr_code");
  console.log(qrCodePaymenInfo)
  return (
    <div>
      <MeetingsHeader heading={"Payment Gateway List"} />
      <div className="main-container">
        <div className="meetings-container">
          <div className="custom-box-shadow fancy-meetings-details-div">
            <div className="font-size-20 white-text flex-space-between p-5 w-100">
              <div className="w-30">Type</div>
              <div className="w-50">Details</div>
              <div className="flex-center w-20">Action</div>
            </div>
            <div className="payment-list-scroll">
              {neftPaymentData && (
                <div className="flex-space-between p-5 border-top">
                  <div className="font-12 w-30">NEFT/RTGS</div>
                  <div className="meetings-time w-50">
                    <div>
                      <div>Name:{neftPaymentData?.account_holder_name}</div>
                      <div>Ac.No:{neftPaymentData?.account_number}</div>
                      <div>IFSC:{neftPaymentData?.ifsc_code}</div>
                      <div>Bank:{neftPaymentData?.bank_name}</div>
                    </div>
                  </div>
                  <div className="flex-center w-20">
                    <MdModeEditOutline
                      className="font-25 clr-white"
                      onClick={() => handleOpenPaymentGateway(neftPaymentData)}
                    />
                  </div>
                </div>
              )}
              {phonePayData && (
                <div className="flex-space-between p-5 border-top">
                  <div className="font-12 w-30">Phone Pe</div>
                  <div className="meetings-time w-50">
                    <div>
                      <div>Name:{phonePayData?.pg_name}</div>
                      <div>Mobile No:{phonePayData?.mobile_number}</div>
                    </div>
                  </div>
                  <div className="flex-center w-20">
                    <MdModeEditOutline
                      className="font-25 clr-white"
                      onClick={() => handleOpenPaymentGateway(phonePayData)}
                    />
                  </div>
                </div>
              )}
              {paytmPmentData && (
                <div className="flex-space-between p-5 border-top">
                  <div className="font-12 w-30">Paytm</div>
                  <div className="meetings-time w-50">
                    <div>
                      <div>Name:{paytmPmentData?.pg_name}</div>
                      <div>Mobile No:{paytmPmentData?.mobile_number}</div>
                    </div>
                  </div>
                  <div className="flex-center w-20">
                    <MdModeEditOutline
                      className="font-25 clr-white"
                      onClick={() => handleOpenPaymentGateway(paytmPmentData)}
                    />
                  </div>
                </div>
              )}
              {gPayPaymentData && (
                <div className="flex-space-between p-5 border-top">
                  <div className="font-12 w-30">G Pay</div>
                  <div className="meetings-time w-50">
                    <div>
                      <div>Name:{gPayPaymentData?.pg_name}</div>
                      <div>Mobile No:{gPayPaymentData?.mobile_number}</div>
                    </div>
                  </div>
                  <div className="flex-center w-20">
                    <MdModeEditOutline
                      className="font-25 clr-white"
                      onClick={() => handleOpenPaymentGateway(gPayPaymentData)}
                    />
                  </div>
                </div>
              )}
              {qrCodePaymenInfo && (
                <div className="flex-space-between p-5 border-top">
                  <div className="font-12 w-30">QR Code</div>
                  <div className="meetings-time w-50">
                    <div>
                      <div>Name:{qrCodePaymenInfo.pg_upi}</div>
                      <img
                        className="qr-code-img"
                        src={qrCodePaymenInfo.uploadImage}
                        alt="QR_Code"
                      />
                    </div>
                  </div>
                  <div className="flex-center w-20">
                    <MdModeEditOutline
                      className="font-25 clr-white"
                      onClick={() => handleOpenPaymentGateway(qrCodePaymenInfo)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddPaymentPopup
        state={paymentGatewayPopup}
        selectedGateway={selectedGateway}
        setState={setPaymentGatewayPopup}
        apiResponse={(e) => setStatus(e)}
      />
    </div>
  );
}

export default PaymentGateWayList;
