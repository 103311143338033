import { IonModal } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { Images } from "../images";
import { BiSolidChevronRight } from "react-icons/bi";
import { useHistory } from "react-router";

function StatementPopup(props) {
  const reports = [
    {
      icon: Images.OnepageReport,
      name: "Sale Tickets",
      path: "/admin-ticket-purchase-data",
    },
    {
      icon: Images.Upgrade,
      name: "Upgrade Packages",
      path: "/package-upgrade",
    },
    {
      icon: Images.MatchStatement,
      name: "Package Statement",
      path: "/package-statement",
    },
    {
      icon: Images.CallHistory,
      name: "Call Reports",
      path: "/call-reports",
    },
    {
      icon: Images.MatchStatement,
      name: "Match Statement",
      path: "/match-statement",
    },
    {
      icon: Images.OnepageReport,
      name: "One Page Report",
      path: "/one-page-report",
    },
    {
      icon: Images.Settlement,
      name: "Settlement",
      path: "/settlement",
    },
    {
      icon: Images.Settlement,
      name: "Settlement-Statement",
      path: "/settlement-statement",
    },
    {
      icon: Images.OnepageReport,
      name: "Admin-One-Page-Report",
      path: "/admin-one-page-report",
    },
    {
      icon: Images.OnepageReport,
      name: "Live-Live-Report",
      path: "/live-line-report",
    },
  ];
  const history = useHistory();
  const { statementPopupOpen, setStatementPopupOpen } = props;
  const handleCloseStatement = () => {
    setStatementPopupOpen(false);
  };
  return (
    <IonModal isOpen={statementPopupOpen} className="statememt-popup-main">
      <div className="modal-bg p-10 clr-white">
        <div className="flex-space-between mb-10 ">
          <span className="reports-heading">Reports</span>
          <IoMdClose
            className="reports-heading"
            onClick={() => handleCloseStatement()}
          />
        </div>
        {reports.map((item, index) => {
          return (
            <div
              key={index}
              className="flex-space-between reports-main custom-box-shadow mb-10"
              onClick={() => history.push(item.path)}
            >
              <div className="d-flex ">
                <img className="reports-img ml-10" src={item.icon}></img>
                <div className="ml-10 reports-text">{item.name}</div>
              </div>
              <BiSolidChevronRight className="reports-icons" />
            </div>
          );
        })}
      </div>
    </IonModal>
  );
}

export default StatementPopup;
