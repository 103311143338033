import React, { useState } from "react";
import { IonImg, IonModal } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { Images } from "../../images";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { MdOutlineZoomOutMap, MdZoomInMap } from "react-icons/md";
import moment from "moment";
function TicketUpgradePopup(props) {
  const {
    showTicketUpgradePopup,
    setShowTicketUpgradePopup,
    handleSuccessfullPopup,
    handlePackageViewPopup,
    transactionData = {},
    isProcessing,
    rejectionList,
    status,
  } = props;
  const handleTicketUpgradePopupClose = () => {
    setShowTicketUpgradePopup(false);
  };

  const [selectedReason, setSelectedReason] = useState(false);
  const [showRejectionDropdown, setShowRejectionDropdown] = useState("");

  const handleRejectionDropdown = () => {
    setShowRejectionDropdown((prev) => !prev);
  };
  const handleRejectionSelect = (value) => {
    setSelectedReason(value);
    setShowRejectionDropdown(false);
  };

  const [zoomOutImage, setZoomOutImage] = useState(false);
  const handleZoomOutImage = () => {
    setZoomOutImage(!zoomOutImage);
  };

  return (
    <IonModal isOpen={showTicketUpgradePopup} className="upgrade-popup-main">
      <div className="modal-bg p-10 clr-white">
        <div>
          <div className="flex-space-between mb-10 relative-position">
            <div>
              <div className="font-16 mb-5">
                {transactionData?.summary?.requester_name} -
                {transactionData?.summary?.requester_role}
              </div>
              <div className="approved-color meetings-date-time-div h-30p w-50 flex-start custom-box-shadow font-size-14 mt-5 p-5">
                Deposit
              </div>
            </div>
            <IoMdClose
              className="reports-heading absolute-position-top-right"
              onClick={() => handleTicketUpgradePopupClose()}
            />
          </div>
          <div className="hr-line mt-10 mb-10"></div>
        </div>
        <div className="flex-space-between upgrade-popup-box-container custom-box-shadow w-100 mt-10 p-5">
          <div className="font-12">Trx ID</div>
          <div className="font-12">#{transactionData?.transaction_id}</div>
        </div>
        <div className="flex-space-between upgrade-popup-box-container custom-box-shadow w-100 mt-10 p-5">
          <div className="font-12">Reference ID</div>
          <div className="font-12">{transactionData?.package_requester_id}</div>
        </div>
        <div className="upgrade-popup-box-container custom-box-shadow w-100 mt-10 p-5 h-auto">
          <div className="flex-space-between mt-5 mb-5 w-100 flex-row">
            <div className="font-12">Amount</div>
            <div className="font-12">
              ${transactionData?.summary?.final_package_cost}
            </div>
          </div>
          <div className="flex-space-between mt-5 mb-5 w-100 flex-row">
            <div className="font-12">Payment Method</div>
            <div className="font-12">{transactionData?.summary?.pg_upi}</div>
          </div>
          <div className="flex-space-between mt-5 mb-5 w-100 flex-row">
            <div className="font-12">From</div>
            <div className="font-12">
              {transactionData?.summary?.requester_name}-
              {transactionData?.summary?.requester_role}
            </div>
          </div>
          <div className="flex-space-between mt-5 mb-5 w-100 flex-row">
            <div className="font-12">To</div>
            <div className="font-12">
              {localStorage.getItem("user_name")}-
              {localStorage.getItem("account_role")}
            </div>
          </div>
          <div className="flex-space-between mt-5 mb-5 w-100 flex-row">
            <div className="font-12">Time</div>
            <div className="font-12">
              {moment(transactionData?.created_date).format("DD-MM-YYYY")}
              {transactionData?.created_time}
            </div>
          </div>
        </div>
        {console.log(transactionData?.summary)}
        <div className="w-100 pos-relative mt-10">
          <IonImg
            className="w-100 h-15"
            src={transactionData?.summary?.transaction_img || Images.DogImage}
          ></IonImg>
          <MdOutlineZoomOutMap
            onClick={() => handleZoomOutImage()}
            className="zoom-icon pos-absolute font-25 clr-white"
          />
        </div>
        <IonModal isOpen={zoomOutImage} className="upgrade-popup-main">
          <div className="w-100 d-flex justify-end">
            <MdZoomInMap
              onClick={() => handleZoomOutImage()}
              className="font-25 clr-white"
            />
          </div>
          <IonImg
            className="w-100 h-75vh"
            src={transactionData?.summary?.transaction_img || Images.DogImage}
          ></IonImg>
        </IonModal>
        <div className="flex-space-between upgrade-popup-box-container custom-box-shadow w-100 mt-10 p-10">
          <div className="font-12">Package List</div>
          <div
            className="font-12 clr-text p-20"
            onClick={handlePackageViewPopup}
          >
            View
          </div>
        </div>
        <div
          className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
          onClick={() => handleRejectionDropdown()}
        >
          <div>
            {selectedReason ? selectedReason : "Select Rejection Reason"}
          </div>
          {showRejectionDropdown ? (
            <RiArrowDropUpLine style={{ fontSize: "40px" }} />
          ) : (
            <RiArrowDropDownLine style={{ fontSize: "40px" }} />
          )}
        </div>
        {showRejectionDropdown && (
          <div className="select-team-dropdown payment-position-dropdown w-50 font-12 h-150px">
            {rejectionList.map((obj, index) => (
              <div
                className="h-40 flex-center mb-5 "
                key={index}
                onClick={() => handleRejectionSelect(obj.reason)}
              >
                {obj.reason}
              </div>
            ))}
          </div>
        )}
        <input
          type="text"
          placeholder="Specify Others"
          className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
        ></input>
        {transactionData.status == "pending" ? (
          <div className="flex-space-around flex-row w-100">
            <button
              onClick={() =>
                handleSuccessfullPopup(
                  transactionData?.transaction_id,
                  transactionData.type,
                  "Approved"
                )
              }
              disabled={isProcessing}
              className="confirm-upgrade-popup-button  button-box-shadow flex-center font-size-14 fw-600 mt-15 w-45 green-clr"
            >
              {isProcessing && status !== "Reject" ? "Processing..." : "Accept"}
            </button>
            <button
              onClick={() =>
                handleSuccessfullPopup(
                  transactionData?.transaction_id,
                  transactionData.type,
                  "Reject",
                  selectedReason
                )
              }
              disabled={isProcessing}
              className="confirm-upgrade-popup-button  button-box-shadow flex-center font-size-14 fw-600 mt-15 w-45 red-clr"
            >
              {isProcessing && status == "Reject" ? "Processing..." : "Reject"}
            </button>
          </div>
        ) : (
          <div className="font-size-14 fw-600 mt-10 ml-10">
            you have already
            <span
              className={
                transactionData.status === "Approved" ? "green-clr" : "red-clr"
              }
            >
              {transactionData.status}
            </span>
          </div>
        )}
      </div>
    </IonModal>
  );
}

export default TicketUpgradePopup;
