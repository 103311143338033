import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";

function FancyShareOversPopup(props) {
  const { handlefancyShareOversPopupClose, data, totalMatchResultData } = props;
  const FANCY_SHARE_POPUP = [
    {
      name: "Srinivas2346",
      overs: "10 Overs",
      clientShare: 10000000.0,
      rfShare: 10000000.0,
      ulShare: 10000000.0,
    },
    {
      name: "Srinivas2346",
      overs: "20 Overs",
      clientShare: 10000000.0,
      rfShare: 10000000.0,
      ulShare: 10000000.0,
    },
    {
      name: "Srinivas2346",
      overs: "30 Overs",
      clientShare: 10000000.0,
      rfShare: 10000000.0,
      ulShare: 10000000.0,
    },
    {
      name: "Srinivas2346",
      overs: "30 Overs",
      clientShare: 10000000.0,
      rfShare: 10000000.0,
      ulShare: 10000000.0,
    },
  ];
  return (
    <IonGrid className="fancy-commission-popup-pos meetings-container w-75 match-share-popup">
      <div className="custom-box-shadow  fancy-meetings-popups-div">
        <div
          className="flex-flex-end fancy-close-icon"
          onClick={() => handlefancyShareOversPopupClose()}
        >
          <RxCross2 />
        </div>
        <IonRow className="flex-space-between w-100 pr-5 pl-5">
          <IonCol
            size="4"
            className="br-15 custom-box-shadow flex-center flex-column font-10"
          >
            <div>Overs</div>
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            C Share
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            RF Share
          </IonCol>
        </IonRow>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data?.length > 0 && data?.map((fancy, index) => (
            <div key={index} className="flex-row">
              <IonRow className="flex-space-between w-100 align-end mt-5 pl-10 pr-10">
                <IonCol size="5" className="meetings-time font-2">
                  <div>{fancy?.key}</div>
                  <div
                    className={fancy?.clientShare >= 0 ? "green-clr" : "red-clr"}
                  >
                    {fancy?.clientShare ? fancy?.clientShare?.toFixed(2) : 0}
                  </div>
                </IonCol>
                <IonCol
                  size="3.5"
                  className={
                    fancy?.rfShare >= 0
                      ? "green-clr font-12 w-20  text-center"
                      : "red-clr font-12 w-20 text-center"
                  }
                >
                  {fancy?.rfShare ? fancy?.rfShare?.toFixed(2) : 0}
                </IonCol>
                <IonCol
                  size="3.5"
                  className={
                    fancy?.upperLevalShare >= 0
                      ? "green-clr font-12 w-20 text-center"
                      : "red-clr font-12 w-20 text-center"
                  }
                >
                  {fancy?.upperLevalShare ? fancy?.upperLevalShare?.toFixed(2) : 0}
                </IonCol>
              </IonRow>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
        </div>
        <IonRow className="flex-space-between align-end mt-5 pl-10 pr-10 fancy-total-row">
          <IonCol size="5" className="meetings-time font-2">
            <div className="font-14 fw-600">Total</div>
          </IonCol>
          <IonCol size="3.5" className="green-clr font-12 w-25 text-center">
            {totalMatchResultData?.clientShare ? totalMatchResultData?.clientShare?.toFixed(2) : 0}
          </IonCol>
          <IonCol size="3.5" className="green-clr font-12 w-25 text-center">
            {totalMatchResultData?.ULShare ? totalMatchResultData?.ULShare?.toFixed(2) : 0}
          </IonCol>
        </IonRow>
      </div>
    </IonGrid>
  );
}

export default FancyShareOversPopup;
