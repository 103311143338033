import { useEffect, useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { call } from "../../config/axios";
import { GET_LIVE_MATCH_RISK_POSITION } from "../../config/endpoints";
import moment from "moment";
import { IonCol, IonRow } from "@ionic/react";

function ShareFancyRisk() {
  const register_id = localStorage?.getItem("register_id");
  const [liveMatches, setLiveMatches] = useState([]);

  const tableMatchRowData = [
    {
      title: "Match Name",
    },
    {
      title: "Teams",
    },
    {
      title: "Match P/L",
    },
    {
      title: "Fancy P/L",
    },
  ];

  const getUlShare = (netPl, ulShare) => {
    const netAmount = (+netPl * +ulShare) / 100;
    return netAmount;
  };

  const totalWin =
    liveMatches &&
    liveMatches?.length > 0 &&
    liveMatches?.reduce(
      (acc, obj) =>
        acc +
        (getUlShare(
          obj?.matchRiskObject?.winCalculation?.profiltLoss,
          obj?.ul_share
        ) || 0),
      0
    );
  const totalLoss =
    liveMatches &&
    liveMatches?.length > 0 &&
    liveMatches?.reduce(
      (acc, obj) =>
        acc +
        (getUlShare(
          obj?.matchRiskObject?.winCalculation?.profiltLoss,
          obj?.ul_share
        ) || 0),
      0
    );

  const matchDetails =
    liveMatches &&
    liveMatches?.length > 0 &&
    liveMatches?.map((match) => {
      return {
        user: match?.user_name,
        matchMode: match?.series_name,
        venue: match?.stadium,
        date: moment(match?.matchTimeStamp).format("DD-MM-YYYY"),
        time: moment(match?.matchTimeStamp).format("hh:mm:ss A"),
        match: (
          <div className="flex-column">
            <div>{match?.team1}</div>
            <div>{match?.team2}</div>
          </div>
        ),
        teama: (
          <div className="flex-column">
            <div
              className={`font-size-12 w-20 flex-center ${
                getUlShare(
                  match?.matchRiskObject?.winCalculation?.profiltLoss,
                  match?.ul_share
                ) >= 0
                  ? "green-clr"
                  : "red-clr"
              }`}
            >
              {getUlShare(
                match?.matchRiskObject?.winCalculation?.profiltLoss,
                match?.ul_share
              )}
            </div>
            <div
              className={`font-size-12 w-20 flex-center ${
                getUlShare(
                  match?.matchRiskObject?.loseCalcultion?.profiltLoss,
                  match?.ul_share
                ) >= 0
                  ? "green-clr"
                  : "red-clr"
              }`}
            >
              {getUlShare(
                match?.matchRiskObject?.loseCalcultion?.profiltLoss,
                match?.ul_share
              )}
            </div>
          </div>
        ),
        teamb: (
          <div className="flex-column">
            <div>0</div>
            <div>0</div>
          </div>
        ),
      };
    });

  const getLiveMatches = async () => {
    await call(GET_LIVE_MATCH_RISK_POSITION, {
      register_id,
    })
      .then(async (res) => {
        const results = res?.data?.data.filter(
          (i) => i?.match_declared !== "Y"
        );
        console.log('results',results)
        setLiveMatches(results);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLiveMatches();
  }, []);

  return (
    <div>
      <MeetingsHeader heading="Share Risk Live Match" />
      <div className="main-container p-10">
        <div className="custom-box-shadow m-none overflow-none meetings-details-div">
          <div className="flex-row flex-space-between p-10">
            <div className=" custom-box-shadow upcoming-meetings-div flex-start font-16 w-60 p-5">
              Share Risk - Live Match
            </div>
          </div>
          <div className="hr-line mt-5" />
          <div className="d-flex">
            {tableMatchRowData.map((matchData, index) => (
              <div key={index} className="flex-center mt-5 pr-5 pl-5 w-25">
                <div className="match-history-button flex-center custom-box-shadow font-10">
                  {matchData.title}
                </div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10 "></div>
          <div className="upcoming-meet-scroll-1  ml-5">
            {matchDetails &&
              matchDetails?.length > 0 &&
              matchDetails?.map((matchdata, index) => (
                <IonRow key={index} className="font-10">
                  <IonCol
                    size="3"
                    className="meetings-time w-20 font-2 padding-tb"
                  >
                    <div className="flex-row ">
                      <div>{matchdata.user}</div>
                      <div>{matchdata.role}</div>
                      <div>{matchdata.matchMode}</div>
                      <div>{matchdata.venue}</div>
                      <div>{matchdata.date}</div>
                      <div>{matchdata.time}</div>
                    </div>
                  </IonCol>
                  <IonCol
                    size="3"
                    className="font-12 w-20 flex-column flex-center"
                  >
                    <div className="flex-center">{matchdata.match}</div>
                  </IonCol>
                  <IonCol size="3" className="flex-center">
                    {matchdata.teama}
                  </IonCol>
                  <IonCol size="3" className="flex-center">
                    {matchdata?.teamb}
                  </IonCol>
                </IonRow>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareFancyRisk;
