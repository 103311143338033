import { useEffect, useState } from "react";
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import EditPopup from "../popups/EditPopup";
import {
  DELETE_MATCH_ENTRY,
  GET_MATCH_ENTRY_DETAILS,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { FaMinus } from "react-icons/fa6";
import EditMatchEntryPopup from "./EditMatchEntryPopup";
import DeleteMatchEntryPopup from "./DeleteMatchEntryPopup";
import moment from "moment";

function MatchBetHistory(props) {
  const {
    seriesType,
    selectedMatch,
    matchAccountData,
    setSelectedMatchEntry,
    status,
    setStatus,
    getMatchPositionData,
  } = props;

  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");

  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletPopup] = useState(false);
  const [data, setData] = useState([]);
  const [matchEntryData, setMatchEntryData] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const handleOpenEditPopup = (DATA) => {
    setMatchEntryData(DATA);
    setEditPopup(true);
  };
  const handleOpenDeletePopup = (matchId) => {
    setSelectedId(matchId);
    setDeletPopup(true);
  };

  const deleteApi = async () => {
    await call(DELETE_MATCH_ENTRY, {
      match_entry_id: selectedId,
      register_id,
    })
      .then((res) => {
        setSelectedId("");
        setStatus((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const getMatchEntryDetails = async () => {
    await call(GET_MATCH_ENTRY_DETAILS, {
      registered_match_id: matchAccountData?.registered_match_id,
      register_id,
    })
      .then((res) => {
        setData(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatchEntryDetails();
  }, [matchAccountData?.registered_match_id, status]);

  console.log(data, "===>data");

  return (
    <div className="meetings-container w-92">
      <div className="custom-box-shadow  fancy-meetings-details-div mt-15">
        <div className="flex-row flex-space-between">
          <div className="custom-box-shadow fancy-upcoming-meetings-div font-14 w-60 p-10 mar-5">
            <b>Bet</b> History
            <span className="font-12 pl-10">{seriesType}</span>
          </div>
        </div>
        <div className="hr-line mt-5"></div>
        <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
          <div className="fancy-match-history-button custom-box-shadow flex-center font-10">
            Bet Place
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Name
          </div>
          {selectedMatch?.game_object?.match_type === "testMatch" && (
            <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
              DRAW
            </div>
          )}
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            {selectedMatch?.team1}
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            {selectedMatch?.team2}
          </div>
        </div>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data?.length > 0 &&
            data
              ?.filter((i) => i.match_status !== "Y")
              ?.map((match, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5 pl-10 pr-10">
                    <div
                      className={
                        match.record_status === "updated"
                          ? "match-entry-edit-color meetings-time w-25 padding-tb"
                          : match.record_status === "deleted"
                          ? "match-entry-delete-color meetings-time w-25 padding-tb"
                          : "meetings-time w-25 padding-tb"
                      }
                    >
                      <div>
                        <div>
                          S.No:
                          {match?.old_s_no === match?.s_no
                            ? match?.s_no
                            : `${match?.s_no}/${match?.old_s_no}`}
                        </div>
                        <div className="flex">
                          <div> {match?.team}</div>-
                          <div
                            className={
                              match?.pe === "P" ? "clr-green" : "clr-pink"
                            }
                          >
                            {match?.pe}
                          </div>
                        </div>
                        <div>Rate:{match?.rate}</div>
                        <div className="yellow-clr">{match?.amount}</div>
                        <div>
                          {moment(match?.givenTimeStamp).format("DD-MM-YYYY")}
                        </div>
                        <div>
                          {moment(match?.givenTimeStamp).format("hh:mm:ss A")}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        match.record_status === "updated"
                          ? "match-entry-edit-color font-12 w-22 mt-15"
                          : "font-12 w-22 mt-15"
                      }
                    >
                      {match?.client_name}
                    </div>
                    {selectedMatch?.game_object?.match_type === "testMatch" && (
                      <div
                        className={`font-12-1 w-20 mt-15 ${
                          +match?.teamObj["draw"] > 0 ? "green-clr" : "red-clr"
                        }`}
                      >
                        {match?.teamObj["draw"]}
                      </div>
                    )}
                    <div
                      className={`font-12-1 w-20 mt-15 ${
                        +match?.teamObj[selectedMatch?.team1] > 0
                          ? "green-clr"
                          : "red-clr"
                      }`}
                    >
                      {match?.teamObj[selectedMatch?.team1]}
                    </div>
                    <div className="flex-column d-flex w-20 mt-45">
                      <div
                        className={`font-12-1 ${
                          +match?.teamObj[selectedMatch?.team2] > 0
                            ? "green-clr"
                            : "red-clr"
                        }`}
                      >
                        {match?.teamObj[selectedMatch?.team2]}
                      </div>
                      <div className="flex-space-between mt-15">
                        {match.record_status === "active" && (
                          <>
                            <MdModeEditOutline
                              className="edit-delete-btn green-clr"
                              onClick={() => handleOpenEditPopup(match)}
                            />
                            <MdDeleteOutline
                              className="edit-delete-btn red-clr"
                              onClick={() =>
                                handleOpenDeletePopup(match?.match_entry_id)
                              }
                            />
                          </>
                        )}
                        {match.record_status === "updated" && (
                          <>
                            <FaMinus className="edit-delete-btn yellow-clr" />
                            <FaMinus className="edit-delete-btn yellow-clr" />
                          </>
                        )}
                        {match.record_status === "deleted" && (
                          <>
                            <FaMinus className="edit-delete-btn yellow-clr" />
                            <FaMinus className="edit-delete-btn yellow-clr" />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hr-line mt-5"></div>
                </div>
              ))}
        </div>
      </div>
      {editPopup && (
        <EditMatchEntryPopup
          openPopup={editPopup}
          setclosePopup={setEditPopup}
          displayData="Are you sure you want edit this match entry?"
          data={matchEntryData}
          setSelectedMatchEntry={setSelectedMatchEntry}
          getMatchPositionData={getMatchPositionData}
        />
      )}
      {deletePopup && (
        <DeleteMatchEntryPopup
          openPopup={deletePopup}
          setclosePopup={setDeletPopup}
          displayData="Are you sure you want to delete?"
          deletedId={selectedId}
          deleteApi={deleteApi}
          setSelectedId={setSelectedId}
          getMatchPositionData={getMatchPositionData}
        />
      )}
    </div>
  );
}

export default MatchBetHistory;
