import { IonAvatar, IonImg } from "@ionic/react";
import "./styles.css";
import { ImageBaseUrl, Images } from "../../images";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { MdOutlineEdit } from "react-icons/md";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import { useEffect, useState } from "react";
import {
  GET_USER_INFO,
  UPDATE_PROFILE,
  GENERATE_SIGNED_URL,
} from "../../config/endpoints";
import { call,Signed } from "../../config/axios";

function EditProfile() {
  const register_id = localStorage?.getItem("register_id");
  const [editProfileSubmitPopup, setEditProfileSubmitPopup] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [singedUrl, setSignedUrl] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    user_name: "",
  });
  const getProfile = async (id) => {
    try {
      const res = await call(GET_USER_INFO, { register_id });
      const userProfileData = res?.data?.data[0];
      setFormData({
        first_name: userProfileData?.first_name || "",
        last_name: userProfileData?.last_name || "",
        email: userProfileData?.email || "",
        mobile_no: userProfileData?.mobile_no || "",
        user_name: userProfileData?.user_name || "",
        profileImage: userProfileData?.profile_image || "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const generateSignedUrl = async () => {
    setIsProcessing(true);
    await Signed(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "profile-images",
    })
      .then(async (res) => {
        setIsProcessing(false);
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "mobile_no" && e.target.value.length > 10) {
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadFileSelect = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    generateSignedUrl();
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    setIsProcessing(true);
    singedUrl &&
      profileImage &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err)));

    await call(UPDATE_PROFILE, {
      register_id: localStorage?.getItem("register_id"),
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile_no: formData.mobile_no,
      user_name: formData.user_name,
      profile_image: `${ImageBaseUrl}/${"profile-images"}/${register_id}.png`,
      account_role: localStorage?.getItem("account_role"),
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setIsProcessing(false);
          setEditProfileSubmitPopup(true);
          window.location.reload("");
          setTimeout(() => {
            setEditProfileSubmitPopup(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!formData?.first_name || !formData?.last_name || !formData?.user_name) {
      newErrors.errorMessage = "Please enter required fields";
      isValid = false;
    }
    if (!/^\S+@gmail\.com$/.test(formData.email)) {
      newErrors.email = "Please enter a Gmail address";
      isValid = false;
    }
    if (!/^[0-9]{10}$/.test(formData.mobile_no)) {
      newErrors.mobile_no = "Mobile number is invalid";
      isValid = false;
    }
    setErrMsg(newErrors);
    return isValid;
  };

  return (
    <div className="main-container">
      <MeetingsHeader heading={"Edit Profile"} />
      <div className="edit-profile-container">
        <div>
          <IonAvatar className="edit-profile-img custom-box-shadow">
            <IonImg
              src={formData?.profileImage || Images.DeclareManImage}
              alt="edit-profile-image"
            />
          </IonAvatar>
        </div>
        <div className="flex-center edit-profile-icon custom-box-shadow">
          <label htmlFor="upload-button" className="flex-center">
            <MdOutlineEdit className="edit-icon-black" />
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleUploadFileSelect}
          />
        </div>
        <div className="mt-20">
          <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow"
            placeholder="First Name"
            type="text"
            name="first_name"
            value={formData?.first_name}
            onChange={handleChange}
          />
          <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow"
            placeholder="Last Name"
            type="text"
            name="last_name"
            value={formData?.last_name}
            onChange={handleChange}
          />
          <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow"
            placeholder="Email ID"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <span className="err-msg">{errMsg?.email}</span>
          <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow"
            placeholder="Mobile Number"
            type="number"
            maxLength={10}
            name="mobile_no"
            value={formData.mobile_no}
            onChange={handleChange}
            required
          />
          <span className="err-msg">{errMsg?.mobile_no}</span>
          <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow"
            placeholder="User Name"
            type="text"
            name="user_name"
            value={formData.user_name}
            onChange={handleChange}
            required
          />
        </div>
        <span className="err-msg">{errMsg?.errorMessage}</span>
        <div
          className="w-100 mt-10 font-14 edit-btn-clr button-box-shadow h-44px br-10 flex-center"
          onClick={handleSubmit}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Update Profile"}
        </div>
      </div>
      <FancyThankYouPopup
        openPopup={editProfileSubmitPopup}
        setclosePopup={setEditProfileSubmitPopup}
        displayData={"Profile Updated Successfully"}
      />
    </div>
  );
}

export default EditProfile;
