import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import UpgradeYourPackagePage from "../packages-accounts/UpgradeYourPackagePage";
import { GET_ADMIN_PACKAGES } from "../../config/endpoints";
import { call } from "../../config/axios";
import TopupHoursAdding from "../packages-accounts/TopupHoursAdding";
import moment from "moment";

function UpgradeYourPackages() {
  const register_id = localStorage?.getItem("register_id");
  const [upgradeType, setUpgradeType] = useState("packages");
  const [disabled, setDisabled] = useState(false);
  const [adminSubscription, setAdminSubscription] = useState();
  const getAdminPackages = async () => {
    await call(GET_ADMIN_PACKAGES, { register_id })
      .then((res) => {
        setAdminSubscription(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdminPackages();
  }, []);

  const subscriptions =
    adminSubscription?.length > 0 &&
    adminSubscription
      ?.filter((item) => item.subscriptions)
      .filter((item) => item.created_date)
      .sort((a, b) => moment(b.created_date) - moment(a.created_date));

  const handleUpgradePacks = (item) => {
    setUpgradeType(item);
  };

  console.log(subscriptions, "subscriptions");

  return (
    <div className="p-5">
      <IonGrid className="connect-device">
        <IonRow className="border-bottom-yellow py-10">
          <IonCol size="4" className="flex-center">
            <div className="upcoming-table-heading custom-box-shadow yellow-clr flex-center font-14 p-5">
              {localStorage.getItem("user_name")}
            </div>
          </IonCol>
          <IonCol size="4" className="flex-center">
            <div className="upcoming-table-heading custom-box-shadow flex-center font-14">
              Package
            </div>
          </IonCol>
          <IonCol size="4" className="flex-center">
            <div className="upcoming-table-heading custom-box-shadow flex-center font-14">
              Date & Hrs
            </div>
          </IonCol>
        </IonRow>
        <div className="h-25vh overflow-scroll">
          {subscriptions?.length > 0 &&
            subscriptions
              ?.filter((item) =>
                moment(item.created_date).isAfter(moment().subtract(7, "days"))
              )
              ?.map((item, index) => (
                <IonRow key={index} className="py-10 border-bottom-yellow">
                  <IonCol size="4.5">
                    <div className="text-start clr-white font-14 w-100">
                      <div>
                        Name:
                        {item?.subscriptions?.map((item) => item?.package_name)}
                      </div>
                      <div className="mt-5">
                        Price:
                        <span className="clr-green">
                          {item?.subscriptions?.map(
                            (item) => item?.package_cost
                          )}
                        </span>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="text-start clr-white font-14 w-100">
                      <div>
                        Exp:
                        <span className="clr-yellow">
                          {moment(item?.expiry_date).format("DD-MM-YYYY")}
                        </span>
                      </div>
                      <div className="mt-5">
                        CD:
                        <span className="clr-yellow">
                          {moment(item?.created_date).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol size="3.5">
                    <div className="text-start clr-white font-14 w-100">
                      <div>
                        Dur:
                        <span className="clr-green ml-5">
                          {item?.subscriptions?.map(
                            (item) => item?.package_limits?.duration
                          )}
                        </span>
                      </div>
                      <div className="mt-5">
                        {item?.subscriptions?.map(
                          (item) => item?.package_duration
                        )}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              ))}
        </div>
      </IonGrid>
      <div className="d-flex justify-content-between align-items-center w-100 mt-15">
        <div
          className="upcoming-table-heading custom-box-shadow flex-center clr-brown w-50 ml-5 mr-5 p-10 fw-600 font-16"
          onClick={() => handleUpgradePacks("packages")}
        >
          Upgrade
          <BsFillRocketTakeoffFill className="ml-5 plus-icon" />
        </div>
        <div
          className="upcoming-table-heading custom-box-shadow flex-center clr-brown w-50 ml-5 mr-5 p-10 fw-600 font-16"
          onClick={() => handleUpgradePacks("hours")}
        >
          Topup Hrs
          <BsFillRocketTakeoffFill className="ml-5 plus-icon" />
        </div>
      </div>
      {upgradeType === "packages" && <UpgradeYourPackagePage />}
      {upgradeType === "hours" && (
        <TopupHoursAdding adminSubscription={adminSubscription} />
      )}
    </div>
  );
}

export default UpgradeYourPackages;
