import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function EditMatchPopUp({
  setMatchDetails,
  popUp,
  setPopUp,
  matchData,
  setMatchEditStatus,
}) {
  const handleClose = () => {
    setPopUp((prev) => !prev);
  };
  
  return (
    <IonModal className="statememt-popup-main" isOpen={popUp}>
      <center>
        <div className="w-100 flex-flex-end p-5" onClick={() => handleClose()}>
          <RxCross1 />
        </div>
        <div>
          <img
            className="fancy-declare-image"
            src={Images.ThumbsUpImage}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-75 fancy-declare-text mt-10">
          Are sure you want edit {matchData?.match_name} match
        </div>
        <div className="flex-center w-100">
          <div
            className="mt-15 flex-space-around w-100"
            onClick={() => {
              setMatchDetails(matchData);
              handleClose();
              setMatchEditStatus(true);
            }}
          >
            <button className="w-80 button-box-shadow fancy-declare-button green-clr">
              YES
            </button>
          </div>
          <div
            className="mt-15 flex-space-around w-100"
            onClick={() => handleClose()}
          >
            <button className="w-80 button-box-shadow fancy-declare-button red-clr">
              NO
            </button>
          </div>
        </div>
      </center>
    </IonModal>
  );
}

export default EditMatchPopUp;
