import { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import AdminManagement from "./AdminManagement";
import AdminCreation from "./AdminCreation";
import AddAdminList from "./AddAdminList";
import UpgradeAdminPackages from "./UpgradeAdminPackages";
import SelectPackages from "./SelectPackages";
import SelectPackagesFooter from "./SelectPackagesFooter";
import UpgradePackagesPopup from "./UpgradePackagesPopup";
import RegistrationSuccessful from "./RegistrationSuccessful";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import TopupHours from "./TopupHours";
import TopupHoursPackages from "./TopupHoursPackages";
import AdiminUsersList from "./AdiminUsersList";
import PackageTransaction from "../packages-accounts/PackageTransaction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AddAdmins() {
  const [typeOfAdd, setTypeOfAdd] = useState("Add Users");
  const [openPackageUpgrade, setOpenPackageUpgrade] = useState(false);
  const [registationPopup, setRegistationPopup] = useState(false);
  const [successUpdatePopup, setSuccessUpdatePopup] = useState(false);
  const accountRole = localStorage.getItem("account_role");
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [returnPackage, setReturnPackage] = useState(true);
  const [purchaseHead, setPurchaseHead] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  return (
    <div>
      <MeetingsHeader heading="Add Admins/Users" />
      <div className="we-2-call-main-section">
        <AdminManagement
          setTypeOfAdd={setTypeOfAdd}
          setSelectedUser={setSelectedUser}
          setEditStatus={setEditStatus}
        />
        {typeOfAdd === "Add Users" && (
          <div>
            <AdminCreation
              typeOfAdd={typeOfAdd}
              setRegistationPopup={setRegistationPopup}
              setIsUserCreated={setIsUserCreated}
              setEditStatus={setEditStatus}
              editStatus={editStatus}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
            />
            <AddAdminList
              isUserCreated={isUserCreated}
              setEditStatus={setEditStatus}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              editStatus={editStatus}
            />
          </div>
        )}
        {typeOfAdd === "Add Admins" && accountRole !== "agent" && (
          <div>
            <AdminCreation
              typeOfAdd={typeOfAdd}
              setIsUserCreated={setIsUserCreated}
              setRegistationPopup={setRegistationPopup}
              setEditStatus={setEditStatus}
              editStatus={editStatus}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
            />
            <AdiminUsersList
              isUserCreated={isUserCreated}
              setEditStatus={setEditStatus}
              editStatus={editStatus}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
            />
          </div>
        )}
        {typeOfAdd === "Purchase Admin Packages" && (
          <div>
            <UpgradeAdminPackages
              setOpenPackageUpgrade={setOpenPackageUpgrade}
              setReturnPackage={setReturnPackage}
              setPurchaseHead={setPurchaseHead}
            />

            <SelectPackages />
            <SelectPackagesFooter
              setOpenPackageUpgrade={setOpenPackageUpgrade}
              setReturnPackage={setReturnPackage}
              setPurchaseHead={setPurchaseHead}
            />
          </div>
        )}
        {typeOfAdd === "Top up Recharge Hours" && (
          <div>
            <TopupHours
              setOpenPackageUpgrade={setOpenPackageUpgrade}
              setReturnPackage={setReturnPackage}
            />

            <TopupHoursPackages />
            <SelectPackagesFooter
              setOpenPackageUpgrade={setOpenPackageUpgrade}
              setReturnPackage={setReturnPackage}
            />
          </div>
        )}
        {typeOfAdd === "Purchase Package Tickets" && <PackageTransaction />}
      </div>
      <UpgradePackagesPopup
        typeOfAdd={typeOfAdd}
        purchaseHead={purchaseHead}
        returnPackage={returnPackage}
        openPackageUpgrade={openPackageUpgrade}
        setOpenPackageUpgrade={setOpenPackageUpgrade}
        setSuccessUpdatePopup={setSuccessUpdatePopup}
      />
      <RegistrationSuccessful
        registationPopup={registationPopup}
        setRegistationPopup={setRegistationPopup}
      />
      <FancyThankYouPopup
        openPopup={successUpdatePopup}
        setclosePopup={setSuccessUpdatePopup}
        displayData={"Purchased Your Package Succesfully"}
      />
    </div>
  );
}

export default AddAdmins;
