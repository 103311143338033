import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Images } from "../../images";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { GET_ALL_PACKAGES } from "../../config/endpoints";
import { call } from "../../config/axios";
import { setSelectedPackages } from "../../redux/actions/commonActions";
import { useDispatch, useSelector} from "react-redux";


function TopupHoursPackages() {
  const [allHourspackages, setAllHourspackages] = useState([]);
  const dispatch = useDispatch();
  const packageList =
    useSelector((State) => State.common.selected_packages) || [];

  //   useEffect(() => {

  //   const updatedSelectedPackagesHourly = allHourspackages.map((i) =>
  //     packageList.find((obj) => obj.package_name === i.package_name)
  //       ? {
  //           ...i, no_of_packages: packageList.find(
  //             (obj) => obj.package_name === i.package_name

  //           ).no_of_packages,
  //         }
  //       : i
  //   );
  //   setAllHourspackages(updatedSelectedPackagesHourly);
  // }, [packageList]);

  const handleSubHourly = (itm) => {
    const updatedSelectedPackagesHourly = allHourspackages.map((i) =>
      i.package_name === itm?.package_name
        ? { ...i, no_of_packages: i.no_of_packages - 1 }
        : i
    );
    setAllHourspackages(updatedSelectedPackagesHourly);
    const packageForRedux = updatedSelectedPackagesHourly
      .filter((obj) => obj.no_of_packages > 0)
      .map((obj) => {
        return {
          cost: obj.cost,
          package_id: obj.package_id,
          no_of_packages: obj.no_of_packages,
          package_name: obj.package_name,
          duration: obj.package_duration,
          discount: obj.discount,
        };
      });
    dispatch(setSelectedPackages(packageForRedux));
  };
  const handleAddHourly = (itm) => {
    const updatedSelectedPackagesHourly = allHourspackages.map((i) =>
      i.package_name === itm?.package_name
        ? { ...i, no_of_packages: (i.no_of_packages || 0) + 1 }
        : i
    );
    setAllHourspackages(updatedSelectedPackagesHourly);
    const packageForRedux = updatedSelectedPackagesHourly
      .filter((obj) => obj.no_of_packages > 0)
      .map((obj) => {
        return {
          cost: obj.cost,
          package_id: obj.package_id,
          no_of_packages: obj.no_of_packages,
          package_name: obj.package_name,
          duration: obj.duration,
          discount: obj.discount,
        };
      });
    dispatch(setSelectedPackages(packageForRedux));
  };

  let Hourspackages = [
    {
      image: Images.Coin,
      packageType: "Standard Package",
      package_name: "standard",
      cost: 0,
    },
    {
      image: Images.SilverMedal,
      packageType: "Silver Package",
      package_name: "silver",
      cost: 0,
    },
    {
      image: Images.GoldMedal,
      packageType: "Gold Package",
      package_name: "gold",
      cost: 0,
    },
    {
      image: Images.Diamond,
      packageType: "Diamond Package ",
      package_name: "diamond",
      cost: 0,
    },
    {
      image: Images.Crown,
      packageType: "Vip Package",
      package_name: "vip",
      cost: 0,
    },
  ];
  const getAllPackages = async () => {
    await call(GET_ALL_PACKAGES, { package_type: true })
      .then((res) => {
        if (res.data.status === 200) {
          const response = res.data.data;
          const updateResponse = getUpdatedPackData(response);
          setAllHourspackages(updateResponse);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllPackages();
  }, []);
  const getUpdatedPackData = (allPackagesInfo) => {
    return Hourspackages.map((itm) => {
      const responseObj = allPackagesInfo?.find(
        (obj) => itm.package_name === obj.package_name
      );
      if (responseObj) {
        return {
          ...itm,
          cost: responseObj.package_cost,
          package_id: responseObj.package_id,
          no_of_packages: responseObj.no_of_packages || 0,
          package_name: responseObj.package_name,
          duration: responseObj.package_duration,
          discount: responseObj.discount || 0,
        };
      } else return itm;
    });
  };

  return (
    <IonGrid>
      <div className="select-package-heading ml-10 mb-10">Select Packages</div>
      <IonRow className="connect-device mrl-10 pb-5">
        <div className="w-100 flex-flex-end mt-10">
          <IonCol
            size="3"
            className="upcoming-table-heading custom-box-shadow flex-center"
          >
            Hourly
          </IonCol>
        </div>
        {allHourspackages.map((item, index) => {
          return (
            <IonCol key={index} size={12}>
              <div className="series-input custom-box-shadow h-80px d-flex">
                <IonCol size="6" className="d-flex">
                  <img className="select-package-images" src={item.image}></img>
                  <div>{item.packageType}</div>
                </IonCol>
                <IonCol size="3">
                  {item?.no_of_packages >= 2 ? 10 * item?.no_of_packages : 10} H
                </IonCol>
                <IonCol size="3" className="add-monthly ">
                  {item.cost}
                  <br />
                  <div className="upcoming-table-heading custom-box-shadow flex-center w-100 flex-space-between mt-10">
                    <AiOutlineMinus
                      onClick={() =>
                        item?.no_of_packages ? handleSubHourly(item) : null
                      }
                      className="plus-icon"
                    />
                    {item?.no_of_packages ? item?.no_of_packages : "Add"}
                    <AiOutlinePlus
                      onClick={() => handleAddHourly(item)}
                      className="plus-icon"
                    />
                  </div>
                </IonCol>
              </div>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
}

export default TopupHoursPackages;
