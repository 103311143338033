import { LiaPlaneDepartureSolid } from "react-icons/lia";
import { Images } from "../../images";
import { useState } from "react";
import YouIntrested from "./YouIntrested";
import TeamIntrested from "./TeamIntrested";

function OfferDetails(props) {
  const { handleOpenYourDetailsComponent } = props;
  const [youIntrested, setYouIntrested] = useState(true);
  const [teamIntrested, setTeamIntrested] = useState(false);
  const handleYouIntrested = () => {
    setYouIntrested(true);
    setTeamIntrested(false);
  };
  const handleTeamIntrested = () => {
    setTeamIntrested(true);
    setYouIntrested(false);
  };
  return (
    <div className="meetings-container w-92">
      <div className="w-100 flex-space-around take-tour-div mt-10">
        <div className="w-45 ml-15">
          <div className="font-clr-white font-20 mt-5">
            Take a part in our tour <LiaPlaneDepartureSolid />
          </div>
          <div className="font-clr-white font-12 mt-5">
            Play and get a chance to join with tour
          </div>
          <div className="font-clr-white font-12 mt-20">
            Tour Start Date:<b> 2nd Aug, 2023</b>
          </div>
        </div>
        <div className="w-45 suitcase-img-div">
          <img
            className="suitcase-image"
            src={Images.SuitcaseImage}
            alt="suitcase_Img"
          />
        </div>
      </div>
      <div className="flex-space-around mt-20">
        <button
          className={`${
            youIntrested
              ? "w-45 button-box-shadow intrested-btn font-12 green-clr"
              : "w-45 custom-box-shadow intrested-btn font-12"
          }`}
          onClick={() => handleYouIntrested()}
        >
          Your Interested
        </button>
        <button
          className={`${
            teamIntrested
              ? "w-45 button-box-shadow intrested-btn font-12 green-clr"
              : "w-45 custom-box-shadow intrested-btn font-12"
          }`}
          onClick={() => handleTeamIntrested()}
        >
          Team Interested
        </button>
      </div>
      {youIntrested && (
        <YouIntrested
          handleOpenYourDetailsComponent={handleOpenYourDetailsComponent}
        />
      )}
      {teamIntrested && <TeamIntrested />}
    </div>
  );
}

export default OfferDetails;
