import { useIonToast } from "@ionic/react";
import { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { useSelector } from "react-redux";

function SelectPackagesFooter(props) {
  const { setOpenPackageUpgrade, setReturnPackage, setPurchaseHead } = props;
  const packageList =
    useSelector((State) => State.common.selected_packages) || [];
  const [present] = useIonToast();
  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };
  const handleOpenUpgradePopup = () => {
    setOpenPackageUpgrade((prev) => !prev);
    setReturnPackage(true);
    // setPurchaseHead(true);
  };
  const selectedPackages = packageList.reduce(
    (acc, obj) => acc + obj.no_of_packages,
    0
  );
  return (
    <div className="select-package-footer flex-space-around">
      <div className="flex-center">
        <HiMiniShoppingBag className="clock-icon mr-10" /> {selectedPackages}
        Package Selected
        <AiOutlineDown className="ml-5" />
      </div>
      <div className="flex-center next-arrow">
        Next
        <BsArrowRight
          className="ml-5 clock-icon"
          onClick={() =>
            packageList.length
              ? handleOpenUpgradePopup()
              : presentToast({ message: "please select package" })
          }
        />
      </div>
    </div>
  );
}

export default SelectPackagesFooter;
