import { useEffect, useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import UserCreation from "./UserCreation";
import ExistingUsers from "./ExistingUsers";
import { call } from "../../config/axios";
import {
  GET_OFFLINE_CLIENTS,
  GET_OFFLINE_CLIENT_DETAILS,
} from "../../config/endpoints";

function UserManagement() {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [existingClients, setExistingClients] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [editStatus, setEditStatus] = useState(false);
  const [addClientStatus, setAddClientStatus] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);

  const getOfflineClients = async () => {
    await call(GET_OFFLINE_CLIENTS, { register_id })
      .then((res) => {
        let results = res?.data?.data?.filter(
          (item) => item.user_status !== "deleted"
        );
        setExistingClients(results);
      })
      .catch((err) => console.log(err));
  };

  const getClientDetails = async (clientID) => {
    setFetchingStatus(true);
    await call(GET_OFFLINE_CLIENT_DETAILS, {
      register_id,
      client_id: clientID,
    })
      .then((res) => {
        let results = res?.data?.data;
        setClientDetails(results);
        setFetchingStatus(false);
        setEditPopUp(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingStatus(false);
        setEditPopUp(false);
      });
  };

  useEffect(() => {
    getOfflineClients();
  }, [addClientStatus]);

  return (
    <div>
      <MeetingsHeader heading={"User Management"} />
      <div className="we-2-call-main-section">
        <UserCreation
          existingClients={existingClients}
          clientDetails={clientDetails}
          editStatus={editStatus}
          setEditStatus={setEditStatus}
          setAddClientStatus={setAddClientStatus}
        />
        <ExistingUsers
          existingClients={existingClients}
          getClientDetails={getClientDetails}
          setEditStatus={setEditStatus}
          fetchingStatus={fetchingStatus}
          setFetchingStatus={setFetchingStatus}
          setEditPopUp={setEditPopUp}
          editPopUp={editPopUp}
          setAddClientStatus={setAddClientStatus}
        />
      </div>
    </div>
  );
}

export default UserManagement;
