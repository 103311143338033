import { useEffect } from "react";
import Table from "../../common/Table";
import {
  GET_INDUVISUAL_MATCH_REPORT,
  GET_INDUVISUAL_REFERRAL_BY,
} from "../../config/endpoints";
import { call } from "../../config/axios";

const IndividualPLReport = (props) => {
  const {
    completeMatchesData,
    columns,
    data,
    individualColumns,
    individualPLReportButtons,
    showClientTable,
    invidiualReportReferralData,
    invidiualReportReferralColumns,
    reportReferralDataStatus,
    invidiualReportReferralHeading,
    clientId,
    setIndivisualMatchReportData,
    individualReportClientData,
    setClientsDataForRefferal,
    refId,
    refClientId,
  } = props;

  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const account_role = localStorage?.getItem("account_role");

  const getIndivisualMatchReport = async () => {
    await call(GET_INDUVISUAL_MATCH_REPORT, {
      register_id,
      client_id: clientId || refClientId,
    })
      .then((res) => {
        setIndivisualMatchReportData([
          ...res?.data?.data?.topLosers,
          ...res?.data?.data?.topWinners,
        ]);
      })
      .catch((err) => console.log(err));
  };

  const getIndividualPLRefferal = async () => {
    await call(GET_INDUVISUAL_REFERRAL_BY, { register_id, refferal_id: refId })
      .then((res) => {
        setClientsDataForRefferal(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (clientId || refClientId) {
      getIndivisualMatchReport();
    }
  }, [clientId, refClientId]);

  useEffect(() => {
    if (refId) {
      getIndividualPLRefferal();
    }
  }, [refId]);
  const totalClientPl =
    data &&
    data?.length > 0 &&
    data?.reduce((acc, obj) => acc + (+obj?.amount?.props?.children || 0), 0);
  const totalreferal =
    completeMatchesData &&
    completeMatchesData?.length > 0 &&
    completeMatchesData?.reduce(
      (acc, obj) => acc + (+obj?.amount?.props?.children || 0),
      0
    );
  const totalClientPlMatch =
    invidiualReportReferralData &&
    invidiualReportReferralData?.length > 0 &&
    invidiualReportReferralData?.reduce(
      (acc, obj) => acc + (+obj?.amount?.props?.children || 0),
      0
    );
  const totalInduvisualReportClientData =
    individualReportClientData &&
    individualReportClientData?.length > 0 &&
    individualReportClientData?.reduce(
      (acc, obj) => acc + (+obj?.amount?.props?.children || 0),
      0
    );
  const totalUrsNet =
    data &&
    data?.length > 0 &&
    data?.reduce((acc, obj) => acc + (+obj?.ULnetPL?.props?.children || 0), 0);
  const totalUlShareNet =
    data &&
    data?.length > 0 &&
    data?.reduce(
      (acc, obj) => acc + (+obj?.afterAllSharePL?.props?.children || 0),
      0
    );
  const totalUrsPlPlatform =
    data &&
    data?.length > 0 &&
    data?.reduce((acc, obj) => acc + (+obj?.netPL?.props?.children || 0), 0);
  const totalPlatform =
    data &&
    data?.length > 0 &&
    data?.reduce(
      (acc, obj) =>
        acc + (+obj?.individualReportPlatformComm?.props?.children || 0),
      0
    );

  return (
    <div>
      <div className="scroll-onepage">
        <Table columns={columns} data={data} />
      </div>
      <table className="w-100 account-summary-main-container mt-5">
        <tfoot>
          <tr>
            <th>Total</th>
            <th className={totalClientPl >= 0 ? "clr-green" : "clr-red"}>
              {totalClientPl ? totalClientPl?.toFixed(2) : null}
            </th>
            <th className={totalClientPl >= 0 ? "clr-green" : "clr-red"}>
              {totalClientPl ? totalClientPl?.toFixed(2) : null}
            </th>
            <th className={totalUrsNet >= 0 ? "clr-green" : "clr-red"}>
              {totalUrsNet ? totalUrsNet?.toFixed(2) : null}
            </th>
            <th className={totalUlShareNet >= 0 ? "clr-green" : "clr-red"}>
              {totalUlShareNet ? totalUlShareNet?.toFixed(2) : null}
            </th>
            <th className={totalUrsPlPlatform >= 0 ? "clr-green" : "clr-red"}>
              {totalUrsPlPlatform ? totalUrsPlPlatform?.toFixed(2) : null}
            </th>
            <th className={totalPlatform >= 0 ? "clr-green" : "clr-red"}>
              {totalPlatform ? totalPlatform?.toFixed(2) : null}
            </th>
            <th className={totalreferal >= 0 ? "clr-green" : "clr-red"}>
              {totalreferal ? totalreferal?.toFixed(2) : null}
            </th>
          </tr>
        </tfoot>
      </table>
      {showClientTable && (
        <div>
          <div className="mt-5 scroll-onepage">
            <Table
              columns={individualColumns}
              data={individualReportClientData}
              buttons={individualPLReportButtons}
            />
          </div>
          <table className="w-100 account-summary-main-container mt-5">
            <tfoot> 
              <tr>
                <th>Total</th>
                <th></th>
                <th>
                  {totalInduvisualReportClientData
                    ? totalInduvisualReportClientData?.toFixed(2)
                    : 0}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
      {reportReferralDataStatus && (
        <div>
          <div className="scroll-onepage">
            <Table
              columns={invidiualReportReferralColumns}
              data={invidiualReportReferralData}
              buttons={invidiualReportReferralHeading}
            />
          </div>
          <table className="w-100 account-summary-main-container mt-5">
            <tfoot>
              <tr>
                <th>Total</th>
                <th>
                  {totalClientPlMatch ? totalClientPlMatch?.toFixed(2) : 0}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default IndividualPLReport;
