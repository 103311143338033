import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";
import { useState } from "react";

function MatchDeclareSuccessPopup(props) {
  const {
    confirmDeclaration,
    setConfirmDeclaration,
    winTeamHeading,
    handleMatchDeclarePopupOpen,
  } = props;

  return (
    <>
      <IonModal
        className="w-80 fancy-declare-popup"
        isOpen={confirmDeclaration}
        onDidDismiss={() => setConfirmDeclaration(false)}
      >
        <div className="align-center flex-column custom-box-shadow fancy-declare-div">
          <div
            className="w-100 flex-flex-end p-5"
            onClick={() => setConfirmDeclaration(false)}
          >
            <RxCross1 />
          </div>
          <div>
            <img
              className="fancy-declare-image"
              src={Images.DeclareManImage}
              alt="Declare_Man_Img"
            />
          </div>
          <div className="w-75 fancy-declare-text mt-10">
            {`Are you sure you want to declare ${winTeamHeading} to win`}
          </div>
          <div className="fancy-declare-text mt-10 green-clr"></div>
          <div className="mt-15 flex-space-around w-100">
            <button
              className="w-40 button-box-shadow fancy-declare-button green-clr"
              onClick={() => handleMatchDeclarePopupOpen()}
            >
              Yes
            </button>
            <button
              className="w-40 custom-box-shadow fancy-declare-button red-clr"
              onClick={() => setConfirmDeclaration(false)}
            >
              No
            </button>
          </div>
        </div>
      </IonModal>
    </>
  );
}

export default MatchDeclareSuccessPopup;
