import MeetingsHeader from "../meetings/MeetingsHeader";
import MatchCreation from "./MatchCreation";
import UrCreatingMatches from "./UrCreatingMatches";
import ScheduleMatches from "./ScheduleMatches";
import { useState } from "react";

function SportManagement() {
  const account_role = localStorage?.getItem("account_role");
  const [status, setStatus] = useState(false);
  const [matchDetails, setMatchDetails] = useState([]);
  const [matchEditStatus, setMatchEditStatus] = useState(false);
  const [matchEditSuccess, setMatchEditSuccess] = useState(false);

  const tableHeading = [
    {
      size: "2",
      headerName: "Sport",
    },
    {
      size: "4.5",
      headerName: "Series Name",
    },
    {
      size: "2",
      headerName: "Gender",
    },
    {
      size: "3.5",
      headerName: "Match Name",
    },
  ];
  // const tableContent = [
  //   {
  //     size: "2",
  //     content: "Cricket",
  //   },
  //   {
  //     size: "4.5",
  //     content: (
  //       <div>
  //         Newziland vs SriLanka
  //         <br />
  //         OneDay <br />
  //         23-06-2023 <br />
  //         15:42:00 PM
  //       </div>
  //     ),
  //   },
  //   {
  //     size: "2.5",
  //     content: "Male",
  //   },
  //   {
  //     size: "3",
  //     content: "Newziland vs SriLanka",
  //   },
  // ];
  // const matchesStatus = ["Live", "Toaday", "UpComing"];
  // const contentRows = [{}, {}, {}, {}];

  return (
    <div>
      <MeetingsHeader heading={"Sports Management"} />
      <div className="we-2-call-main-section">
        <ScheduleMatches status={status} />
        <MatchCreation
          setStatus={setStatus}
          matchDetails={matchDetails}
          matchEditStatus={matchEditStatus}
          setMatchEditStatus={setMatchEditStatus}
          setMatchEditSuccess={setMatchEditSuccess}
          matchEditSuccess={matchEditSuccess}
        />
        <UrCreatingMatches
          status={status}
          setMatchDetails={setMatchDetails}
          setMatchEditStatus={setMatchEditStatus}
          setMatchEditSuccess={setMatchEditSuccess}
        />
      </div>
    </div>
  );
}

export default SportManagement;
