import { useState } from "react";
import { BiRightArrowCircle, BiChevronDown } from "react-icons/bi";
import FancyEntryInputs from "./FancyEntryInputs";
import FancyResultInputs from "./FancyResultInputs";
import { useHistory } from "react-router";

function FancyHeader(props) {
  const history = useHistory();
  const {
    fancyEntryInputs,
    fancyResultInputs,
    handleFancyEntryOpen,
    handleFancyResultOpen,
    selectedMatch,
    matchAccountData,
    selectedMatchEntry,
    setStatus,
    setSelectedMatchEntry,
    setMatchOver,
    setMatchInnigs,
    getRiskRunningData,
    getFancyProfitLoss,
    profitLossData,
    profitLossDataInnings,
    setAfterDeclare,
  } = props;

  const [inningsOpen, setInningsOpen] = useState(false);
  const [inningsHeading, setInningsHeading] = useState("first");

  const toggleInningsOpen = () => {
    setInningsOpen(!inningsOpen);
  };
  const handleInningsHeading = (content) => {
    setInningsHeading(content);
    setMatchInnigs(content);
    setInningsOpen(false);
  };

  return (
    <div className="match-scroll-div fancy-header-div mt-10">
      <div className="flex-space-between mb-10">
        <div className="w-70 fancy-meetings-button custom-box-shadow flex-center flex-column">
          <div>
            {selectedMatch
              ? `${selectedMatch?.date} - ${selectedMatch?.time}`
              : "Date & Time"}
          </div>
          <div>
            {selectedMatch?.series_name || "Series Name"} -
            {selectedMatch?.gender || "Gender"}
          </div>
        </div>
        <div className="clr-green fancy-meetings-button custom-box-shadow flex-center w-25">
          {selectedMatch?.match_name || "Match Name"}
        </div>
      </div>
      <hr className="horizantal-line" />
      <div className="flex-space-between mt-10 mb-10">
        <div className="w-60 flex-space-between">
          <div onClick={handleFancyEntryOpen}>
            <div
              className={`${
                !fancyEntryInputs
                  ? "fancy-btn-position flex-column"
                  : "active-fancy-btn-position flex-column"
              }`}
            >
              <div className="flex-center fancy-text">
                Fancy Entry
                <BiChevronDown
                  className="fancy-down-arrow"
                  onClick={() => toggleInningsOpen()}
                />
              </div>
              <div className="first-innings-text"> {inningsHeading} </div>
              {inningsOpen ? (
                <div className="flex-space-around fancy-innings-div custom-box-shadow w-100 mt-10 fancy-inn-margin">
                  <div
                    className="fancy-first-inn custom-box-shadow flex-center"
                    onClick={() => handleInningsHeading("first")}
                  >
                    1st Inn
                  </div>
                  <div
                    className="fancy-first-inn custom-box-shadow flex-center mt-5"
                    onClick={() => handleInningsHeading("second")}
                  >
                    2nd Inn
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div
            onClick={handleFancyResultOpen}
            className={`${
              fancyEntryInputs
                ? "fancy-button custom-box-shadow flex-center"
                : "active-fancy-result-btn flex-center"
            }`}
          >
            Fancy Result
          </div>
        </div>
        <div
          className="fancy-button custom-box-shadow flex-center w-100px"
          onClick={() => history.push("/matchentry")}
        >
          Match Entry
          <BiRightArrowCircle className="right-arrow-icon ml-5" />
        </div>
      </div>
      {fancyEntryInputs && (
        <FancyEntryInputs
          selectedMatch={selectedMatch}
          registered_match_id={matchAccountData?.registered_match_id}
          selectedMatchEntry={selectedMatchEntry}
          setStatus={setStatus}
          inningsHeading={inningsHeading}
          setSelectedMatchEntry={setSelectedMatchEntry}
          setMatchOver={setMatchOver}
          getRiskRunningData={getRiskRunningData}
          getFancyProfitLoss={getFancyProfitLoss}
          profitLossData={profitLossData}
          profitLossDataInnings={profitLossDataInnings}
          setAfterDeclare={setAfterDeclare}
        />
      )}
      {fancyResultInputs && (
        <FancyResultInputs
          registered_match_id={matchAccountData?.registered_match_id}
          selectedMatch={selectedMatch}
          setStatus={setStatus}
          selectedMatchEntry={selectedMatchEntry}
          setSelectedMatchEntry={setSelectedMatchEntry}
          inningsHeading1={inningsHeading}
          getFancyProfitLoss={getFancyProfitLoss}
          setAfterDeclare={setAfterDeclare}
          profitLossData={profitLossData}
          profitLossDataInnings={profitLossDataInnings}
        />
      )}
    </div>
  );
}
export default FancyHeader;
