import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";

function ViewDetailsPopup(props) {
  const { showViewDetailsPopup, setShowViewDetailsPopup,action } = props;
  const handleViewPopupClose = () => {
    setShowViewDetailsPopup(false);
  };
  return (
    <IonModal className="statememt-popup-main" isOpen={showViewDetailsPopup}>
      <center>
        <div
          className="w-100 flex-flex-end"
          onClick={() => handleViewPopupClose()}
        >
          <RxCross1 />
        </div>
        <h3>Bulk {action}</h3>
        <div className="flex-row ">
          <h5 className="clr-orng">Diamond Packages</h5>
          <h4 className="clr-green">50 Nos</h4>
        </div>
        <h4 className="flex-start clr-red">
          <u> Note:-</u>
        </h4>
        <ul>
          <li>Non-Refundable</li>
          <li>Non-Refundable</li>
          <li>Non-Refundable</li>
          <li>Non-Refundable</li>
        </ul>
      </center>
    </IonModal>
  );
}

export default ViewDetailsPopup;
