import React from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function UploadScreenshotPopup(props) {
  const { showUploadScreenshotPopup, setShowUploadScreenshotPopup } = props;
  const handleUploadPopupClose = () => {
    setShowUploadScreenshotPopup(false);
  };
  return (
    <IonModal
      className="statememt-popup-main"
      isOpen={showUploadScreenshotPopup}
    >
      <center>
        <div
          className="w-100 flex-flex-end p-5"
          onClick={() => handleUploadPopupClose()}
        >
          <RxCross1 />
        </div>
        <h5>UploadScreenshot</h5>
        <div>
          <img src={Images.DogImage} className="custom-upload-image"/>
        </div>
      </center>
    </IonModal>
  );
}

export default UploadScreenshotPopup;
