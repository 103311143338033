function AdminReportsTable(props) {
  const { columns, data } = props;
  return (
    <div className="account-summary-main-container mt-5">
      <table className="account-settlement-summary account-settlement-summary-data">
        <thead>
          <tr>
            {columns?.map((item, index) => (
              <th key={index}>{item.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0
            ? data?.map((rowData, index) => (
                <tr key={index}>
                  {columns?.map((item, index) => (
                    <td key={index}>{rowData[item.header]}</td>
                  ))}
                </tr>
              ))
            : data && (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
}

export default AdminReportsTable;
