import React from "react";
import { IonImg } from "@ionic/react";

function GoldHourPackgaes(props) {
    const {
        checked,
        handleUpgradePopup,
        setSelectedImage,
        allPackages,
        setSelectedPackages,
        setSelectPackagesDropdown,
      } = props;
    
      const handleVipState = (item1, item2,selectedPack) => {
        handleUpgradePopup();
        setSelectedImage(item1);
        setSelectPackagesDropdown(item2);
        setSelectedPackages(selectedPack)
    
      };
      const vipHoursPack = allPackages.find(obj => obj.package_name === "vip")
      const goldList = [
        {
          title: "Join Call With 15 users",
        },
        {
          title: "Monthly 20 meetings",
        },
        {
          title: "Use 20 personal Meetings",
        },
        {
          title: "Audio Calls",
        },
        {
          title: "Vedio Calls",
        },
      ];
  return (
    <div>
      <div className="w-100 mt-10 p-5">
        <div className="vip-package relative-position font-14">
          <div className="vip-rupee-circle flex-center flex-column">
            <div>Rs</div>
            <div className="fw-600">
              {vipHoursPack?.package_cost }
            </div>
          </div>
          <div>
            <div>
              <IonImg
                className="badge-images badge-images-crown"
                src={process.env.PUBLIC_URL + "./assets/crown.png"}
              />
            </div>
            <div
              className="flex-center relative-position-right fw-600 mt-40 mb-40"
              style={{ fontSize: "16px", color: "var(--font-color)" }}
            >
              VIP HOURS
            </div>
          </div>

          <div className="flex-start flex-wrap">
            {goldList.map((value, index) => {
              return (
                <div
                  key={index}
                  className="font-10 flex-row flex-start mb-10 ml-10"
                >
                  <IonImg
                    className="flex-start check-mark mr-10"
                    src={process.env.PUBLIC_URL + "./assets/check_mark.png"}
                  />
                  {value.title}
                </div>
              );
            })}
          </div>
          <div className="flex-start ml-10 mt-20">
            <button
              className="upgrade-button upgrade-vip-button font-10 flex-center mr-5"
              onClick={() =>
                handleVipState("./assets/crown.png", "VIP package",vipHoursPack)
              }
            >
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoldHourPackgaes