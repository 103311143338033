import { BiChevronDown } from "react-icons/bi";
import { useState } from "react";
import FancyShareNamePopup from "../fancypopups/FancySharenamePopup";
import FancyShareOversPopup from "../fancypopups/FancyShareOversPopup";
import FancyCommNamePopup from "../fancypopups/FancyCommNamePopup";
import FancyCommOversPopup from "../fancypopups/FancyCommOversPopup";

function FancyEntryPLTable(props) {
  const { nameAndOvers, profitLossData, selectedMatch } = props;
  const [fancySharePopupWithName, setFancySharePopupWithName] = useState(false);
  const [fancySharePopupWithOvers, setFancySharePopupWithOvers] =
    useState(false);
  const [fancyCommNamePopup, setFancyCommNamePopup] = useState(false);
  const [fancyCommOverPopup, setFancyCommOverPopup] = useState(false);
  const handlefancyShareNamePopupOpen = () => {
    setFancySharePopupWithName(!fancySharePopupWithName);
  };
  const handlefancyShareNamePopupClose = () => {
    setFancySharePopupWithName(false);
  };
  const handlefancyShareOversPopupOpen = () => {
    setFancySharePopupWithOvers(!fancySharePopupWithOvers);
  };
  const handlefancyShareOversPopupClose = () => {
    setFancySharePopupWithOvers(false);
  };
  const handleFancyCommNamePopupOpen = () => {
    setFancyCommNamePopup(!fancyCommNamePopup);
  };
  const handleFancyCommNamePopupClose = () => {
    setFancyCommNamePopup(false);
  };
  const handleFancyCommOverPopupOpen = () => {
    setFancyCommOverPopup(!fancyCommOverPopup);
  };
  const handleFancyCommOverPopupClose = () => {
    setFancyCommOverPopup(false);
  };

  const FANCY_ENTRY_PL_TABLE =
    profitLossData &&
    Object.keys(profitLossData)?.map((key) => {
      let {
        amount,
        clientCommission,
        clientShare,
        referalShare,
        referralComission,
        totalLossOrProfit,
        upperLevalShare,
      } = profitLossData[key];
      return {
        key: key,
        runs: key,
        amount: parseFloat(amount),
        clientComm: parseFloat(clientCommission),
        rfComm: parseFloat(referralComission),
        clientShare: parseFloat(clientShare),
        upperLevalShare: parseFloat(upperLevalShare),
        masterPl: parseFloat(totalLossOrProfit),
        rfShare: parseFloat(referalShare),
        rfNet: parseFloat(referalShare) + parseFloat(referralComission),
        clientNet:
          parseFloat(amount) +
          (parseFloat(clientCommission) + parseFloat(clientShare)),
      };
    });

  let totalMatchResultData = {};
  if (FANCY_ENTRY_PL_TABLE?.length > 0) {
    totalMatchResultData = {
      amount: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.amount || 0),
        0
      ),
      clientComm: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.clientComm || 0),
        0
      ),
      rfComm: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.rfComm || 0),
        0
      ),
      afterComm: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.afterComm || 0),
        0
      ),
      clientShare: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.clientShare || 0),
        0
      ),
      rfShare: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.rfShare || 0),
        0
      ),
      afterShare: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.afterShare || 0),
        0
      ),
      clientNet: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.clientNet || 0),
        0
      ),
      rfNet: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.rfNet || 0),
        0
      ),
      ulNet: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.ulNet || 0),
        0
      ),
      resultTeam: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.resultTeam || 0),
        0
      ),
      ULShare: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.upperLevalShare || 0),
        0
      ),
      ulComm: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.ulComm || 0),
        0
      ),
      urPosition: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.urPosition || 0),
        0
      ),
      masterPl: FANCY_ENTRY_PL_TABLE.reduce(
        (acc, obj) => acc + (obj.masterPl || 0),
        0
      ),
    };
  }
  return (
    <div className="meetings-container flex-column w-92 mt-2rem">
      <div className="custom-box-shadow  fancy-meetings-details-div">
        <div className="flex-row flex-space-between">
          <div className=" custom-box-shadow fancy-upcoming-meetings-div font-14 w-60 p-10 mar-5">
            <b> Fancy</b> Result P/L
            <span className="font-10 ml-5">
              {selectedMatch?.team1} vs {selectedMatch?.team2}
            </span>
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handleFancyCommNamePopupOpen()}
          >
            Comm’s <BiChevronDown className="fancy-down-arrow" />
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handlefancyShareNamePopupOpen()}
          >
            Shares <BiChevronDown className="fancy-down-arrow" />
          </div>
        </div>
        <div className="hr-line mt-5"></div>
        <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
          <div className="fancy-match-history-button custom-box-shadow flex-center flex-column font-10">
            <div>{nameAndOvers}</div>
            <div>Gross P/L</div>
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            C Net
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Rf Net
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Net P/L
          </div>
        </div>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {FANCY_ENTRY_PL_TABLE?.length > 0 &&
            FANCY_ENTRY_PL_TABLE?.map((data, index) => (
              <div key={index} className="flex-row p-5">
                <div className="flex-space-between align-end mt-5 pl-10">
                  <div className="meetings-time w-25">
                    <div>{`${data?.key} ${
                      nameAndOvers === "Overs" ? "Over" : ""
                    }`}</div>
                    <div
                      className={data?.amount >= 0 ? "green-clr" : "red-clr"}
                    >
                      {data?.amount ? data?.amount?.toFixed(2) : 0}
                    </div>
                  </div>
                  <div
                    className={
                      data?.amount >= 0
                        ? "green-clr font-12 w-20 text-center"
                        : "red-clr font-12 w-20 text-center"
                    }
                  >
                    {
                    // nameAndOvers === "Overs"
                    //   ? "--"
                    //   :
                       data?.clientNet
                      ? data?.clientNet?.toFixed(2)
                      : 0}
                  </div>
                  <div
                    className={
                      data?.rfNet >= 0
                        ? "green-clr font-12 w-20 text-center"
                        : "red-clr font-12 w-20 text-center"
                    }
                  >
                    {
                    // nameAndOvers === "Overs"
                    //   ? "--"
                    //   :
                       data?.rfNet
                      ? data?.rfNet?.toFixed(2)
                      : 0}
                  </div>
                  <div
                    className={
                      data?.masterPl >= 0
                        ? "green-clr font-12 w-20 text-center"
                        : "red-clr font-12 w-20 text-center"
                    }
                  >
                    {
                    // nameAndOvers === "Overs"
                    //   ? "--"
                    //   :
                       data?.masterPl
                      ? data?.masterPl?.toFixed(2)
                      : 0}
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
        </div>
        <div className="flex-space-between align-end mt-5 pl-10 fancy-total-row">
          <div className="meetings-time w-25 font-2">
            <div className="font-14 fw-600">Total</div>
            <div
              className={
                totalMatchResultData?.amount > 0 ? "green-clr" : "red-clr"
              }
            >
              {totalMatchResultData?.amount
                ? totalMatchResultData?.amount?.toFixed(2)
                : 0}
            </div>
          </div>
          <div
            className={
              totalMatchResultData?.clientNet >= 0
                ? "green-clr font-12 w-20 text-center"
                : "red-clr font-12 w-20 text-center"
            }
          >
            {nameAndOvers === "Overs"
              ? "-"
              : totalMatchResultData?.clientNet
              ? totalMatchResultData?.clientNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalMatchResultData?.rfNet >= 0
                ? "green-clr font-12 w-20 text-center"
                : "red-clr font-12 w-20 text-center"
            }
          >
            {nameAndOvers === "Overs"
              ? "-"
              : totalMatchResultData?.rfNet
              ? totalMatchResultData?.rfNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalMatchResultData?.masterPl > 0
                ? "green-clr font-12 w-20 text-center"
                : "red-clr font-12 w-20 text-center"
            }
          >
            {nameAndOvers === "Overs"
              ? "-"
              : totalMatchResultData?.masterPl
              ? totalMatchResultData?.masterPl?.toFixed(2)
              : 0}
          </div>
        </div>
        <div className="flex-space-between w-100">
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14  fw-600">UL Shares</div>
            <div
              className={
                totalMatchResultData?.ULShare >= 0
                  ? "green-clr font-12 text-center"
                  : "red-clr font-12 text-center"
              }
            >
              {totalMatchResultData?.ULShare
                ? totalMatchResultData?.ULShare?.toFixed(2)
                : 0}
            </div>
          </div>
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14 fw-600">Urs Net P/L</div>
            <div
              className={
                totalMatchResultData?.masterPl >= 0
                  ? "green-clr font-12 text-center"
                  : "red-clr font-12 text-center"
              }
            >
              {totalMatchResultData?.masterPl
                ? totalMatchResultData?.masterPl?.toFixed(2)
                : 0}
            </div>
          </div>
        </div>
      </div>
      {fancyCommNamePopup && (
        <FancyCommNamePopup
          handleFancyCommNamePopupClose={handleFancyCommNamePopupClose}
          data={FANCY_ENTRY_PL_TABLE}
          totalMatchResultData={totalMatchResultData}
        />
      )}
      {fancySharePopupWithName && (
        <FancyShareNamePopup
          nameAndOvers={nameAndOvers}
          handlefancyShareNamePopupClose={handlefancyShareNamePopupClose}
          data={FANCY_ENTRY_PL_TABLE}
          totalMatchResultData={totalMatchResultData}
        />
      )}
      {fancySharePopupWithOvers && (
        <FancyShareOversPopup
          nameAndOvers={nameAndOvers}
          handlefancyShareOversPopupClose={handlefancyShareOversPopupClose}
          data={FANCY_ENTRY_PL_TABLE}
          totalMatchResultData={totalMatchResultData}
        />
      )}
      {fancyCommOverPopup && (
        <FancyCommOversPopup
          handleFancyCommOverPopupClose={handleFancyCommOverPopupClose}
          data={FANCY_ENTRY_PL_TABLE}
          totalMatchResultData={totalMatchResultData}
        />
      )}
    </div>
  );
}

export default FancyEntryPLTable;
