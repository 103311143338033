import { useEffect, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import { BiCamera } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { AiOutlineAudio } from "react-icons/ai";
import "./styles.css";
import { open, send } from "../../utils/WebSocket";
import { GET_USER_MESSAGES, GENERATE_SIGNED_URL } from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";
import ChatsHeader from "./ChatsHeader";
import NoChats from "./NoChats";
import { useParams } from "react-router";

function Chats() {
  const { id, name } = useParams();
  const register_id = localStorage?.getItem("register_id");
  const ImageBaseUrl =
    "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";

  const [supportData, setSupportData] = useState([]);
  //const [selectUserName, setSelectUserName] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [file, setFile] = useState([]);
  const [singedUrl, setSignedUrl] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  //const [chatId, setChatId] = useState("");

  const handleChange = (e) => {
    setFile([file, e.target.files[0]]);
  };

  const inputHandler = async () => {
    if (profileImage) {
      await imageUpploadToBucket();
      const imageUrl = `${ImageBaseUrl}/${"chat-images"}/${register_id}/${
        profileImage.name
      }`;
      await send(imageUrl, "text", id);
      setUserInput("");
      setProfileImage(null);
    } else if (userInput.trim() !== "") {
      await send(userInput, "text", id);
      setUserInput("");
    }
  };

  const addMessage = (message, msg_c = 0) => {
    let temp = { ...message, ts: new Date().getTime(), msg_c };
    setSupportData((prev) => {
      if (!Array.isArray(prev)) {
        return [temp];
      }
      return [...prev, temp];
    });
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };
  const hanldeKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftyKey) {
      event.preventDefault();
      inputHandler();
    }
  };

  const getAllCreatorMessages = async () => {
    await call(GET_USER_MESSAGES, {
      from_user_id: localStorage?.getItem("register_id"),
      to_user_id: localStorage?.getItem("creator_id"),
    })
      .then((res) => {
        setSupportData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUserMessages = async () => {
    await call(GET_USER_MESSAGES, {
      from_user_id: id,
      to_user_id: localStorage?.getItem("register_id"),
    })
      .then((res) => {
        setSupportData(res?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateSignedUrl = async (filename) => {
    const chatId = new Date().getTime();
    await call(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "chat-images",
      file_name: filename,
    })
      .then(async (res) => {
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        console.log("generating signed url error", err);
      });
  };

  const imageUpploadToBucket = async () => {
    console.log(singedUrl);
    singedUrl &&
      profileImage &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          console.log("response", res);
          return true;
        })
        .catch((err) => {
          console.log("err: ", err);
        }));
  };

  const onMessageRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    if (id === msg?.to_user_id || id === msg?.from_user_id) {
      addMessage(msg);
    }
  };
  useEffect(() => {
    if (name === "company") {
      getAllCreatorMessages();
    } else {
      getAllUserMessages();
    }
  }, []);

  useEffect(() => {
    open({ onmessage: onMessageRecieve });
  }, [supportData]);

  const uploadfileInputRef = useRef(null);

  const handleUploadFileSelect = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const filename = file.name;
      setProfileImage(file);
      generateSignedUrl(filename);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };
  const handleUploadButtonClick = () => {
    uploadfileInputRef.current.click();
  };

  const chatRef = useRef(null);
  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [supportData]);

  return (
    <div className="chat-main-container">
      <div>
        <ChatsHeader name={name} />
        <div className="msg-container" id="chat" ref={chatRef}>
          {Array.isArray(supportData) && supportData?.length > 0 ? (
  supportData?.map((msg, index) => {
    // Format message timestamp
    const messageDate = moment(msg.ts).format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

    // Get previous message date for comparison
    const prevMessageDate = index > 0 ? moment(supportData[index - 1].ts).format("YYYY-MM-DD") : null;

    // Determine if we need to display the date
    const displayDate = messageDate !== prevMessageDate;

    // Convert to "Today" or "Yesterday" if applicable
    const displayDateText = messageDate === today ? "Today" :
                            messageDate === yesterday ? "Yesterday" :
                            moment(msg.ts).format("MMMM DD, YYYY");

    return (
      <div key={index}>
        {/* Display date in the middle if it changes */}
        {displayDate && (
          <div className="text-center font-weight-bold mt-3 mb-2">
            {displayDateText}
          </div>
        )}

        {/* Display the message */}
        <div
          className={`mt-5 ${
            msg?.to_user_id !== id ? "chat-box-outgoing" : "chat-box-incoming"
          }`}
        >
          <div
            key={index}
            className={`mt-5 message ${
              msg?.to_user_id !== id ? "outgoing-message" : "incoming-message"
            }`}
          >
            {typeof msg.message === "string" && msg.message.includes("http") ? (
              <img src={msg?.message} alt="chat-image" />
            ) : typeof msg.message !== "object" && !Array.isArray(msg.message) ? (
              <div>{msg.message}</div>
            ) : (
              <></>
            )}
          </div>
          <div className="font-size-12 white-text p-5">
            {moment(msg.ts).format("hh:mm:ss A")}
          </div>
        </div>
      </div>
    );
  })
) : (
  <NoChats />
)}

        </div>
      </div>
      <div className="message-send-box">
        <div className="message-send-container ml-15">
          <textarea
            name="postMessage"
            rows={1}
            value={userInput}
            className="send-text-area"
            autoFocus
            placeholder="Write here ..."
            onChange={(e) => {
              handleInputChange(e);
            }}
            onKeyDown={(e) => userInput && hanldeKeyDown(e)}
          />
          <button className="flex send-icon" onClick={() => inputHandler()}>
            <BsSend />
          </button>
        </div>
        <div className="chat-icon-button-carrier ">
          <label className="send-icon message-icons" htmlFor="uploadbutton">
            <BiCamera className="chat-icons" />
            <input
              type="file"
              id="uploadbutton"
              style={{ display: "none" }}
              onChange={(e) => handleChange(e)}
            />
          </label>
          <div
            className="send-icon message-icons"
            onClick={handleUploadButtonClick}
          >
            <ImAttachment className="chat-icons" />
            <input
              type="file"
              ref={uploadfileInputRef}
              style={{ display: "none" }}
              onChange={handleUploadFileSelect}
            />
          </div>
          <div className="send-icon message-icons">
            <AiOutlineAudio className="chat-icons" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chats;
