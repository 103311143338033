import React from "react";
import { Route, Redirect} from "react-router-dom";

import HomePage from "../pages/homepage/HomePage";

function PublicRoutes() {
  return (
    <>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Redirect to="/" />
    </>
  );
}

export default PublicRoutes;
