import { useState } from "react";
import Table from "../../common/Table";
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";

const Tickets = ({ purchaseClick }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const columns = [
    {
      header: "date_time",
      name: "Date & Time",
    },
    { header: "pkg", name: "Pkg" },
    { header: "type", name: "Type" },
    { header: "amount", name: "Amount" },
    { header: "status", name: "Status" },
  ];

  const userDetailsProfitLoss = [
    {
      date_time: (
        <div className="settlemt-statement-client-data">
          <div>16-10-24 2:30:38 </div>
          <div>trx-id-1752635456</div>
        </div>
      ),
      pkg: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">10</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">5</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">0</span>{" "}
          </div>
        </div>
      ),
      type: (
        <div className="settlemt-statement-client-data">
          <div>Live Streaming - Stock</div>
          <div>
            Exp: <span className="clr-orng">21-11-24</span>
          </div>
        </div>
      ),
      amount: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">T:100000 </div>
          <div className="clr-white">D:100000 </div>
          <div className="clr-white">P/L:100000 </div>
        </div>
      ),
      status: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="clr-orng action-btn-shadow action-btn br-10 p-5 flex-center">
            Pending
          </div>
          <div className="  p-5 flex-center ">-</div>
        </div>
      ),
    },
    {
      date_time: (
        <div className="settlemt-statement-client-data">
          <div>16-10-24 2:30:38 </div>
          <div>trx-id-1752635456</div>
        </div>
      ),
      pkg: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">10</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">5</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">0</span>{" "}
          </div>
        </div>
      ),
      type: (
        <div className="settlemt-statement-client-data">
          <div>Live Streaming - Stock</div>
          <div>
            Exp: <span className="clr-orng">21-11-24</span>
          </div>
        </div>
      ),
      amount: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">T:100000 </div>
          <div className="clr-white">D:100000 </div>
          <div className="clr-white">P/L:100000 </div>
        </div>
      ),
      status: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="clr-green action-btn-shadow action-btn br-10 p-5 flex-center">
            Approve
          </div>
          <div className="  p-5 flex-center ">-</div>
        </div>
      ),
    },
    {
      date_time: (
        <div className="settlemt-statement-client-data">
          <div>16-10-24 2:30:38 </div>
          <div>trx-id-1752635456</div>
        </div>
      ),
      pkg: (
        <div className="settlemt-statement-client-data">
          <div>
            30 days- <span className="clr-orng">10</span>{" "}
          </div>
          <div>
            90 days- <span className="clr-orng">5</span>{" "}
          </div>
          <div>
            180 days- <span className="clr-orng">0</span>{" "}
          </div>
        </div>
      ),
      type: (
        <div className="settlemt-statement-client-data">
          <div>Live Streaming - Stock</div>
          <div>
            Exp: <span className="clr-orng">21-11-24</span>
          </div>
        </div>
      ),
      amount: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">T:100000 </div>
          <div className="clr-white">D:100000 </div>
          <div className="clr-white">P/L:100000 </div>
        </div>
      ),
      status: (
        <div className="settlemt-statement-client-data flex-centre">
          <div className="clr-red action-btn-shadow action-btn br-10 p-5 flex-center">
            Rejected
          </div>
          <div className="  p-5 flex-center ">-</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-10 font-weight-600">
        <span className="clr-orng">Super Admin {">"}</span> Srinivas
      </div>

      {purchaseClick === false && (
        <>
          <div className="sales-container br-10 d-flex w-100">
            <div className="flex-space-between date-input w-50 m-5  p-10 ">
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="date-none w-100"
              />
              <FaCalendarAlt className="calendar-icon" />
            </div>
            <MdOutlineSwapHorizontalCircle className="swap-icon" />
            <div className="flex-space-between date-input m-5  w-50 p-10 ">
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="date-none w-100"
              />
              <FaCalendarAlt className="calendar-icon" />
            </div>
          </div>
        </>
      )}

      <div className="scrolling-settlement">
        <Table columns={columns} data={userDetailsProfitLoss} />
      </div>
      <table className="w-100 account-summary-main-container mt-5">
        <tfoot>
          <tr>
            <th>P</th>
            <th>800000</th>
            <th>S</th>
            <th className="clr-orng br-10 ">500000</th>
            <th>P/L</th>
            <th className="clr-green">500000</th>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default Tickets;
