import { getAllowedRoutes, isLoggedIn } from "../utils/helpers";
import PrivateRoute from "../PrivateRoute";
import { Redirect, useLocation,useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CallMeetings from "../pages/video-call-matchposition/CallMeetings";
import MatchPosition from "../pages/video-call-matchposition/MatchPosition";
import Calling from "../pages/video-call-matchposition/Calling";
import { GET_LIVE_MEETING } from "../config/endpoints";
import { call } from "../config/axios";
import { IonImg } from "@ionic/react";
import image from "./calling_user_minimizer1.jpg";
import { setMinimize,setMinimizeUser } from "../redux/actions/dataActions";
import MeetingsHeader from "../pages/meetings/MeetingsHeader";
import Join from "../components/GroupCall/join";
import JoinUP from "../components/GroupCall/joinup";
import GroupCall from "../components/GroupCall";
import GroupCallUPLevel from "../components/GroupCall/uplevel";

const PrivateRoutes = () => {
    const history = useHistory();
  const condition = isLoggedIn();
  const isMeetingPath = window?.location?.pathname?.startsWith(`/meeting/`);
  const isupmeetingsPath = window?.location?.pathname?.startsWith(`/upmeetings/`);
  const isUPJoinPath = window?.location?.pathname?.startsWith(`/upjoin/`);
  const isJoinPath = window?.location?.pathname?.startsWith(`/join/`);
  const location = useLocation();
  const [liveMeeting, setLiveMeeting] = useState({});
  const [waiting, setWaiting] = useState(false);
  const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
  const isMeetingStarted = useSelector((State) => State.data?.isMeetingStarted);
  const isMinimize = useSelector((State) => State.data?.isMinimize);
  const isMinimizeuser = useSelector((State) => State.data?.isMinimizeUser);
  const on_going_meeting = useSelector((state) => state.data?.ongoingMeeting);
  const uplevelMeeting = useSelector((state) => state.data?.uplevelMeeting);
  const [position, setPosition] = useState({ x: 0, y: 15 });
  const [isDragging, setIsDragging] = useState(false);
  const [muteVideo, setMuteVideo] = useState(null);
  const [removeUserId, setRemoveUserId] = useState(null);
  const meetingId = location.pathname.split("/").slice(-1)?.[0];
  const dispatch = useDispatch();
  const [isUserJoin, setIsUserJoin] = useState(false);
    const [userData, setUserData] = useState(null);
  const onUserJoinClick = () => {
    setIsUserJoin(true);
  };
     const getLiveMeetingData = async () => {
     const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
    return await call(GET_LIVE_MEETING, {
      creator_id: creator_id,
      meeting_id: meetingId,
    })
      .then((res) => {
        console.log("res",res)
        setLiveMeeting(res.data?.data?.Items?.[0]);
      })
      .catch((err) => {
        throw err;
      });
  };
   const handleUserDataUpdate = (userData) => {
    console.log("userData",userData)
    setUserData(userData);
  };

  useEffect(() => {
    if (meetingId?.includes("meeting") && isMeetingStarted) {
      getLiveMeetingData();
    }
  }, [isMeetingStarted]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const onMaximizeClick = () => {
    dispatch(setMinimize(false));
    if(on_going_meeting != null){
      history.push(`/meeting/${on_going_meeting.meeting_id}`)
    }
  };
  const onMaximizeClickUser = () =>{
    console.log('setMinimizeUser',uplevelMeeting)
    dispatch(setMinimizeUser(false));
    if(uplevelMeeting != null){
      history.push(`/upmeetings/${uplevelMeeting.meeting_id}`)
    }
  }

  const handleMuteUpdate = (mute) => { 
    console.log('mut-object',mute)
    setMuteVideo(mute);
  };
  

  let allowedRoutes = [];
  if (condition) {
    allowedRoutes = getAllowedRoutes(localStorage.getItem("account_role"));
  } else {
    return <Redirect to="/" />;
  }
  const butttonClick = document.getElementsByTagName("video")?.[0];
  butttonClick?.addEventListener("click", async () => {
    onMaximizeClick();
  });
  const handleUserRemoveUpdate = (id) => {
    setRemoveUserId(id);
  };
  return (
    <div>
        {((isMeetingStarted && isMinimize) ||
        !isMeetingStarted ||
        isMinimize === undefined) &&
        allowedRoutes?.map(({ component, path }) => (
          <PrivateRoute key={path} component={component} path={path} exact />
        ))}
        {((isMeetingStarted && isMinimizeuser) ||
        !isMeetingStarted ||
        isMinimizeuser === undefined) &&
        allowedRoutes?.map(({ component, path }) => (
          <PrivateRoute key={path} component={component} path={path} exact />
        ))}
        
        {isMinimize &&
        (liveMeeting?.video_call_type == 'audio-video' ? null : (
          <IonImg
            src={image}
            className="
            group-call-minimize
             w-audio-80"
            //  style={{
            //     position: 'absolute',
            //     right: position.x,
            //     bottom: position.y,
            //     border: '1px solid black',
            //     padding: '10px',
            //     cursor: isDragging ? 'grabbing' : 'grab'
            //   }}
            //  onMouseDown={handleMouseDown}
            //  onMouseMove={handleMouseMove}
            //  onMouseUp={handleMouseUp}
            onClick={onMaximizeClick}
          />
        ))}
        {isMinimizeuser &&
        (liveMeeting?.video_call_type == 'audio-video' ? null : (
          <IonImg
            src={image}
            className="
            group-call-minimize
             w-audio-80"
            //  style={{
            //     position: 'absolute',
            //     right: position.x,
            //     bottom: position.y,
            //     border: '1px solid black',
            //     padding: '10px',
            //     cursor: isDragging ? 'grabbing' : 'grab'
            //   }}
            //  onMouseDown={handleMouseDown}
            //  onMouseMove={handleMouseMove}
            //  onMouseUp={handleMouseUp}
            onClick={onMaximizeClickUser}
          />
        ))}
      <div id="group-call-container"> 
        <div>
          {on_going_meeting != null ? (
            <>
             {/* <MeetingsHeader id={meetingId} /> */}
            {isMeetingPath ? 
              on_going_meeting?.meeting_type === "Professional" ? (
                <CallMeetings
                isAdminCreated={isAdminCreated}
                meetingId={meetingId}
                userData={userData}
                liveMeeting={liveMeeting}
                removeUserFromMeeting={handleUserRemoveUpdate}
                muteusers={handleMuteUpdate}
              />
              ) : (
                <MeetingsHeader id={meetingId} />
              )
             : <></>}
            <GroupCall id={meetingId} userJoin={handleUserDataUpdate} removeUser={removeUserId}
              mute={muteVideo}
            />
            </>
          ) : (
            isJoinPath ? <Join id={meetingId} UserJoinClick={onUserJoinClick}/> : <></> 
          )}
          {uplevelMeeting != null ? (
            <>
            {isupmeetingsPath ? (
              uplevelMeeting?.meeting_type === "Professional" ? (
                <Calling
                  meetingId={meetingId}
                  liveMeeting={liveMeeting}
                />
              ) : (
                <MeetingsHeader id={meetingId} />
              )
            )
             : <></>}
            <GroupCallUPLevel id={meetingId} userJoin={handleUserDataUpdate} removeUser={removeUserId}
              mute={muteVideo}
            />
            </>
          ) : (
            isUPJoinPath ? <JoinUP id={meetingId} UserJoinClick={onUserJoinClick}/> : <></> 
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivateRoutes;
