import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import FancyShareRiskPopup from "../fancypopups/FancyShareRiskPopup";
import FancyCommRiskPopup from "../fancypopups/FancyCommRiskPopup";

function FancyRiskRunningTable({ riskRunningData, matchInnings }) {
  const data =
    riskRunningData &&
    Object.keys(riskRunningData)?.map((key) => {
      let {
        amount,
        clientCommission,
        clientShare,
        referalShare,
        referralComission,
        totalLossOrProfit,
        upperLevalShare,
        ulComm,
      } = riskRunningData[key];
      return {
        key: key,
        runs: key,
        amount: parseFloat(amount || 0),
        clientComm: parseFloat(clientCommission || 0),
        rfComm: parseFloat(referralComission || 0),
        clientShare: parseFloat(clientShare || 0),
        ulShare: parseFloat(upperLevalShare || 0),
        masterPl: parseFloat(totalLossOrProfit || 0),
        rfShare: parseFloat(referalShare || 0),
        rfNet:
          parseFloat(referalShare || 0) + parseFloat(referralComission || 0),
        clientNet:
          parseFloat(clientCommission || 0) + parseFloat(clientShare || 0),
        ulComm: parseFloat(ulComm || 0),
        ulNet: parseFloat(upperLevalShare || 0) + parseFloat(ulComm || 0),
      };
    });
  let totalRiskRunningResultData;
  if (data?.length > 0) {
    totalRiskRunningResultData = {
      amount: data.reduce((acc, obj) => acc + (obj.amount || 0), 0),
      clientComm: data.reduce((acc, obj) => acc + (obj.clientComm || 0), 0),
      clientNet: data.reduce((acc, obj) => acc + (obj.clientNet || 0), 0),
      rfComm: data.reduce((acc, obj) => acc + (obj.rfComm || 0), 0),
      clientShare: data.reduce((acc, obj) => acc + (obj.clientShare || 0), 0),
      ulShare: data.reduce((acc, obj) => acc + (obj.ulShare || 0), 0),
      totalLossOrProfit: data.reduce(
        (acc, obj) => acc + (obj.totalLossOrProfit || 0),
        0
      ),
      rfShare: data.reduce((acc, obj) => acc + (obj.rfShare || 0), 0),
      rfNet: data.reduce((acc, obj) => acc + (obj.rfNet || 0), 0),
      ulNet: data.reduce((acc, obj) => acc + (obj.ulNet || 0), 0),
      ulComm: data.reduce((acc, obj) => acc + (obj.ulComm || 0), 0),
    };
  }

  const [fancyShareRiskPopup, setFancyShareRiskPopup] = useState(false);
  const [fancyCommRiskPopup, setFancyCommRiskPopup] = useState(false);
  const handleFancyRiskPopupOpen = () => {
    setFancyShareRiskPopup(!fancyShareRiskPopup);
  };
  const handleFancyRiskPopupClose = () => {
    setFancyShareRiskPopup(false);
  };
  const handleFancyCommRiskPopupOpen = () => {
    setFancyCommRiskPopup(!fancyCommRiskPopup);
  };
  const handleFancyCommRiskPopupClose = () => {
    setFancyCommRiskPopup(false);
  };

  return (
    <div className="meetings-container w-92 mt-2rem">
      <div className="custom-box-shadow  fancy-meetings-details-div">
        <div className="flex-row flex-space-between">
          <div className="custom-box-shadow fancy-upcoming-meetings-div font-14 w-60 p-10 mar-5 white-space">
            Risk Running Pos P/L -
            {matchInnings === "second" ? "2nd Inn" : "1st Inn"}
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handleFancyCommRiskPopupOpen()}
          >
            Comm’s <BiChevronDown className="fancy-down-arrow" />
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handleFancyRiskPopupOpen()}
          >
            Shares <BiChevronDown className="fancy-down-arrow" />
          </div>
        </div>
        <div className="hr-line mt-5"></div>
        <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
          <div className="fancy-match-history-button custom-box-shadow flex-center flex-column font-10">
            <div>Runs</div>
            <div>Amount</div>
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            C - Position
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Rf - Position
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Urs Position
          </div>
        </div>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data?.length > 0 &&
            data?.map((risk, index) => (
              <div key={index} className="flex-row p-5">
                <div className="flex-space-between align-end mt-5 pl-10">
                  <div className="meetings-time w-25 font-2">
                    <div>{risk?.runs}</div>
                    <div
                      className={risk?.amount >= 0 ? "green-clr" : "red-clr"}
                    >
                      {risk?.amount ? risk?.amount?.toFixed(2) : 0}
                    </div>
                  </div>
                  <div
                    className={
                      risk?.clientNet >= 0
                        ? "green-clr font-12 w-20"
                        : "red-clr font-12 w-20"
                    }
                  >
                    {risk?.clientNet ? risk?.clientNet?.toFixed(2) : 0}
                  </div>
                  <div
                    className={
                      risk?.rfNet >= 0
                        ? "green-clr font-12 w-20"
                        : "red-clr font-12 w-20"
                    }
                  >
                    {risk?.rfNet ? risk?.rfNet?.toFixed(2) : 0}
                  </div>
                  <div
                    className={
                      risk?.ulNet >= 0
                        ? "green-clr font-12 w-20"
                        : "red-clr font-12 w-20"
                    }
                  >
                    {risk?.ulNet ? risk?.ulNet?.toFixed(2) : 0}
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
        </div>
        {/* <div className="flex-space-between align-end mt-5 pl-10 fancy-total-row">
          <div className="meetings-time w-25 font-2">
            <div className="font-14 fw-600">Total</div>
            <div
              className={
                totalRiskRunningResultData?.amount >= 0
                  ? "green-clr"
                  : "red-clr"
              }
            >
              {totalRiskRunningResultData?.amount
                ? totalRiskRunningResultData?.amount?.toFixed(2)
                : 0}
            </div>
          </div>
          <div
            className={
              totalRiskRunningResultData?.clientNet >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalRiskRunningResultData?.clientNet
              ? totalRiskRunningResultData?.clientNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalRiskRunningResultData?.rfNet >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalRiskRunningResultData?.rfNet
              ? totalRiskRunningResultData?.rfNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalRiskRunningResultData?.ulNet >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalRiskRunningResultData?.ulNet
              ? totalRiskRunningResultData?.ulNet?.toFixed(2)
              : 0}
          </div>
        </div> */}
        <div className="flex-space-between w-100">
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14 fw-600">UL Position</div>
            <div className="green-clr font-12">
              {totalRiskRunningResultData?.ulNet
                ? totalRiskRunningResultData?.ulNet?.toFixed(2)
                : 0}
            </div>
          </div>
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14 fw-600">Position</div>
            <div className="green-clr font-12">
              {totalRiskRunningResultData?.amount
                ? totalRiskRunningResultData?.amount?.toFixed(2)
                : 0}
            </div>
          </div>
        </div>
      </div>
      {fancyShareRiskPopup && (
        <FancyShareRiskPopup
          data={data}
          totalRiskRunningResultData={totalRiskRunningResultData}
          handleFancyRiskPopupClose={handleFancyRiskPopupClose}
        />
      )}
      {fancyCommRiskPopup && (
        <FancyCommRiskPopup
          data={data}
          totalRiskRunningResultData={totalRiskRunningResultData}
          handleFancyCommRiskPopupClose={handleFancyCommRiskPopupClose}
        />
      )}
    </div>
  );
}

export default FancyRiskRunningTable;
