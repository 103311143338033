import React, { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { AiFillEye } from "react-icons/ai";

import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import PackageTransaction from "./PackageTransaction";
import TicketUpgradePopup from "./TicketUpgradePopup";
import AdminTicketPurchaseData from "./AdminTicketPurchaseData";

function DownlevelTransaction() {
  const transactionList = ["Purchase Admin Package", "Package Transaction"];
  const downTransactionList = [
    "Admins Packages Sale Tickets",
    "Your Package Transaction",
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTransIndex, setActiveTransIndex] = useState(0);
  const handleTransactionButton = (value) => {
    setActiveIndex(value);
  };
  const handleDownTransactionButton = (value) => {
    setActiveTransIndex(value);
  };
  const [showTicketUpgradePopup, setShowTicketUpgradePopup] = useState(false);
  const handleTicketUpgradePopup = () => {
    setShowTicketUpgradePopup((prev) => !prev);
  };
  return (
    <div>
      <div className="package-upgrade-container">
        <div className="flex-space-around flex-row mt-20">
          {downTransactionList.map((data, index) => {
            return (
              <div
                key={index}
                className={
                  activeTransIndex === index
                    ? "package-active-buttons button-box-shadow flex-center font-12 h-40"
                    : "package-inactive-buttons button-box-shadow flex-center font-12 h-40"
                }
                onClick={() => handleDownTransactionButton(index)}
              >
                {data}
              </div>
            );
          })}
        </div>
       
        {/* {activeTransIndex === 0 && <AdminTicketPurchaseData />} */}
        {activeTransIndex === 1 && <PackageTransaction type="subscription"/>}
        <TicketUpgradePopup
          showTicketUpgradePopup={showTicketUpgradePopup}
          setShowTicketUpgradePopup={setShowTicketUpgradePopup}
          handleTicketUpgradePopup={handleTicketUpgradePopup}
        />
        {/* <AdminTicketPurchaseData
          showTicketUpgradePopup={showTicketUpgradePopup}
          setShowTicketUpgradePopup={setShowTicketUpgradePopup}
          handleTicketUpgradePopup={handleTicketUpgradePopup}

        /> */}
      </div>
    </div>
  );
}

export default DownlevelTransaction;
