import { IonCol, IonImg, IonModal, IonRow, useIonToast } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import {
  AiFillCloseCircle,
  AiOutlineMinus,
  AiOutlinePlus,
} from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackages } from "../../redux/actions/commonActions";
import ImageZoom from './imagezoom';
import {
  CREATE_PACKAGE_SUBSCRIPTION,
  GENERATE_SIGNED_URL,
  GET_ADMIN_PACKAGES,
  GET_ADMIN_PACKAGES_TRACKER_INFO,
  GET_ALL_PAYMENT_GATEWAYS,
  GET_USER_INFO,
} from "../../config/endpoints";
import { call, Signed } from "../../config/axios";
import { CgProfile } from "react-icons/cg";
import { BsTelephone } from "react-icons/bs";
import { ImageBaseUrl } from "../../images";
import { ToastMessage } from "../../utils/helpers";

function UpgradePackagesPopup(props) {
  const {
    openPackageUpgrade,
    setOpenPackageUpgrade,
    setSuccessUpdatePopup,
    returnPackage,
    first_dropdown,
    heading,
    typeOfAdd,
    purchaseHead,
  } = props;
  const packageList =
    useSelector((State) => State.common.selected_packages) || [];
  const totalPackagesCost = packageList.reduce(
    (acc, obj) => acc + obj.cost * obj.no_of_packages,
    0
  );
  const totalPackagesDiscountValue = packageList.reduce(
    (acc, obj) => acc + (obj.cost * obj.no_of_packages * obj.discount) / 100,
    0
  );
  const totalDiscountAmount = packageList?.reduce((acc, item) => {
    return acc + item?.cost * (item?.discount / 100);
  }, 0);

  const totalPackagesDiscount = packageList.reduce(
    (acc, obj) => acc + obj.discount / packageList?.length,
    0
  );

  const [disabled, setDisabled] = useState(false);
  const register_id = localStorage.getItem("register_id");
  const typeOfPackages = ["Standard", "Silver", "Gold", "Diamond", "VIP"];
  const returnPackages = ["", "", ""];
  const [openElectedPackages, setOpenElectedPackages] = useState();
  const [openExistedPackages, setOpenExistedPackages] = useState();
  const [openExistedHours, setOpenExistedHours] = useState();
  const [openReturnPackages, setOpenReturnPackages] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [summary, setSummary] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [showPaymentDropDown, setShowPaymentDropDown] = useState("");
  const [upgradePaymentPopup, setUpgradePaymentPopup] = useState();
  const [showUpgradePopup, setShowUpgradePopup] = useState();
  const [showPayButton, setShowPayButton] = useState(false);
  const [transactionImg, setTransactionImg] = useState(false);
  const [singedUrl, setSignedUrl] = useState("");
  const [discount, setDiscount] = useState("");
  const [allPaymentGateway, setAllPaymentGateway] = useState([]);
  const [selectedMethodInfo, setSelectedMethodInfo] = useState("");
  const [trxId, setImageTrxId] = useState("");
  const [userPackageList, setAdminPackages] = useState([]);
  const [returnPackageList, setReturnPackageList] = useState([]);
  const [returnPackageTrackerList, setReturnPackageTrackerList] = useState([]);
  const [selectedReturnPackageTotalCost, setSelectedReturnPackageTotalCost] =
    useState(0);
  const [discountDropdown, setDiscountDropdown] = useState(false);
  const handleDiscountDropdownOpen = () => {
    setDiscountDropdown(!discountDropdown);
  };

  const [present] = useIonToast();
  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };
  const fileInputRef = useRef(null);
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setTransactionImg(file);
    generateSignedUrl();
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handlePaymentDropdown = () => {
    setShowPaymentDropDown((prev) => !prev);
  };

  const dispatch = useDispatch();
  const handleClosePackageUpgrade = () => {
    setOpenPackageUpgrade(false);
    setShowPayButton(false);
    setSelectedMethodInfo("");
    setTransactionImg("");
  };
  const handleOpenElectedPackages = () => {
    setOpenElectedPackages((prev) => !prev);
  };
  const handleExistedPackages = () => {
    setOpenExistedPackages((prev) => !prev);
    setOpenExistedHours(false);
    setOpenReturnPackages((prev) => !prev);
  };
  useState(() => {
    if (packageList) {
      setSummary({});
    }
  }, [packageList]);
  const handleExistedHours = () => {
    setOpenExistedPackages(false);
    setOpenExistedHours((prev) => !prev);
    setOpenReturnPackages(false);
  };
  const generateSignedUrl = async () => {
    const trxId = new Date().getTime();
    await Signed(GENERATE_SIGNED_URL, {
      trxId:trxId,
      event_type: "user_profile_image",
      folder_name: "payments"
    })
      .then(async (res) => {
        console.log('res',res)
        let url = res?.data?.data?.result?.signed_url;
        console.log(url)
        setSignedUrl(url);  
        setImageTrxId(trxId);
      })
      .catch((err) => console.log("generating signed url error", err));
  };
  

  
  const handlePaymentSelect = (itm) => {
    setSelectedMethod(itm.label);
    setShowPaymentDropDown(false);
    const obj = allPaymentGateway.find((obj) => obj.pg_upi === itm.value);
    setSelectedMethodInfo(obj);
  };
  // const handleConfirm = async (itm) => {
  //   setShowPayButton(true);
  //   setIsProcessing(true);
  //   singedUrl &&
  //     transactionImg &&
  //     (await fetch(singedUrl, {
  //       method: "PUT",
  //       body: transactionImg,
  //       headers: {
  //         "Content-Type": "image/jpeg",
  //         "cache-control": "public, max-age=0",
  //       },
  //     })
  //       .catch((err) => {
  //       }));
  //   const discountValue = parseInt((totalPackagesCost * discount) / 100)

  //   const summary = {
  //     total_packages_cost: totalPackagesCost,
  //     final_package_cost: totalPackagesCost - discountValue -totalPackagesDiscountValue,
  //     transaction_img: `${ImageBaseUrl}/transaction/${register_id}.png`
  //   }
  //   if (singedUrl) {
  //     await call(CREATE_PACKAGE_SUBSCRIPTION, {
  //       register_id,
  //       packages: packageList,
  //       summary,
  //     })
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           setSuccessUpdatePopup(true);
  //           setIsProcessing(false)
  //           setOpenPackageUpgrade(false);
  //           dispatch(setSelectedPackages([]))
  //         }
  //       })
  //       .catch((err) => {
  //         setIsProcessing(false);
  //       });
  //   } else {
  //     presentToast({ message: "please upload screenshot", clr: "danger" });
  //   }
  // };

  // const handleReturnPackages = () => {
  //   setOpenReturnPackages((prev) => !prev);
  //   setOpenExistedPackages(false);
  //   setOpenExistedHours(false);
  //   // setOpenPackageUpgrade(false);
  // };
  const onAddandSubtractClick = (obj, value) => {
    let updatePackages = [];
    if (obj.no_of_packages === 1 && value < 1) {
      updatePackages = packageList.filter(
        (itm) => itm.package_id !== obj.package_id
      );
    } else {
      updatePackages = packageList.map((item) => {
        if (item.package_id === obj.package_id) {
          item.no_of_packages = item.no_of_packages + value;
        }
        return item;
      });
    }
    dispatch(setSelectedPackages(updatePackages));
  };

  const onAddandSubtractExistingPackClick = (obj, value) => {
    let updatePackages = [];
    if (selectedReturnPackageTotalCost > finalPackageCost) {
      presentToast({
        message: "return packages cost will not exceed curent packages",
      });
      return;
    }
    updatePackages = userPackageList.map((item) => {
      if (item.package_id === obj.package_id) {
        item.no_of_packages = item.no_of_packages;
        item.selected_no_of_packages =
          (item.selected_no_of_packages || 0) + value;
      }
      return item;
    });
    let updateReturnPackageList = [];
    if (returnPackageList?.length) {
      const findIndex = returnPackageList.findIndex(
        (list) => list?.package_id === obj?.package_id
      );
      if (findIndex < 0) {
        const newArr = [
          {
            cost: obj?.package_cost,
            package_id: obj?.package_id,
            no_of_packages: value,
            selected_no_of_packages: value,
          },
        ];
        updateReturnPackageList = [...returnPackageList, ...newArr];
      } else {
        updateReturnPackageList = returnPackageList.map((list) => {
          if (list?.package_id === obj?.package_id) {
            list.no_of_packages = list.no_of_packages + value;
            list.selected_no_of_packages = list.selected_no_of_packages + value;
          }
          return list;
        });
      }
    } else {
      updateReturnPackageList = [
        {
          ...obj,
          cost: obj?.package_cost,
          package_id: obj?.package_id,
          no_of_packages: value,
          selected_no_of_packages: value,
        },
      ];
    }
    setReturnPackageList(updateReturnPackageList);
    setAdminPackages(updatePackages);
    returnPackagesTotalCost(updateReturnPackageList);
  };

  const returnPackagesTotalCost = (returnPackageList) => {
    if (returnPackageList?.length && returnPackageTrackerList?.length) {
      const returnPackageTrackerList1 = returnPackageTrackerList;
      const returnPackTrackList = returnPackageList.map((returnPackObj) => {
        let no_of_return_packages = returnPackObj.no_of_packages,
          totalValue = 0;
        const filterTrackerListWithId = returnPackageTrackerList1.filter(
          (trackObj) => trackObj.package_id == returnPackObj.package_id
        );
        let i = 0;
        let matched_no_return_pack = no_of_return_packages;
        if (!matched_no_return_pack) {
          returnPackObj.totalValue = totalValue;
        }
        while (
          i < filterTrackerListWithId.length &&
          matched_no_return_pack > 0
        ) {
          const currentItem = filterTrackerListWithId[i];
          currentItem.matched_no_return_pack = currentItem?.no_of_packages;
          const packsToSubtract = Math.min(
            matched_no_return_pack,
            currentItem.no_of_packages
          );
          matched_no_return_pack -= packsToSubtract;
          currentItem.matched_no_return_pack -= packsToSubtract;

          returnPackObj.tracker_id = currentItem?.tracker_id;
          totalValue = totalValue + +currentItem.after_discount_per_single_pack;
          returnPackObj.totalValue = totalValue;
          const existingValues = returnPackObj.tracker_List || [];
          returnPackObj.tracker_List = [
            ...existingValues,
            currentItem?.tracker_id,
          ];
          if (currentItem.matched_no_return_pack === 0) {
            returnPackObj.status = "settled";
            i++;
          }
        }
        return returnPackObj;
      });
      const totalReturnPackValue = returnPackTrackList.reduce(
        (acc, obj) => acc + obj?.totalValue,
        0
      );
      if (totalReturnPackValue > finalPackageCost) {
        setReturnPackageList([]);
        setAdminPackages(
          userPackageList.map((obj) => {
            delete obj.selected_no_of_packages;
            return obj;
          })
        );
        presentToast({
          message: "return packages cost will not exceed curent packages",
        });
      } else {
        setSelectedReturnPackageTotalCost(totalReturnPackValue);
      }
    }
  };
  const totalUserPackageCost = returnPackageList?.reduce(
    (acc, obj) => acc + obj?.cost * obj?.no_of_packages,
    0
  );
  const finalPackageCost = totalPackagesCost;
  const paymentMethodSelect = [
    {
      label: "NEFT/RTGS",
      value: "neft",
    },
    {
      label: "PhonePe",
      value: "phonepe",
    },
    {
      label: "Paytm",
      value: "paytm",
    },
    {
      label: "GooglePay",
      value: "gpay",
    },
    {
      label: "QR Code",
      value: "qr_code",
    },
  ];
  useEffect(() => {
    if (selectedReturnPackageTotalCost > finalPackageCost) {
      presentToast({
        message: "return packages cost will not exceed curent packages",
      });
    }
  }, [selectedReturnPackageTotalCost]);

  const handleConfirmClick = () => {
    setShowPayButton(true);
  };
  const handlePayButton = async () => {
    setDisabled(true);
    await imageUpploadToBucket();
    const discountValue = parseInt((totalPackagesCost * discount) / 100);
    const finalPackageCost =
      totalPackagesCost - discountValue - totalPackagesDiscountValue;
    if (selectedReturnPackageTotalCost > finalPackageCost) {
      presentToast({
        message: "return packages cost will not exceed curent packages",
      });
      return;
    }
    const summary = {
      total_packages_cost: totalPackagesCost,
      final_package_cost: finalPackageCost - selectedReturnPackageTotalCost,
      after_all_discount_final_package_cost: finalPackageCost,
      transaction_img: `${ImageBaseUrl}/payments/${trxId}.png`,
      ...selectedMethodInfo,
      user_discount: discount,
      return_package_list: returnPackageList,
      requester_name: localStorage.getItem("user_name"),
      requester_role: localStorage.getItem("account_role"),
    };
    if (!selectedMethodInfo) {
      presentToast({ message: "please select payment method" });
      return;
    }
    if (!transactionImg) {
      presentToast({ message: "please upload screenshot" });
      return;
    }

    console.log(summary, "summary");
    setIsProcessing(true);
    await call(CREATE_PACKAGE_SUBSCRIPTION, {
      register_id,
      packages: packageList,
      summary,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setSuccessUpdatePopup(true);
          setIsProcessing(false);
          setOpenPackageUpgrade(false);
          setOpenPackageUpgrade(false);
          setSelectedMethodInfo("");
          setTransactionImg("");
          dispatch(setSelectedPackages([]));
        } else {
          setIsProcessing(false);
          presentToast({ message: res.data.message });
        }
      })
      .catch((err) => {
        setIsProcessing(false);
      });
    setUpgradePaymentPopup(true);
    setShowUpgradePopup(false);
  };

  const imageUpploadToBucket = async () => {
    singedUrl &&
      transactionImg &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: transactionImg,
       headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          console.log(res);
          return true;
        })
        .catch((err) => {
          console.log(err);
        }));
  };

  const getProfile = async (id) => {
    const payload = {
      register_id,
    };
    try {
      const res = await call(GET_USER_INFO, payload);
      const userProfileData = res?.data?.data?.[0];
      setDiscount(userProfileData?.package_discount || 0);
    } catch (err) {
      console.log(err);
    }
  };
  const getAllPaymentData = async () => {
    const payload = {
      register_id: localStorage.getItem("creator_id"),
    };
    try {
      const res = await call(GET_ALL_PAYMENT_GATEWAYS, payload);
      if (res.status) {
        setAllPaymentGateway(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAdminPackages = async () => {
    await call(GET_ADMIN_PACKAGES, { register_id })
      .then((res) => {
        const data = res?.data?.data;
        const bulk_subscriptions = [];
        data?.forEach((obj) => {
          if (obj.bulk_subscriptions && obj.bulk_subscriptions.length > 0) {
            bulk_subscriptions.push(obj);
          }
        });
        setAdminPackages(
          bulk_subscriptions[0]?.bulk_subscriptions?.filter(
            (obj) =>
              obj.duration !== "hourly" || obj.package_duration !== "hourly"
          ) || []
        );
      })
      .catch((err) => console.log(err));
  };

  const getAdminTrackerPackages = async () => {
    await call(GET_ADMIN_PACKAGES_TRACKER_INFO, { register_id })
      .then((res) => {
        const adminTrackerPackages = res?.data?.data
          ?.map((item) => {
            const dateTimeString = `${item.created_date} ${item.created_time}`;
            const timestamp = new Date(dateTimeString).getTime();
            item.timestamp = timestamp;
            item.matched_no_return_pack = item.no_of_packages;
            return item;
          })
          ?.sort((a, b) => b.timestamp - a.timestamp);
        setReturnPackageTrackerList(adminTrackerPackages || []);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getProfile();
    getAllPaymentData();
    getAdminPackages();
    getAdminTrackerPackages();
  }, []);

  return (
    <IonModal isOpen={openPackageUpgrade} className="statememt-popup-main">
      <div className="flex-space-between mb-10">
        <span className="reports-heading">
          {typeOfAdd === "Purchase Admin Packages"
            ? purchaseHead === true
              ? "Purchase Packages"
              : "Bulk Packages"
            : "Bulk Hours"}
        </span>
        <IoMdClose
          className="reports-heading"
          onClick={() => handleClosePackageUpgrade()}
        />
      </div>
      <div className="upcoming-table-heading">
        {localStorage.getItem("account_role")} -
        {localStorage.getItem("user_name")}
      </div>
      <div className="upcoming-table mt-10 mb-10 w-100"></div>
      <div className="h-45px mb-10">
        <div
          className="series-input flex-space-between custom-box-shadow"
          onClick={() => handleOpenElectedPackages()}
        >
          <div className="w-80 flex-space-between">
            <span>
              {typeOfAdd === "Purchase Admin Packages"
                ? "Total Packages Price"
                : "Bulk Hours Price"}
            </span>
            <FaChevronDown className="d-flex" />
          </div>

          <div>{totalPackagesCost}</div>
        </div>
        {openElectedPackages && (
          <div className="select-team-dropdown w-80 font-12 h-150px">
            {packageList.map((item, index) => {
              // const selectedPacks = packageList.reduce((acc,obj)=> acc+obj.package_name ===item.toLocaleLowerCase() ? obj.no_of_packages :0,0)
              return (
                <div key={index} className="h-40 flex-space-around mb-5">
                  <span className="package-name">{`${item.package_name}-${item.duration}`}</span>
                  <span className="d-flex">
                    <AiOutlineMinus
                      className="plus-icon"
                      onClick={() => onAddandSubtractClick(item, -1)}
                    />
                    <div className="mrl-5 p-5">{item.no_of_packages}</div>
                    <AiOutlinePlus
                      className="plus-icon"
                      onClick={() => onAddandSubtractClick(item, 1)}
                    />
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {couponCode && (
        <div className="series-input flex-space-between custom-box-shadow mt-10 h-80px p-5 mb-10">
          <div>
            <span className="ml-5">Code Applied :15%</span>
            <div className="upcoming-table-heading flex-space-between">
              FI10cARTq3 <AiFillCloseCircle className="close-icon1 ml-5" />
            </div>
          </div>
          <div>-22500</div>
        </div>
      )}
      {returnPackage ? (
        <div className="h-45px mb-10 w-100">
          <div
            className="series-input flex-space-between custom-box-shadow"
            onClick={() => handleExistedPackages()}
          >
            <div className="w-80 flex-space-between">
              <span>Return existed Packages</span>
              <FaChevronDown />
            </div>
            <div>
              {selectedReturnPackageTotalCost ? "-" : ""}
              {selectedReturnPackageTotalCost}
            </div>
          </div>
          {openReturnPackages && (
            <div className="select-team-dropdown w-80 font-12 h-150px">
              {userPackageList.map((item, index) => {
                // const selectedPacks = returnPackageList.reduce((acc,obj)=> acc+obj.package_name ===item.toLocaleLowerCase() ? obj.no_of_packages :0,0)
                return (
                  <>
                    {item.no_of_packages > 0 ? (
                      <>
                        <div
                          key={index}
                          className="h-40 flex-space-around mb-5"
                        >
                          <span className="package-name">{`${item.package_name} ${item.duration}`}</span>
                          <div className="p-5">
                            {item.selected_no_of_packages > 0
                              ? item.no_of_packages -
                                item.selected_no_of_packages
                              : item.no_of_packages}
                          </div>

                          <span className="d-flex">
                            <AiOutlineMinus
                              className="plus-icon"
                              onClick={() =>
                                item.selected_no_of_packages
                                  ? onAddandSubtractExistingPackClick(item, -1)
                                  : null
                              }
                            />
                            <div className="p-5">
                              {item.selected_no_of_packages || 0}
                            </div>
                            <AiOutlinePlus
                              className="plus-icon"
                              onClick={() =>
                                item?.selected_no_of_packages < -1 ||
                                item.selected_no_of_packages ===
                                  item.no_of_packages
                                  ? null
                                  : onAddandSubtractExistingPackClick(item, +1)
                              }
                            />
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="h-45px mb-10 w-100">
        <div className="series-input flex-space-between custom-box-shadow">
          <div
            className="w-80 flex-space-between"
            onClick={() => handleDiscountDropdownOpen()}
          >
            <span>Tot Dis</span>
            {/* {totalPackagesDiscount || 0} */}
            <FaChevronDown />
          </div>
          <span>
            {totalPackagesDiscountValue
              ? -parseInt(totalPackagesDiscountValue)
              : 0}
          </span>
        </div>
        {discountDropdown && (
          <div className="select-team-dropdown w-80 font-12 mt-5 p-5">
            <IonRow className="border-bottom-white  p-5">
              <IonCol size="5">Pkg Name</IonCol>
              <IonCol size="3">Dis</IonCol>
              <IonCol size="4">Amount</IonCol>
            </IonRow>
            <div className="h-150px">
              {packageList?.length > 0 &&
                packageList?.map((item, index) => (
                  <IonRow className="flex-space-between mt-5" key={index}>
                    <IonCol size="5">
                      {item?.package_name}-
                      {item?.duration === "monthly" ? "(M)" : "(Y)"}
                    </IonCol>
                    <IonCol size="3">{item?.discount}%</IonCol>
                    <IonCol size="4">
                      {item?.cost * (item?.discount / 100)}
                    </IonCol>
                  </IonRow>
                ))}
            </div>
            <IonRow className="border-top-white p-5">
              <IonCol size="8">Total</IonCol>
              <IonCol size="4">{totalDiscountAmount}</IonCol>
            </IonRow>
          </div>
        )}
        {/* {openReturnPackages && false && (
          <div className="select-team-dropdown w-70 font-12 h-150px">
            {returnPackages.map((item, index) => {
              return (
                <div key={index} className="p-10 mb-5 w-100">
                  <span className="w-100 flex-space-between mt-10">
                    <span>Standard Value</span>
                    <span>10000</span>
                  </span>
                  <span className="w-100 flex-space-between mt-10">
                    <span>Avail Days</span>
                    <span>60</span>
                  </span>
                  <span className="w-100 flex-space-between mt-10">
                    <span>Current Value</span>
                    <span>6000</span>
                  </span>
                </div>
              );
            })}
          </div>
        )} */}
      </div>
      <div className="series-input flex-space-between custom-box-shadow">
        Special Discount {discount}%
        <div>
          {discount ? -parseInt((totalPackagesCost * discount) / 100) : 0}
        </div>
      </div>
      {false && (
        <div className="h-45px mb-10 w-100">
          <div
            className="series-input flex-space-between custom-box-shadow mt-10"
            onClick={() => handleExistedHours()}
          >
            <div>Return available Hours</div>
            <FaChevronDown className="mr-10" />
            <div>150000</div>
          </div>
          {openExistedHours && (
            <div className="select-team-dropdown w-70 font-12 h-150px">
              {typeOfPackages.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="h-40 flex-space-around mb-5 w-100"
                  >
                    <span className="package-name">{item}</span>
                    <span>1 h</span>
                    <span className="d-flex">
                      <AiOutlineMinus className="plus-icon mr-5" />
                      <div className="p-5">10</div>
                      <AiOutlinePlus className="plus-icon ml-5" />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      <div className="w-100 h-45 flex-space-between mt-20">
        <div>Total</div>
        <div>
          {(isNaN(parseInt(finalPackageCost))
            ? 0
            : parseInt(finalPackageCost)) -
            (isNaN(parseInt((totalPackagesCost * discount) / 100))
              ? 0
              : parseInt((totalPackagesCost * discount) / 100)) -
            (isNaN(parseInt(totalPackagesDiscountValue))
              ? 0
              : parseInt(totalPackagesDiscountValue)) -
            (isNaN(parseInt(selectedReturnPackageTotalCost))
              ? 0
              : parseInt(selectedReturnPackageTotalCost))}
        </div>
      </div>

      <div className="upcoming-table mt-10 mb-10 w-100 mt-10 mb-10" />
      {
        <button
          className="submit-button w-100 mb-20 clr-green"
          onClick={() => handleConfirmClick()}
        >
          Confirm and Pay
        </button>
      }
      {showPayButton && (
        <div>
          <div className="hr-line mt-20 w-100 mb-20"></div>
          <div
            className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
            onClick={() => handlePaymentDropdown()}
          >
            <div className="pl-10">
              {selectedMethod ? selectedMethod : "Select Payment Method"}
            </div>
            <IonImg
              className="drop-down-icon"
              src={process.env.PUBLIC_URL + "./assets/big_dropdown.png"}
            />
          </div>
          {showPaymentDropDown && (
            <div className="select-team-dropdown payment-position-dropdown  w-85 font-12 h-150px">
              {paymentMethodSelect.map((value, index) => (
                <div
                  className="h-30p flex-center mb-5 font-12"
                  key={index}
                  onClick={() => handlePaymentSelect(value)}
                >
                  {value.label}
                </div>
              ))}
            </div>
          )}
          {selectedMethod === "NEFT/RTGS" ? (
            selectedMethodInfo ? (
              <div className="payment-package-container flex-column font-14 mt-5">
                <div className="flex-row flex-space-around">
                  <div>Name : {selectedMethodInfo?.account_holder_name}</div>
                  <div>IFSC : {selectedMethodInfo?.ifsc_code}</div>
                </div>
                <div className="flex-row flex-space-around mt-5">
                  <div>A/C No: {selectedMethodInfo?.account_number}</div>
                  <div>Bank : {selectedMethodInfo?.bank_name}</div>
                </div>
              </div>
            ) : (
              <div className="font-12 ml-20"> No Data Found </div>
            )
          ) : (
            ""
          )}
          {selectedMethod === "QR Code" ?
            
            (selectedMethodInfo ? (
                <>
                <div className="payment-package-container flex-column font-14 mt-5">
                  <div className="flex-row flex-space-around">
                    <ImageZoom src={selectedMethodInfo.uploadImage}/>
                  </div>
                </div>
                </>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              )
            ): (
              ""
            )}
          {selectedMethod === "Paytm" &&
            (selectedMethodInfo ? (
              <>
                <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                  <input
                    required
                    type="text"
                    placeholder="Enter UPI Name"
                    name="upi_name"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.pg_name}
                  />
                  <CgProfile className="font-size-20 pink-clr" />
                </div>
                <div className="series-input flex-center custom-box-shadow mb-10 mt-10">
                  <input
                    required
                    type="text"
                    placeholder="Enter Phone Number"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.mobile_number}
                  />
                  <BsTelephone className="font-size-20 pink-clr" />
                </div>
              </>
            ) : (
              <div className="font-12 ml-20"> No Data Found </div>
            ))}
          {selectedMethod === "PhonePe" &&
            (selectedMethodInfo ? (
              <>
                <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                  <input
                    required
                    type="text"
                    placeholder="Enter UPI Name"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.pg_name}
                  />
                  <CgProfile className="font-size-20 pink-clr" />
                </div>
                <div className="series-input flex-center custom-box-shadow mb-10">
                  <input
                    required
                    type="text"
                    placeholder="Enter Phone Number"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.mobile_number}
                  />
                  <BsTelephone className="font-size-20 pink-clr" />
                </div>
              </>
            ) : (
              <div className="font-12 ml-20"> No Data Found </div>
            ))}
          {selectedMethod === "GooglePay" &&
            (selectedMethodInfo ? (
              <>
                <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                  <input
                    required
                    type="text"
                    placeholder="Enter UPI Name"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.pg_name}
                  />
                  <CgProfile className="font-size-20 pink-clr" />
                </div>
                <div className="series-input flex-center custom-box-shadow mb-10">
                  <input
                    required
                    type="text"
                    placeholder="Enter Phone Number"
                    className="flex-center w-80 match-input"
                    value={selectedMethodInfo?.mobile_number}
                  />
                  <BsTelephone className="font-size-20 pink-clr" />
                </div>
              </>
            ) : (
              <div className="font-12 ml-20"> No Data Found </div>
            ))}
          <div
            className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
            onClick={handleButtonClick}
          >
            <div className="font-12 ml-10">Upload Screenshot</div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <IonImg
              className="drop-down-icon"
              src={process.env.PUBLIC_URL + "./assets/upload.png"}
            />
          </div>
          <button
            className="confirm-upgrade-popup-button  button-box-shadow flex-center mt-15 w-100"
            disabled={disabled}
            onClick={() => handlePayButton()}
          >
            {isProcessing ? "Processing..." : "Pay"}
          </button>
        </div>
      )}
    </IonModal>
  );
}
export default UpgradePackagesPopup;
