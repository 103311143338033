import { IonCol, IonGrid, IonRow } from "@ionic/react";
import "./styles.css";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineQuickreply } from "react-icons/md";

import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { MdCallEnd } from "react-icons/md";
import { useEffect, useState } from "react";
import {
  GET_ALL_CLIENTS,
  GET_LIVE_MEETING,
  SIGNAL_RECORDING,
  UPDATE_MEETING,
  GET_ADMIN_PACKAGES,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import callIcon from "./assents/blue_calling_icon.jpg";
import { useParams } from "react-router";
import { remove } from "ionicons/icons";
import toast from "react-hot-toast";

function JoinList(props) {
  const [activeIndex, setActiveIndex] = useState();
  const [micOff, setMicOff] = useState(false);
  const [userData, setUserData] = useState([]);
  const [allUserListToJoin, setAllUserListToJoin] = useState([]);
  const [liveMeeting, setLiveMeeting] = useState({});
  const [adminSubscription, setAdminSubscription] = useState([]);
  const [error, setError] = useState(null);
  const handleMicOff = (index,streamId) => {
    setMicOff(!micOff);
    setActiveIndex(index);
    const data ={
      streamId:streamId,
      mute:micOff
    }
    props.muteusers(data);
  };
  const getAllUsers = async () => {
    await call(GET_ALL_CLIENTS, {
      register_id: localStorage.getItem("register_id"),
      account_role: localStorage.getItem("account_role"),
    })
      .then((res) => {
        let result = res?.data?.data;
        result = result.map((itm) => {
          return { id: itm.register_id, name: itm.user_name };
        });
        setAllUserListToJoin(result);
      })
      .catch((err) => {
        throw err;
      });
  };
  const getLiveMeetingData = async () => {
    const creator_id = props?.isAdminCreated
      ? localStorage.getItem("register_id")
      : localStorage.getItem("register_id");
    await call(GET_LIVE_MEETING, {
       creator_id: creator_id,
        meeting_id: props?.meetingId,
      })
      .then((res) => {
        const data = res?.data?.data?.Items?.[0];
        setLiveMeeting(data);
      })
      .catch((err) => {
        throw err;
      });
  };
   const getAdminSubscription = async () => {
      return await call(GET_ADMIN_PACKAGES, {
        register_id: localStorage.getItem("register_id"),
      })
        .then((res) => {
            console.log("getAdminSubscription", res);
            const subscriptions = res?.data?.data?.flatMap(item => item?.subscriptions?.flatMap(sub => sub.Items));
            setAdminSubscription(subscriptions);
            console.log("subscriptions", subscriptions);
        })
        .catch((err) => {
          throw err;
        });
    
  };
  useEffect(() => {
    getAllUsers();
    getAdminSubscription();
    getLiveMeetingData();
  }, []);

  useEffect(() => {
        setUserData(props.userData)
        console.log('userData-join',props.userData)
        allUserListToJoin?.map((itm) => 
          props.userData?.find((user) => user.userID === itm.id || user.userID === itm.id+'up')
        );

}, [ props.userData]);

  const addUserInMeeting = async (id) => {
    const package_data = adminSubscription &&
       adminSubscription?.length > 0 &&
        adminSubscription
          ?.filter((item) => liveMeeting?.package_id === item?.package_id);
    if (package_data[0].package_limits?.members <= liveMeeting.meetingUserIds?.length) {
        setError("Users Limit exceeded, Please Update");
      return false;
    }
    console.log("add user", id);
     if (!liveMeeting?.meetingUserIds.includes(id)) {
    liveMeeting.meetingUserIds.push(id);
  }
    console.log("add user", liveMeeting);
    await call(UPDATE_MEETING, { ...liveMeeting })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getLiveMeetingData();
          getAllUsers();
        } else {
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  const handleRemoveUserFromMeeting = async (removeUserId) => {
    await call(SIGNAL_RECORDING, {
      register_id: localStorage.getItem("register_id"),
      meeting_id: props?.meetingId,
      type: "remove-user-from-room",
      userId: removeUserId,
      userType: "admin",
    })
      .then((res) => {
        console.log("remove user", res);
      })
      .catch((err) => {
        throw err;
      });
  };
  const removeUserInMeeting = async (id) => {
    await handleRemoveUserFromMeeting(id);
  };

  const meetingOutSideUsers = allUserListToJoin.filter(
    (itm) => !liveMeeting?.meetingUserIds?.includes(itm.id)
  );
  console.log('outside',meetingOutSideUsers)

  const meetingInSideUsers = allUserListToJoin.filter((itm) =>
    liveMeeting?.meetingUserIds?.includes(itm.id)
  );

  console.log('meetingInSideUsers',meetingInSideUsers)
  console.log('liveMeeting',liveMeeting)

  const MuteUnmuteUser =  ({itm}) => {
    const user = userData?.find((user) => user.userID === itm.id+'up');
    console.log('user-join',user)
    const streamId = user ? user.streamId : null;
    return (
      <>
      <button
        className="btn-mic-join"
        onClick={() => handleMicOff(itm.id, streamId)}
      >
        {micOff && activeIndex === itm.id ? (
          <BsFillMicMuteFill />
        ) : (
          <BsFillMicFill />
        )}
      </button>
      <button
        className="btn-remove-join"
        onClick={() => removeUserInMeeting(itm.id)}
      > <MdCallEnd /></button>
    </>
    );
  };

  return (
    <div className=" br-10 p-5 cstm-score-calling">
    <IonGrid class="flex-flex-end mt-5">
      <div className="w-65 custom-box-shadow br-15 p-5">
        {error && <div className="error">{error}</div>}
        <div className="w-100 custom-call-join-box mt-5">
          {allUserListToJoin?.map((itm) => (
            <IonRow class="custom-box-shadow br-10 mt-5" key={itm.id}>
              <IonCol size="6" class="align-center">
                <div className="font-14">{itm.name}</div>
              </IonCol>
              <IonCol size="" className="text-end">
                {
                  userData?.find((user) => user.userID === itm.id+'up') ? (
                  <MuteUnmuteUser itm={itm} />
                    
                  ) : 
                  liveMeeting?.meetingUserIds?.includes(itm.id) ? (
                    <button
                      className="btn-wait-join"
                    > Waiting <MdOutlineQuickreply /></button>
                  ) : (
                    <button
                      className="btn-add-join"
                      onClick={() => addUserInMeeting(itm.id)}
                    > Add <FaPlus /></button>
                  )
                }
              </IonCol>
            </IonRow>
          ))}
        </div>
      </div>
    </IonGrid>
    </div>
  );
}

export default JoinList;
