import React from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
function ClientShareOversPopup(props) {
  const { showClientShreOversPopup, setShowClientShareOversPopup } = props;
  const handleClientShareOversPopupClose = () => {
    setShowClientShareOversPopup(false);
  };
  const clientShareOversTable = [
    {
      title: "Overs",
    },
    {
      title: "Client Share",
    },
    {
      title: "Rf Share",
    },
    {
      title: "UL Share",
    },
  ];
  const clientShareOversData = [
    {
      overs: "20 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
    {
      overs: "15 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },

    {
      overs: "12 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
    {
      overs: "10 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
    {
      overs: "8 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
    {
      overs: "5 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
    {
      overs: "3 Over",
      clientshare: "10000000.00",
      rfshare: "500000.00",
      ulshare: "500000.00",
    },
  ];
  return (
    <div className="border-colour">
      <IonModal
        className="package-popup-main p-0"
        isOpen={showClientShreOversPopup}
      >
        
        <div className="flex-center">
          <div className="w-40 p-10 font-14">Client Share</div>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={handleClientShareOversPopupClose}
          >
            <RxCross1 />
          </div>
        </div>
        <div>
          <div className="flex-space-between flex-row cutom-box-shadow table-title-holder font-size-12 w-100 h-30p mb-10">
            {clientShareOversTable.map((shareData, index) => (
              <div key={index} className="w-30 flex-center">
                <div>{shareData.title}</div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll h-20 ml-10 mr-5">
            {clientShareOversData.map((sdata, index) => (
              <div key={index} className="flex-row">
                <div className="flex-space-between mt-5 w-100">
                  <div className="meetings-time w-30 flex-center  font-2 padding-tb">
                    {sdata.overs}
                  </div>
                  <div className="font-size-10 flex-center  w-30 clr-green">
                    {sdata.clientshare}
                  </div>
                  <div className="font-size-10  flex-center w-30 clr-green">
                    {sdata.rfshare}
                  </div>
                  <div className="font-size-10 flex-center clr-green  w-30">
                    {sdata.ulshare}
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
          </div>
        </div>
        <div className="pl-10 pr-10">
          <div className="flex-space-between flex-row total-count-container w-100 h-30p mb-10">
            <div className="font-14 ">Total</div>
            <div className="approved-color">1000000.00</div>
            <div className="approved-color">1000000.00</div>
            <div className="approved-color">500000.00</div>
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default ClientShareOversPopup;
