import { IonGrid, IonModal, IonText } from "@ionic/react";
import UserwisePackagesPopup from "./UserwisePackagesPopup";
import { useEffect, useState } from "react";
import { GET_ALL_CLIENTS, BLOCK_UNBLOCK } from "../../config/endpoints";
import { call } from "../../config/axios";
import HomeTable from "../../common/HomeTable";
import { Images } from "../../images";
import { RxCross1 } from "react-icons/rx";
import ChangePassword from "./ChangePassword";

function AdiminUsersList({
  isUserCreated,
  setSelectedUser,
  selectedUser,
  setEditStatus,
  editStatus,
}) {
  const [openUserwisePopup, setOpenUserwisePopup] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [blockUnBlockPopup, setBlockUnBlockPopup] = useState(false);
  const [err, setErr] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);

  const register_id = localStorage?.getItem("register_id");

  const handleBlockUnblock = async () => {
    setIsProcessing(true);
    await call(BLOCK_UNBLOCK, {
      register_id: selectedUser?.register_id,
      creator_id: register_id,
      active: !selectedUser?.active,
      account_role: selectedUser?.account_role,
    })
      .then((res) => {
        setIsProcessing(false);
        getAllAdmins();
        setSelectedUser("");
        handleBlockUnBlockPopupOpen();
      })
      .catch((err) => {
        setIsProcessing(false);
        handleBlockUnBlockPopupOpen();
        setErr(err);
        console.log(err);
      });
  };

  const handleBlockUnBlockPopupOpen = () => {
    setBlockUnBlockPopup(false);
  };

  const handleIsEditPopupOpen = () => {
    setIsEditProfile(false);
  };
  const adminsColumns = [
    { header: "User Name", field: "user_name" },
    { header: "Packages", field: "p_id" },
    { header: "Action", field: "active" },
  ];
  const getAllAdmins = async () => {
    const payload = {
      register_id: localStorage?.getItem("register_id"),
    };
    await call(GET_ALL_CLIENTS, payload)
      .then((res) => {
        const results = res?.data?.data;
        const filteredData =
          results &&
          results?.length > 0 &&
          results?.filter((i) => i.account_role !== "client");
        setAllAdmins(filteredData);
      })
      .catch((err) => console.log(err));
  };

  const BlockUnBlockUserPopUp = () => {
    return (
      <IonModal className="statememt-popup-main" isOpen={blockUnBlockPopup}>
        <center>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={() => handleBlockUnBlockPopupOpen()}
          >
            <RxCross1 />
          </div>
          <div>
            <img
              className="fancy-declare-image"
              src={Images?.ThumbsUpImage}
              alt="Declare_Man_Img"
            />
          </div>
          <div className="w-75 fancy-declare-text mt-10">
            Are sure you want change the status?.
          </div>
          <div className="flex-center w-100">
            {isProcessing ? (
              "Processing"
            ) : (
              <>
                <div
                  className="mt-15 flex-space-around w-100"
                  onClick={() => {
                    handleBlockUnblock();
                  }}
                >
                  <button className="w-80 button-box-shadow fancy-declare-button green-clr">
                    YES
                  </button>
                </div>
                <div
                  className="mt-15 flex-space-around w-100"
                  onClick={() => handleBlockUnBlockPopupOpen()}
                >
                  <button className="w-80 button-box-shadow fancy-declare-button red-clr">
                    NO
                  </button>
                </div>
              </>
            )}
          </div>
          {err && (
            <IonText color="danger" className="ion-text-center">
              {err}
            </IonText>
          )}
        </center>
      </IonModal>
    );
  };

  const UpdateProfilePopUp = () => {
    return (
      <IonModal className="statememt-popup-main" isOpen={isEditProfile}>
        <center>
          <div
            className="w-100 flex-flex-end p-5"
            onClick={() => handleIsEditPopupOpen()}
          >
            <RxCross1 />
          </div>
          <div>
            <img
              className="fancy-declare-image"
              src={Images?.ThumbsUpImage}
              alt="Declare_Man_Img"
            />
          </div>
          <div className="w-75 fancy-declare-text mt-10">
            Are sure you want edit?.
          </div>
          <div className="flex-center w-100">
            {isProcessing ? (
              "Processing"
            ) : (
              <>
                <div
                  className="mt-15 flex-space-around w-100"
                  onClick={() => {
                    setEditStatus(true);
                    handleIsEditPopupOpen();
                  }}
                >
                  <button className="w-80 button-box-shadow fancy-declare-button green-clr">
                    YES
                  </button>
                </div>
                <div
                  className="mt-15 flex-space-around w-100"
                  onClick={() => {
                    handleIsEditPopupOpen();
                    setSelectedUser("");
                  }}
                >
                  <button className="w-80 button-box-shadow fancy-declare-button red-clr">
                    NO
                  </button>
                </div>
              </>
            )}
          </div>
          {err && (
            <IonText color="danger" className="ion-text-center">
              {err}
            </IonText>
          )}
        </center>
      </IonModal>
    );
  };

  useEffect(() => {
    getAllAdmins();
  }, [isUserCreated]);

  const userColumns = [
    { header: "Admins", field: "user_name" },
    { header: "Share", field: "share" },
    { header: "ULShare", field: "ul_share" },
    { header: "Action", field: "active" },
  ];

  return (
    <IonGrid  className="pad-left-right">
      {/* <div className="flex-space-between pr-5 pl-15 mb-5">
        {userColumns.map((column, index) => (
          <div key={index} className="table-heading mr-15 mr-15">
            {column?.header}
          </div>
        ))}
      </div> */}
      <div className="account-summary-main-container">
        <HomeTable
          data={allAdmins}
          columns={userColumns}
          setSelectedUser={setSelectedUser}
          setBlockUnBlockPopup={setBlockUnBlockPopup}
          setIsChangePassword={setIsChangePassword}
          setIsEditProfile={setIsEditProfile}
        />
      </div>
      {blockUnBlockPopup && <BlockUnBlockUserPopUp />}
      {isChangePassword && (
        <ChangePassword
          selectedUser={selectedUser}
          open={isChangePassword}
          setIsChangePassword={setIsChangePassword}
        />
      )}
      {isEditProfile && <UpdateProfilePopUp />}
      <UserwisePackagesPopup
        openUserwisePopup={openUserwisePopup}
        setOpenUserwisePopup={setOpenUserwisePopup}
      />
    </IonGrid>
  );
}
export default AdiminUsersList;
