import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaRegUser, FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { MdPayment } from "react-icons/md";
import UPIDetails from "./UPIDetails";
import { useState } from "react";
import { BiListCheck } from "react-icons/bi";

function PaymentDetails() {
  const [removeMember, setRemovemember] = useState(true);
  const handleRemovMember = (index) => {
    setRemovemember(!removeMember);
  };
  const NUMBER_OF_MEMBERS_DETAILS = [
    {
      member: 1,
      memberName: "Ranjith",
      memberAge: "25",
      memberGender: "Male",
    },
    {
      member: 2,
      memberName: "Priya",
      memberAge: "25",
      memberGender: "Female",
    },
  ];
  return (
    <IonGrid>
      <div className="meetings-container w-92">
        <div className="w-100 flex-space-between mt-20">
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaRegUser className="green-clr font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <BiListCheck className="green-clr font-20" />
          </div>
          <div className="middle-line  middle-line-bg"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <MdPayment className="green-clr font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaCheck className="font-20" />
          </div>
        </div>
        <div className="w-100 flex-space-between mt-5">
          <div className="font-10">Your Details</div>
          <div className="font-10">Payment</div>
          <div className="font-10">Complete</div>
        </div>
        <div className="w-100 custom-box-shadow mt-20 details-container">
          <div className="font-16 p-10">
            <b>Payment </b> Deatils
          </div>
          <hr className="horizantal-line" />
          <div className="w-92 custom-box-shadow members-container mt-10 mb-10">
            <div className="w-100 flex-space-between p-8">
              <div className="font-14">Number of Members</div>
              <div>2</div>
            </div>
            <hr className="horizantal-line" />
            <div className="w-22 flex-center font-12 custom-box-shadow br-15 ml-5 mt-5">
              Details
            </div>
            {NUMBER_OF_MEMBERS_DETAILS?.map((item, index) => (
              <div key={index}>
                <IonRow className="flex-center">
                  <IonCol size="1">
                    <div
                      className="font-14 d-flex"
                      onClick={(index) => handleRemovMember(index)}
                    >
                      {removeMember ? (
                        <FaCheck className="flex green-clr" />
                      ) : (
                        <FaXmark className="flex red-clr" />
                      )}
                    </div>
                  </IonCol>
                  <IonCol size="5">
                    <div className="font-10">Member {item.member}</div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="font-10">
                      <span>{item.memberName},</span>
                      <span>{item.memberGender},</span>
                      <span>{item.memberAge}</span>
                    </div>
                  </IonCol>
                  <IonCol size="2">
                    <div className="font-10 viewID-text p-5 button-box-shadow fw-600">
                      View ID
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            ))}
            <hr className="offers-hr-line mt-5 mb-5" />
            <IonRow className="mt-10">
              <IonCol size="4" className="font-10">
                Package Amount
              </IonCol>
              <IonCol size="2" className="font-10">
                1,00000
              </IonCol>
              <IonCol size="4" className="font-10">
                Total Members
              </IonCol>
              <IonCol size="2" className="font-10">
                02
              </IonCol>
            </IonRow>
            <IonRow className="flex-space-between mt-5 mb-10">
              <IonCol size="4" className="font-10">
                Total Amount to Pay
              </IonCol>
              <IonCol size="2" className="font-10">
                2,00000
              </IonCol>
            </IonRow>
          </div>
          <button className="w-92 mt-5 button-box-shadow green-clr font-16 br-10 h-44px ml-5per">
            Confirm & Pay
          </button>
          <hr className="offers-hr-line mt-15 mb-10" />
          <UPIDetails />
        </div>
      </div>
    </IonGrid>
  );
}

export default PaymentDetails;
