import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";

function AdminManagement(props) {
  const { setTypeOfAdd, setSelectedUser, setEditStatus } = props;
  const [showUpdradePackage, setShowUpdradePackage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(false);
  const [activeHeading, setActiveHeading] = useState(0);
  const accountRole = localStorage.getItem("account_role");
  const handleTypeofAdding = (item, index) => {
    setEditStatus(false);
    setTypeOfAdd(item);
    setSelectedUser("");
    setShowUpdradePackage(false);
    setActiveHeading(index);
    setSelectedPackage(false);
  };
  const handleUpgradePackageDropdown = () => {
    setShowUpdradePackage((prev) => !prev);
    setActiveHeading(false);
  };
  const handleSelectedPackage = (item) => {
    setSelectedPackage(item);
    setTypeOfAdd(item);
    setShowUpdradePackage(false);
  };

  const selectTeamDropdown = [
    "Purchase Admin Packages",
    "Top up Recharge Hours",
    "Purchase Package Tickets",
  ];

  const userManagement =
    accountRole === "agent" ? ["Add Users"] : ["Add Users", "Add Admins"];
  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10 h-110px" id="mt-10">
        <div className="connect-device-header custom-box-shadow m-5">
          User Management/Creation
        </div>
        <div className="w-100 upcoming-table d-flex">
          {userManagement?.map((item, index) => {
            return (
              <IonCol key={index} size={accountRole === "agent" ? "12" : "4"}>
                <div
                  className="series-input flex-center custom-box-shadow type-of-user"
                  onClick={() => handleTypeofAdding(item, index)}
                >
                  <div
                    className={`${
                      activeHeading === index ? "clr-green" : ""
                    } flex-center w-80 match-input font-12`}
                  >
                    {item}
                  </div>
                  <AiOutlinePlus className="clock-icon" />
                </div>
              </IonCol>
            );
          })}
          {accountRole !== "agent" && (
            <IonCol size={"4"}>
              <div
                className="series-input flex-center custom-box-shadow"
                onClick={() => handleUpgradePackageDropdown()}
              >
                <div
                  className={`${
                    selectedPackage === false ? " " : "clr-green"
                  } flex-center w-80 match-input pl-10 font-12`}
                >
                  {selectedPackage ? selectedPackage : "Packages"}
                </div>
                <FaChevronDown />
              </div>
            </IonCol>
          )}
        </div>
        {showUpdradePackage && accountRole !== "agent" && (
          <div className="w-100 flex-flex-end">
            <IonCol size="6">
              <div className="select-team-dropdown h-150px">
                {selectTeamDropdown.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="h-40 flex-center mb-5 text-center"
                      onClick={() => handleSelectedPackage(item)}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </IonCol>
          </div>
        )}
      </IonRow>
    </IonGrid>
  );
}

export default AdminManagement;
