import { TbArrowBarToDown } from "react-icons/tb";
import { PiShareFatFill } from "react-icons/pi";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { Images } from "../../images";

function Message() {
  return (
    <div>
      <MeetingsHeader heading={"Message"} />
      <div className="offline-page-container mt-10">
        <div className="custom-box-shadow p-10 br-10">
          <div className="font-12">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
            ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
            accumsan lacus velsis.
          </div>
          <div className="yellow-clr font-12 mt-5">14 july, 2023</div>
        </div>
        <div className="custom-box-shadow p-10 br-10 mt-20">
          <div className="font-12">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </div>
          <div className="flex-space-between mt-10">
            <div className="yellow-clr font-12 mt-5">14 july, 2023</div>
            <div className="w-20 flex-space-between">
              <div className="flex-center message-share-icon button-box-shadow">
                <TbArrowBarToDown />
              </div>
              <div className="flex-center message-share-icon button-box-shadow">
                <PiShareFatFill />
              </div>
            </div>
          </div>
          <div className="mt-10">
            <img src={Images.PDFBooking} alt="PDF" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
