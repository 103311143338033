import { IonModal, IonText } from "@ionic/react";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { call } from "../../config/axios";
import { CHANGE_ADMIN_PASSWORD } from "../../config/endpoints";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ChangeAdminsPassword(props) {
  const { setIsChangePassword, open, selectedUser } = props;
  const register_id = localStorage?.getItem("register_id");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [creatorPassword, setCreatorPassword] = useState("");
  const [err, setErr] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: true,
    confirmPassword: true,
    creatorPassword: true,
  });

  const handlePasswordVisible = (fieldName) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handdleResetFields = () => {
    setPassword("");
    setConfirmPassword("");
    setCreatorPassword("");
  };
  const handleChangePassword = async () => {
    if (
      password === "" ||
      !password ||
      confirmPassword === "" ||
      !confirmPassword ||
      creatorPassword === "" ||
      !creatorPassword
    ) {
      return setErr("Please enter all fields");
    }
    if (password !== confirmPassword) {
      return setErr("password mismatch");
    }
    setErr("");
    setIsProcessing(true);
    await call(CHANGE_ADMIN_PASSWORD, {
      register_id: selectedUser?.register_id,
      creator_id: register_id,
      new_password: password,
      confirm_password: confirmPassword,
      creator_password: creatorPassword,
      account_role: selectedUser?.account_role,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res?.data?.error === "false") {
          handleClose();
          setErr("");
          setIsProcessing(false);
          handdleResetFields();
        } else {
          setErr(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setErr(err?.message ? err?.message : `something wen't wrong`);
      });
  };

  const handleClose = () => {
    setIsChangePassword(false);
  };

  return (
    <IonModal className="w-70 create-referral-popup" isOpen={open}>
      <div className="align-center flex-column">
        <div className="w-100 flex-flex-end p-5" onClick={() => handleClose()}>
          <IoCloseSharp />
        </div>
        <div className="w-100">
          <div className="reports-heading flex-center mb-15">
            Change Password
          </div>
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-100 match-input"
              placeholder="Enter password"
              name="password"
              id="password"
              type={showPassword.password ? "password" : "text"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword.password ? (
              <AiFillEyeInvisible
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("password")}
              />
            ) : (
              <AiFillEye
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("password")}
              />
            )}
          </div>
          <div className="series-input flex-center custom-box-shadow mt-10">
            <input
              className="flex-center w-100 match-input"
              placeholder="Confirm password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword || ""}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showPassword.confirmPassword ? "password" : "text"}
            />
            {showPassword.confirmPassword ? (
              <AiFillEyeInvisible
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("confirmPassword")}
              />
            ) : (
              <AiFillEye
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("confirmPassword")}
              />
            )}
          </div>
          <div className="series-input flex-center custom-box-shadow mt-10">
            <input
              className="flex-center w-100 match-input"
              placeholder="Creator password"
              name="creatorPassword"
              id="creatorPassword"
              value={creatorPassword || ""}
              onChange={(e) => setCreatorPassword(e.target.value)}
              type={showPassword.creatorPassword ? "password" : "text"}
            />
            {showPassword.creatorPassword ? (
              <AiFillEyeInvisible
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("creatorPassword")}
              />
            ) : (
              <AiFillEye
                className="dropdown-icon"
                onClick={() => handlePasswordVisible("creatorPassword")}
              />
            )}
          </div>
          <div className="flex-space-between mt-10">
            <button
              className="edit-btn-clr flex-center w-100 fw-600 button-box-shadow p-10 br-10 font-14 h-40"
              disabled={isProcessing}
              onClick={() => handleChangePassword()}
            >
              {isProcessing ? "Proccessing..." : "Submit"}
            </button>
          </div>
        </div>
        {err && (
          <IonText color="danger" className="ion-text-center">
            {err}
          </IonText>
        )}
      </div>
    </IonModal>
  );
}

export default ChangeAdminsPassword;
