import { BiSolidHandDown } from "react-icons/bi";

function YouIntrested(props) {
  const { handleOpenYourDetailsComponent } = props;
  return (
    <div>
      <div className="flex-center mt-20 font-16">
        You have Interested on this check details
        <BiSolidHandDown className="mt-5 ml-5 hands-down-icon" />
      </div>
      <div className="conditions-text flex-center font-12 mt-10 conditions-text">
        This company established under the laws of Costa Rica, with registered
        address at Costa Rica and having its gaming sublicence No. 1669/KAV
        issued by Costa Rica e-Gaming and all rights to operate the gaming
        software. Freestyle is a company established under the laws of Cyprus,
        with registered address at Flamoudiou, 13, Strovolos 2036, Nicosia,
        Cyprus. These Terms & Conditions apply to you, and are binding upon you,
        if you Participate at T EXCHANGE. By Participating, you agree that you
        have read and understood these Terms & Conditions and you acknowledge
        that these Terms & Conditions shall apply to you.
        <br />
        This company established under the laws of Costa Rica, with registered
        address at Costa Rica and having its gaming sublicence No. 1669/KAV
        issued by Costa Rica e-Gaming and all rights to operate the gaming
        software. Freestyle is a company established under the laws of Cyprus,
        with registered address at Flamoudiou, 13, Strovolos 2036, Nicosia,
        Cyprus. These Terms & Conditions apply to you, and are binding upon you,
        if you Participate at T EXCHANGE. By Participating, you agree that you
        have read and understood these Terms & Conditions and you acknowledge
        that these Terms & Conditions shall apply to you.
      </div>
      <button
        className="w-100 button-box-shadow green-clr font-16 br-10 h-44px mt-50"
        onClick={handleOpenYourDetailsComponent}
      >
        Book Now
      </button>
    </div>
  );
}

export default YouIntrested;
