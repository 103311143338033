import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { call } from "../../config/axios";
import { GET_UPDATED_MATCHES_DATA } from "../../config/endpoints";
import moment from "moment";
import { isLoggedIn } from "../../utils/helpers";

function Alerts() {
  let token = isLoggedIn();

  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");

  const [data, setData] = useState([]);

  const tableHeading = [
    {
      size: "2",
      headerName: "User",
    },
    {
      size: "5",
      headerName: "Match Name",
    },
    {
      size: "3",
      headerName: "Amount",
    },
    {},
  ];

  const tableContent = (match) => {
    return [
      {
        size: "2",
        content: (
          <div>
            S.No:{`${match?.s_no}/${match?.old_s_no}`}
            <br />
            {match?.client_name}
          </div>
        ),
      },
      {
        size: "5",
        content: (
          <div>
            {match?.team}-
            <span className="eating">{match?.pe || match?.yN}</span>
            <br />
            {Object.keys(match?.teamObj)[0]} vs {Object.keys(match?.teamObj)[1]}
            <br />
            {moment(match?.matchTimeStamp).format("DD-MM-YYYY")}
            <br />
            {moment(match?.matchTimeStamp).format("hh:mm:ss A")}
          </div>
        ),
      },
      {
        size: "2.5",
        content: (
          <div
            className={`${
              match?.record_status === "updated"
                ? "match-entry-edit-color"
                : match?.record_status === "deleted"
                ? "red-clr"
                : ""
            }`}
          >
            {match?.amount}
          </div>
        ),
      },
      {
        size: "2.5",
        content:
          match?.record_status === "updated" ? (
            <div className="match-entry-edit-color">Edited</div>
          ) : match?.record_status === "deleted" ? (
            <div className="red-clr">Deleted</div>
          ) : (
            ""
          ),
      },
    ];
  };
  const getMatchReports = async () => {
    await call(GET_UPDATED_MATCHES_DATA, { register_id })
      .then((res) => {
        setData([...res?.data?.matchEntryData, ...res?.data?.fancyEntryData]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (token) {
      getMatchReports();
    }
  }, []);

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10" id="mt-10">
        <IonCol size="11.4" className="flex-space-between ">
          <div className="connect-device-header custom-box-shadow">Alerts</div>
          <div className="connect-device-seemore custom-box-shadow">
            See All
          </div>
        </IonCol>
        <div className="w-100 upcoming-table d-flex ">
          {tableHeading?.map((value, index) => {
            return (
              <IonCol
                key={index}
                size={value.size}
                className="d-flex justify-content-center align-items-center"
              >
                <div
                  className={
                    index <= 2
                      ? "upcoming-table-heading custom-box-shadow flex-center"
                      : ""
                  }
                >
                  {value?.headerName}
                </div>
              </IonCol>
            );
          })}
        </div>
        <div className="table-rows-scroll w-100">
          {data?.length > 0 &&
            data?.map((match, index) => {
              return (
                <div key={index} className="w-100 upcoming-table d-flex">
                  {tableContent(match)?.map((value, index) => {
                    return (
                      <IonCol
                        className={
                          index <= 2
                            ? "table-content-text flex-center"
                            : "table-content-delete flex-center"
                        }
                        size={value.size}
                        key={index}
                      >
                        <div className="max-width-60p">{value?.content}</div>
                      </IonCol>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </IonRow>
    </IonGrid>
  );
}

export default Alerts;
