import React, { useState } from "react";

import { useHistory } from "react-router";
import MeetingsHeader from "../pages/meetings/MeetingsHeader";
import AdminCreation from "../pages/add-admins/AdminCreation";
import AddAdminList from "../pages/add-admins/AddAdminList";
import UpgradePackagesPopup from "../pages/add-admins/UpgradePackagesPopup";
import AddUserManagement from "./AddUserManagement";

function AddUsers() {
  const [openPackageUpgrade, setOpenPackageUpgrade] = useState(false);
  const history = useHistory();
  return (
    <div>
      <MeetingsHeader heading={"Add Users"} />
      <button
        className="font-14 w-30 h-40 text-black-color"
        onClick={() => history.push("/add-users")}
      >
        Click Me !!
      </button>
      <div className="we-2-call-main-section">
        <AddUserManagement />
      </div>
      <UpgradePackagesPopup
        openPackageUpgrade={openPackageUpgrade}
        setOpenPackageUpgrade={setOpenPackageUpgrade}
      />
    </div>
  );
}

export default AddUsers;
