import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function CreateUserSuccessPopup(props) {
  const { openPopup, setclosePopup, displayData, } = props;
  const handleFancyThankyouPopupClose = () => {
    setclosePopup(false);
  };

  return (
    <IonModal className="statememt-popup-main" isOpen={openPopup}>
      <center>
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handleFancyThankyouPopupClose}
        >
          <RxCross1 />
        </div>
        <div>
          <img
            className="fancy-declare-image"
            src={Images.ThumbsUpImage}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-75 fancy-declare-text mt-10">{displayData}</div>
        <div
          className="mt-15 flex-space-around w-100"
          onClick={handleFancyThankyouPopupClose}
        >
          <button className="w-40 button-box-shadow fancy-declare-button green-clr fw-600"
           onClick={handleFancyThankyouPopupClose}>
            OK
          </button>
        </div>
      </center>
    </IonModal>
  );
}

export default CreateUserSuccessPopup;