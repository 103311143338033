import { IonImg } from "@ionic/react";
import { useState } from "react";
import { BsFillRocketTakeoffFill } from "react-icons/bs";

function PackagesContainer(props) {
  const {
    checked,
    handleUpgradePopup,
    handleAddPaymentPopup,
    setSelectedImage,
    setSelectPackagesDropdown,
    setSelectedPackages,
    allPackages,
  } = props;

  const handelState = (item1, item2, selectedPack) => {
    handleUpgradePopup();
    setSelectedImage(item1);
    setSelectPackagesDropdown(item2);
    setSelectedPackages(selectedPack);
  };

  const trialPack = allPackages.find((obj) => obj.package_name === "trial");
  const standardMonthPack = allPackages?.find(
    (obj) =>
      obj.package_name === "standard" && obj.package_duration === "monthly"
  );
  const standardAnnualPack = allPackages.find(
    (obj) =>
      obj.package_name === "standard" && obj.package_duration === "yearly"
  );
  const silverMonthPack = allPackages.find(
    (obj) => obj.package_name === "silver" && obj.package_duration === "monthly"
  );
  const silverAnnualPack = allPackages.find(
    (obj) => obj.package_name === "silver" && obj.package_duration === "yearly"
  );
  const goldMonthPack = allPackages.find(
    (obj) => obj.package_name === "gold" && obj.package_duration === "monthly"
  );
  const goldAnnualPack = allPackages.find(
    (obj) => obj.package_name === "gold" && obj.package_duration === "yearly"
  );
  const diamondMonthPack = allPackages.find(
    (obj) =>
      obj.package_name === "diamond" && obj.package_duration === "monthly"
  );

  const diamondAnnualPack = allPackages.find(
    (obj) => obj.package_name === "diamond" && obj.package_duration === "yearly"
  );
  const trialPackage = [
    `${trialPack?.package_limits?.no_of_meetings} calls free`,
  ];

  const standardList = [
    {
      title: `Join Call With ${
        checked
          ? standardAnnualPack?.package_limits?.members
          : standardMonthPack?.package_limits?.members
      } users`,
    },
    {
      title: `Monthly ${
        checked
          ? standardAnnualPack?.package_limits?.meetings
          : standardMonthPack?.package_limits?.meetings
      } meetings`,
    },
    {
      title: `Use ${
        checked
          ? standardAnnualPack?.package_limits?.personal_meetings
          : standardMonthPack?.package_limits?.personal_meetings
      } personal Meetings`,
    },
    {
      title: `${
        checked
          ? standardAnnualPack?.package_limits?.audio_calls
          : standardMonthPack?.package_limits?.audio_calls
      } Call Only`,
    },
  ];
  const silverList = [
    {
      title: `Join Call With ${
        checked
          ? silverAnnualPack?.package_limits?.members
          : silverMonthPack?.package_limits?.members
      } users`,
    },
    {
      title: `Monthly ${
        checked
          ? silverAnnualPack?.package_limits?.meetings
          : silverMonthPack?.package_limits?.meetings
      } meetings`,
    },
    {
      title: `Use ${
        checked
          ? silverAnnualPack?.package_limits?.personal_meetings
          : silverMonthPack?.package_limits?.personal_meetings
      } personal Meetings`,
    },
    {
      title: `${
        checked
          ? silverAnnualPack?.package_limits?.audio_calls
          : silverMonthPack?.package_limits?.audio_calls
      } Calls`,
    },
  ];
  const goldList = [
    {
      title: `Join Call With ${
        checked
          ? goldAnnualPack?.package_limits?.members
          : goldMonthPack?.package_limits?.members
      } users`,
    },
    {
      title: `Monthly ${
        checked
          ? goldAnnualPack?.package_limits?.meetings
          : goldMonthPack?.package_limits?.meetings
      } meetings`,
    },
    {
      title: `Use ${
        checked
          ? goldAnnualPack?.package_limits?.personal_meetings
          : goldMonthPack?.package_limits?.personal_meetings
      } personal Meetings`,
    },
    {
      title: `${
        checked
          ? goldAnnualPack?.package_limits?.audio_calls
          : goldMonthPack?.package_limits?.audio_calls
      } Calls`,
    },
  ];
  const diamondList = [
    {
      title: `Join Call With ${
        checked
          ? diamondAnnualPack?.package_limits?.join_call_with_users
          : diamondMonthPack?.package_limits?.join_call_with_users
      } users`,
    },
    {
      title: `Monthly ${
        checked
          ? diamondAnnualPack?.package_limits?.meetings
          : diamondMonthPack?.package_limits?.meetings
      } meetings`,
    },
    {
      title: `Use ${
        checked
          ? diamondAnnualPack?.package_limits?.personal_meetings
          : diamondMonthPack?.package_limits?.personal_meetings
      } personal Meetings`,
    },
    {
      title: `${
        checked
          ? diamondAnnualPack?.package_limits?.audio_calls
          : diamondMonthPack?.package_limits?.audio_calls
      } Calls`,
    },
  ];
  const [upgradeType, setUpgradeType] = useState("packages");
  const handleUpgradePacks = (item) => {
    setUpgradeType(item);
  };
  return (
    <div>
      <div>
        {/* <div className="flex-space-evenly flex-row mt-10 p-5">
          <div
            className="flex-space-evenly h-7 flex-row w-100 br-15"
            style={{ background: "#1b256a" }}
          >
            <div
              className="flex-center custom-box-shadow h-30p br-15 w-30"
              style={{ fontSize: "12px", color: "var(--font-color)" }}
            >
              Trial Package
            </div>
            <div className="flex-column flex-center">
              {trialPackage.map((pdata, index) => {
                return (
                  <div
                    key={index}
                    className="font-10 flex-row ml-15 flex-center"
                  >
                    <IonImg
                      className="flex-start check-mark mr-10 "
                      src={process.env.PUBLIC_URL + "./assets/check_mark.png"}
                    />
                    {pdata}
                  </div>
                );
              })}
            </div>

            <div className="flex-center p-5">
              <button
                className="add-package-button text-black-color flex-center fw-600"
                onClick={() =>
                  handelState("/assets/coin.png", "Trial Package", trialPack)
                }
              >
                Upgrade
              </button>
            </div>
          </div>
      
        </div> */}
        <div className="flex-space-around flex-row mt-10 p-5">
          <div className="flex-center relative-position flex-column w-100 ">
            <div className="rupees-circle flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">
                {checked
                  ? standardAnnualPack?.package_cost
                  : standardMonthPack?.package_cost}
              </div>
            </div>
            <div className="standard-package font-14">
              <div>
                <IonImg
                  className="badge-images"
                  src={process.env.PUBLIC_URL + "./assets/coin.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>
                    {checked
                      ? standardAnnualPack?.discount
                      : standardMonthPack?.discount}
                    %
                  </b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-30"
                style={{
                  fontSize: "16px",
                  color: "var(--font-color)",
                  fontWeight: "600",
                }}
              >
                Standard Package
              </div>
              <div>
                {standardList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={process.env.PUBLIC_URL + "./assets/check_mark.png"}
                      />
                      {data.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center p-5">
                <button
                  className="add-package-button flex-center mt-20"
                  onClick={() =>
                    handelState(
                      "/assets/coin.png",
                      "Standard Package",
                      checked ? standardAnnualPack : standardMonthPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
          <div className="flex-center relative-position flex-column w-100 ">
            <div className="rupees-circle rupees-circle-silver flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">
                {checked
                  ? silverAnnualPack?.package_cost
                  : silverMonthPack?.package_cost}
              </div>
            </div>
            <div className="silver-package font-14 space-between">
              <div>
                <IonImg
                  className="badge-images silver-medal"
                  src={process.env.PUBLIC_URL + "./assets/silver_medal.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>
                    {checked
                      ? silverAnnualPack?.discount
                      : silverMonthPack?.discount}
                    %
                  </b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />

              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Silver Package
              </div>
              <div>
                {silverList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {value.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="upgrade-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "./assets/silver_medal.png",
                      "Sliver Package",
                      checked ? silverAnnualPack : silverMonthPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-space-around flex-row mt-10 p-5">
          <div className="flex-center relative-position flex-column w-100">
            <div className="rupees-circle rupees-circle-gold flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">
                {checked
                  ? goldAnnualPack?.package_cost
                  : goldMonthPack?.package_cost}
              </div>
            </div>
            <div className="standard-package gold-package font-14">
              <div>
                <IonImg
                  className="badge-images"
                  src={process.env.PUBLIC_URL + "./assets/gold_medal.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>
                    {checked
                      ? goldAnnualPack?.discount
                      : goldMonthPack?.discount}
                    %
                  </b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Gold Package
              </div>
              <div>
                {goldList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {data.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="upgrade-button upgrade-gold-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "/assets/gold_medal.png",
                      "Gold Package",
                      checked ? goldAnnualPack : goldMonthPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
          <div className="flex-center relative-position flex-column w-100">
            <div className="rupees-circle rupees-circle-diamond flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">
                {checked
                  ? diamondAnnualPack?.package_cost
                  : diamondMonthPack?.package_cost}
              </div>
            </div>
            <div className="silver-package diamond-package font-14">
              <div>
                <IonImg
                  className="badge-images silver-medal"
                  src={process.env.PUBLIC_URL + "./assets/diamond.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>
                    {checked
                      ? diamondAnnualPack?.discount
                      : diamondMonthPack?.discount}
                    %
                  </b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Diamond Package
              </div>
              <div>
                {diamondList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {value.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="add-package-button upgrade-diamond-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "./assets/diamond.png",
                      "Diamond Package",
                      checked ? diamondAnnualPack : diamondMonthPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagesContainer;
