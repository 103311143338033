import { IonModal, useIonToast } from "@ionic/react";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaShareFromSquare } from "react-icons/fa6";
import { Images } from "../../images";
import "./styles.css";
import ShareButtons from "../homepage/ShareButtons";
import { useState } from "react";

function SharePopup(props) {
  const { sharePopup, setSharePopup } = props;
  const register_id = localStorage?.getItem("register_id");
  const refferal_code = localStorage?.getItem("refferal_code");
  const [showShareSection, setShowShareSection] = useState(false);

  const referralData = "www.madmin.we2call.com";
  const handleShowShareButton = () => {
    setShowShareSection((prev) => !prev);
  };
  const handleShareClose = () => {
    setSharePopup(false);
  };
  const [present] = useIonToast();
  const presentToast = ({ message, color = "success" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };
  const handleCopyText = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(referralData);
    presentToast({ message: "Copied Succesfully!" });
  };
  return (
    <IonModal className="w-60 share-popup" isOpen={sharePopup}>
      <div className="align-center flex-column">
        <div
          className="w-100 flex-flex-end p-5"
          onClick={() => handleShareClose()}
        >
          <IoCloseSharp />
        </div>
        <div className="flex-center w-100">
          <img className="refer-w2c-img" src={Images.We2call}></img>
        </div>
        <div className="w-100">
          <div className="font-12 mt-10 ml-10">Master ID</div>
          <div className="align-center custom-box-shadow font-12 p-10 br-10 mt-5 h-40">
            {register_id}
          </div>
          <div className="font-12 mt-20 ml-10">Refer/Share ID</div>
          <div className="align-center custom-box-shadow font-12 p-10 br-10 mt-5 h-40">
            {refferal_code}
          </div>
          <div className="font-12 mt-20 ml-10">Website URL</div>
          <div className="align-center custom-box-shadow font-12 p-10 br-10 mt-5 h-40">
            www.madmin.we2call.com
          </div>
          <div className="flex-space-between mt-10">
            <button
              className="flex-center w-45 copy-color fw-600 button-box-shadow p-10 br-10 font-14 h-40"
              onClick={(e) => handleCopyText(e)}
            >
              <MdOutlineContentCopy className="mr-5 pencil-clr-icon" />
              Copy
            </button>
            <button
              className="flex-center w-45 fw-600 custom-box-shadow p-10 br-10 font-14 h-40"
              onClick={(e) => handleShowShareButton(e)}
            >
              <FaShareFromSquare className="mr-5 green-clr" />
              Share
            </button>
          </div>
        </div>
        {showShareSection && <ShareButtons data={referralData} />}
      </div>
    </IonModal>
  );
}

export default SharePopup;
