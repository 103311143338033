import React, { useEffect, useRef, useState } from "react";
import { IonImg, IonModal } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { BiSolidBank } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { BiCloudUpload } from "react-icons/bi";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import ThankYouPopup from "./ThankYouPopup";
import UpgradePagePopup from "./UpgradePagePopup";
import { ADD_PAYMENT, UPDATE_PAYMENT_GATEWAY,GENERATE_SIGNED_URL } from "../../config/endpoints";
import { useHistory } from "react-router";
import { call, Signed } from "../../config/axios";
import { ImageBaseUrl } from "../../images";
function AddPaymentPopup(props) {
  const [showTQPopup, setShowTQPopup] = useState(false);
  const register_id = localStorage.getItem('register_id')
  const status = props.status
  const handleAddPaymentPopupClose = () => {
    setState(false);
  };
  const { setState, state, showThankYouPopup, setShowThankYouPopup } = props;

  const addPaymentMethods = [
    {
      label: "NEFT/RTGS",
      value: 'neft'
    },
    {
      label: "PhonePe",
      value: 'phonepe'
    },
    {
      label: "Paytm",
      value: "paytm"
    },
    {
      label: "GooglePay",
      value: 'gpay'
    },
    {
      label: "QR Code",
      value: 'qr_code'
    },
  ];
  //payment process//
  const [addPaymentDropdown, setAddPaymentDropdown] = useState(false);
  const [trxId, setImageTrxId] = useState("");
  const [showAddPaymentDropdown, setShowAddPaymentDropdown] = useState("");
  const [singedUrl, setSignedUrl] = useState("");
  const [inputData, setInputData] = useState({});
  const [transactionImg, setTransactionImg] = useState(false);
  const history = useHistory()
  const handleAddPaymentDropdown = () => {
    setShowAddPaymentDropdown((prev) => !prev);
  };
  const handleAddPaymentSelect = (value) => {
    setAddPaymentDropdown(value.label);
    setInputData({ ...inputData, pg_upi: value.value })
    setShowAddPaymentDropdown(false);
  };

  const generateSignedUrl = async () => {
    const trxId = new Date().getTime();
    await Signed(GENERATE_SIGNED_URL, {
      trxId:trxId,
      event_type: "user_profile_image",
      folder_name: "payment_gateway"
    })
      .then(async (res) => {
        console.log('res',res)
        let url = res?.data?.data?.result?.signed_url;
        console.log(url)
        setSignedUrl(url);  
        setImageTrxId(trxId);
      })
      .catch((err) => console.log("generating signed url error", err));
  };

  const uploadfileInputRef = useRef(null);
  const handleUploadFileSelect = (e) => {
    const file = e.target.files[0];
    setTransactionImg(file);
    generateSignedUrl();
  };
  const handleUploadButtonClick = () => {
    uploadfileInputRef.current.click();
  };
  const onInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
  }

  const imageUpploadToBucket = async () => {
    singedUrl &&
      transactionImg &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: transactionImg,
       headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          console.log(res);
          return true;
        })
        .catch((err) => {
          console.log(err);
        }));
  };

  const handleOpenTQPopup = async (id) => {
    await imageUpploadToBucket();
    const payload = {
      register_id,
      uploadImage:`${ImageBaseUrl}/payment_gateway/${trxId}.png`,
      ...inputData
    };
    try {
      const url = props.selectedGateway ? UPDATE_PAYMENT_GATEWAY : ADD_PAYMENT
      const res = await call(url, payload);
      if (res.status) {
        setShowTQPopup(true);
        history.push('/payment-gateway-list')
        props.apiResponse(!status)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setInputData({ ...inputData, ...props.selectedGateway })
    const obj = addPaymentMethods.find((obj) => obj?.value === props?.selectedGateway?.pg_upi)
    setAddPaymentDropdown(obj?.label);
  }, [props?.selectedGateway])

  return (
    <IonModal isOpen={state} className="upgrade-popup-main">
      <div className="modal-bg p-10 clr-white">
        
        <div className="flex-space-between mb-10 relative-position">
          <div>
            <div className="font-16 mb-5">Add Payment Mode</div>
          </div>
          <IoMdClose
            className="reports-heading absolute-position-top-right"
            onClick={() => handleAddPaymentPopupClose()}
          />
        </div>
        <div className="hr-line mt-10 mb-10"></div>
        <div
          className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10 mb-10"
          onClick={() => handleAddPaymentDropdown()}
        >
          <div className="pl-10">
            {addPaymentDropdown ? addPaymentDropdown : "Select Payment Method"}
          </div>
          {showAddPaymentDropdown ? (
            <RiArrowDropUpLine style={{ fontSize: "40px" }} />
          ) : (
            <RiArrowDropDownLine style={{ fontSize: "40px" }} />
          )}
        </div>
        {showAddPaymentDropdown && (
          <div className="select-team-dropdown h-auto absolute-top24 w-100 font-12">
            {addPaymentMethods.map((value, index) => (
              <div
                className="h-30p flex-center mb-5 font-12"
                key={index}
                onClick={() => handleAddPaymentSelect(value)}
              >
                {value.label}
              </div>
            ))}
          </div>
        )}
        {addPaymentDropdown === "NEFT/RTGS" && (
          <>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="Account Holder Name"
                className="flex-center w-80 match-input"
                name="account_holder_name"
                onChange={onInputChange}
                value={inputData.account_holder_name}
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="Bank Name"
                className="flex-center w-80 match-input"
                name="bank_name"
                onChange={onInputChange}
                value={inputData.bank_name}
              />
              <BiSolidBank className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="Bank Account Number"
                className="flex-center w-80 match-input"
                name="account_number"
                onChange={onInputChange}
                value={inputData.account_number}
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="IFSC code"
                className="flex-center w-80 match-input"
                name="ifsc_code"
                onChange={onInputChange}
                value={inputData.ifsc_code}
              />
              <BiSolidBank className="font-size-20 pink-clr" />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "Paytm" && (
          <>
            
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter UPI Name"
                className="flex-center w-80 match-input"
                name="pg_name"
                onChange={onInputChange}
                value={inputData.pg_name}
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter Phone Number"
                className="flex-center w-80 match-input"
                name="mobile_number"
                onChange={onInputChange}
                value={inputData.mobile_number}
              />
              <BsTelephone className="font-size-20 pink-clr" />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "PhonePe" && (
          <>
            
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter UPI Name"
                name="pg_name"
                onChange={onInputChange}
                className="flex-center w-80 match-input"
                value={inputData.pg_name}
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter Phone Number"
                className="flex-center w-80 match-input"
                name="mobile_number"
                onChange={onInputChange}
                value={inputData.mobile_number}
              />
              <BsTelephone className="font-size-20 pink-clr" />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "GooglePay" && (
          <>
            
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter UPI Name"
                name="pg_name"
                onChange={onInputChange}
                value={inputData.pg_name}

                className="flex-center w-80 match-input"
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                required
                type="text"
                placeholder="Enter Phone Number"
                className="flex-center w-80 match-input"
                name="mobile_number"
                value={inputData.mobile_number}
                onChange={onInputChange}
              />
              <BsTelephone className="font-size-20 pink-clr" />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "QR Code" && (
          <>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload QR Code</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload QR Code"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "Upi ID" && (
          <>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="Enter Name"
                className="flex-center w-80 match-input"
                name=""
                onChange={onInputChange}
              />
              <CgProfile className="font-size-20 pink-clr" />
            </div>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <input
                type="text"
                placeholder="Enter UPI ID"
                className="flex-center w-80 match-input"
              />
              <BiSolidBank className="font-size-20 pink-clr" />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        {addPaymentDropdown === "Others" && (
          <>
            <div className="series-input flex-center custom-box-shadow mb-10">
              <textarea
                type="text"
                // placeholder="Enter Name"
                className="flex-center w-80 match-input"
                name=""
                onChange={onInputChange}
              />
            </div>
            <div
              className="series-input flex-space-between custom-box-shadow mb-10 p-20"
              onClick={handleUploadButtonClick}
            >
              <div className="font-12">Upload Screenshot</div>
              <input
                type="file"
                ref={uploadfileInputRef}
                style={{ display: "none" }}
                onChange={handleUploadFileSelect}
                placeholder="Upload Screenshot"
                className="flex-center w-80 match-input"
              />
              <BiCloudUpload className="font-size-20 pink-clr" />
            </div>
          </>
        )}
        <button
          className="confirm-upgrade-popup-button  button-box-shadow flex-center mt-15 w-100"
          onClick={() => handleOpenTQPopup()}
        >
          Submit
        </button>
        <ThankYouPopup
          showThankYouPopup={showTQPopup}
          setShowThankYouPopup={setShowTQPopup}
        // handleThankYouPopup={handleThankYouPopup}
        // handleAddPaymentPopupClose={handleAddPaymentPopupClose}
        />
      </div>
    </IonModal>
  );
}

export default AddPaymentPopup;
