import { useState } from "react";
import { BsShare } from "react-icons/bs";
import DetailsSharePopup from "./../pages/popups/DetailsSharePopup";

function HomeTable(props) {
  const {
    data,
    columns,
    setBlockUnBlockPopup,
    setSelectedUser,
    setIsChangePassword,
    setIsEditProfile,
  } = props;

  const [clientDetailsShare, setClientDetailsShare] = useState(false);
  const [clientData, setClientData] = useState();
  const handleClientDetailsPopup = (data) => {
    setClientDetailsShare(true);
    setClientData(data);
  };

  return (
    <>
      <table className="account-settlement-summary account-settlement-summary-data">
        <thead>
          <tr>
            {columns?.map((column, index) => (
              <th key={index} className="table-heading">
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {columns?.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.field === "active" ? (
                      <div className="flex-space-center">
                        <div>
                          {item.active ? (
                            <div className="flex">
                              <div
                                className={`table-button ${
                                  item?.active ? "clr-green" : "clr-red"
                                }`}
                                onClick={() => {
                                  setBlockUnBlockPopup(true);
                                  setSelectedUser(item);
                                }}
                              >
                                UB
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => {
                                  setIsEditProfile(true);
                                  setSelectedUser(item);
                                }}
                              >
                                E
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => {
                                  setIsChangePassword(true);
                                  setSelectedUser(item);
                                }}
                              >
                                CP
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => handleClientDetailsPopup(item)}
                              >
                                <BsShare />
                              </div>
                            </div>
                          ) : (
                            <div className="flex">
                              <div
                                className={`table-button ${
                                  item?.active ? "clr-green" : "clr-red"
                                }`}
                                onClick={() => {
                                  setBlockUnBlockPopup(true);
                                  setSelectedUser(item);
                                }}
                              >
                                B
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => {
                                  setIsEditProfile(true);
                                  setSelectedUser(item);
                                }}
                              >
                                E
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => {
                                  setIsChangePassword(true);
                                  setSelectedUser(item);
                                }}
                              >
                                CP
                              </div>
                              <div
                                className="table-button clr-green"
                                onClick={() => handleClientDetailsPopup(item)}
                              >
                                <BsShare />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      item[column?.field]
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <DetailsSharePopup
        clientData={clientData}
        registationPopup={clientDetailsShare}
        setRegistationPopup={setClientDetailsShare}
      />
    </>
  );
}

export default HomeTable;
