import {
  SET_MEETING_IS_STARTED,
  SET_MINIMIZE,
  SET_MINIMIZE_USER,
  SET_USER_JOIN_CLICK,
  SET_ONGOING_MEETING,
  REMOVE_ONGOING_MEETING,
  SET_UPLEVEL_MEETING,
  REMOVE_UPLEVEL_MEETING,
} from "../actions/actionConstants";

const initialState = {};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MINIMIZE:
      return { ...state, isMinimize: action.payload };

    case SET_MINIMIZE_USER:
      return { ...state, isMinimizeUser: action.payload };
    case SET_MEETING_IS_STARTED:
      return { ...state, isMeetingStarted: action.payload };
    case REMOVE_ONGOING_MEETING:
      return {
        ...state,
        ongoingMeeting: null,
      };
    case REMOVE_UPLEVEL_MEETING:
      return {
        ...state,
        uplevelMeeting: null,
      };
    case SET_ONGOING_MEETING:
      return {
        ...state,
        ongoingMeeting: action.payload,
      };
    case SET_UPLEVEL_MEETING:
      return {
        ...state,
        uplevelMeeting: action.payload,
      };
    case SET_USER_JOIN_CLICK:
      return { ...state, onUserJoinClick: action.payload };
    default:
      return state;
  }
};

export default dataReducer;
