import { IonGrid } from "@ionic/react";
import "./styles.css";
import VideoOnImage from "./assents/videoIcon.png";
import VideoOffImage from "./assents/videoOffIcon.png";
import MicOnImage from "./assents/micIcon.png";
import MicOffImage from "./assents/micOffIcon.png";
import { useState } from "react";
import { Images } from "../../images";

function VideoCallJoining() {
  const [videoOff, setVideoOff] = useState(false);
  const [micOff, setMicOff] = useState(false);
  const handleVideoOff = () => {
    setVideoOff(!videoOff);
  };
  const handleMicOff = () => {
    setMicOff(!micOff);
  };
  return (
    <IonGrid class="main-container">
      <div className="custom-box-shadow table-page-conatainer w-92 h-50vh br-15">
        <div className="flex-center flex-column mt-15">
          <img
            className="calling-profile-img"
            src={Images.CallingProfileImage}
            alt="Profile_Image"
          />
          <div className="mt-10 font-16 fw-600">Srinivas</div>
          <div className="mt-5 font-14">Inviting for video call...</div>
          <div className="mt-10 font-14 fw-600">Camera is Off..</div>
          <div className="flex-space-between w-100 mt-20">
            <div
              className={`${
                videoOff
                  ? "video-img-div br-10 flex-center red-bg-clr"
                  : "video-img-div br-10 flex-center green-bg-clr"
              }`}
            >
              <img
                className={`${videoOff ? "mic-images" : "video-on-img"}`}
                src={videoOff ? VideoOffImage : VideoOnImage}
                onClick={() => handleVideoOff()}
              />
            </div>
            <div
              className={`${
                micOff
                  ? "video-img-div br-10 flex-center red-bg-clr"
                  : "video-img-div br-10 flex-center green-bg-clr"
              }`}
            >
              <img
                className={`${micOff ? "mic-images" : "mic-on-img"}`}
                src={micOff ? MicOffImage : MicOnImage}
                onClick={() => handleMicOff()}
              />
            </div>
          </div>
        </div>
        <hr className="horizantal-line mt-20 mb-10 w-100" />
        <div>
          <div className="font-14 text-initial">Your Name</div>
          <div className="flex-space-between mt-5">
            <input
              className="custom-box-shadow p-10 br-5 outline-none"
              type="text"
              placeholder="Enter Your Name"
            />
            <div className="green-clr button-box-shadow green-clr font-14 fw-600 p-10 br-5 ml-10">
              JOIN
            </div>
          </div>
        </div>
      </div>
    </IonGrid>
  );
}

export default VideoCallJoining;
