import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import MeetingsHeader from "../meetings/MeetingsHeader";

function ExistingLoginUsers() {
  const matchesStatusDetails = [
    {
      size: "4",
      heading: "UserName",
    },
    {
      size: "5",
      heading: "Action",
    },
  ];
  const deleteBlock = ["Delete", "Block"];
  const [dropdownIndexid, setDropdownIndexid] = useState("");
  const [showDeleteDropdown, setShowDeleteDropdown] = useState();
  const [deleteOrBlock, setDeleteOrBlock] = useState("Delete");
  const ActionButton = ["Edit", deleteOrBlock, "Res.Passw"];
  const UserAliasDetail = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  const handleDeleteBlock = (i, index) => {
    setDropdownIndexid(i);
    setShowDeleteDropdown((prev) => !prev);
  };
  const handleDeleteDropdown = (type) => {
    setDeleteOrBlock(type);
    setShowDeleteDropdown(false);
  };
  return (
    <div>
      <MeetingsHeader heading={"Existing Login Users"} />

      <IonGrid>
        <IonRow className="connect-device mrl-10">
          <IonCol size="12" className="flex-space-between">
            <div className="connect-device-header custom-box-shadow">
              Exesting Login Users
            </div>
          </IonCol>
          <div className="flex-space-between upcoming-table w-100">
            {matchesStatusDetails.map((value, index) => (
              <IonCol key={index} size={value.size}>
                <div className="connect-device-seemore custom-box-shadow">
                  {value.heading}
                </div>
              </IonCol>
            ))}
          </div>
          <div className="exist-user-container w-100">
          {UserAliasDetail.map((value, i) => {
            return (
              <div key={i} className="d-flex upcoming-table">
                <IonCol size="4" className="users-details-list">
                  <div>Sai-OffLine-User</div>
                  <div>Agent</div>
                  <div>Alias Name-sai143</div>
                  <div>REf-jay</div>
                </IonCol>
                <IonCol size="8" className="flex-space-between">
                  {ActionButton.map((value, index) => (
                    <div key={index}>
                      <div
                        id={
                          index === 0 ? "clr-grn" : index === 1 ? "clr-red" : ""
                        }
                        className="connect-device-seemore flex-center"
                        onClick={() => handleDeleteBlock(i, index)}
                      >
                        {value}
                        {index === 1 && (
                          <RiArrowDropDownLine className="delete-dropdown" />
                        )}
                      </div>
                      {index === 1 &&
                        i === dropdownIndexid &&
                        showDeleteDropdown && (
                          <div className="select-team-dropdown-delete mb-neg-100">
                            {deleteBlock.map((type, index) => (
                              <div
                                key={index}
                                className="font-14 flex-center mb-5 p-5"
                                onClick={() => handleDeleteDropdown(type)}
                              >
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  ))}
                </IonCol>
              </div>
            );
          })}
          </div>
        </IonRow>
      </IonGrid>
    </div>
  );
}

export default ExistingLoginUsers;
