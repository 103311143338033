import { useEffect, useState } from "react";
import "./styles.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MatchesScroll from "./MatchesScroll";
import FancyHeader from "./FancyHeader";
import FancyEntryPLTable from "./FancyEntryPLTable";
import FancyRiskRunningTable from "./FancyRiskRunningTable";
import FancyBetHistory from "./FancyBetHistory";
import { call } from "../../config/axios";
import {
  FANCY_RESULT_PROFIT_LOSS,
  GET_ACCOUNT_MATCHES_DATA,
  GET_OFFLINE_ALL_MATCHES,
  RISK_RUNNING_SESSION,
} from "../../config/endpoints";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

function FancyEntry() {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");

  const [fancyEntryInputs, setFancyEntryInputs] = useState(true);
  const [fancyResultInputs, setFancyResultInputs] = useState(false);
  const [allMatches, setAllMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState([]);
  const [matchAccountData, setMatchAccountData] = useState([]);
  const [selectedMatchEntry, setSelectedMatchEntry] = useState("");
  const [status, setStatus] = useState(false);
  const [matchOver, setMatchOver] = useState(1);
  const [matchInnings, setMatchInnigs] = useState("first");
  const [riskRunningData, setRiskRunningData] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [afterDeclare, setAfterDeclare] = useState(false);

  const selectedLiveMatch = useSelector(
    (state) => state?.common?.selected_match
  );
  const handleFancyEntryOpen = () => {
    setFancyEntryInputs(true);
    setFancyResultInputs(false);
  };
  const handleFancyResultOpen = () => {
    setFancyResultInputs(true);
    setFancyEntryInputs(false);
  };

  const getAllMatches = async () => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id,
      account_role,
    })
      .then(async (res) => {
        let result = res?.data?.data;
        const temp = result?.liveMatches?.filter(
          (i) => i.match_declared !== "Y"
        );
        await getCompanyMatches(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyMatches = async (data) => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id: "company",
      account_role,
    })
      .then((res) => {
        let result = res?.data?.data;
        const temp =
          result === undefined
            ? []
            : result?.liveMatches?.filter(
                (i) => i.match_declared !== "Y" || ""
              );
        setAllMatches([...data, ...temp]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllMatches();
    };

    fetchData();
  }, [afterDeclare]);

  const getMatchInfo = async () => {
    await call(GET_ACCOUNT_MATCHES_DATA, {
      register_id,
      match_id: selectedLiveMatch?.match_id,
    })
      .then(async (res) => {
        let temp =
          res?.data && res?.data?.data && res?.data?.data[0]
            ? res?.data?.data[0]
            : res?.data?.data;
        setMatchAccountData(temp);
        await getRiskRunningData(temp?.registered_match_id);
        await getFancyProfitLoss(temp?.registered_match_id);
      })
      .catch((err) => console.log(err));
  };

  const getRiskRunningData = async (ID) => {
    await call(RISK_RUNNING_SESSION, {
      register_id,
      registered_match_id: ID ? ID : matchAccountData?.registered_match_id,
      match_over: matchOver,
      innings: matchInnings === "second" ? "2" : "1",
    })
      .then((res) => {
        setRiskRunningData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const getFancyProfitLoss = async (ID) => {
    await call(FANCY_RESULT_PROFIT_LOSS, {
      register_id,
      registered_match_id: ID ? ID : matchAccountData?.registered_match_id,
    })
      .then((res) => {
        setProfitLossData(res?.data?.data);
        console.log('res?.data?.data',res?.data?.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchMatchInfo = async () => {
      if (selectedMatch) {
        await getMatchInfo();
      }
    };

    fetchMatchInfo();
  }, [selectedLiveMatch?.match_id, afterDeclare]);

  if (selectedLiveMatch?.game_object?.match_type === "testMatch") {
    return <Redirect to="/matchentry" />;
  }
  console.log(matchInnings, "matchInnings");
  return (
    <div>
      <Header />
      <div className="main-container pb-20">
        <MatchesScroll
          data={allMatches}
          setSelectedMatch={setSelectedMatch}
          selectedMatch={selectedLiveMatch}
        />
        <FancyHeader
          fancyEntryInputs={fancyEntryInputs}
          fancyResultInputs={fancyResultInputs}
          handleFancyEntryOpen={handleFancyEntryOpen}
          handleFancyResultOpen={handleFancyResultOpen}
          selectedMatch={selectedLiveMatch}
          matchAccountData={matchAccountData}
          selectedMatchEntry={selectedMatchEntry}
          setSelectedMatchEntry={setSelectedMatchEntry}
          setStatus={setStatus}
          setMatchInnigs={setMatchInnigs}
          setMatchOver={setMatchOver}
          getRiskRunningData={getRiskRunningData}
          getFancyProfitLoss={getFancyProfitLoss}
          profitLossData={profitLossData?.oversObject}
          profitLossDataInnings={profitLossData?.inningsObject}
          setAfterDeclare={setAfterDeclare}
        />
        {fancyEntryInputs && (
          <div>
            <FancyBetHistory
              selectedMatch={selectedLiveMatch}
              seriesType={""}
              setStatus={setStatus}
              matchAccountData={matchAccountData}
              setSelectedMatchEntry={setSelectedMatchEntry}
              profitLossDataInnings={profitLossData?.inningsObject}
              status={status}
            />
            <FancyEntryPLTable
              nameAndOvers={"Name"}
              profitLossData={profitLossData?.clientsData}
              selectedMatch={selectedLiveMatch}
            />
            <FancyEntryPLTable
              nameAndOvers={"Overs"}
              selectedMatch={selectedLiveMatch}
              profitLossData={profitLossData?.oversObject}
            />
            <FancyRiskRunningTable
              matchInnings={matchInnings}
              riskRunningData={riskRunningData}
              selectedMatch={selectedLiveMatch}
            />
          </div>
        )}
        {fancyResultInputs && (
          <div className="mb-20">
            <FancyBetHistory
              selectedMatch={selectedLiveMatch}
              seriesType={""}
              setStatus={setStatus}
              matchAccountData={matchAccountData}
              setSelectedMatchEntry={setSelectedMatchEntry}
              profitLossDataInnings={profitLossData?.inningsObject}
              status={status}
            />
            <FancyEntryPLTable
              nameAndOvers={"Name"}
              selectedMatch={selectedLiveMatch}
              profitLossData={profitLossData?.clientsData}
            />
            <FancyEntryPLTable
              nameAndOvers={"Overs"}
              selectedMatch={selectedLiveMatch}
              profitLossData={profitLossData?.oversObject}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default FancyEntry;
