import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";

function FancyCommOversPopup(props) {
  const { handleFancyCommOverPopupClose, data, totalMatchResultData } = props;
  const FANCY_COMMISSION_POPUP = [
    {
      name: "Srinivas2346",
      overs: "10 Overs",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulComm: "- -",
    },
    {
      name: "Srinivas2346",
      overs: "10 Overs",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulComm: "- -",
    },
    {
      name: "Srinivas2346",
      overs: "10 Overs",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulComm: "- -",
    },
    {
      name: "Srinivas2346",
      overs: "10 Overs",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulComm: "- -",
    },
  ];
  return (
    <IonGrid className="fancy-commission-popup-pos meetings-container w-75 match-share-popup">
      <div className="custom-box-shadow  fancy-meetings-popups-div">
        <div
          className="flex-flex-end fancy-close-icon"
          onClick={() => handleFancyCommOverPopupClose()}
        >
          <RxCross2 />
        </div>
        <IonRow className="flex-space-between w-100 pr-5 pl-5">
          <IonCol
            size="4"
            className="br-15 custom-box-shadow flex-center flex-column font-10"
          >
            <div>Overs</div>
          </IonCol>
          <IonCol
            size="3.5"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            Client Comm
          </IonCol>
          <IonCol
            size="3.5"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            Rf Comm
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
             UL Comm ww
          </IonCol>
        </IonRow>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data?.length > 0 && data.map((fancy, index) => (
            <div key={index} className="flex-row">
              <IonRow className="flex-space-between w-100 align-end mt-5 pl-10 pr-10">
                <IonCol size="3" className="meetings-time font-2">
                  <div>{fancy?.over}</div>
                  <div
                    className={fancy?.clientComm >= 0 ? "green-clr" : "green-clr"}
                  >
                    {100}
                  </div>
                </IonCol>
                <IonCol
                  size="3"
                  className={
                    fancy?.rfComm >= 0
                      ? "green-clr font-12 w-20 text-center"
                      : "red-clr font-12 w-20 text-center"
                  }
                >
                  {100}
                </IonCol>
                <IonCol
                  size="2"
                  className={
                    fancy?.ulComm >= 0
                      ? "green-clr font-12 w-20 text-center"
                      : "red-clr font-12 w-20 text-center"
                  }
                >
                  {100}
                </IonCol>
                
              </IonRow>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
        </div>
        <IonRow className="flex-space-between align-end mt-5 pl-10 pr-10 fancy-total-row">
          <IonCol size="5" className="meetings-time font-2">
            <div className="font-14 fw-600">Total</div>
            <div className="green-clr">{totalMatchResultData?.clientComm ? totalMatchResultData?.clientComm?.toFixed(2) : 0}</div>
          </IonCol>
          <IonCol size="5" className="green-clr font-12 w-25 text-center">
            {totalMatchResultData?.rfComm ? totalMatchResultData?.rfComm?.toFixed(2) : 0}
          </IonCol>
          <IonCol size="2" className="green-clr font-12 w-25 text-center">
          {totalMatchResultData?.ulComm ? totalMatchResultData?.ulComm?.toFixed(2) : 0}
          </IonCol>
          
        </IonRow>
      </div>
    </IonGrid>
  );
}

export default FancyCommOversPopup;
