import { IonHeader, IonImg } from "@ionic/react";
import { FaRegUser, FaCheck } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { useHistory } from "react-router";
import { Images } from "../../images";
import { BiListCheck, BiSolidChevronLeft } from "react-icons/bi";

function BookingComplete() {
  const history = useHistory();
  return (
    <div>
      <IonHeader className="w-100 user-outer-div">
        <div className="flex-space-between w-96">
          <div className="flex-center">
            <BiSolidChevronLeft
              className="custom-box-shadow custom-back-icon"
              onClick={() => history.goBack()}
            />
            <div className="meetings-heading ml-5">Offers</div>
          </div>
          <div
            className="custom-box-shadow flex-center p-5 br-10"
            onClick={() => history.push("/message")}
          >
            <img
              className="discount-image pad-5"
              src={Images.OffersMessage}
              alt="Discount"
            />
          </div>
        </div>
        <hr className="horizantal-line" />
      </IonHeader>
      <div className="meetings-container w-92">
        <div className="w-100 flex-space-between mt-20">
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaRegUser className="green-clr font-20" />
          </div>
          <div className="middle-line middle-line-bg"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <BiListCheck className="green-clr font-20" />
          </div>
          <div className="middle-line middle-line-bg"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <MdPayment className="green-clr font-20" />
          </div>
          <div className="middle-line middle-line-bg"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaCheck className="green-clr font-20" />
          </div>
        </div>
        <div className="w-100 flex-space-between mt-5">
          <div className="font-10">Your Details</div>
          <div className="font-10">Your Details</div>
          <div className="font-10">Payment</div>
          <div className="font-10">Complete</div>
        </div>
        <div className="custom-box-shadow mt-20 flex-center flex-column br-15">
          <div className="w-90">
            <div className="flex-center mt-20">
              <img
                className="congrats-image"
                src={Images.CongratsImage}
                alt="Congrats"
              />
            </div>
            <div className="font-16 fw-600 mt-10 flex-center">
              Congratulations!
            </div>
            <div className="font-12 fw-600 mt-10 flex-center">
              Your Booking is Completed
            </div>
            <div className="font-10 mt-10 flex-center conditions-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis.
            </div>
            <button
              className="w-100 button-box-shadow green-clr font-16 br-10 h-44px ml-5 mb-20 mt-20"
              onClick={() => history.push("/message")}
            >
              Go Back to Message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingComplete;
