import * as actionTypes from "./actionConstants";

export const setLoginModal = (data) => {
  return {
    type: actionTypes.SET_LOGIN_MODAL,
    data,
  };
};

export const setMeetingStartStatus = (data) => {
  return {
    type: actionTypes.SET_MEETING_IS_STARTED,
    payload: data,
  };
};
export const setMinimize = (data) => {
  return {
    type: actionTypes.SET_MINIMIZE,
    payload: data,
  };
};
export const setMinimizeUser = (data) => {
  return {
    type: actionTypes.SET_MINIMIZE_USER,
    payload: data,
  };
};
export const setUserJoinClick = (data) => {
  return {
    type: actionTypes.SET_USER_JOIN_CLICK,
    payload: data,
  };
};
export const setOngoingMeeting = (meeting) => {
  return {
    type: actionTypes.SET_ONGOING_MEETING,
    payload: meeting,
  };
};
export const removeOngoingMeeting = () => {
  return {
    type: actionTypes.REMOVE_ONGOING_MEETING,
  };
};
export const setUplevelMeeting = (meeting) => {
  return {
    type: actionTypes.SET_UPLEVEL_MEETING,
    payload: meeting,
  };
};
export const removeUplevelMeeting = () => {
  return {
    type: actionTypes.REMOVE_UPLEVEL_MEETING,
  };
};
