import React, { useRef, useState } from "react";
import MeetingsHeader from "./MeetingsHeader";
import { AiFillEye } from "react-icons/ai";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import AddPaymentPopup from "../packages-accounts/AddPaymentPopup";
import ThankYouPopup from "../packages-accounts/ThankYouPopup";
import UploadScreenshotPopup from "../popups/UploadScreenshotPopup";

function CallSettelment(props) {
  const [showUploadScreenshotPopup, setShowUploadScreenshotPopup] = useState(false);
  const [showAddPaymentPopupOpen, setShowAddPaymentPopupOpen] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const handleAddPaymentPopup = () => {
    setShowAddPaymentPopupOpen(true);
  };
  const handleUploadScreenshot = () => {
    setShowUploadScreenshotPopup(true);
  };
  const callSettelmentData = [
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      price: 5000,
      status: "Approved",
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      price: 5000,
      status: "Approved",
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      price: 5000,
      status: "Rejected",
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      price: 5000,
      status: "Approved",
    },
  ];
  return (
    <>
      <h5 className="clr-white text-center">Call Settlement</h5>
      <div className="main-container p-5">
        <div className="custom-box-shadow  meetings-details-div mt-10">
          <div className="flex-space-around flex-row w-100 p-5">
            <div className="flex-column align-start flex-space-around custom-box-shadow settelment-div w-45 mt-10 p-10">
              <div className="font-12">Package Charges</div>
              <div className="flex-row flex-space-between w-100 mt-5">
                <div className="meetings-date align-start ">5000</div>
                <div className="font-12 ">
                  
                  Upto
                  <div className="font-12">27-06-23</div>
                </div>
              </div>
            </div>
            <div className="flex-column align-start flex-space-between custom-box-shadow settelment-div w-45 mt-10 p-10">
              <div className="font-12 "> Total Duration</div>
              <div className="meetings-date ">1h 10m</div>
            </div>
          </div>
          <div className="flex-space-around flex-row w-100 p-10">
            <div className="flex-column align-start flex-space-between custom-box-shadow settelment-div w-30 mt-10 p-10">
              <div className="font-12 "> Total Amount</div>
              <div className="meetings-date ">5000</div>
            </div>
            <div className="flex-column align-start flex-space-between custom-box-shadow settelment-div w-35 mt-10 p-10">
              <div className="font-12 ">Setteled Amount</div>
              <div className="meetings-date">5000</div>
            </div>
            <div className="flex-column align-start  flex-space-between custom-box-shadow settelment-div w-30 mt-10 p-10">
              <div className="font-12 ">Bal. Amnt</div>
              <div className="meetings-date">0.00</div>
            </div>
          </div>

          <div className="flex-center w-100 p-10 h-50 p-20 ">
            <input
              type="number"
              placeholder="Setteled Amount"
              className="flex-space-between custom-box-shadow font-12 settelment-div p-20"
            ></input>
          </div>

          <div className="flex-space-around flex-row w-100 p-10">
            <button className="settelment-button button-box-shadow w-100 font-12 mt-10 mb-10">
              Create Settelment
            </button>
          </div>
        </div>
        <div>
          <div className="flex-space-between mt-20 pr-20 pl-10 mb-20">
            <div className="match-history-button custom-box-shadow flex-column flex-center ">
              Create <div>Date & Time</div>
            </div>
            <div className="match-history-button custom-box-shadow flex-center">
              Setteled Amount
            </div>
            <div className="match-history-button custom-box-shadow flex-center">
              Status
            </div>
            <div className="match-history-button custom-box-shadow flex-center">
              View
            </div>
          </div>
          <div className="call-settlement-scroll">
          {callSettelmentData?.map((value, index) => (
            <div
              className="flex-space-between custom-box-shadow meetings-details-div mt-10"
              key={index}
            >
              <div className="flex-space-evenly flex-column custom-box-shadow meetings-date-time-div w-20 ml-10">
                <div className="meetings-date">{value.date}</div>
                <div className="meetings-time">{value.time}</div>
              </div>
              <div className="font-12 w-25 flex-center">{value.price}</div>

              <div
                className={`${
                  value.status === "Approved"
                    ? "custom-box-shadow approved-color meetings-time-text"
                    : "custom-box-shadow rejected-color meetings-time-text"
                }`}
              >
                {value.status}
              </div>

              <div
                className="flex-center play-delete-div mr-20"
                onClick={() => handleUploadScreenshot()}
              >
                <AiFillEye className="IonIcon-eye" alt="Play" />
              </div>
            </div>
          ))}
          </div>
          <div className="flex-flex-end mt-10">
            <MdOutlineArrowBackIosNew className="custom-ionicon-container" />
            <div className="custom-ionicon-container flex-center p-3">1</div>
            <MdOutlineArrowForwardIos className="custom-ionicon-container" />
          </div>
        </div>
      </div>

      <AddPaymentPopup
        showAddPaymentPopupOpen={showAddPaymentPopupOpen}
        setShowAddPaymentPopupOpen={setShowAddPaymentPopupOpen}
        setShowThankYouPopup={setShowThankYouPopup}
      />
      <ThankYouPopup
        showThankYouPopup={showThankYouPopup}
        setShowThankYouPopup={setShowThankYouPopup}
      />
      <UploadScreenshotPopup
        showUploadScreenshotPopup={showUploadScreenshotPopup}
        setShowUploadScreenshotPopup={setShowUploadScreenshotPopup}
      />
    </>
  );
}

export default CallSettelment;
