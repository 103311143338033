import { IonModal } from "@ionic/react";
import { MdOutlinePayment, MdOutlinePrivacyTip } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { LuPlane } from "react-icons/lu";
import { useHistory } from "react-router";
import { useState } from "react";
import AddPaymentPopup from "../packages-accounts/AddPaymentPopup";

function FooterTermsPopup(props) {
  const history = useHistory();
  const { footerTermsPopup, handleFooterTermsPopupClose } = props;
  const [showAddPaymentPopupOpen, setShowAddPaymentPopupOpen] = useState(false);

  const handlePaymentGatewayButton = () => {
    setShowAddPaymentPopupOpen((prev) => !prev);
    handleFooterTermsPopupClose();
  };
  const handlePrivcyPolicy = () => {
    history.push("/privacy-policy");
  };

  const handleToursAndTournaments = () => {
    history.push("/tours-and-tournaments");
  };
  const handlePaymentGatewayList = () => {
    history.push("/payment-gateway-list");
  };

  const FOOTER_BUTTONS = [
    {
      name: "Privacy Policy",
      icon: <MdOutlinePrivacyTip />,
      path: handlePrivcyPolicy,
    },
    // {
    //   name: "Tours & Tournaments",
    //   icon: <LuPlane />,
    //   path: handleToursAndTournaments,
    // },
    {
      name: "Add Payment Gateway",
      icon: <MdOutlinePayment />,
      path: handlePaymentGatewayButton,
    },
    {
      name: "Payment Gateway List",
      icon: <MdOutlinePayment />,
      path: handlePaymentGatewayList,
    },
  ];
  return (
    <>
      <IonModal className="footer-terms-popup" isOpen={footerTermsPopup}>
        <div className="align-center flex-column pb-10">
          <div
            className="w-100 flex-flex-end terms-footer-cross"
            onClick={handleFooterTermsPopupClose}
          >
            <RxCross1 />
          </div>
          <div className="w-100 mt-5">
            {FOOTER_BUTTONS?.map((item, index) => (
              <div
                key={index}
                className="w-100 p-8 align-center custom-box-shadow br-10 mt-10"
                onClick={item.path}
              >
                <div className="flex font-22 mr-10">{item.icon}</div>
                <div className="font-14">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </IonModal>
      <AddPaymentPopup
        state={showAddPaymentPopupOpen}
        setState={setShowAddPaymentPopupOpen}
      />
    </>
  );
}

export default FooterTermsPopup;
