import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useRef, useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BiCloudUpload, BiListCheck } from "react-icons/bi";
import { FaRegUser, FaCheck, FaAngleDown } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
function YourDetails(props) {
  const { handlePaymentDetailsComponentOpen } = props;
  const [membersSelect, setMembersSelect] = useState(false);
  const [members, setmembers] = useState(false);
  const [genderSelect, setGenderSelect] = useState(false);
  const [gender, setGender] = useState(false);
  const [idProofSlelct, setIdProofSelect] = useState(false);
  const [IDProof, setIDProof] = useState(false);
  const [membersCount, setMembersCount] = useState(0);
  const [totalMembers, setTotalMembers] = useState();
  const handleMembersIncrease = () => {
    setMembersCount(membersCount + 1);
  };
  const handleMembersDecrease = () => {
    return membersCount > 0 ? setMembersCount(membersCount - 1) : "";
  };
  const handleMemeberSelectOpen = () => {
    setMembersSelect(!membersSelect);
  };
  const handleSelectMember = (content, index) => {
    setmembers(content.label);
    setMembersSelect(false);
    // setTotalMembers(content.value);
    {
      let arr = [];
      for (let i = 0; i < content.value; i++) {
        arr.push({});
      }
      setTotalMembers(arr);
    }
  };
  const handleGenderSelectOpen = (index) => {
    setGenderSelect(!genderSelect);
  };
  const handleSelectGender = (content) => {
    setGender(content);
    setGenderSelect(false);
  };
  const handleIDProofOpen = () => {
    setIdProofSelect(!idProofSlelct);
  };
  const handleSelectIDProof = (content) => {
    setIDProof(content);
    setIdProofSelect(false);
  };
  const fileInputRef = useRef(null);
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const NUMBER_OF_MEMBERS = totalMembers;
  const MEMBERS_HEADING = [
    { label: "100000 - 200000", value: 1 },
    { label: "200000 - 300000", value: 3 },
    { label: "300000 - 400000", value: 5 },
    { label: "400000 - 500000", value: 7 },
    { label: "500000 - above", value: 10 },
  ];
  const IDPROOF_HEADING = [
    { id: 1, heading: "Adhar Card" },
    { id: 2, heading: "PAN Card" },
    { id: 3, heading: "Licence" },
  ];
  return (
    <IonGrid>
      <div className="meetings-container w-92">
        <div className="w-100 flex-space-between mt-20">
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaRegUser className="green-clr font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <BiListCheck className="font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <MdPayment className="font-20" />
          </div>
          <div className="middle-line custom-box-shadow"></div>
          <div className="flex-center your-details-icon custom-box-shadow">
            <FaCheck className="font-20" />
          </div>
        </div>
        <div className="w-100 flex-space-between mt-5">
          <div className="font-10">Your Details</div>
          <div className="font-10">Payment</div>
          <div className="font-10">Complete</div>
        </div>
        <div className="w-100 custom-box-shadow mt-20 details-container">
          <div className="font-16 p-10 flex-space-between">
            <div>
              <b>Fill Your </b> Deatils
            </div>
            <div className="w-30 flex-space-evenly">
              {/* <button
                style={{ background: "none" }}
                className="font-22 pencil-clr-icon"
                onClick={() => handleMembersDecrease()}
              >
                <AiOutlineMinusCircle className="flex" />
              </button> */}
              <div>
                Members:<span className="font-22">{membersCount}</span>
              </div>
              {/* <button
                style={{ background: "none" }}
                className="font-22 pencil-clr-icon"
                onClick={() => handleMembersIncrease()}
              >
                <AiOutlinePlusCircle className="flex" />
              </button> */}
            </div>
          </div>
          <hr className="horizantal-line" />
          <IonRow className="mt-5">
            <IonCol size="6">
              <div
                className="custom-box-shadow p-8 flex-space-between br-10"
                onClick={() => handleMemeberSelectOpen()}
              >
                <div className="font-12">{members || "Select Package"}</div>
                <div className="font-16 mt-5">
                  <FaAngleDown />
                </div>
              </div>
              {membersSelect && (
                <div className="w-90 custom-box-shadow mt-5 br-10 p-5 pos-absolute z-index">
                  {MEMBERS_HEADING?.map((item, index) => (
                    <div
                      key={index}
                      className="custom-box-shadow p-5 br-5 font-12 mt-5"
                      onClick={() => handleSelectMember(item, index)}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </IonCol>
          </IonRow>
          <div className="scrolling-inputs">
            {NUMBER_OF_MEMBERS?.map((item, index) => (
              <div key={index}>
                <IonRow className="d-flex justify-content-between">
                  <IonCol size="6">
                    <div className="font-12 custom-box-shadow p-5 br-15">
                      Member A {index + 1}
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <div className="custom-box-shadow p-8 flex-space-between br-10">
                      <div className="font-12">Already Registered</div>
                      <div className="font-16 mt-5">
                        <FaAngleDown />
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6">
                    <div className="custom-box-shadow p-8 flex-space-between br-10 h-44px">
                      <input
                        className="font-12 details-name-input"
                        placeholder="Name"
                      />
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div className="custom-box-shadow p-8 flex-space-between br-10 h-44px">
                      <input
                        className="font-12 details-name-input"
                        placeholder="Age"
                      />
                    </div>
                  </IonCol>
                  <IonCol size="3">
                    <div
                      className="custom-box-shadow p-8 flex-space-between br-10 h-44px"
                      onClick={() => handleGenderSelectOpen(index)}
                    >
                      <div className="font-12 details-name-input">
                        {gender || "Gender"}
                      </div>
                      <div className="font-16 mt-5">
                        <FaAngleDown />
                      </div>
                    </div>
                    {genderSelect && (
                      <div className="w-90 custom-box-shadow mt-5 br-10 p-5 pos-absolute z-index">
                        <div
                          className="custom-box-shadow p-5 br-5 font-12"
                          onClick={() => handleSelectGender("Male")}
                        >
                          Male
                        </div>
                        <div
                          className="custom-box-shadow p-5 br-5 font-12 mt-5"
                          onClick={() => handleSelectGender("Female")}
                        >
                          Female
                        </div>
                      </div>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6">
                    <div
                      className="custom-box-shadow p-8 flex-space-between br-10 h-44px"
                      onClick={() => handleIDProofOpen()}
                    >
                      <div className="font-12 details-name-input">
                        {IDProof || "Select ID Proof"}
                      </div>
                      <div className="font-16 mt-5">
                        <FaAngleDown />
                      </div>
                    </div>
                    {idProofSlelct && (
                      <div className="w-90 custom-box-shadow mt-5 br-10 p-5 pos-absolute z-index">
                        {IDPROOF_HEADING?.map((item, index) => (
                          <div
                            key={index}
                            className="custom-box-shadow p-5 br-5 font-12 mt-5"
                            onClick={() => handleSelectIDProof(item.heading)}
                          >
                            {item.heading}
                          </div>
                        ))}
                      </div>
                    )}
                  </IonCol>
                  <IonCol size="6">
                    <div
                      className="w-100 h-44px custom-box-shadow br-10 p-10 flex-space-between"
                      onClick={handleButtonClick}
                    >
                      <div className="font-12">Upload ID Photo</div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                      />
                      <BiCloudUpload className="payment-upload-icon" />
                    </div>
                  </IonCol>
                </IonRow>
                <hr className="offers-hr-line mt-10 mb-10" />
              </div>
            ))}
          </div>
          <button
            className="w-97 button-box-shadow green-clr font-16 br-10 h-44px ml-5 mb-20"
            onClick={handlePaymentDetailsComponentOpen}
          >
            Submit
          </button>
        </div>
      </div>
    </IonGrid>
  );
}

export default YourDetails;
