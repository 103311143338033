import React, { useEffect, useState } from "react";
import { call } from "../../config/axios";
import { REQUESTED_PACKAGES } from "../../config/endpoints";
import moment from "moment";

function PackageTransaction(props) {
  const {type} = props
 const [requestedPackages, setRequestedPackages] = useState([])

 const getAllPackageRequests = async()=> { 
  const payload = {
    register_id: localStorage.getItem("register_id"),
    creator_id: localStorage.getItem("creator_id")
  };
  await call(REQUESTED_PACKAGES, payload)
    .then((res) => {
      const resp = res?.data?.data?.filter((obj)=>obj.type === type)?.map((item) => {
        const dateTimeString = `${item.created_date} ${item.created_time}`;
        const timestamp = new Date(dateTimeString).getTime();
        item.timestamp = timestamp;
        return item
      })?.sort((a, b) => b.timestamp - a.timestamp)
      setRequestedPackages(resp);
    })
    .catch((err) => console.log(err));}

  useEffect(()=>{
    getAllPackageRequests()
  },[])

  const userPackageData = requestedPackages?.map((obj)=>{
    return {
      user: localStorage.getItem("user_name"),
      date: moment(obj.created_date).format("DD-MM-YYYY"),
      time: obj.created_time,
      packageAmt: obj.summary.total_packages_cost,
      txrID: obj.transaction_id,
      price: obj.summary.final_package_cost,
      status: obj.status,
      text: obj.reason,
    }
  })

  return (
    <div className="main-container">
      <div className="flex-space-between pl-15 pr-20 mt-20">
        <div className="meetings-button custom-box-shadow flex-center w-auto font-10 p-10">
          Date & Time
        </div>
        <div className="meetings-button custom-box-shadow flex-center w-auto font-10 p-10">
          Trx ID
        </div>
        <div className="meetings-button custom-box-shadow flex-center w-auto  font-10 p-10">
          Pay Amount
        </div>
        <div className="meetings-button custom-box-shadow flex-center w-auto font-10 p-10">
          Status
        </div>
      </div>
      {userPackageData?.map((valuee, index) => (
        <div
          className="flex-space-between custom-box-shadow meetings-details-div mt-10"
          key={index}
        >
          <div className="flex-space-around flex-column w-20">
            <div className="font-12 ml-5 mt-5">{valuee.user}</div>
            <div className="flex-space-evenly flex-column custom-box-shadow meetings-date-time-div w-100  mt-10">
              <div className="font-10">{valuee.date}</div>
              <div className="font-10">{valuee.time}</div>
            </div>
          </div>

          <div className="meetings-time w-30 padding-tb font-12">
            <div className="mb-10">{valuee.txrID} </div>
            <div className="mt-20">Upgrade Package : {valuee.packageAmt} </div>
          </div>
          <div className="custom-box-shadow flex-end  meetings-time-text font-12">
            {valuee.price}
          </div>

          <div className="flex-column flex-space-around play-btn-div w-20">
            <div
              className={`${
                valuee.status === "Approved"
                  ? "flex-center status-button clr-green w-70 fw-600"
                  : valuee.status === "pending"
                  ? "flex-center status-button clr-brown w-70 fw-600"
                  : "flex-center status-button clr-red w-70 fw-600"
              }`}
            >
              {valuee.status}
            </div>
            {valuee.text &&<div className="font-10 ml-10">{valuee.text}</div>}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PackageTransaction;
