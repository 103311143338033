import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TermsAndConditionsPopup from "../pages/popups/TermsAndConditionsPopup";
import RegistrationSuccessfulPopup from "../pages/popups/RegistrationSuccessfulPopup";
import FooterTermsPopup from "../pages/popups/FooterTermsPopup";
import StatementPopup from "./StatementPopup";
function Footer(props) {
  const history = useHistory();
  const [termsAndConditionsPopup, setTermsAndConditionsPopup] = useState(false);
  const [registrationSuccessfull, setRegistrationSuccessfull] = useState(false);
  const [footerTermsPopup, setFooterTermsPopup] = useState(false);
  const [statementPopupOpen, setStatementPopupOpen] = useState(false);
  const [footerClass, setFooterClass] = useState(
    "home-footer footer-div flex-space-around w-100"
  );

  const footerImages = [
    "/assets/match_entry_footer_img.png",
    "/assets/reports_footer_img.png",
    "/assets/home_footer_img.png",
    "/assets/chat_footer_img.png",
    "/assets/more_footer_icon.png",
  ];
  const handleTermsAndConditionsPopupOpen = () => {
    setTermsAndConditionsPopup(!termsAndConditionsPopup);
  };
  const handleTermsAndConditionsPopupClose = () => {
    setTermsAndConditionsPopup(false);
  };
  const handleRegistrationSuccessfullPopupOpen = () => {
    setRegistrationSuccessfull(!registrationSuccessfull);
  };
  const handleRegistrationSuccessfullPopupClose = () => {
    setRegistrationSuccessfull(false);
  };
  const handleFooterTermsPopupOpen = () => {
    setFooterTermsPopup(!footerTermsPopup);
  };
  const handleFooterTermsPopupClose = () => {
    setFooterTermsPopup(false);
  };
  const handleReportsPopupOpen = () => {
    setStatementPopupOpen(!statementPopupOpen);
  };

  const [activeIndex, setActiveIndex] = useState(2);
  useEffect(() => {
    if (
      history.location.pathname == "/matchentry" ||
      history.location.pathname == "/fancyentry"
    ) {
      setFooterClass("match-entry-footer footer-div flex-space-around w-100");
      setActiveIndex(0);
    }
  }, [setActiveIndex]);
  const handleFooterOpretions = (index) => {
    switch (index) {
      case 0:
        history.push("/matchentry");
        setFooterClass("match-entry-footer footer-div flex-space-around w-100");
        break;
      case 1:
        setFooterClass("reports-footer footer-div flex-space-around w-100");
        handleReportsPopupOpen();
        break;
      case 2:
        setFooterClass("home-footer footer-div flex-space-around w-100");
        history.push("/");
        break;
      case 3:
        setFooterClass("chat-footer footer-div flex-space-around w-100");
        // history.push("/chat");
        history.push("/chat-lists");
        // chat-lists
        break;
      case 4:
        setFooterClass("more-footer footer-div flex-space-around w-100");
        handleFooterTermsPopupOpen();
        break;
      default:
        setFooterClass("match-entry-footer footer-div flex-space-around w-100");
    }
    setActiveIndex(index);
  };

  return (
    <div className={footerClass}>
      {footerImages.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => handleFooterOpretions(index)}
            className={
              activeIndex === index
                ? "w-20 flex-center footer-image-main"
                : "w-20 flex-center"
            }
          >
            <div className="footer-image-wrap flex-center">
              <img
                className="footer-image"
                src={process.env.PUBLIC_URL + item}
              ></img>
            </div>
          </div>
        );
      })}
      <TermsAndConditionsPopup
        termsAndConditionsPopup={termsAndConditionsPopup}
        handleTermsAndConditionsPopupClose={handleTermsAndConditionsPopupClose}
      />
      <RegistrationSuccessfulPopup
        registrationSuccessfull={registrationSuccessfull}
        handleRegistrationSuccessfullPopupClose={
          handleRegistrationSuccessfullPopupClose
        }
      />
      <FooterTermsPopup
        footerTermsPopup={footerTermsPopup}
        handleFooterTermsPopupClose={handleFooterTermsPopupClose}
      />
      <StatementPopup
        statementPopupOpen={statementPopupOpen}
        setStatementPopupOpen={setStatementPopupOpen}
      />
    </div>
  );
}
export default Footer;
