import { useEffect, useRef, useState } from "react";
import { IonImg, IonModal, useIonToast } from "@ionic/react";
import { IoMdClose } from "react-icons/io";
import { ImageBaseUrl } from "../../images";
import { CgProfile } from "react-icons/cg";
import { BsTelephone } from "react-icons/bs";
import  ImageZoom  from '../add-admins/imagezoom'
import {
  CREATE_PACKAGE_SUBSCRIPTION,
  GENERATE_SIGNED_URL,
  GET_ALL_PAYMENT_GATEWAYS,
  GET_USER_INFO,
} from "../../config/endpoints";
import { call,Signed } from "../../config/axios";

function UpgradePagePopup(props) {
  const {
    isProcessing,
    showUpgradePopup,
    setShowUpgradePopup,
    selectedImage,
    setSelectedImage,
    selectPackagesDropdown,
    setSelectPackagesDropdown,
    setShowThankYouPopup,
    selectedPackages,
    setUpgradePaymentPopup,
    allPackages,
    hoursPack,
  } = props;

  const paymentMethodSelect = [
    {
      label: "NEFT/RTGS",
      value: "neft",
    },
    {
      label: "PhonePe",
      value: "phonepe",
    },
    {
      label: "Paytm",
      value: "paytm",
    },
    {
      label: "GooglePay",
      value: "gpay",
    },
    {
      label: "QR Code",
      value: "qr_code",
    },
  ];

  let packageList = [
    {
      title: "Standard Monthly Package",
      mapName: "standard",
      package_type: "monthly",
      img: "./assets/coin.png",
    },
    {
      title: "Standard Annual Package",
      img: "./assets/coin.png",
      mapName: "standard",
      package_type: "yearly",
    },
    {
      title: "Silver Monthly Package",
      img: "./assets/silver_medal.png",
      mapName: "silver",
      package_type: "monthly",
    },
    {
      title: "Silver Annual Package",
      img: "./assets/silver_medal.png",
      mapName: "silver",
      package_type: "yearly",
    },
    {
      title: "Gold Monthly Package",
      img: "./assets/gold_medal.png",
      mapName: "gold",
      package_type: "monthly",
    },
    {
      title: "Gold Annual Package",
      img: "./assets/gold_medal.png",
      mapName: "gold",
      package_type: "yearly",
    },
    {
      title: "Diamond Monthly Package",
      img: "./assets/diamond.png",
      mapName: "diamond",
      package_type: "monthly",
    },
    {
      title: "Diamond Annual Package",
      img: "./assets/diamond.png",
      mapName: "diamond",
      package_type: "yearly",
    },
    {
      title: "VIP Monthly Package",
      img: process.env.PUBLIC_URL + "./assets/crown.png",
      mapName: "vip",
      package_type: "monthly",
    },
    {
      title: "VIP Annual Package",
      img: process.env.PUBLIC_URL + "./assets/crown.png",
      mapName: "vip",
      package_type: "yearly",
    },
  ];
   const [transactionImg, setTransactionImg] = useState(false);
  const register_id = localStorage.getItem("register_id");
  const [showPayButton, setShowPayButton] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPackages, setShowPackages] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(false);
  const [selectedMethodInfo, setSelectedMethodInfo] = useState("");
  const [showPaymentDropDown, setShowPaymentDropDown] = useState("");
  // const [selectedPackage, setSelectedPackage] = useState(selectedPackages)
  const [singedUrl, setSignedUrl] = useState(false);
  const [allPaymentGateway, setAllPaymentGateway] = useState([]);

  const [trxId, setImageTrxId] = useState("");
  const [present] = useIonToast();

  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };

  const [image, setImage] = useState("");
  const [discount, setDiscount] = useState(0);

  const fileInputRef = useRef(null);
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setTransactionImg(file);
    generateSignedUrl();
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handlePaymentDropdown = () => {
    setShowPaymentDropDown((prev) => !prev);
  };
  const handlePaymentSelect = (itm) => {
    setSelectedMethod(itm.label);
    setShowPaymentDropDown(false);
    const obj = allPaymentGateway.find((obj) => obj.pg_upi === itm.value);
    setSelectedMethodInfo(obj);
  };
  const handlePackageSelect = () => {
    setShowPackages((prev) => !prev);
  };
  const handleUpgradePopupClose = () => {
    setShowUpgradePopup(false);
    setTransactionImg(false);
  };
  const handleConfirmClick = () => {
    setShowPayButton(true);
  };
  const handleThankYouPopup = () => {
    setShowThankYouPopup(true);
    setShowUpgradePopup(false);
  };
  const discountValue = parseInt(
    (selectedPackages?.package_cost * discount) / 100
  );
  const splDiscountValue =
    parseInt(
      (selectedPackages.package_cost * selectedPackages.discount) / 100
    ) || 0;
  const totalPackageCost =
    selectedPackages.package_cost - splDiscountValue - discountValue;

  const handlePayButton = async () => {
    await imageUpploadToBucket();
    const summary = {
      total_packages_cost: selectedPackages?.package_cost,
      final_package_cost: totalPackageCost,
      transaction_img: `${ImageBaseUrl}/${"payments"}/${trxId}.png`,
      ...selectedMethodInfo,
      requester_name: localStorage.getItem("user_name"),
      requester_role: localStorage.getItem("account_role"),
    };
    if (!selectedMethodInfo) {
      presentToast({ message: "please select payment method" });
      return;
    }
    if (!transactionImg) {
      presentToast({ message: "please upload screenshot" });
      return;
    }
    setDisabled(true);
    await call(CREATE_PACKAGE_SUBSCRIPTION, {
      register_id,
      summary,
      type: "subscription",
      packages: [selectedPackages],
    })
      .then((res) => {
        if (res.data.status == 200) {
          setUpgradePaymentPopup(true);
          setShowUpgradePopup(false);
        } else {
          presentToast({ message: "error occure" });
        }
      })
      .catch((err) => console.log("error while calling payment click", err));
  };

  const generateSignedUrl = async () => {
    const trxId = new Date().getTime();
    await Signed(GENERATE_SIGNED_URL, {
      trxId: trxId,
      event_type: "user_profile_image",
      folder_name: "payments",
    })
      .then(async (res) => {
        console.log(res)
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
        setImageTrxId(trxId);
      })
      .catch((err) => console.log("generating signed url error", err));
  };
  const imageUpploadToBucket = async () => {
    singedUrl &&
      transactionImg &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: transactionImg,
        headers: {
          "Content-Type": "image/png",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          console.log('Image Uploaded',res)
          return true;
        })
        .catch((err) => {
          console.log(err);
        }));
  };

  const getProfile = async (id) => {
    const payload = {
      register_id,
    };
    try {
      const res = await call(GET_USER_INFO, payload);
      const userProfileData = res?.data?.data[0];
      setDiscount(userProfileData?.discount || 0);
    } catch (err) {
      console.log(err);
    }
  };
  const getAllPaymentData = async () => {
    const payload = {
      register_id: localStorage.getItem("creator_id"),
    };
    try {
      const res = await call(GET_ALL_PAYMENT_GATEWAYS, payload);
      if (res.status) {
        setAllPaymentGateway(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProfile();
    getAllPaymentData();
  }, []);
  return (
    <IonModal isOpen={showUpgradePopup} className="upgrade-popup-main">
      <div>
        <div className="flex-space-between mb-10">
          <span className="font-16">Package Upgrade</span>
          <IoMdClose
            className="reports-heading"
            onClick={() => handleUpgradePopupClose()}
          />
        </div>

        <div>
          <div
            className="diamond-package-container flex-space-between custom-box-shadow"
            onClick={() => handlePackageSelect()}
          >
            <IonImg
              className="diamond-popup-image mt-10"
              src={
                selectedImage
                  ? selectedImage
                  : process.env.PUBLIC_URL + "./assets/coin.png"
              }
            ></IonImg>
            <div className="font-10">
              {selectPackagesDropdown ? selectPackagesDropdown : "sportsname"}
            </div>
          </div>
          {/* {showPackages && (
            <div className="select-team-dropdown  package-dropdown-container w-50 position-right font-12 h-150px">
              {packageList.map((plist, index) => {
                return (
                  <div
                    key={index}
                    className="w-85 h-30p d-flex mb-5"
                    onClick={() => handlePackageDropdown(plist)}
                  >
                    <IonImg
                      className="diamond-popup-image"
                      src={process.env.PUBLIC_URL + plist.img}
                    ></IonImg>
                    <span className="font-10">{plist.title}</span>
                  </div>
                );
              })}
            </div>
          )} */}
        </div>

        <div className="hr-line mt-5 w-100"></div>
        {hoursPack === "hoursPack" ? (
          <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10">
            <div style={{ textTransform: "capitalize" }}>Topup Hours</div>
            <div>{selectedPackages?.package_cost} INR</div>
          </div>
        ) : (
          <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10">
            <div style={{ textTransform: "capitalize" }}>
              {selectedPackages?.package_duration} Subscription
            </div>
            <div>{selectedPackages?.package_cost} INR</div>
          </div>
        )}

        <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10">
          <div style={{ textTransform: "capitalize" }}>
            Discount {discount}%
          </div>
          <div>{discountValue} INR</div>
        </div>
        <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10">
          <div style={{ textTransform: "capitalize" }}>
            Special Discount {selectedPackages.discount}%
          </div>
          <div>{splDiscountValue} INR</div>
        </div>
        {/* <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10">
          <div>Old Price</div>
          <div>-1249.49 INR</div>
        </div> */}

        {/* <div className="upgrade-popup-box-container custom-box-shadow w-100 font-12 h-7 flex-space-between mt-10">
          <div className="flex-column">
            <div>Code Applied</div>
            <div className="coupon-holder flex-space-between flex-row mt-10">
              <div className="font-14">FI10cARTq3</div>
              <IonImg
                className="cross-icon-popup-image"
                src={process.env.PUBLIC_URL + "./assets/cross-sign-icon.png"}
              ></IonImg>
            </div>
          </div>
          <div>-764.90 INR</div>
        </div> */}
        <div className="flex-space-between flex-row p-5 mt-10">
          <div className="font-14 fw-600">Total</div>
          <div className="font-14 fw-600">{totalPackageCost} INR</div>
        </div>
        <div className="hr-line mt-5 w-100"></div>
        <button
          className="confirm-upgrade-popup-button  button-box-shadow flex-center mt-15 w-100"
          onClick={() => handleConfirmClick()}
          disabled={isProcessing}
        >
          Confirm and Pay
        </button>

        {showPayButton && (
          <div>
            <div className="hr-line mt-20 w-100 mb-20"></div>
            <div
              className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
              onClick={() => handlePaymentDropdown()}
            >
              <div className="pl-10">
                {selectedMethod ? selectedMethod : "Select Payment Method"}
              </div>
              <IonImg
                className="drop-down-icon"
                src={process.env.PUBLIC_URL + "./assets/big_dropdown.png"}
              />
            </div>
            {showPaymentDropDown && (
              <div className="select-team-dropdown payment-position-dropdown  w-85 font-12 h-150px">
                {paymentMethodSelect.map((value, index) => (
                  <div
                    className="h-30p flex-center mb-5 font-12"
                    key={index}
                    onClick={() => handlePaymentSelect(value)}
                  >
                    {value.label}
                  </div>
                ))}
              </div>
            )}
            {selectedMethod === "NEFT/RTGS" ? (
              selectedMethodInfo ? (
                <div className="payment-package-container flex-column font-14 mt-5">
                  <div className="flex-row flex-space-around">
                    <div>Name : {selectedMethodInfo?.account_holder_name}</div>
                    <div>IFSC : {selectedMethodInfo?.ifsc_code}</div>
                  </div>
                  <div className="flex-row flex-space-around mt-5">
                    <div>A/C No: {selectedMethodInfo?.account_number}</div>
                    <div>Bank : {selectedMethodInfo?.bank_name}</div>
                  </div>
                </div>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              )
            ) : (
              ""
            )}
            {console.log(selectedMethod)}
            {selectedMethod === "QR Code" ?
            
            (selectedMethodInfo ? (
                <>
                <div className="payment-package-container flex-column font-14 mt-5">
                  <div className="flex-row flex-space-around">
                     <ImageZoom src={selectedMethodInfo.uploadImage}/>
                  </div>
                </div>
                </>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              )
            ): (
              ""
            )}
            {selectedMethod === "Paytm" &&
              (selectedMethodInfo ? (
                <>
                  <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                    <input
                      required
                      type="text"
                      placeholder="Enter UPI Name"
                      name="upi_name"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.pg_name}
                    />
                    <CgProfile className="font-size-20 pink-clr" />
                  </div>
                  <div className="series-input flex-center custom-box-shadow mb-10 mt-10">
                    <input
                      required
                      type="text"
                      placeholder="Enter Phone Number"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.mobile_number}
                    />
                    <BsTelephone className="font-size-20 pink-clr" />
                  </div>
                </>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              ))
              }
            {selectedMethod === "PhonePe" &&
              (selectedMethodInfo ? (
                <>
                  <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                    <input
                      required
                      type="text"
                      placeholder="Enter UPI Name"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.pg_name}
                    />
                    <CgProfile className="font-size-20 pink-clr" />
                  </div>
                  <div className="series-input flex-center custom-box-shadow mb-10">
                    <input
                      required
                      type="text"
                      placeholder="Enter Phone Number"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.mobile_number}
                    />
                    <BsTelephone className="font-size-20 pink-clr" />
                  </div>
                </>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              ))}
            {selectedMethod === "GooglePay" &&
              (selectedMethodInfo ? (
                <>
                  <div className="series-input flex-center custom-box-shadow mb-10 mt-20">
                    <input
                      required
                      type="text"
                      placeholder="Enter UPI Name"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.pg_name}
                    />
                    <CgProfile className="font-size-20 pink-clr" />
                  </div>
                  <div className="series-input flex-center custom-box-shadow mb-10">
                    <input
                      required
                      type="text"
                      placeholder="Enter Phone Number"
                      className="flex-center w-80 match-input"
                      value={selectedMethodInfo?.mobile_number}
                    />
                    <BsTelephone className="font-size-20 pink-clr" />
                  </div>
                </>
              ) : (
                <div className="font-12 ml-20"> No Data Found </div>
              ))}
            <div
              className="upgrade-popup-box-container custom-box-shadow w-100 font-12 flex-space-between mt-10"
              onClick={handleButtonClick}
            >
              <div className="font-12 ml-10">Upload Screenshot</div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              <IonImg
                className="drop-down-icon"
                src={process.env.PUBLIC_URL + "./assets/upload.png"}
              />
            </div>
            <button
              className="confirm-upgrade-popup-button  button-box-shadow flex-center mt-15 w-100"
              disabled={disabled}
              onClick={() => handlePayButton()}
            >
              Pay
            </button>
          </div>
        )}
      </div>
    </IonModal>
  );
}

export default UpgradePagePopup;
