import CallHisory from "./CallHistory";
import MeetingsHeader from "./MeetingsHeader";

function CallReports() {
  return (
    <div>
      <MeetingsHeader heading={"Call Reports"} />
      <CallHisory />
    </div>
  );
}

export default CallReports;
