import { useEffect, useState } from "react";
import Table from "./Table";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { BiDownArrowCircle, BiUpArrowCircle } from "react-icons/bi";
import {
  FANCY_RESULT_PROFIT_LOSS,
  GET_ACCOUNT_MATCHES_DATA,
  GET_FANCY_ENTRY_DATA,
  GET_MATCH_ENTRY_DETAILS,
  GET_MATCH_POSITION_DATA,
  GET_OFFLINE_USER_DETAILS,
} from "../../config/endpoints";
import { call } from "../../config/axios";
function MatchPositionTables(props) {
  const { liveMeeting } = props;
  const [matchPositionTable, setMatchPositionTable] = useState(false);
  const [fancyPLTable, setFancyPLTable] = useState(false);
  const [data, setData] = useState([]);
  const [matchPositionData, setMatchPositionData] = useState([]);
  const [userFancyEntrys, setUserFancyEntrys] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [matchRegisterData, setMatchRegisterData] = useState([]);
  const userName = localStorage.getItem("user_name");
  const handleMatchPositionTableOpen = () => {
    setMatchPositionTable(!matchPositionTable);
  };
  const handleFancyPLTableOpen = () => {
    setFancyPLTable(!fancyPLTable);
  };
  const getMatchEntryDetails = async () => {
    await call(GET_MATCH_ENTRY_DETAILS, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setData(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (matchRegisterData?.registered_match_id) {
      getMatchEntryDetails();
    }
  }, [matchRegisterData?.registered_match_id]);

  const getFancyProfitLoss = async (ID) => {
    await call(FANCY_RESULT_PROFIT_LOSS, {
      register_id: localStorage.getItem("creator_id"),
      registered_match_id: matchRegisterData?.registered_match_id,
    })
      .then((res) => {
        setProfitLossData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const getMatchInfo = async () => {
    await call(GET_ACCOUNT_MATCHES_DATA, {
      register_id: localStorage.getItem("creator_id"),
      match_id: liveMeeting?.match_id,
    })
      .then(async (res) => {
        let temp =
          res?.data && res.data.data && res.data.data[0]
            ? res.data.data[0]
            : res.data.data;
        setMatchRegisterData(temp);
        // await getMatchPositionData(temp?.registered_match_id);
      })
      .catch((err) => console.log(err));
  };

  const getUserDetails = async () => {
    await call(GET_OFFLINE_USER_DETAILS, {
      existing_user_id: localStorage.getItem("register_id"),
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        getMatchPositionData(res?.data?.data?.client_id);
        getFancyEntryDetails(res?.data?.data?.client_id);
      })
      .catch((err) => console.log(err));
  };

  const getMatchPositionData = async (clientID) => {
    await call(GET_MATCH_POSITION_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
      client_id: clientID,
    })
      .then((res) => {
        setMatchPositionData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const getFancyEntryDetails = async (clientID) => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
      client_id: clientID,
    })
      .then((res) => {
        let results = res?.data?.data?.Items;
        setUserFancyEntrys(results);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatchInfo();
  }, []);

  useEffect(() => {
    if (matchRegisterData?.registered_match_id) {
      getUserDetails();
      getFancyProfitLoss();
    }
  }, [matchRegisterData?.registered_match_id]);
  return (
    <IonGrid>
      <div className="custom-box-shadow table-page-conatainer">
        <IonRow className="w-100">
          <IonCol size="4">
            <div className="custom-box-shadow p-5 br-15 font-12">
              {liveMeeting?.date}
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="custom-box-shadow p-5 br-15 font-12">T20</div>
          </IonCol>
          <IonCol size="4">
            <div className="custom-box-shadow p-5 br-15 font-12">
              {liveMeeting?.match_name}
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          class="w-100 p-5 custom-box-shadow br-10 mt-10 flex-space-between font-14 fw-600"
          onClick={() => handleMatchPositionTableOpen()}
        >
          
        </IonRow>
       
        <div
          className="custom-box-shadow user-match-position flex-space-between font-16 p-5"
          onClick={() => handleFancyPLTableOpen()}
        >
          <p>
            Fancy P/L=
            <span className="custom-box-shadow br-5 win-amount">
              {-profitLossData?.clientsData?.[userName]?.totalLossOrProfit}
            </span>
          </p>
          
        </div>
       
      </div>
    </IonGrid>
  );
}

export default MatchPositionTables;
