import "./styles.css";
import { IonHeader, IonImg } from "@ionic/react";
import { useHistory } from "react-router";
import { Images } from "../../images";
import { BiSolidChevronLeft } from "react-icons/bi";

function ChatsHeader({ name }) {
  const history = useHistory();
  return (
    <IonHeader className="user-outer-div">
      <div className="flex-jc-c">
        <BiSolidChevronLeft
          className="custom-ionicon-container custom-back-icon"
          src={Images.BackArrow}
          onClick={() => history.push("/chat-lists")}
        />
        <IonImg
          className="custom-ionicon-container custom-meeting-image"
          src={Images.ViratImage}
        />
        <div className="meetings-heading ml-5">{name}</div>
      </div>
    </IonHeader>
  );
}

export default ChatsHeader;
