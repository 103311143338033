import { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import GiftOffers from "./GiftOffers";
import OfferDetails from "./OfferDetails";
import PaymentDetails from "./PaymentDetails";
import YourDetails from "./YourDetails";
import "./styles.css";
import AddUsersListPage from "./AddUsersListPage";

function Offers() {
  const [offerDetailsComponent, setOffersDetailsComponent] = useState(true);
  const [yourDetailsComponent, setYourDetailsComponent] = useState(false);
  const [paymentDetailsComponent, setPaymentDetailsComponent] = useState(false);
  const [paymentConfirmation, setPaymentConfirmation] = useState(false);
  const handleOpenYourDetailsComponent = () => {
    setYourDetailsComponent(true);
    setOffersDetailsComponent(!offerDetailsComponent);
  };
  const handlePaymentDetailsComponentOpen = () => {
    setPaymentDetailsComponent(true);
    setYourDetailsComponent(!yourDetailsComponent);
  };
  return (
    <div>
      <MeetingsHeader heading={"Offers"} />
      {offerDetailsComponent && (
        <OfferDetails
          handleOpenYourDetailsComponent={handleOpenYourDetailsComponent}
        />
      )}
      {yourDetailsComponent && (
        <YourDetails
          handlePaymentDetailsComponentOpen={handlePaymentDetailsComponentOpen}
        />
      )}
      {paymentDetailsComponent && (
        <AddUsersListPage
          setYourDetailsComponent={setYourDetailsComponent}
          setPaymentConfirmation={setPaymentConfirmation}
          setPaymentDetailsComponent={setPaymentDetailsComponent}
        />
      )}
      {paymentConfirmation && <PaymentDetails />}
    </div>
  );
}

export default Offers;
