import { IonCol, IonGrid, IonRow, IonImg, IonToggle } from "@ionic/react";
import { useEffect, useState } from "react";
import { GET_ADMIN_PACKAGES } from "../../config/endpoints";
import { call } from "../../config/axios";
import "./styles.css";
import ViewDetailsPopup from "../popups/ViewDetailsPopup";

function UpgradeAdminPackages(props) {
  const { setOpenPackageUpgrade, setReturnPackage, setPurchaseHead } = props;
  const register_id = localStorage.getItem("register_id");
  const [adminPackages, setAdminPackages] = useState([]);
  const [showViewDetailsPopup, setShowViewDetailsPopup] = useState(false);
  const [monthlyYearlyFilter, setMonthlyYearlyFilter] = useState(false);
  const handleMonthlyYearlyFilter = () => {
    setMonthlyYearlyFilter((prev) => !prev);
  };

  const PackagesHeadings = [
    { size: "4", name: "Packages" },
    { size: "3", name: "Total Pkgs" },
    { size: "2.5", name: "Sale Pkgs" },
    { size: "2.5", name: "Avail Pkgs" },
  ];

  let PackagesTypes = [
    {
      packageType: "Standard",
      purchase: "0",
      used: "0",
      available: "0",
    },
    { packageType: "Silver", purchase: "0", used: "0", available: "0" },
    { packageType: "Gold", purchase: "0", used: "0", available: "0" },
    { packageType: "Diamond", purchase: "0", used: "0", available: "0" },
    { packageType: "Vip", purchase: "0", used: "0", available: "0" },
  ];

  const handleBuyButton = () => {
    setOpenPackageUpgrade(true);
    setReturnPackage(false);
    setPurchaseHead(false);
  };
  const openViewDetailsPopup = () => {
    setShowViewDetailsPopup(true);
  };

  const getAdminPackages = async () => {
    await call(GET_ADMIN_PACKAGES, { register_id })
      .then((res) => {
        console.log(res, "res");
        const data = res?.data?.data;
        const bulk_subscriptions = [];
        data?.forEach((obj) => {
          if (obj.bulk_subscriptions && obj.bulk_subscriptions.length > 0) {
            bulk_subscriptions.push(obj);
          }
        });
        setAdminPackages(
          bulk_subscriptions[0]?.bulk_subscriptions?.filter(
            (obj) =>
              obj.duration !== "hourly" || obj.package_duration !== "hourly"
          ) || []
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdminPackages();
  }, []);

  PackagesTypes = PackagesTypes.map((obj) => {
    let noOfpackages = 0,
      usedPackages = 0;
    adminPackages
      ?.filter((item) =>
        monthlyYearlyFilter === false
          ? item?.duration === "monthly"
          : item?.duration === "yearly"
      )
      .forEach((obj1) => {
        if (obj?.packageType?.toLocaleLowerCase() === obj1.package_name) {
          noOfpackages += obj1.no_of_packages || 0;
          usedPackages += obj1.used_packages || 0;
          obj = {
            ...obj,
            purchase: noOfpackages + usedPackages,
            used: usedPackages,
            available: noOfpackages,
          };
        }
      });
    return obj;
  });

  return (
    <IonGrid className="py-10">
      <div>
        <IonRow className="connect-device white-text mrl-10">
          <IonCol className="flex-center font-14">Regular Package Stock</IonCol>
          <IonCol className="flex-center font-12">
            Monthly
            <IonToggle
              aria-label="Warning toggle"
              color="warning"
              checked={monthlyYearlyFilter}
              onClick={() => handleMonthlyYearlyFilter()}
            ></IonToggle>
            Yearly
          </IonCol>
        </IonRow>
        <IonRow className="connect-device mrl-10 mt-10">
          <div className="h-45px w-100">
            <div className="w-100 d-flex">
              {PackagesHeadings.map((item, index) => (
                <IonCol key={index} size={item.size} className="flex-center">
                  <div className="upcoming-table-heading custom-box-shadow">
                    {item.name}
                  </div>
                </IonCol>
              ))}
            </div>
          </div>
          {PackagesTypes.map((item, index) => (
            <div key={index} className="w-100 upcoming-table d-flex">
              <IonCol size="4.5" className="table-content-text">
                {item?.packageType} -
                {monthlyYearlyFilter === false ? (
                  <span className="yellow-clr ml-5">(M)</span>
                ) : (
                  <span className="yellow-clr ml-5">(Y)</span>
                )}
              </IonCol>
              <IonCol size="2.5" className="flex-center table-content-text">
                {item?.purchase}
              </IonCol>
              <IonCol
                size="2.5"
                className="flex-center table-content-text clr-orng"
              >
                {item?.used}
              </IonCol>
              <IonCol
                size="2.5"
                className="flex-center table-content-text clr-green"
              >
                {item?.available}
              </IonCol>
            </div>
          ))}
        </IonRow>
      </div>
      <IonGrid className="my-4">
        <IonRow className="d-flex">
          <IonCol size="6" className="d-flex">
            <div className="package-container">
              <div className="we-2-call-description">
                <div>Special Package</div>
                <div className="bold-text we-2-call-text">For New Admins</div>
                <div className="flex-space-between mt-10 w-90">
                  <div className="flex-column">
                    <div className="font-10">Monthly</div>
                    <div className="font-10">5999/-</div>
                  </div>
                  <div className="flex-column">
                    <div className="font-10">Yearly</div>
                    <div className="font-10">60000/-</div>
                  </div>
                </div>
                <div className="flex-row flex-space-between w-100">
                  <button
                    className="custom-view-details-button mt-5 mb-10 mr-5"
                    onClick={() => openViewDetailsPopup()}
                  >
                    View Details
                  </button>
                  <button
                    className="custom-view-details-buy-button mt-5 mb-10 ml-5"
                    onClick={() => handleBuyButton()}
                  >
                    Buy
                  </button>
                </div>
              </div>
              <div className="flex-column">
                <div className="off-icon flex-center flex-column">
                  <div style={{ fontSize: "8px" }}>FLAT</div>
                  <div style={{ fontSize: "12px" }}>
                    <b>10%</b>
                  </div>
                  <div style={{ fontSize: "8px" }}>Off</div>
                </div>
                <IonImg
                  className="discount-img"
                  src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
                />
                <IonImg
                  className="giftbox-img"
                  src={process.env.PUBLIC_URL + "./assets/gift_box.png"}
                />
              </div>
            </div>
          </IonCol>
          <IonCol size="6" className="d-flex">
            <div className="package-container-second">
              <div className="we-2-call-description">
                <div>Special Package</div>
                <div className="bold-text we-2-call-text">For New Admins</div>
                <div className="flex-space-between mt-10 w-90">
                  <div className="flex-column">
                    <div className="font-10">Monthly</div>
                    <div className="font-10">5999/-</div>
                  </div>
                  <div className="flex-column">
                    <div className="font-10">Yearly</div>
                    <div className="font-10">60000/-</div>
                  </div>
                </div>
                <div className="flex-row flex-space-between w-100">
                  <button
                    className="custom-view-details-button mt-5 mb-10 mr-5"
                    onClick={() => openViewDetailsPopup()}
                  >
                    View Details
                  </button>
                </div>
              </div>
              <div className="flex-column">
                <div className="off-icon flex-center flex-column">
                  <div style={{ fontSize: "8px" }}>FLAT</div>
                  <div style={{ fontSize: "12px" }}>
                    <b>10%</b>
                  </div>
                  <div style={{ fontSize: "8px" }}>Off</div>
                </div>
                <IonImg
                  className="discount-img"
                  src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
                />
                <IonImg
                  className="giftbox-img"
                  src={process.env.PUBLIC_URL + "./assets/gift_box.png"}
                />
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <ViewDetailsPopup
        showViewDetailsPopup={showViewDetailsPopup}
        setShowViewDetailsPopup={setShowViewDetailsPopup}
      />
    </IonGrid>
  );
}

export default UpgradeAdminPackages;
