import { useState, useEffect } from "react";

import "./style.css";
import SettlementData from "./SettlementData";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { GET_OFFLINE_CLIENTS } from "../../config/endpoints";
import { call } from "../../config/axios";
import Table from "../../common/Table";
import { sumOfData } from "../../utils";

const Settlement = () => {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [settlementStatus, setSettlementStatus] = useState(false);

  const [settlementData, setSettlementData] = useState([]);
  const columns = [
    { header: "totalBalance", name: "Total Amount" },
    { header: "totalSettledBalance", name: "Total Settled Bal C/D" },
    { header: "totalBalanceReceived", name: "Total Balance" },
  ];

  const totalAmount =
    settlementData &&
    settlementData?.length > 0 &&
    settlementData?.reduce((acc, obj) => acc + (+obj?.total_amount || 0), 0);

  const setteldAmount =
    settlementData &&
    settlementData?.length > 0 &&
    settlementData?.reduce((acc, obj) => acc + (+obj?.settled_amount || 0), 0);

  const pendingAmount =
    settlementData &&
    settlementData?.length > 0 &&
    settlementData?.reduce((acc, obj) => acc + (+obj?.pending_amount || 0), 0);

  const getSettlementData = async () => {
    await call(GET_OFFLINE_CLIENTS, {
      register_id,
      account_role,
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setSettlementData(res?.data?.data);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getSettlementData();
  }, [settlementStatus]);

  const data = [
    {
      totalBalanceReceived: (
        <div
          className={
            sumOfData(settlementData, "total_amount") >= 0
              ? "approved-color"
              : "red-clr"
          }
        >
          {sumOfData(settlementData, "total_amount")}
        </div>
      ),
      totalSettledBalance: (
        <div
          className={
            sumOfData(settlementData, "settled_amount") >= 0
              ? "approved-color"
              : "red-clr"
          }
        >
          {sumOfData(settlementData, "settled_amount")}
        </div>
      ),
      totalBalance: (
        <div
          className={
            sumOfData(settlementData, "pending_amount") >= 0
              ? "approved-color"
              : "red-clr"
          }
        >
          {sumOfData(settlementData, "pending_amount")}
        </div>
      ),
    },
  ];
  const accountSettlementSummaryHeadings = (
    <div>
      <button className="common-active-css active-css">Account Summary</button>
      <div className="hr-line"></div>
    </div>
  );
  return (
    <>
      <MeetingsHeader heading="Settlement" />
      <div className="offline-page-container">
        <div className="mb-10">
          <Table
            columns={columns}
            data={data}
            buttons={accountSettlementSummaryHeadings}
          />
        </div>
        <div className="scrolling-settlement">
          <SettlementData
            settlementData={settlementData}
            afterSuccessCall={getSettlementData}
            setSettlementStatus={setSettlementStatus}
          />
        </div>
        <table className="w-100 account-summary-main-container mt-5">
          <tfoot>
            <tr>
              <th>Total</th>
              <th className={totalAmount ? "clr-green" : "clr-red"}>
                {totalAmount ? totalAmount.toFixed(2) : 0}
              </th>
              <th className={setteldAmount ? "clr-green" : "clr-red"}>
                {setteldAmount ? setteldAmount.toFixed(2) : 0}
              </th>
              <th className={pendingAmount ? "clr-green" : "clr-red"}>
                {pendingAmount ? pendingAmount.toFixed(2) : 0}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default Settlement;
