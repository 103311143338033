import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Marquee from "react-fast-marquee";
import "./styles.css";
import "./common.css";
import { IonHeader, IonImg, IonLabel } from "@ionic/react";
import { Images } from "../images";
import Signup from "../pages/signup/Signup";
import SharePopup from "../pages/popups/SharePopup";
import FancyThankYouPopup from "../pages/fancypopups/FancyThankYouPopup";
import { setTheme } from "../theme/themes";
import { FiMail, FiSettings } from "react-icons/fi";
import { LiaUserEditSolid } from "react-icons/lia";
import { IoMdLogOut } from "react-icons/io";
import { BsShare } from "react-icons/bs";
import { GET_ALL_NOTIFICATIONS } from "../config/endpoints";
import { call } from "../config/axios";

function Header(props) {
  const history = useHistory();
  const id = localStorage.getItem("register_id");
  let user_name = localStorage?.getItem("user_name");
  const { handleResetPassword, handleReferModal } = props;
  const [activeUserDropdown, setActiveUserDropdown] = useState(false);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [sharePopup, setSharePopup] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isDarkmode, setDarkmode] = useState(true);
  const [forgotPasswordPopupOpen, setForgitPasswordPopupOpen] = useState(false);
  const [notifications, setnotifications] = useState([]);

  const navigate = async (path) => {
    history.push(path);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    setActiveUserDropdown(false);
    localStorage.clear();
    window.location.reload("");
  };
  const MENU = [
    {
      tab: "Darkmode",
      img: Images.DarkModeColour,
    },
    {
      tab: "Settings",
      icon: <FiSettings />,
    },
    {
      tab: "message",
      icon: <FiMail />,
    },
  ];
  const Dropdown = [
    {
      option: "Edit Profile",
      img: <LiaUserEditSolid />,
      onClick: () => history.push("/edit-profile"),
    },
    {
      option: "Share",
      img: <BsShare />,
      onClick: () => handleSharePopupOpen(),
    },
    {
      option: "Log Out",
      img: <IoMdLogOut />,
      onClick: handleLogout,
    },
  ];
  const handleActiveUserDropdown = () => {
    setActiveUserDropdown((prev) => !prev);
  };
  const handleSharePopupOpen = () => {
    setSharePopup(!sharePopup);
  };

  const weTwoCallMenu = useRef(null);
  const closeActiveDropdown = (e) => {
    if (
      weTwoCallMenu.current &&
      activeUserDropdown &&
      !weTwoCallMenu.current.contains(e.target)
    ) {
      setActiveUserDropdown(false);
    }
  };
  document.addEventListener("mousedown", closeActiveDropdown);
  const theme = isDarkmode ? "dark-mode" : "lite-mode";
  setTheme(theme, theme === "dark-mode" ? "lite-mode" : "dark-mode");

  const handleMenuitem = (index) => {
    {
      index === 0 && handleLogin();
    }
    {
      index === 0 && setDarkmode(!isDarkmode);
    }
    {
      index === 1 && handleActiveUserDropdown();
    }
    {
      index === 2 && handleSharePopupOpen();
    }
    {
      index === 2 && navigate("/message");
    }
  };
  const handleLogin = () => {
    setLoginPopupOpen((prev) => !prev);
  };

  const getNotifications = async () => {
    await call(GET_ALL_NOTIFICATIONS, {
      register_id: "company",
    })
      .then((res) => {
        const arr = res?.data?.data;
        setnotifications(arr);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <IonHeader ref={weTwoCallMenu}>
        <div>
          <div className="w-100 d-flex scrolling-text mt-10">
            <img src={process.env.PUBLIC_URL + "/assets/promate.png"} />
            <Marquee className="flex scroll-container">
              {notifications?.map((obj, index) => (
                <div key={index}>
                  {obj?.status === true &&
                    obj?.notification_type === "Admin" && (
                      <li className="ml-3rem">{obj?.description} </li>
                    )}
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <div className="user-outer-div">
          <div className="flex-center" onClick={() => navigate("/")}>
            <IonImg
              className="custom-ion-img"
              src={isDarkmode ? Images.We2call : Images.We2CallLite}
            />
          </div>
          <div className="header-right">
            <div
              className="flex-center we-2-call-icon1 mr-5"
              onClick={() => history.push("/add-admins")}
            >
              Add Users
            </div>
            <div
              className="flex-center we-2-call-icon1"
              onClick={() => history.push("/share-risk-live-matches")}
            >
              Risk
            </div>
            {MENU.map((menuItem, index) => {
              return (
                <div key={index} onClick={() => handleMenuitem(index)}>
                  {index <= 0 ? (
                    <IonImg
                      className="custom-ionicon-container custom-box-shadow"
                      src={process.env.PUBLIC_URL + menuItem.img}
                    ></IonImg>
                  ) : (
                    <div className="flex-center custom-ionicon-container custom-box-shadow fw-600 font-18">
                      {menuItem.icon}
                    </div>
                  )}
                </div>
              );
            })}
            {activeUserDropdown && (
              <div className="flex-column user-home-dropdown-container user-dropdown-container">
                {Dropdown.map((obj, index) => {
                  return (
                    <div
                      className="d-flex sub-dropdown-container mb-5"
                      key={index}
                      onClick={() => obj.onClick()}
                    >
                      <div
                        alt="add-your-account"
                        className="flex-center sub-dropdown-image"
                      >
                        {obj.img}
                      </div>
                      <span className="dropdown-title">{obj.option}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="date-time flex-space-between mrl-10" id="mb-10">
          <IonLabel>
            {user_name?.charAt(0)?.toUpperCase() + user_name?.slice(1) || ""}
          </IonLabel>
          <IonLabel>
            {currentDateTime.toLocaleDateString()},
            {currentDateTime.toLocaleTimeString()}
          </IonLabel>
        </div>

        <Signup
          signPopupOpen={signPopupOpen}
          setSignPopupOpen={setSignPopupOpen}
          setForgitPasswordPopupOpen={setForgitPasswordPopupOpen}
        />
        <FancyThankYouPopup
          openPopup={forgotPasswordPopupOpen}
          setclosePopup={setForgitPasswordPopupOpen}
          displayData={"Changed Your Password Succesfully"}
        />
        <SharePopup sharePopup={sharePopup} setSharePopup={setSharePopup} />
        {/* <ResetPassword
          resetPasswordPopupOpen={resetPasswordPopupOpen}
          setResetPasswordPopupOpen={setResetPasswordPopupOpen}
        /> */}
      </IonHeader>
    </>
  );
}
export default Header;
