import "./styles.css";
import moment from 'moment';
import { IonHeader } from "@ionic/react";
import { useHistory } from "react-router";
import { BiSolidChevronLeft } from "react-icons/bi";
import { GET_LIVE_MEETING } from "../../config/endpoints";
import { call } from "../../config/axios";
import { useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import AdminScoreBoard from "../video-call-matchposition/AdminScoreBoard";
import CallMeetings from "../video-call-matchposition/CallMeetings";
function MeetingsHeader(props) {
  const history = useHistory();

  const [liveMeeting, setLiveMeeting] = useState({});
   const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
const {onMinimizeClick=()=>{}} = props
  const onBackClick = () => {
   onMinimizeClick()
    history.push("/")
  }
   const getLiveMeetingData = async () => {
     const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
    return await call(GET_LIVE_MEETING, {
      creator_id: creator_id,
      meeting_id: props.id,
    })
      .then((res) => {
        console.log("res",res)
        setLiveMeeting(res.data?.data?.Items?.[0]);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    
      getLiveMeetingData();
  }, [props.id]);

  return (
    <IonHeader className="user-outer-div">
      <div className={` ${props.id ? 'meeting-header' : 'flex-center'}`}>
        <BiSolidChevronLeft
          className="custom-box-shadow custom-back-icon"
          onClick={() =>onBackClick() }
        />
        {props.id ? (
          <div className="custom-box-shadow meets-head ml-5 p-5">
              <div>
                { moment(liveMeeting?.date, 'M/D/YYYY').format('D-MM-YY')}  {liveMeeting?.time}
              </div>
              <div>
                {liveMeeting?.match_name}
              </div>
          </div>
          ):(
               <div className="meetings-heading ml-5">{props.heading}</div>
          )
         }
       
      </div>
      <hr className="horizantal-line" />
    </IonHeader>
  );
}

export default MeetingsHeader;
