import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { call } from "../../config/axios";
import { FANCY_DECLARATION } from "../../config/endpoints";
import { IonText } from "@ionic/react";
import FancyDeclareSuccessPopup from "./FancyDeclareSuccessPopup";
import MatchEntryCreatePopup from "../matchEntry/MatchEntryCreatePopup";

function FancyResultInputs({
  registered_match_id,
  selectedMatch,
  setStatus,
  selectedMatchEntry,
  inningsHeading1,
  getFancyProfitLoss,
  setSelectedMatchEntry,
  profitLossData = {},
  profitLossDataInnings
}) {
  const register_id = localStorage?.getItem("register_id");
  const [oversOpen, setOversOpen] = useState(false);
  const [oversHeading, setOversHeading] = useState("");
  const [inningsOpen, setInningsOpen] = useState(false);
  const [inningsHeading, setInningsHeading] = useState("");
  const [teamHeading, setTeamHeading] = useState("");
  const [teamOpen, setTeamOpen] = useState(false);
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [runs, setRuns] = useState("");
  let selectedInnigs = inningsHeading1;
  const [confirmDeclaration, setConfirmDeclaration] = useState(false);
  const [afterConfirm, setAfterConfirm] = useState(false);

  const resetFields = () => {
    setRuns("");
    setOversHeading("");
    setInningsHeading("");
    setTeamHeading("");
  };

  const toggleInningsOpen = () => {
    setInningsOpen(!inningsOpen);
    setOversOpen(false);
  };
  const toggleOversOpen = () => {
    setOversOpen(!oversOpen);
    setInningsOpen(false);
  };
  const handleInningsHeading = (content) => {
    setInningsHeading(content);
    setOversOpen(false);
    setInningsOpen(false);
  };
  const handleOversHeading = (content) => {
    setOversHeading(content);
    setOversOpen(false);
    setInningsOpen(false);
  };

  const handleFancyDeclaration = async () => {
    setConfirmDeclaration(false);
    setIsProcessing(true);
    setAfterConfirm(true);
    setIsProcessing(true);
    setError("");
    await call(FANCY_DECLARATION, {
      registered_match_id,
      register_id,
      team: teamHeading,
      runs: +runs,
      innings: inningsHeading === "1st" ? "1" : "2",
      over: oversHeading,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          setConfirmDeclaration(false);
          setStatus((prev) => !prev);
          getFancyProfitLoss();
          resetFields();
          setTimeout(() => {
            setAfterConfirm(false);
          }, 2000);
        } else {
          setConfirmDeclaration(false);
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wen't wrong`);
        console.log(err);
      });
  };

  const toggleTeamOpen = () => {
    setTeamOpen(!teamOpen);
    setOversOpen(false);
  };

  const handleTeamHeading = (content) => {
    setTeamHeading(content);
    setOversOpen(false);
    setTeamOpen(false);
  };

  const handleConfirmDeclaration = () => {
    if (!runs || !teamHeading || !oversHeading || !inningsHeading) {
      return setError("Please enter required fields");
    }
    setConfirmDeclaration(true);
  };

  const checkOverExists = (innings, over) => {
  return innings?.some(inning => Object.keys(inning).includes(`${over}`));
};

const renderOvers = (overs, innings) => {
  return overs
    .filter(over => !checkOverExists(innings, over))
    .map((over, index) => (
      <div
        key={index}
        className="fancy-first-inn custom-box-shadow flex-center mt-5"
        onClick={() => handleOversHeading(over)}
      >
        {over}
      </div>
    ));
};

  useEffect(() => {
    setRuns(selectedMatchEntry?.runs);
    setOversHeading(selectedMatchEntry?.over);
    selectedInnigs = selectedMatchEntry.innings;
  }, [selectedMatchEntry]);

  return (
    <div>
      <div className="flex-space-between mt-10 mb-10">
        <div className="w-60 flex-space-between">
          <div
            className="align-center pl-10 fancy-inputs custom-box-shadow flex-column w-45"
            onClick={() => toggleInningsOpen()}
          >
            <div className="flex-center pt-5 w-100">
              <div className="flex-space-between">
                {inningsHeading || <div> Innings </div>}
                <div>
                  <BiChevronDown className="fancy-down-arrow" />
                </div>
              </div>
            </div>

            {inningsOpen ? (
              <div className="flex-space-around fancy-innings-div custom-box-shadow w-100 mt-10 fancy-inn-margin">
                <div
                  className="fancy-first-inn custom-box-shadow flex-center"
                  onClick={() => handleInningsHeading("1st")}
                >
                  1st Inn
                </div>
                <div
                  className="fancy-first-inn custom-box-shadow flex-center mt-5"
                  onClick={() => handleInningsHeading("2nd")}
                >
                  2nd Inn
                </div>
              </div>
            ) : null}
          </div>
          <div
            className="align-center pl-5 fancy-inputs custom-box-shadow flex-column fancy-overs-position"
            onClick={() => toggleOversOpen()}
          >
            <div className="flex-space-between w-100">
              {oversHeading || <div> Overs </div>}
              <div>
                <BiChevronDown className="fancy-down-arrow" />
              </div>
            </div>
            {oversOpen ? (
              <div className="flex-space-around fancy-innings-div custom-box-shadow w-100 mt-10 fancy-overs-margin">
                {selectedInnigs === "first"
  ? renderOvers(selectedMatch?.game_object?.first_innings_fancy_overs, profitLossDataInnings?.innings1)
  : renderOvers(selectedMatch?.game_object?.second_innings_fancy_overs, profitLossDataInnings?.innings2)}
                {/* {selectedInnigs === "first"
                  ? selectedMatch?.game_object?.first_innings_fancy_overs
                      // ?.filter(
                      //   (i) =>
                      //     Object.keys(profitLossData).length === 0 ||
                      //     !Object.keys(profitLossData)?.includes(`${i}`)
                      // )
                      ?.map((over, index) => {
                        return (
                          <div
                            key={index}
                            className="fancy-first-inn custom-box-shadow flex-center mt-5"
                            onClick={() => handleOversHeading(over)}
                          >
                            {over}
                          </div>
                        );
                      })
                  : selectedMatch?.game_object?.second_innings_fancy_overs
                      ?.filter(
                        (i) =>
                          Object.keys(profitLossData).length === 0 ||
                          !Object.keys(profitLossData)?.includes(`${i}`)
                      )
                      ?.map((over, index) => {
                        return (
                          <div
                            key={index}
                            className="fancy-first-inn custom-box-shadow flex-center mt-5"
                            onClick={() => handleOversHeading(over)}
                          >
                            {over}
                          </div>
                        );
                      })} */}
              </div>
            ) : null}
          </div>
        </div>
        <input
          className="align-center pl-10 fancy-inputs custom-box-shadow w-40"
          placeholder="Result Runs"
          type="number"
          value={runs || ""}
          onChange={(e) => setRuns(e.target.value)}
        />
      </div>
      <div
        className="align-center pl-10 fancy-inputs custom-box-shadow flex-column fancy-team-position"
        onClick={() => toggleTeamOpen()}
      >
        <div className="flex-space-between w-100">
          {teamHeading || <div>Enter Team</div>}
          <div>
            <BiChevronDown className="fancy-down-arrow" />
          </div>
        </div>

        {teamOpen ? (
          <div className="flex-space-around fancy-teams-div custom-box-shadow mt-10 fancy-team-margin">
            <div
              className="fancy-teamname custom-box-shadow flex-center"
              onClick={() => handleTeamHeading(selectedMatch?.team1)}
            >
              {selectedMatch?.team1}
            </div>
            <div
              className="fancy-teamname custom-box-shadow flex-center mt-5"
              onClick={() => handleTeamHeading(selectedMatch?.team2)}
            >
              {selectedMatch?.team2}
            </div>
          </div>
        ) : null}
      </div>
      <button
        className="mt-15 fancy-inputs button-box-shadow flex-center w-100"
        onClick={() => handleConfirmDeclaration()}
        disabled={isProcessing}
      >
        {isProcessing ? "Declaring..." : "Fancy Declaration"}
      </button>
      {error && (
        <IonText color="danger" className="ion-text-center">
          {error}
        </IonText>
      )}

      {confirmDeclaration && (
        <FancyDeclareSuccessPopup
          winTeamHeading={runs}
          overs={oversHeading}
          confirmDeclaration={confirmDeclaration}
          setConfirmDeclaration={setConfirmDeclaration}
          handleMatchDeclarePopupOpen={handleFancyDeclaration}
        />
      )}
      {afterConfirm && (
        <MatchEntryCreatePopup
          openPopup={afterConfirm}
          setclosePopup={setAfterConfirm}
          isProcessing={isProcessing}
          error={error}
          displayData={
            isProcessing ? "Declaring..." : "Fancy declared successfully"
          }
        />
      )}
    </div>
  );
}

export default FancyResultInputs;
