import { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MatchesScroll from "../fancyentry/MatchesScroll";
import MatchBetHistory from "./MatchBetHistory";
import MatchHeader from "./MatchHeader";
import MatchPositionTable from "./MatchPositionTable";
import "./styles.css";
import {
  GET_ACCOUNT_MATCHES_DATA,
  GET_MATCH_POSITION_DATA,
  GET_OFFLINE_ALL_MATCHES,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { useSelector } from "react-redux";

function MatchEntry() {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [allMatches, setAllMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState([]);
  const [matchAccountData, setMatchAccountData] = useState([]);
  const [selectedMatchEntry, setSelectedMatchEntry] = useState("");
  const [status, setStatus] = useState(false);
  const [matchPositionData, setMatchPositionData] = useState([]);
  const [afterDeclare, setAfterDeclare] = useState(false);

  const selectedLiveMatch = useSelector(
    (state) => state?.common?.selected_match
  );

  const getAllMatches = async () => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id,
      account_role,
    })
      .then(async (res) => {
        let result = res?.data?.data;
        const temp = result?.liveMatches?.filter(
          (i) => i.match_declared !== "Y"
        );
        await getCompanyMatches(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyMatches = async (data) => {
    await call(GET_OFFLINE_ALL_MATCHES, {
      register_id: "company",
      account_role,
    })
      .then((res) => {
        let result = res?.data?.data;
        const temp =
          result === undefined
            ? []
            : result?.liveMatches?.filter(
                (i) => i.match_declared !== "Y" || ""
              );
        setAllMatches([...data, ...temp]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllMatches();
    };

    fetchData();
  }, []);

  const getMatchPositionData = async (ID) => {
    console.log("ID", ID);
    console.log("matchAccountData", matchAccountData);
    await call(GET_MATCH_POSITION_DATA, {
      registered_match_id: ID ? ID : matchAccountData?.registered_match_id,
      register_id,
    })
      .then((res) => {
        setMatchPositionData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getMatchInfo = async () => {
    await call(GET_ACCOUNT_MATCHES_DATA, {
      register_id,
      match_id: selectedLiveMatch?.match_id,
    })
      .then(async (res) => {
        let temp =
          res?.data && res.data.data && res.data.data[0]
            ? res.data.data[0]
            : res.data.data;
        setMatchAccountData(temp);
        await getMatchPositionData(temp?.registered_match_id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchMatchInfo = async () => {
      if (selectedMatch) {
        await getMatchInfo();
      }
    };

    fetchMatchInfo();
  }, [selectedMatch?.match_id, afterDeclare]);

  return (
    <div>
      <Header />
      <div className="main-container pb-20">
        <MatchesScroll
          data={allMatches}
          selectedMatch={selectedLiveMatch}
          setSelectedMatch={setSelectedMatch}
        />
        <MatchHeader
          selectedMatch={selectedLiveMatch}
          matchAccountData={matchAccountData}
          selectedMatchEntry={selectedMatchEntry}
          setStatus={setStatus}
          getMatchPositionData={getMatchPositionData}
          setAfterDeclare={setAfterDeclare}
        />
        <div>
          <MatchBetHistory
            selectedMatch={selectedLiveMatch}
            seriesType={""}
            matchAccountData={matchAccountData}
            setSelectedMatchEntry={setSelectedMatchEntry}
            status={status}
            setStatus={setStatus}
            getMatchPositionData={getMatchPositionData}
          />
          <MatchPositionTable
            matchPositionData={matchPositionData}
            team1={selectedMatch?.team1}
            win={selectedMatch?.team1}
          />
          <MatchPositionTable
            team1={selectedMatch?.team2}
            team2={selectedMatch?.team2}
            matchPositionData={matchPositionData}
          />
          {selectedMatch?.game_object?.match_type === "testMatch" && (
            <MatchPositionTable
              team1="draw"
              team2="draw"
              matchPositionData={matchPositionData}
              teamName="Draw"
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MatchEntry;
