import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { FaMobileAlt } from "react-icons/fa";
import { Images } from "../../images";
import { BsPhoneFill } from "react-icons/bs";
import { RiComputerFill } from "react-icons/ri";

function ConnectDevices() {
  return (
    <IonGrid className="mb-150px">
      <IonRow className="connect-device mrl-10" id="mt-5">
        <IonCol size="11.4" className="flex-space-between ">
          <div className="connect-device-header custom-box-shadow">
            Connect Devices
          </div>
          <div className="connect-device-seemore custom-box-shadow">
            See All
          </div>
        </IonCol>
        <div className="w-100 flex-center total-devices mt-5">
          Total Devicecs 3
        </div>
        <IonCol size="12">
          <div className="w-100 devices-chart">
            <div className="flex-wrapper">
              <div className="single-chart">
                <div>
                  <BsPhoneFill className="round-image" />
                  {/* <img className="round-image" src={Images.Phone}></img> */}
                </div>

                <svg viewBox="0 0 36 36" className="circular-chart orange">
                  <path
                    className="circle-bg"
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    className="circle"
                    strokeDasharray="30, 100"
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </svg>
              </div>
              <div className="single-chart">
                <div>
                  {/* <img
                    className="round-image round-image1"
                    src={Images.Desktop}
                  ></img> */}
                  <RiComputerFill className="round-image round-image1" />
                </div>

                <svg viewBox="0 0 36 36" className="circular-chart green">
                  <path
                    className="circle-bg"
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    className="circle"
                    strokeDasharray="60, 100"
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </svg>
              </div>
            </div>
          </div>
        </IonCol>
        <div className="w-100 flex-space-around total-device">
          <div>Phone 1</div>
          <div>Desktop 2</div>
        </div>
      </IonRow>
    </IonGrid>
  );
}

export default ConnectDevices;
