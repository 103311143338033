import { IonImg } from "@ionic/react";

function PackageHourContainer(props) {
  const {
    checked,
    handleUpgradePopup,
    handleAddPaymentPopup,
    setSelectedImage,
    setSelectPackagesDropdown,
    setSelectedPackages,
    adminSubscription,
    allPackages,
  } = props;

  const handelState = (item1, item2, selectedPack) => {
    handleUpgradePopup();
    setSelectedImage(item1);
    setSelectPackagesDropdown(item2);
    setSelectedPackages(selectedPack);
  };
  const trialPackage = ["10-15 calls free"];
  const standardList = [
    {
      title: `Join Call With 10 users`,
    },
    {
      title: "Monthly 10 meetings",
    },
    {
      title: "Use 5 personal Meetings",
    },
    {
      title: "Audio Call Only",
    },
  ];
  const silverList = [
    {
      title: "Join Call With 15 users",
    },
    {
      title: "Monthly 15 meetings",
    },
    {
      title: "Use 10 personal Meetings",
    },
    {
      title: "Audio Calls",
    },
    {
      title: "Vedio Calls",
    },
  ];
  const goldList = [
    {
      title: "Join Call With 15 users",
    },
    {
      title: "Monthly 20 meetings",
    },
    {
      title: "Use 20 personal Meetings",
    },
    {
      title: "Audio Calls",
    },
    {
      title: "Vedio Calls",
    },
  ];
  const diamondList = [
    {
      title: "unlimited users",
    },
    {
      title: "Monthly unlimited meetings",
    },
    {
      title: "Unlimited personal Meetings",
    },
    {
      title: "Audio Calls",
    },
    {
      title: "Vedio Calls",
    },
  ];

  const standardHoursPack = allPackages.find(
    (obj) => obj.package_name === "standard"
  );
  const silverHoursPack = allPackages.find(
    (obj) => obj.package_name === "silver"
  );
  const goldHoursPack = allPackages.find((obj) => obj.package_name === "gold");
  const diamondHoursPack = allPackages.find(
    (obj) => obj.package_name === "diamond"
  );

  return (
    <div>
      <div>
        {/* <div className="flex-space-evenly flex-row mt-10 p-5">
          <div
            className="flex-space-evenly h-7 flex-row w-100 br-15"
            style={{ background: "#1b256a" }}
          >
            <div
              className="flex-center custom-box-shadow h-30p br-15 w-30 mb-20"
              style={{ fontSize: "12px", color: "var(--font-color)" }}
            >
              Trial Package
            </div>
            <div className="flex-column flex-center">
              
              {trialPackage.map((pdata, index) => {
                return (
                  <div
                    key={index}
                    className="font-10 flex-row ml-15 flex-center"
                  >
                    <IonImg
                      className="flex-start check-mark mr-10 "
                      src={process.env.PUBLIC_URL + "./assets/check_mark.png"}
                    />
                    {pdata}
                  </div>
                );
              })}
            </div>

            <div className="flex-center p-5">
              <button
                className="add-package-button text-black-color flex-center mt-20 fw-600"
                onClick={() => handelState("/assets/coin.png", "Trial Package")}
              >
                Upgrade
              </button>
            </div>
          </div>
        </div> */}
        <div className="flex-space-around flex-row mt-10 p-5">
          <div className="flex-center relative-position flex-column w-100 ">
            <div className="rupees-circle flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">{standardHoursPack?.package_cost}</div>
            </div>
            <div className="standard-package font-14">
              <div>
                <IonImg
                  className="badge-images"
                  src={process.env.PUBLIC_URL + "./assets/coin.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>10%</b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-30"
                style={{
                  fontSize: "16px",
                  color: "var(--font-color)",
                  fontWeight: "600",
                }}
              >
                Standard Package Hours
              </div>
              <div>
                {standardList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={process.env.PUBLIC_URL + "./assets/check_mark.png"}
                      />
                      {data.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center p-5">
                <button
                  className="add-package-button flex-center mt-20"
                  onClick={() =>
                    handelState(
                      "/assets/coin.png",
                      "Standard Package",
                      standardHoursPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
          <div className="flex-center relative-position flex-column w-100 ">
            <div className="rupees-circle rupees-circle-silver flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">{silverHoursPack?.package_cost}</div>
            </div>
            <div className="silver-package font-14 space-between">
              <div>
                <IonImg
                  className="badge-images silver-medal"
                  src={process.env.PUBLIC_URL + "./assets/silver_medal.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>10%</b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />

              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Silver Package Hours
              </div>
              <div>
                {silverList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {value.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="upgrade-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "./assets/silver_medal.png",
                      "Sliver Package",
                      silverHoursPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-space-around flex-row mt-10 p-5">
          <div className="flex-center relative-position flex-column w-100">
            <div className="rupees-circle rupees-circle-gold flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">{goldHoursPack?.package_cost}</div>
            </div>
            <div className="standard-package gold-package font-14">
              <div>
                <IonImg
                  className="badge-images"
                  src={process.env.PUBLIC_URL + "./assets/gold_medal.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>10%</b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Gold Package Hours
              </div>
              <div>
                {goldList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {data.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="upgrade-button upgrade-gold-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "/assets/gold_medal.png",
                      "Gold Package",
                      goldHoursPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
          <div className="flex-center relative-position flex-column w-100">
            <div className="rupees-circle rupees-circle-diamond flex-center flex-column">
              <div>Rs</div>
              <div className="fw-600">{diamondHoursPack?.package_cost}</div>
            </div>
            <div className="silver-package diamond-package font-14">
              <div>
                <IonImg
                  className="badge-images silver-medal"
                  src={process.env.PUBLIC_URL + "./assets/diamond.png"}
                />
              </div>
              <div className="off-icon absolute-top-left  flex-center flex-column fw-600">
                <div style={{ fontSize: "8px" }}>FLAT</div>
                <div style={{ fontSize: "12px" }}>
                  <b>10%</b>
                </div>
                <div style={{ fontSize: "8px" }}>Off</div>
              </div>
              <IonImg
                className="off-package-icon"
                src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
              />
              <div
                className="flex-center mt-10 mb-20"
                style={{
                  fontSize: "16px",
                  color: "#080c27",
                  fontWeight: "600",
                }}
              >
                Diamond Package Hours
              </div>
              <div>
                {diamondList.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="font-10 flex-row flex-start mb-10 ml-15 text-black-color"
                    >
                      <IonImg
                        className="flex-start check-mark mr-10"
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/check_mark_dark.png"
                        }
                      />
                      {value.title}
                    </div>
                  );
                })}
              </div>
              <div className="flex-center">
                <button
                  className="upgrade-button upgrade-diamond-button flex-center mt-10 mr-5"
                  onClick={() =>
                    handelState(
                      "./assets/diamond.png",
                      "Diamond Package",
                      diamondHoursPack
                    )
                  }
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageHourContainer;
