import { IonModal, IonText } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function MatchEntryCreatePopup(props) {
  const { openPopup, setclosePopup, displayData, isProcessing, error } = props;
  const handleFancyThankyouPopupClose = () => {
    setclosePopup(false);
  };

  return (
    <IonModal className="statememt-popup-main" isOpen={openPopup}>
      <center>
        {!isProcessing ? (
          <>
            <div
              className="w-100 flex-flex-end p-5"
              onClick={handleFancyThankyouPopupClose}
            >
              <RxCross1 />
            </div>
            <div>
              <img
                className="fancy-declare-image"
                src={Images.ThumbsUpImage}
                alt="Declare_Man_Img"
              />
            </div>
            <div className="w-75 fancy-declare-text mt-10">
              {error ? (
                <IonText color="danger" className="ion-text-center">
                  {error}
                </IonText>
              ) : (
                displayData
              )}
            </div>
            <div
              className="mt-15 flex-space-around w-100"
              onClick={handleFancyThankyouPopupClose}
            >
              <button className="w-40 button-box-shadow fancy-declare-button green-clr fw-600">
                OK
              </button>
            </div>
          </>
        ) : error ? (
          error
        ) : (
          "Results Declaring..."
        )}
      </center>
    </IonModal>
  );
}

export default MatchEntryCreatePopup;
