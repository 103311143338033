import MeetingsHeader from "../meetings/MeetingsHeader";
import { IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import Table from "../../common/Table";
import ReactPaginate from "react-paginate";
import "./styles.css";
import { GET_ALL_PACKAGES_APPROVED_HSITORY } from "../../config/endpoints";
import { call } from "../../config/axios";

function PackageStatement() {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [packagesStatement, setPackagesStatement] = useState([]);
  const [summary, setSummary] = useState({});
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const packageStatementColumns = [
    {
      header: "pkg_trans",
      name: "Pkg Trans..",
    },
    {
      header: "date_time",
      name: "Date & Time",
    },
    {
      header: "paid_amount",
      name: "Paid Amount",
    },
    {
      header: "sell_amount",
      name: "Sell Amount",
    },
  ];

  const creator_id = localStorage.getItem("creator_id");
  const register_id = localStorage.getItem("register_id");
  const getAllPackageRequests = async () => {
    await call(GET_ALL_PACKAGES_APPROVED_HSITORY, { register_id, creator_id })
      .then((res) => {
        const resp = res?.data?.data?.records
          ?.map((item) => {
            const dateTimeString = `${item.created_date} ${item.created_time}`;
            const timestamp = new Date(dateTimeString).getTime();
            item.timestamp = timestamp;
            return item;
          })
          ?.sort((a, b) => b.timestamp - a.timestamp);
        setSummary(res?.data?.data?.summary);
        setPackagesStatement(resp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllPackageRequests();
  }, []);

  let totalPaidAmount = 0,
    totalSellAmount = 0;

  const packageStatementData = packagesStatement.map((obj) => {
    const paidAmount =
      obj?.register_id !== register_id ? obj?.summary.final_package_cost : 0;
    const sellAmount =
      obj?.register_id === register_id ? obj?.summary.final_package_cost : 0;
    totalPaidAmount += paidAmount;
    totalSellAmount += sellAmount;
    return {
      pkg_trans: obj?.summary.requester_name,
      date_time: (
        <div>
          <div>{obj.created_date}</div>
          <div>{obj.created_time}</div>
        </div>
      ),
      paid_amount: paidAmount,
      sell_amount: sellAmount,
    };
  });

  const currentData = packageStatementData.slice(startIndex, endIndex);
  return (
    <div>
      <MeetingsHeader heading="Package-Statement" />
      <div className="offline-page-container mt-10">
        <Table columns={packageStatementColumns} data={packageStatementData} />
        <div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(packageStatementData.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
        <div className="mt-10 font-14 flex-space-between flex-row total-count-container">
          <div>
            Net P/L =
            <span
              className={`${
                summary.lossOrProfitAmmount >= 0
                  ? "approved-color"
                  : "rejected-color"
              }`}
            >
              {summary.lossOrProfitAmmount || 0}
            </span>
             </div>
          <div>
            Paid Total =
            <span
              className={`${
                totalPaidAmount >= 0 ? "approved-color" : "rejected-color"
              }`}
            >
              {totalPaidAmount || 0}
            </span>
          </div>
          <div>
            Sell Total =
            <span
              className={`${
                totalSellAmount >= 0 ? "approved-color" : "rejected-color"
              }`}
            >
              {totalSellAmount || 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageStatement;
