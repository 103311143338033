import { PATTERNLIKE_TYPES } from "@babel/types";
import { useRef, useState } from "react";
import { BiChevronDown, BiCloudUpload } from "react-icons/bi";
import { useHistory } from "react-router";

function UPIDetails() {
  const history = useHistory();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentHeading, setPaymentHeading] = useState("");
  const togglePaymentOpen = () => {
    setPaymentOpen(!paymentOpen);
  };
  const handlePaymentHeading = (content) => {
    setPaymentHeading(content);
    setPaymentOpen(false);
  };
  const fileInputRef = useRef(null);
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const PAYMENT_TYPES = [
    {
      type: "upi",
      name: "Phone Pay",
    },
    {
      type: "upi",
      name: "Google Pay",
    },
    {
      type: "upi",
      name: "Paytm",
    },
    {
      type: "bank",
      name: "NEFT/RTGS",
    },
    {
      type: "cash",
      name: "Other-Cash",
    },
  ];
  return (
    <div className="w-100 members-container">
      <div
        className="w-92 custom-box-shadow align-center flex-column  p-5 br-10 font-14 h-44px"
        onClick={() => togglePaymentOpen()}
      >
        <div className="flex-space-between w-100 p-5">
          {paymentHeading || "Payment Type"}
          <BiChevronDown className="fancy-down-arrow" />
        </div>
        {paymentOpen ? (
          <div className="flex-space-around flex-column p-10 custom-box-shadow w-100 mt-20 z-index br-10">
            {PAYMENT_TYPES?.map((item, index) => (
              <div
                key={index}
                className="custom-box-shadow d-flex font-14 h-44px w-100 br-10 p-5 mt-5"
                onClick={() => handlePaymentHeading(item.name)}
              >
                {item.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="w-92 custom-box-shadow p-10 mt-10 br-10">
        <div className="w-100 flex-space-between font-12">
          <div>Name : Jayanta</div>
          <div>IFSC : SBIN0000111</div>
        </div>
        <div className="w-100 flex-space-between font-12 mt-10">
          <div>A/C No: 1234567892344</div>
          <div>Bank : SBI</div>
        </div>
      </div>
      <div
        className="w-92 mt-10 h-44px custom-box-shadow br-10 p-10 flex-space-between"
        onClick={handleButtonClick}
      >
        <div className="font-14">Upload Screenshot</div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
        <BiCloudUpload className="payment-upload-icon" />
      </div>
      <button
        className="w-92 button-box-shadow green-clr font-16 br-10 h-44px mt-10 mb-10"
        onClick={() => history.push("/booking-complete")}
      >
        Pay
      </button>
    </div>
  );
}
export default UPIDetails;
