import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function WantToJoinPopup({ openWantToJoinPopup,msg, setWantToJoinPopup,setacceptClick,settRejectClick }) {
  const handleWantToJoinClose = () => {
    console.log("Reject Clicked");
    setWantToJoinPopup(false);
  };

  const handleWantToacceptClick = () => {
    console.log("Accept Clicked");
    setacceptClick('accept',msg?.from_user_id);
  };
  const handleWantToRejectClick = () => {
    console.log("Reject Clicked");
   
    settRejectClick('reject',msg?.from_user_id);
  }
  return (
    <IonModal className="confirm-popup-main"  isOpen={openWantToJoinPopup}>
      {console.log("WantToJoinPopup",msg)}
      <center>
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handleWantToJoinClose}
        >
          <RxCross1 />
        </div>
        <div className="d-flex flex-row w-100">
          <img
            className="confirm-image"
            src={Images.CallingProfileImage}
            alt="Declare_Man_Img"
          />
          <div className="w-75 fancy-declare-text mt-10">
            {msg?.from_user_name} Wants to join the call?
          </div>
        </div>

        <div
          className="mt-15 flex-space-around w-100"
          onClick={handleWantToJoinClose}
        >
          <button
            className="w-40 button-box-shadow  br-15 white-bg clr-white fw-600 p-5 font-size-12 text-center"
            onClick={handleWantToacceptClick}
          >
            accept
          </button>
          <button
            className="w-40 border-white text-center font-size-12 fw-600 p-5 br-15 clr-black"
            onClick={handleWantToRejectClick}
          >
            reject
          </button>
        </div>
      </center>
    </IonModal>
  );
}

export default WantToJoinPopup;
