import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonImg,
} from "@ionic/react";
import { Images } from "../../images";
import { useHistory } from "react-router";

function CallManagement() {
  const history = useHistory();
  const handleCallManagement = () => {
    history.push("/call-management");
  };
  return (
    <IonCard className="d-flex main-home-banner mrl-10 ion-align-items-center">
      <IonCardHeader>
        <IonCardTitle className="we-2-call-description">
          <div>Call</div>
          <div className="bold-text we-2-call-text">Management</div>
          <div className="bold-text we-2-meeting-text">
            Create your new meetings
          </div>
        </IonCardTitle>
        <IonButton
          className="we-2-call-button"
          onClick={() => handleCallManagement()}
        >
          We2Call
        </IonButton>
      </IonCardHeader>
      <IonImg className="banner-image" src={Images.Banner} />
    </IonCard>
  );
}

export default CallManagement;
