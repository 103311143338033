import { IonImg, IonIcon, IonModal, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { Images } from "../../images";
const SettledPayment = ({
  showModal,
  setShowModal,
  heading,
  btnObj,
  handleSettlement,
  error,
}) => {
  const onYesClick = () => {
    handleSettlement();
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <IonModal
      isOpen={showModal}
      backdropDismiss={true}
      className="settled-payment-modal"
    >
      <div className="settled-payment-container custom-box-shadow">
        <IonIcon
          icon={closeOutline}
          className="close-icon"
          onClick={() => handleCloseModal()}
        />

        <div className="flex-center mb-10">
          <IonImg
            className="logoImage w-80px"
            src={Images.paymentSettled}
            alt="hand-illus"
          />
        </div>
        <h1 className="payment-settlement-heading mb-20">{heading}</h1>
        <div className="flex-space-between  w-100 mb-10 mt-10">
          <button
            className={`settled-payment-btn yes-btn w-48 h-40`}
            onClick={() => onYesClick()}
          >
            {btnObj.btn1.name}
          </button>
          <button
            className={`settled-payment-btn no-btn w-48 h-40`}
            onClick={() => handleCloseModal()}
          >
            {btnObj.btn2.name}
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default SettledPayment;
