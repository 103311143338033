import React from "react";
import { IonPage } from "@ionic/react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import Table from "../../common/Table";

const FinancialStatementPopUp = () => {
  const clientFinancialStatementColumns = [
    {
      header: "masterProfitLoss",
      name: "Name M+F+RC",
    },
    {
      header: "clientShareProfitLoss",
      name: "C Share",
    },
    {
      header: "masterwithoutComm",
      name: "M+ - Comm ",
    },
    {
      header: "afterClientComm",
      name: "C Net P/L",
    },
  ];
  const clientFinancialStatementData = [
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      clientShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      clientShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      clientShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
  ];
  const referralFinancialStatementColumns = [
    {
      header: "masterProfitLoss",
      name: "Name M+F+RC",
    },
    {
      header: "referralShareProfitLoss",
      name: "Rf Share",
    },
    {
      header: "masterwithoutComm",
      name: "M+ - Comm ",
    },
    {
      header: "afterClientComm",
      name: "Rf - Net",
    },
  ];
  const referralFinancialStatementData = [
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
  ];
  const ulShareFinancialStatementColumns = [
    {
      header: "masterProfitLoss",
      name: "Name Total Net",
    },
    {
      header: "referralShareProfitLoss",
      name: "UL Share",
    },
    {
      header: "masterwithoutComm",
      name: "Urs P/L",
    },
    {
      header: "afterClientComm",
      name: "UL Comm",
    },
  ];
  const ulShareFinancialStatementData = [
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
    {
      masterProfitLoss: (
        <div className="settlemt-statement-client-data">
          <div>Sri23465</div>
          <div>1000000.00</div>
        </div>
      ),
      referralShareProfitLoss: 50000.0,
      masterwithoutComm: 50000.0,
      afterClientComm: 50000.0,
    },
  ];
  const clientHeadingButtons = (
    <div >
      <button className="common-active-css inactive-css font-12">
        Match Name IND vs SL
      </button>
      <button className="common-active-css inactive-css font-12">
        Date: 27/06/2023
      </button>
      <button className="common-active-css active-css font-12">Client</button>
      <div className="hr-line"></div>
    </div>
  );
  const clientReferralButtons = (
    <div >
      <button className="common-active-css inactive-css font-12">
        Match Name IND vs SL
      </button>
      <button className="common-active-css inactive-css font-12">
        Date: 27/06/2023
      </button>
      <button className="common-active-css active-css font-12">Referral</button>
      <div className="hr-line"></div>
    </div>
  );
  const clientUlShareButtons = (
    <div>
      <button className="common-active-css inactive-css font-12">
        Match Name IND vs SL
      </button>
      <button className="common-active-css inactive-css font-12">
        Date: 27/06/2023
      </button>
      <button className="common-active-css active-css font-12">UL Share</button>
      <div className="hr-line"></div>
    </div>
  );
  return (
    <IonPage>
      <MeetingsHeader heading="Financial Statement" />
      <div className="offline-page-container mt-10">
        <div>
          <Table
            columns={clientFinancialStatementColumns}
            data={clientFinancialStatementData}
            buttons={clientHeadingButtons}
          />
        </div>
        <div className="mt-10">
          <Table
            columns={referralFinancialStatementColumns}
            data={referralFinancialStatementData}
            buttons={clientReferralButtons}
          />
        </div>
        <div className="mt-10">
          <Table
            columns={ulShareFinancialStatementColumns}
            data={ulShareFinancialStatementData}
            buttons={clientUlShareButtons}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default FinancialStatementPopUp;
