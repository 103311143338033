import { IonImg, IonModal } from "@ionic/react";
import React, { useRef, useState } from "react";
import { Images } from "../../images";
import { IoMdClose } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Signup(props) {
  const { signPopupOpen, setSignPopupOpen, setForgitPasswordPopupOpen } = props;
  const [activeHeading, setActiveHeading] = useState(0);
  const [showClientTypeDropdoen, setShowClientTypeDropdoen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const headings = ["Signup", "By Phone", "By Email"];
  const cricket_countries = [
    "India",
    "Australia",
    "England",
    "Pakistan",
    "South Africa",
    "West Indies",
    "New Zealand",
    "Sri Lanka",
    "Bangladesh",
    "Zimbabwe",
    "Afghanistan",
    "Ireland",
    "Scotland",
    "Netherlands",
    "United Arab Emirates",
    "Nepal",
    "Oman",
    "Namibia",
    "Papua New Guinea",
    "Canada",
    "Kenya",
    "Hong Kong",
    "Bermuda",
    "United States",
    "Singapore",
    "Malaysia",
    "Qatar",
    "Kuwait",
    "Saudi Arabia",
    "Fiji",
    "Italy",
    "Spain",
    "Germany",
    "Denmark",
    "Belgium",
    "Norway",
    "Bahrain",
    "Jersey",
    "Guernsey",
    "Tanzania",
    "Uganda",
    "Botswana",
    "Argentina",
    "Namibia",
  ];

  const reEnterPassword = ["Enter New Password", "Re Enter New Password"];
  const otpInput = [{}, {}, {}, {}, {}, {}];

  const handleCloseSignUp = () => {
    setSignPopupOpen(false);
    setForgitPasswordPopupOpen(true);
    handleHeadings(0);
  };
  const handleHeadings = (index) => {
    setActiveHeading(index);
    setShowOtpInput(false);
  };
  const handleSelelctDropdown = () => {
    setShowClientTypeDropdoen((prev) => !prev);
  };
  const handleClientSelect = (item) => {
    setSelectedValue(item);
    setShowClientTypeDropdoen(false);
  };
  const handleEyeImage = () => {
    setShowPassword((prev) => !prev);
  };
  const handleShowOtpIpnut = (e) => {
    e.preventDefault();
    setShowOtpInput(true);
  };
  const inputRefs = useRef([]);
  const handleOTPChange = (index, event) => {
    const otpvalue = event.target.value;
    const nextIndex = index + 1;
    inputRefs.current[index] = otpvalue;
    // setOTP(otpvalue);

    if (otpvalue && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
  };
  return (
    <IonModal isOpen={signPopupOpen} className="statememt-popup-main">
      <div className="flex-flex-end mb-10">
        <IoMdClose
          className="reports-heading"
          onClick={() => handleCloseSignUp()}
        />
      </div>
      <div className="flex-space-between mb-10">
        <span className="reports-heading">Forgot Password</span>
        <IonImg className="popup-img" src={Images.We2call} />
      </div>
      <div className="flex-space-between mt-10 mb-10"></div>
      <div className="w-100">
        <input
          className="series-input flex-center custom-box-shadow mb-10 w-100"
          placeholder="Enter User Name"
        ></input>
        {reEnterPassword.map((item, index) => {
          return (
            <div
              key={index}
              className="series-input flex-center custom-box-shadow mb-10"
            >
              <input
                className="flex-center w-80 match-input"
                placeholder={item}
                type={showPassword ? "text" : "password"}
              ></input>
              {showPassword ? (
                <AiFillEye
                  className="dropdown-icon"
                  onClick={() => handleEyeImage()}
                />
              ) : (
                <AiFillEyeInvisible
                  className="dropdown-icon"
                  onClick={() => handleEyeImage()}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="w-100">
        <div className="flex-center">
          <select className="select-options email-input mr-10">
            <option>+91</option>
          </select>
          <input
            placeholder="Enter Phone Number"
            type="number"
            className="series-input flex-center custom-box-shadow w-100"
          />
        </div>
        <button
          className="submit-button w-100 mb-10 mt-10"
          onClick={handleShowOtpIpnut}
        >
          Send OTP
        </button>
      </div>
      {showOtpInput && (
        <center>
          <div className="flex-space-between">
            {otpInput.map((digit, index) => (
              <input
                className="flex-center otp-inputs"
                key={index}
                type="number"
                inputMode="numeric"
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(event) => handleOTPChange(index, event)}
                maxLength={1}
              />
            ))}
          </div>
          <button
            className="submit-button w-100 mb-10 mt-10 clr-green"
            onClick={() => handleCloseSignUp()}
          >
            Submit
          </button>
        </center>
      )}
    </IonModal>
  );
}

export default Signup;
