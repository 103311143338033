import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";
import { useHistory } from "react-router";

function TermsAndConditionsPopup(props) {
  const history = useHistory();
  const { termsAndConditionsPopup, handleTermsAndConditionsPopupClose } = props;
  return (
    <IonModal
      className="w-80 terms-conditions-popup"
      isOpen={termsAndConditionsPopup}
    >
      <div className="align-center flex-column custom-box-shadow terms-conditions-div">
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handleTermsAndConditionsPopupClose}
        >
          <RxCross1 />
        </div>
        <div>
          <img
            className="terms-conditions-image"
            src={Images.TermsVectorImage}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-80">
          <div className="terms-conditions-text mt-10">Terms & Conditions</div>
          <div className="terms-conditions-subtext mt-10">
            This company established under the laws of Costa Rica, with
            registered address at Costa Rica and having its gaming sublicence
            No. 1669/KAV issued by Costa Rica e-Gaming and all rights to operate
            the gaming software.
          </div>
        </div>
        <div className="flex-space-around flex-column w-90">
          <button
            className="w-100 button-box-shadow fancy-declare-button mt-15 green-clr font-14"
            onClick={handleTermsAndConditionsPopupClose}
          >
            I Agree
          </button>
          <button
            className="w-100 custom-box-shadow fancy-declare-button mt-15 font-14"
            onClick={() => history.push("/privacy-policy")}
          >
            Read Terms & Policy
          </button>
        </div>
      </div>
    </IonModal>
  );
}

export default TermsAndConditionsPopup;
