import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Images } from "../../images";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { GET_ALL_PACKAGES } from "../../config/endpoints";
import { call } from "../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPackages } from "../../redux/actions/commonActions";

function SelectPackages() {
  let packages = [
    {
      image: Images.Coin,
      packageType: "Standard Package",
      name: "standard",
      value: 0,
    },
    {
      image: Images.SilverMedal,
      packageType: "Silver Package",
      name: "silver",
      value: 0,
    },
    {
      image: Images.GoldMedal,
      packageType: "Gold Package",
      name: "gold",
      value: 0,
    },
    {
      image: Images.Diamond,
      packageType: "Diamond Package ",
      name: "diamond",
      value: 0,
    },
    {
      image: Images.Crown,
      packageType: "Vip Package",
      name: "vip",
      value: 0,
    },
  ];

  const [allPackages, setAllPackages] = useState([]);
  const dispatch = useDispatch();
  const packageList =
    useSelector((State) => State.common.selected_packages) || [];
  const handleAddAndSubtract = (selectedPack, packageType, value) => {
    const selectedNewPackages = allPackages.map((obj) => {
      if (obj?.[packageType]?.package_id === selectedPack?.package_id) {
        obj[packageType] = {
          ...obj?.[packageType],
          no_of_packages: obj?.[packageType]?.no_of_packages + value,
        };
      }
      return obj;
    });
    let selectedPackList = [];
    let monthly = [],
      yearly = [];
    allPackages.forEach((obj) => {
      if (obj["monthly"].no_of_packages > 0) {
        obj["monthly"].package_name = obj.name;
        obj["monthly"].duration = "monthly";
        monthly.push(obj["monthly"]);
      }
      if (obj["yearly"].no_of_packages > 0) {
        obj["yearly"].package_name = obj.name;
        obj["yearly"].duration = "yearly";
        yearly.push(obj["yearly"]);
      }
    });
    selectedPackList = [...monthly, ...yearly];
    dispatch(setSelectedPackages(selectedPackList));
    setAllPackages(selectedNewPackages);
  };

  const getAllPackages = async () => {
    await call(GET_ALL_PACKAGES)
      .then((res) => {
        if (res.data.status === 200) {
          const response = res.data.data;
          const updateResponse = getUpdatedPackData(response);
          setAllPackages(updateResponse);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllPackages();
  }, []);
  useEffect(() => {
    packageList.forEach((obj) => {
      allPackages.forEach((itm) => {
        if (obj.package_name === itm.name) {
            
            const pkgLst =  packageList.filter((item) => item.package_name === obj.package_name);
            
            if(pkgLst.length < 2){

             
              if(obj.duration === "yearly"){
                
                   itm.monthly.no_of_packages = 0;
                
              }else{
               itm.yearly.no_of_packages = 0;
              }
            }else{
          //        if(obj.duration === "yearly"){
          //     itm.yearly.no_of_packages =  obj?.no_of_packages ?  obj.no_of_packages : 0;
          // }else{
          //     itm.monthly.no_of_packages = obj?.no_of_packages ?  obj.no_of_packages : 0;
          // }
            }

         

          
          
        }
      });
    });
  }, [packageList]);

  const getUpdatedPackData = (allPackagesInfo) => {
    return packages.map((itm) => {
      let monthly, yearly;
      allPackagesInfo.forEach((obj) => {
        if (
          obj.package_name === itm.name &&
          obj.package_duration === "monthly"
        ) {
          monthly = obj;
        }
        if (
          obj.package_name === itm.name &&
          obj.package_duration === "yearly"
        ) {
          yearly = obj;
        }
      });
      return {
        ...itm,
        monthly: {
          cost: monthly?.package_cost,
          package_id: monthly?.package_id,
          no_of_packages: 0,
          discount: monthly?.discount,
        },
        yearly: {
          cost: yearly?.package_cost,
          package_id: yearly?.package_id,
          no_of_packages: 0,
          discount: monthly?.discount,
        },
      };
    });
  };

  return (
    <IonGrid>
      <div className="select-package-heading ml-10 mb-10">Select Packages</div>
      <IonRow className="connect-device mrl-10 pb-5">
        <div className="w-100 flex-flex-end mt-10">
          <IonCol
            size="3"
            className="upcoming-table-heading custom-box-shadow flex-center mr-25"
          >
            Monthly
          </IonCol>
          <IonCol
            size="3"
            className="upcoming-table-heading custom-box-shadow flex-center"
          >
            Yearly
          </IonCol>
        </div>
        {allPackages.map((item, index) => {
          return (
            <IonCol key={index}>
              <div className="series-input custom-box-shadow h-80px d-flex">
                <IonCol size="5" className="d-flex">
                  <img className="select-package-images" src={item.image}></img>
                  <div>{item.packageType}</div>
                </IonCol>
                <IonCol size="3" className="add-monthly mr-25">
                  {item.monthly?.cost}
                  <br />
                  <div className="upcoming-table-heading custom-box-shadow flex-center w-100 flex-space-between mt-10">
                    <AiOutlineMinus
                      onClick={() =>
                        item.monthly.no_of_packages
                          ? handleAddAndSubtract(item.monthly, "monthly", -1)
                          : null
                      }
                      className="plus-icon"
                    />

                    {item.monthly?.no_of_packages
                      ? item.monthly.no_of_packages
                      : "Add"}
                    <AiOutlinePlus
                      onClick={() =>
                        handleAddAndSubtract(item.monthly, "monthly", 1)
                      }
                      className="plus-icon"
                    />
                  </div>
                </IonCol>
                <IonCol size="3" className="add-monthly">
                  {item.yearly?.cost}
                  <br />
                  <div className="upcoming-table-heading custom-box-shadow flex-center w-100 flex-space-between mt-10">
                    <AiOutlineMinus
                      onClick={() =>
                        item.yearly?.no_of_packages
                          ? handleAddAndSubtract(item.yearly, "yearly", -1)
                          : null
                      }
                      className="plus-icon"
                    />
                    {item.yearly?.no_of_packages
                      ? item.yearly.no_of_packages
                      : " Add"}
                    <AiOutlinePlus
                      onClick={() =>
                        handleAddAndSubtract(item.yearly, "yearly", 1)
                      }
                      className="plus-icon"
                    />
                  </div>
                </IonCol>
              </div>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
}

export default SelectPackages;
