import { IonImg, IonModal, IonText } from "@ionic/react";
import { Images } from "../../images/index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { isLoggedIn, setLocalStorageItems } from "../../utils/helpers";
import { call } from "../../config/axios";
import { USER_LOGIN } from "../../config/endpoints";

function LoginPopup() {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(!isLoggedIn());
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleEyeicon = () => {
    setIsVisible((prev) => !prev);
  };
  const handleClose = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLogin = () => {
    setErr("");
    setIsProcessing(true);
    call(USER_LOGIN, { user_name: username, password: password })
      .then((res) => {
        if (res?.data?.status === 200) {
          setIsProcessing(false);
          const {
            email,
            first_name,
            last_name,
            register_id,
            creator_id,
            account_role,
            user_name,
            share,
            ul_share,
            creator_name,
          } = res.data.data;
          setLocalStorageItems({
            email,
            token: password + new Date(),
            first_name,
            last_name,
            register_id,
            creator_id,
            account_role,
            user_name,
            share,
            ul_share,
            creator_name,
          });
          handleClose();
          window.location.reload("/");
        } else {
          setIsProcessing(false);
          setErr(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false)
        setErr(err?.message ? err?.message : `something wen't wrong`);
        console.log(err);
      });
  };
  return (
    <IonModal
      isOpen={isOpen}
      className="statememt-popup-main"
      animated
      backdropDismiss={false}
    >
      {/* <div className="flex-flex-end mb-10">
        <IoMdClose className="reports-heading" onClick={() => handleClose()} />
      </div> */}
      <div className="login-popup-div">
        <div className="flex-space-between mb-10">
          <span className="reports-heading">Login</span>
          <IonImg
            className="popup-img"
            src={Images.We2call}
            alt="wecall-icon"
          />
        </div>
        <input
          className="pl-15 w-100 mb-20 font-14 edit-profile-input-properties custom-box-shadow"
          placeholder="User Name"
          type="text"
          name="username"
          id="username"
          value={username || ""}
          onChange={(e) => setUserName(e.target.value)}
        ></input>
        <div className="series-input flex-center custom-box-shadow mb-10">
          <input
            className="flex-center w-80 match-input font-14"
            placeholder="Password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password || ""}
            type={isVisible ? "text" : "password"}
          ></input>
          {isVisible ? (
            <AiFillEye
              className="dropdown-icon"
              onClick={() => handleEyeicon()}
            />
          ) : (
            <AiFillEyeInvisible
              className="dropdown-icon"
              onClick={() => handleEyeicon()}
            />
          )}
        </div>
        {/* <div
          className="font-12 flex-flex-end mb-10"
          // onClick={() => handleSignUp()}
        >
          Forgot Your Password ?
        </div> */}
        {err && (
          <IonText color="danger" className="ion-text-center">
            {err}
          </IonText>
        )}
        <button
          type="submit"
          className="w-100 mt-5 font-14 edit-btn-clr button-box-shadow h-44px br-10"
          disabled={isProcessing}
          onClick={() => handleLogin()}
        >
          {isProcessing ? "Authenticating..." : "Login"}
        </button>
      </div>
    </IonModal>
  );
}

export default LoginPopup;
