import AddAdmins from "../pages/add-admins/AddAdmins";
import HomePage from "../pages/homepage/HomePage";
import SportManagement from "../pages/sport-management/SportManagement";
import UserManagement from "../pages/user-management/UserManagement";
import FancyEntry from "../pages/fancyentry/FancyEntry";
import MatchEntry from "../pages/matchEntry/MatchEntry";
// import ToursAndTournaments from "../pages/tours-tournaments/ToursAndTournaments";
import Offers from "../pages/offers/Offers";
import OnePageReport from "../pages/onePageReport/OnePageReport";
import settlementStatement from "../pages/settlementStatement/settlementStatement";
import MatchStatement from "../pages/matchesStatement/MatchStatement";
import CallManagement from "../pages/meetings/CallManagement";
import FinancialStatementPopUp from "../pages/matchesStatement/FinancialStatementPopUp";
import StatementPopUp from "../pages/matchesStatement/StatementPopUp";
import BookingComplete from "../pages/offers/BookingComplete";
import Message from "../pages/offers/Message";
import PackageUpgrade from "../pages/packages-accounts/PackageUpgrade";
import DownlevelTransaction from "../pages/packages-accounts/DownlevelTransaction";
import settlement from "../pages/settlement/settlement";
import ExistingLoginUsers from "../pages/user-management/ExistingLoginUsers";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import CallHistory from "../pages/meetings/CallHistory";
import CallSettelment from "../pages/meetings/CallSettelment";
import EditProfile from "../pages/editprofile/EditProfile";
import CallStatement from "../pages/meetings/CallStatement";
import UpgradeYourPackagePage from "../pages/packages-accounts/UpgradeYourPackagePage";
import AdminOnePageReport from "../pages/admin/onePageReport/AdminOnePageReport";
import AddUsers from "../add-users/AddUsers";
import PackageTransaction from "../pages/packages-accounts/PackageTransaction";
import AdminTicketPurchaseData from "../pages/packages-accounts/AdminTicketPurchaseData";
import ViewUsersAdmins from "../pages/add-admins/ViewUsersAdmins";
import CallMeetings from "../pages/video-call-matchposition/CallMeetings";
import AddViewUsersAdmins from "../pages/add-admins/AddViewUsersAdmins";
import MatchShareRisk from "../pages/riskpages/MatchShareRisk";
import ShareFancyRisk from "../pages/riskpages/ShareFancyRisk";
import ShareRiskLiveMatches from "../pages/riskpages/ShareRiskLiveMatches";
import Chats from "../pages/chats/Chats";
import GroupCall from "../components/GroupCall";
import GroupCallup from "../components/GroupCall/uplevel";
import Join from "../components/GroupCall/join";
import Joinup from "../components/GroupCall/joinup";
import PaymentGateWayList from "../pages/paymentGatewayList/PaymentGateWayList";
import PackageStatement from "../pages/packagetransaction/PackageStatement";
import CallReports from "../pages/meetings/CallReports";
import AdminStatement from "../pages/matchesStatement/AdminStatement";
import ChatLists from "../pages/chats/ChatLists";
import { useParams } from 'react-router-dom';
import LiveLineStreamReport from "../pages/livestream-line-reports/LiveLineStreamReport";




export const AgentRoutes = [
  {
    component: HomePage,
    path: "/",
  },
  {
    component: UserManagement,
    path: "/user-management",
  },
  {
    component: SportManagement,
    path: "/sport-management",
  },
  {
    component: AddAdmins,
    path: "/add-admins",
  },
  {
    component: FancyEntry,
    path: "/fancyentry",
  },
  {
    component: MatchEntry,
    path: "/matchentry",
  },
  // {
  //   component: ToursAndTournaments,
  //   path: "/tours-and-tournaments",
  // },
  {
    component: Offers,
    path: "/offers",
  },
  {
    component: settlement,
    path: "/settlement",
  },
  {
    component: OnePageReport,
    path: "/one-page-report",
  },
  {
    component: settlementStatement,
    path: "/settlement-statement",
  },
  {
    component: MatchStatement,
    path: "/matches-statement",
  },
  {
    component: FinancialStatementPopUp,
    path: "/financial-statement-popup",
  },
  {
    component: StatementPopUp,
    path: "/statement-popup/:id/:match/:date/:winTeam",
  },
  {
    component: BookingComplete,
    path: "/booking-complete",
  },
  {
    component: Message,
    path: "/message",
  },
  // {
  //   component: DownlevelTransaction,
  //   path: "/meetings",
  // },
  {
			path: 'meetings',
			children: [
				{
					path: '',
					element: (
							<DownlevelTransaction />
					
					)
				},
				{
					path: ':id',
					element: (
							<GroupCall/>
					)
				}
				
			]
		},
    {
			path: 'upmeetings',
			children: [
				{
					path: '',
					element: (
							<DownlevelTransaction />
					
					)
				},
				{
					path: ':id',
					element: (
							<GroupCallup/>
					)
				}
				
			]
		},
    {
			path: 'join',
			children: [
				{
					path: '',
					element: (
							<DownlevelTransaction />
					
					)
				},
				{
					path: ':id',
					element: (
							<Join/>
					)
				}
				
			]
		},
    {
			path: 'upjoin',
			children: [
				{
					path: '',
					element: (
							<DownlevelTransaction />
					
					)
				},
				{
					path: ':id',
					element: (
							<Joinup/>
					)
				}
				
			]
		},
  {
    component: CallMeetings,
    path: "/call-meetings",
  },
  {
    component: MatchStatement,
    path: "/match-statement",
  },
  {
    component: AdminStatement,
    path: "/admin-statement",
  },
  {
    component: PrivacyPolicy,
    path: "/privacy-policy",
  },
  {
    component: CallManagement,
    path: "/call-management",
  },
  // {
  //   component: GroupCall,
  //   path: "/meeting/:id",
  // },
  {
    component: CallHistory,
    path: "/call-history",
  },
  {
    component: CallStatement,
    path: "/call-statement",
  },
  {
    component: CallSettelment,
    path: "/call-settelment",
  },
  {
    component: PackageUpgrade,
    path: "/package-upgrade",
  },
  {
    component: EditProfile,
    path: "/edit-profile",
  },
  {
    component: ExistingLoginUsers,
    path: "/existing-users",
  },
  {
    component: UpgradeYourPackagePage,
    path: "/upgrade-your-packages-page",
  },
  {
    component: AdminOnePageReport,
    path: "/admin-one-page-report",
  },
  {
    component: AddUsers,
    path: "/add-users",
  },
  {
    component: PackageTransaction,
    path: "/package-transaction",
  },
  {
    component: ViewUsersAdmins,
    path: "/view-users-admins",
  },
  {
    component: AddViewUsersAdmins,
    path: "/add-view-users-admins",
  },
  {
    component: MatchShareRisk,
    path: "/match-share-risk",
  },
  {
    component: ShareFancyRisk,
    path: "/share-fancy-risk",
  },
  {
    component: ShareFancyRisk,
    path: "/share-risk-live-matches",
  },
  {
    component: Chats,
    path: "/chat/:id/:name",
  },
  {
    component: ChatLists,
    path: "/chat-lists",
  },
  {
    component: PaymentGateWayList,
    path: "/payment-gateway-list",
  },
  {
    component: AdminTicketPurchaseData,
    path: "/admin-ticket-purchase-data",
  },
  {
    component: PackageStatement,
    path: "/package-statement",
  },
  {
    component: CallReports,
    path: "/call-reports",
  },
  {
    component: LiveLineStreamReport,
    path: "/live-line-report",
  },
];

