import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import MeetingsHeader from "../../meetings/MeetingsHeader";
import Table from "../../../common/Table";
import MatchWiseULPlatformCom from "./MatchWiseULPlatformCom";
import AdminOnePageReportIndividual from "./AdminOnepageReportIndvidual";
import { useHistory } from "react-router";
import AdminReportsTable from "./AdminReportsTable";
import {
  GET_LIVE_MATCH_RISK_POSITION,
  GET_OFFLINE_CLIENTS,
} from "../../../config/endpoints";
import { call } from "../../../config/axios";
import AdminPaymentPopup from "./AdminPaymentPopup";
import AdminSettlementStatement from "./AdminSettlementStatement";
import PaymentSuccessPopUp from "./PaymentSuccessPopUp";

const AdminOnePageReport = () => {
  const register_id = localStorage.getItem("register_id");
  const [allUsers, setAllUsers] = useState([]);
  const [ulShare, setUlShare] = useState(0);
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [pendinAmount, setPendingAmount] = useState(0);
  const [paymentSuccessPopUp, setPaymentSuccessPopUp] = useState(false);
  const [success, setSuccess] = useState(false);

  const adminOnePageReports = [
    "Admins One Page Reports",
    "U/L Comm Report",
    "Admins Share/Comm SettleMent Statement",
  ];
  const [onePageReportStatus, setOnepageReportStatus] = useState(false);
  const [amdinOnePageReports, setAdminOnePageReports] = useState(
    adminOnePageReports[0]
  );
  const [matchWiseULPlatformComTable, setMatchWiseULPlatformCommTable] =
    useState(false);
  const [showAdminIndTable, setShowAdminIndTable] = useState(false);
  const [induvisualUserReport, setInduvisualUserReport] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const adminOnePageReportColumns = [
    { header: "admin_name", name: "Admins Name" },
    { header: "net_pl", name: "Admin Net P/L" },
    { header: "ul_share", name: "UL Share" },
  ];

  const adminsReportsdata = [
    {
      name: "Animesh",
      role: "Agent",
      net_pl: 1000000.0,
      ul_share: 500000.0,
    },
    {
      name: "Sri8647",
      role: "Master",
      net_pl: 1000000.0,
      ul_share: 500000.0,
    },
    {
      name: "Ganesh",
      role: "Admin",
      net_pl: 1000000.0,
      ul_share: 500000.0,
    },
  ];
  const handleIndividualAdminOnePageReport = (
    userId,
    ulShare,
    username,
    role
  ) => {
    getUserMatches(username);
    setUlShare(ulShare);
    setUserName(username);
    setUserRole(role);
    setShowAdminIndTable(true);
    setMatchWiseULPlatformCommTable(false);
  };
  const getUlShare = (netPl, ulShare) => {
    const netAmount = (+netPl || 0 * +ulShare || 0) / 100;
    return netAmount;
  };
  const onePageReportNetPL =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.reduce((acc, obj) => acc + (+obj?.total_amount || 0), 0);
  const onePageReportUlNet =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.reduce(
      (acc, obj) => acc + (+getUlShare(obj?.total_amount, obj?.ul_share) || 0),
      0
    );

  const adminOnePageReportData =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.map((user) => {
      const netPL = getUlShare(user?.total_amount, user?.ul_share);
      return {
        admin_name: (
          <div className="client-name-role-container mb-5 mt-5">
            <div>{user?.client_name}</div>
            <div>{`- ${user?.account_role}`}</div>
          </div>
        ),
        net_pl: (
          <div className={user?.total_amount >= 0 ? "green-clr" : "red-clr"}>
            {user?.total_amount ? user?.total_amount?.toFixed(3) : 0}
          </div>
        ),
        ul_share: (
          <div className={netPL >= 0 ? "green-clr" : "red-clr"}>
            {netPL ? netPL?.toFixed(3) : 0}
          </div>
        ),
        onClick: () =>
          handleIndividualAdminOnePageReport(
            user?.register_id,
            user?.ul_share,
            user?.client_name,
            user?.account_role
          ),
      };
    });

  const handleULPlatformCommOnePageReport = (admin) => {
    if (admin === admin) {
      setMatchWiseULPlatformCommTable(true);
      setShowAdminIndTable(false);
    }
  };
  const ulPlatformOnePageReportColumns = [
    { header: "admin_name", name: "Admins Name" },
    { header: "admin_role", name: "Admin Role" },
    { header: "ul_platform_comm", name: "UL pf comm" },
  ];
  const ulPlatformComm =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.map((user) => {
      const netPL = getUlShare(user?.total_amount, user?.ul_share);
      return {
        admin_name: user?.client_name,
        admin_role: user?.account_role,
        ul_platform_comm: (
          <div className={`${netPL >= 0 ? "green-clr" : "red-clr"}`}>
            {user?.totalPlatformNet ? user?.totalPlatformNet?.toFixed(2) : 0}
          </div>
        ),
      };
    });

  const [paymentPopupOpen, setPaymentPopupOpen] = useState(false);

  const handlePaymentPopup = (user) => {
    setSelectedUser(user);
    const resultAmount =
      getUlShare(user?.total_amount, user?.ul_share) +
      (+user?.totalPlatformNet || 0);
    const pendinAmount =
      user?.pending_settlement_platform_amount ||
      user?.pending_settlement_platform_amount == 0
        ? user?.pending_settlement_platform_amount
          ? user?.pending_settlement_platform_amount?.toFixed(2)
          : 0
        : resultAmount
        ? resultAmount?.toFixed(2)
        : 0;
    setTotalAmount(resultAmount);
    setPendingAmount(pendinAmount);
    setPaymentPopupOpen(true);
  };

  const AdminCommSattlementStatementColumns = [
    {
      header: "admin_name",
      name: (
        <div>
          Admin Name
          <br /> Role
        </div>
      ),
    },
    {
      header: "amount",
      name: (
        <div className="w-fit-content">
          Share + <br />
          UL comm
        </div>
      ),
    },
    { header: "credit_debit", name: <div>C/D</div> },
    { header: "balance", name: <div>Bal</div> },
    { header: "pay", name: <div>Pay</div> },
  ];
  const totalNetPl = allUsers.reduce(
    (acc, obj) =>
      acc +
      (getUlShare(obj?.total_amount, obj?.ul_share) +
        (+obj?.totalPlatformNet || 0) || 0),
    0
  );
  const totalCD =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.reduce(
      (acc, obj) => acc + (+obj?.settled_platform_amount || 0),
      0
    );
  const totalBalance =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.reduce(
      (acc, obj) => acc + (+obj?.pending_settlement_platform_amount || 0),
      0
    );
  const totalPlatForm =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.reduce((acc, obj) => acc + (+obj?.totalPlatformNet || 0), 0);
  const AdminCommSattlementStatementData =
    allUsers &&
    allUsers?.length > 0 &&
    allUsers?.map((user) => {
      const netPL =
        getUlShare(user?.total_amount, user?.ul_share) +
        (+user?.totalPlatformNet || 0);
      return {
        admin_name: (
          <div>
            {user?.client_name}
            <br />
            {user?.account_role}
          </div>
        ),
        amount: (
          <div className={netPL >= 0 ? "green-clr" : "red-clr"}>
            {netPL ? netPL?.toFixed(2) : 0}
          </div>
        ),
        credit_debit: user?.settled_platform_amount || 0,
        balance: (
          <div className={netPL >= 0 ? "green-clr" : "red-clr"}>
            {user?.pending_settlement_platform_amount ||
            user?.pending_settlement_platform_amount == 0
              ? user?.pending_settlement_platform_amount
                ? user?.pending_settlement_platform_amount?.toFixed(2)
                : 0
              : netPL
              ? netPL?.toFixed(2)
              : 0}
          </div>
        ),
        pay: (
          <div
            className="account-summary-main-container"
            onClick={() => +netPL !== 0 && handlePaymentPopup(user)}
          >
            {+netPL === 0 ? "N/A" : "pay"}
          </div>
        ),
      };
    });
  // const AdminCommSattlementStatementData =  allUsers &&
  // allUsers?.length > 0 &&
  // allUsers?.map((user) => {
  //   const netPL = getUlShare(user?.total_amount, user?.ul_share);
  //   return (
  //     {
  //       admin_name: (
  //         <div>
  //           {user?.client_name}
  //           <br />
  //           {user?.account_role}
  //         </div>
  //       ),
  //       amount: netPL,
  //       ul_pf_comm: 1000000.0,
  //       credit_debit: 10000,
  //       balance: "100100",
  //     }
  //   )

  // const ulPlatformCommOnePageReportData = ulPlatformComm.map((admin) => ({
  //   admin_name: (
  //     <div className="client-name-role-container mb-5 mt-5">
  //       <div>{admin.name}</div>
  //       <div>{admin.role}</div>
  //     </div>
  //   ),
  //   net_pl: admin.net_pl,
  //   ul_platform_comm: admin.ul_platform_comm,
  //   onClick: () => handleULPlatformCommOnePageReport(admin),
  // }));

  const handleOnepageReportIndividual = () => {
    setOnepageReportStatus((prev) => !prev);
  };
  const handleAdminReport = (report, index) => {
    setAdminOnePageReports(report);
    if (index !== 0) {
      return setShowAdminIndTable(false);
    }
  };
  const getAllUsers = async () => {
    await call(GET_OFFLINE_CLIENTS, { register_id })
      .then((res) => {
        let results = res?.data?.data?.filter(
          (item) => item.user_status !== "deleted"
        );
        setAllUsers(results);
      })
      .catch((err) => console.log(err));
  };
  const getUserMatches = async (username) => {
    await call(GET_LIVE_MATCH_RISK_POSITION, { user_name: username })
      .then((res) => {
        setInduvisualUserReport(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllUsers();
  }, [success]);

  return (
    <>
      <MeetingsHeader heading="Admin OnePage Report" />
      <div className="ml-10 white-text">{amdinOnePageReports}</div>
      <div className="offline-page-container">
        <div className="account-summary-main-container mt-5">
          <div className="flex-space-between">
            <div
              className="common-active-css active-css flex-space-between position-relative"
              onClick={() => handleOnepageReportIndividual()}
            >
              <div className="admin-report-title">{amdinOnePageReports}</div>
              {onePageReportStatus ? (
                <RiArrowDropUpLine className="dropdown-icon" />
              ) : (
                <RiArrowDropDownLine className="dropdown-icon" />
              )}
              {onePageReportStatus && (
                <div className="admin-reports-dropdown w-100 dropdown-position font-12 h-190px">
                  {adminOnePageReports.map((report, index) => {
                    return (
                      <div
                        key={index}
                        className={`h-40 flex-center mb-5 font-color text-center`}
                      >
                        <div onClick={() => handleAdminReport(report, index)}>
                          {report}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="common-active-css inactive-css font-12 text-center">
              All Matches
            </div>
          </div>
        </div>
        {amdinOnePageReports === "Admins One Page Reports" && (
          <div>
            <Table
              columns={adminOnePageReportColumns}
              data={adminOnePageReportData}
            />
            <table className="w-100 account-summary-main-container mt-5">
              <tbody>
                <tr>
                  <th>Total</th>
                  <th
                    className={onePageReportNetPL > 0 ? "clr-green" : "clr-red"}
                  >
                    {onePageReportNetPL ? onePageReportNetPL?.toFixed(2) : 0}
                  </th>
                  <th
                    className={onePageReportUlNet > 0 ? "clr-green" : "clr-red"}
                  >
                    {onePageReportUlNet ? onePageReportUlNet?.toFixed(2) : 0}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {amdinOnePageReports === "U/L Comm Report" && (
          <div>
            <AdminReportsTable
              columns={ulPlatformOnePageReportColumns}
              data={ulPlatformComm}
            />
            <table className="w-100 account-summary-main-container mt-5">
              <tfoot>
                <tr>
                  <th>_____</th>
                  <th>Total</th>
                  <th className={totalPlatForm > 0 ? "clr-green" : "clr-red"}>
                    {totalPlatForm ? totalPlatForm?.toFixed(2) : 0}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {amdinOnePageReports === "Admins Share/Comm SettleMent Statement" && (
          <div>
            <AdminReportsTable
              columns={AdminCommSattlementStatementColumns}
              data={AdminCommSattlementStatementData}
            />
            <table className="w-100 account-summary-main-container mt-5">
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th className={totalNetPl > 0 ? "clr-green" : "clr-red"}>
                    {totalNetPl ? totalNetPl?.toFixed(2) : 0}
                  </th>
                  <th className={totalCD > 0 ? "clr-green" : "clr-red"}>
                    {totalCD ? totalCD?.toFixed(2) : 0}
                  </th>
                  <th className={totalBalance > 0 ? "clr-green" : "clr-red"}>
                    {totalBalance ? totalBalance?.toFixed(2) : 0}
                  </th>
                  <th>__</th>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {matchWiseULPlatformComTable && <MatchWiseULPlatformCom />}
        {showAdminIndTable && (
          <AdminOnePageReportIndividual
            data={induvisualUserReport}
            ulShare={ulShare}
            userName={userName}
            userRole={userRole}
          />
        )}
        {paymentPopupOpen && (
          <AdminPaymentPopup
            paymentPopupOpen={paymentPopupOpen}
            setPaymentPopupOpen={setPaymentPopupOpen}
            selectedUser={selectedUser}
            totalAmount={totalAmount}
            pendinAmount={pendinAmount}
            setPaymentSuccessPopUp={setPaymentSuccessPopUp}
            setSuccess={setSuccess}
          />
        )}
        {paymentSuccessPopUp && (
          <PaymentSuccessPopUp
            openPopup={paymentSuccessPopUp}
            setclosePopup={setPaymentSuccessPopUp}
            displayData="Payment done succesfullyI"
          />
        )}
      </div>
    </>
  );
};

export default AdminOnePageReport;
