import React from "react";
import Table from "../../../common/Table";

const MatchWiseULPlatformCom = ({ adminsName }) => {
  const matchWiseULPlatformComColumns = [
    { header: "series_name", name: "Series" },
    { header: "team_name", name: "Team Name" },
    { header: "admin_pl", name: "Admin P/L" },
    { header: "ul_platform_comm", name: "UL /Pla Com" },
  ];
  const adminMatchWisePlformData = [
    {
      series_name: "T20 world cup",
      match_place: "Hyderabad",
      date: "23-06-2023",
      time: "15:42:00 PM",
      team: "India vs England",
      win_team: "India",
      admin_pl: 1000000.0,
      ul_platform_comm: 1000.0,
    },
  ];
  const matchWiseULPlatformComData = adminMatchWisePlformData.map(
    (adminUldata) => ({
      series_name: (
        <div className="mb-10 text-align-left">
          <div>{adminUldata?.series_name}</div>
          <div>{adminUldata?.match_place}</div>
          <div>{adminUldata?.date}</div>
          <div>{adminUldata?.time}</div>
          <div>{adminUldata?.team}</div>
        </div>
      ),
      team_name: (
        <div>
          <div>{adminUldata?.team}</div>
          <div>
            <span>Win - </span>
            <span className="clr-green">{adminUldata?.win_team}</span>
          </div>
        </div>
      ),
      admin_pl: adminUldata?.admin_pl,
      ul_platform_comm: adminUldata?.ul_platform_comm,
    })
  );
  const matchWiseULPlatformHeadings = (
    <div className="flex-space-between">
      <button className="common-active-css inactive-css">
        Match Wise UL/Platform Com
      </button>
      <button className="clr-green common-active-css inactive-css">
        {`${adminsName?.name}-${adminsName?.role}`}
      </button>
    </div>
  );
  return (
    <>
      <div className="mt-10">
        <Table
          columns={matchWiseULPlatformComColumns}
          data={matchWiseULPlatformComData}
          buttons={matchWiseULPlatformHeadings}
        />
      </div>
    </>
  );
};

export default MatchWiseULPlatformCom;
