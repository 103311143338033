import React from "react";
import { Redirect, Route } from "react-router";

const PrivateRoute: React.FC<{
  component: any;
  exact: boolean;
  path: string;
}> = (props) => {
  function performValidation(): boolean {
    return localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
      ? true
      : false;
  }

  const isAuthorized = performValidation();

  return isAuthorized ? (
    <Route exact path={props.path} component={props.component}></Route>
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
