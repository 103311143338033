import Table from "../../common/Table";
import { IonPage, IonIcon } from "@ionic/react";
import { useEffect, useState } from "react";
import MatchFancyRLComm from "./MatchFancyRLComm";
import IndividualPLReport from "./IndividualPLReport";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { closeOutline } from "ionicons/icons";

import "./style.css";
import { call } from "../../config/axios";
import {
  GET_COMPLETED_MATCHES_BY_CLEINT,
  GET_FINANCIAL_STATEMENT_BY_DATE,
  GET_ONEPAGE_REPORT,
  GET_REFFERAL_DATA,
} from "../../config/endpoints";
import moment from "moment";

const OnePageReport = () => {
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const account_role = localStorage?.getItem("account_role");
  const ulShare = localStorage?.getItem("ul_share") || 0;
  const [netPLInduvisualClient, setNetPLInduvisualClient] = useState(0);
  const [onePageReportData, setOnePageReportData] = useState([]);
  const [individualClientData, setIndividualClientData] = useState([]);
  const [indivisualMatchReportData, setIndivisualMatchReportData] = useState(
    []
  );
  const [clientsDataForRefferal, setClientsDataForRefferal] = useState([]);
  const [induvisualClientStatus, setInduvisualClientStatus] = useState(false);

  const [IndividualPLReportsButtons, setIndividualPLReportsButtons] =
    useState("");
  const [showClientTable, setShowClientTable] = useState(false);
  const [reportReferralDataStatus, setReportReferralDataStatus] =
    useState(false);
  const [clientName, setClientName] = useState("");
  const [refName, setRefName] = useState("");
  const [refId, setRefId] = useState("");
  const [refData, setRefData] = useState("");
  const [clientId, setClientId] = useState("");
  const [invidiualReportReferralData, setInvidiualReportReferralData] =
    useState("");
  const [invidiualReportReferralColumns, setInvidiualReportReferralColumns] =
    useState("");
  const [completeMatchesData, setCompleteMatchesData] = useState([]);
  const [induvisualClientName, setInduvisualClientName] = useState("");
  const [induvisualClientRef, setInduvisualClientRef] = useState("");
  const [refClientId, setRefClientId] = useState("");
  const [refClientName, setRefClientName] = useState("");
  const [refClientData, setRefClientData] = useState([]);

  const clientColumns = [
    {
      header: "client_name",
      name: "Client Name",
      render: (data) => data?.client_name,
    },
    {
      header: "amount",
      name: "Total P/L",
      render: (data) =>
        data?.totalLossOrProfit ? data?.totalLossOrProfit?.toFixed(2) : 0,
    },
  ];
  const individualReportReferralColumns = [
    { header: "referral_id", name: "Refferal Name" },
    {
      header: "amount",
      name: "Total P/L",
    },
  ];
  const individualReportULShareColumn = [
    {
      header: "matchName",
      name: "Match Name",
    },
    {
      header: "winTeam",
      name: "Win Team",
    },
    { header: "ULnetPL", name: "Urs Net P/L" },

    {
      header: "afterAllSharePL",
      name: "UL Share P/L",
    },
  ];
  const individualReportPlatformCommColumns = [
    {
      header: "matchName",
      name: "Match Name",
    },
    {
      header: "winTeam",
      name: "Win Team",
    },
    { header: "netPL", name: "Urs Net P/L" },

    {
      header: "individualReportPlatformComm",
      name: "Plat Comm",
    },
  ];
  const handleClientName = (client_name, client_id, netPL) => {
    setIndividualColumns(individualReportClientColumns);
    setShowClientTable(true);
    setReportReferralDataStatus(false);
    setClientName(client_name);
    setClientId(client_id);
    setNetPLInduvisualClient(netPL);
  };
  const handleReferralId = (name, refId) => {
    setClientId("");
    setShowClientTable(true);
    setIndividualColumns(individualReferralName);
    setIndividualPLReportsButtons(individualReferralNameData);
    setRefName(name);
    setRefId(refId);
  };

  const invidiualReportReferral = [
    { header: "matchName", name: "Match Name" },
    { header: "winTeam", name: "Win Team" },
    { header: "amount", name: "Net P/L" },
  ];
  const indReportReferralData =
    indivisualMatchReportData &&
    indivisualMatchReportData?.length > 0 &&
    indivisualMatchReportData?.map((match) => {
      const netPL = match?.referralComission + match?.referalShare;
      return {
        matchName: (
          <div>
            <div>{match?.match_name}</div>
            <div>{moment(match?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        winTeam: match?.winTeam,
        amount: (
          <div className={netPL >= 0 ? "approved-color" : "red-clr"}>
            {netPL}
          </div>
        ),
      };
    });
  const handleReportReferral = (name, id) => {
    setInvidiualReportReferralColumns(invidiualReportReferral);
    setInvidiualReportReferralData(indReportReferralData);
    setReportReferralDataStatus(true);
    setRefClientName(name);
    setRefClientId(id);
  };
  const clientData =
    onePageReportData?.length > 0 &&
    onePageReportData?.map((report) => ({
      client_name: report?.client_name,
      amount: (
        <div
          className={`${
            report?.totalLossOrProfit >= 0 ? "approved-color" : "red-clr"
          }`}
        >
          {report?.totalLossOrProfit
            ? report?.totalLossOrProfit?.toFixed(2)
            : 0}
        </div>
      ),
      onClick: () =>
        handleClientName(
          report?.client_name,
          report?.client_id,
          report?.totalLossOrProfit
        ),
    }));
  const individualReportReferralData =
    refData &&
    refData?.length > 0 &&
    refData?.map((ref) => {
      return {
        referral_id: ref?.referral_name,
        amount: (
          <div
            className={ref?.total_profit_or_loss >= 0 ? "clr-green" : "clr-red"}
          >
            {ref?.total_profit_or_loss
              ? ref?.total_profit_or_loss?.toFixed(2)
              : 0}
          </div>
        ),
        onClick: () => handleReferralId(ref?.referral_name, ref?.refferal_id),
      };
    });

  const individualReportULShareData =
    completeMatchesData &&
    completeMatchesData?.length > 0 &&
    completeMatchesData?.map((match) => {
      const netAmount = +match?.totalAmount?.totalLossOrProfit;
      const netAfterUlShare = +((+netAmount * +ulShare) / 100);
      return {
        matchName: (
          <div>
            <div>{match?.match_name}</div>
            <div>{moment(match?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        ULnetPL: (
          <div className={+netAmount >= 0 ? "approved-color" : "red-clr"}>
            {netAmount ? netAmount?.toFixed(2) : 0}
          </div>
        ),
        winTeam: match?.winTeam,
        afterAllSharePL: (
          <div className={+netAmount >= 0 ? "red-clr" : "approved-color"}>
            {netAfterUlShare ? -netAfterUlShare?.toFixed(2) : 0}
          </div>
        ),
      };
    });

  const individualReportPlatformCommData =
    completeMatchesData &&
    completeMatchesData?.length > 0 &&
    completeMatchesData?.map((match) => {
      const netAmount = +match?.totalAmount?.totalLossOrProfit;
      return {
        matchName: (
          <div>
            <div>{match?.match_name}</div>
            <div>{moment(match?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        netPL: (
          <div className={+netAmount >= 0 ? "approved-color" : "red-clr"}>
            {netAmount ? netAmount?.toFixed(2) : 0}
          </div>
        ),
        winTeam: match?.winTeam,
        individualReportPlatformComm: (
          <div
            className={
              +match?.totalAmount?.platformCommission >= 0
                ? "approved-color"
                : "red-clr"
            }
          >
            {match?.totalAmount?.platformCommission
              ? match?.totalAmount?.platformCommission?.toFixed(2)
              : 0}
          </div>
        ),
      };
    });

  const individualReportClientColumns = [
    { header: "matchName", name: "Match Name" },
    { header: "winTeam", name: "Win Team" },
    { header: "amount", name: "Net P/L" },
  ];
  const individualReportClientData =
    indivisualMatchReportData &&
    indivisualMatchReportData?.length > 0 &&
    indivisualMatchReportData?.map((match) => {
      return {
        matchName: (
          <div>
            <div>{match?.match_name}</div>
            <div>{moment(match?.matchTimeStamp).format("DD-MM-YYYY")}</div>
          </div>
        ),
        netPL: 1000000.0,
        winTeam: match?.winTeam,
        amount: (
          <div
            className={
              match?.totalLossOrProfit >= 0 ? "approved-color" : "red-clr"
            }
          >
            {match?.totalLossOrProfit
              ? match?.totalLossOrProfit?.toFixed(2)
              : 0}
          </div>
        ),
      };
    });

  const individualReferralName = [
    { header: "referral_name", name: "Client Name" },
    { header: "amount", name: "Total P/L" },
  ];
  const individualRefferralData =
    clientsDataForRefferal &&
    clientsDataForRefferal?.length > 0 &&
    clientsDataForRefferal?.map((client) => {
      return {
        referral_name: client?.client_name,
        amount: (
          <div
            className={client?.referralTotalNet >= 0 ? "green-clr" : "red-clr"}
          >
            {client?.referralTotalNet
              ? client?.referralTotalNet?.toFixed(2)
              : 0}
          </div>
        ),
        onClick: () =>
          handleReportReferral(client?.client_name, client?.client_id),
      };
    });

  const [active, setActive] = useState(true);
  const [columns, setColumns] = useState(clientColumns);
  const [data, setData] = useState(clientData);
  const [individualColumns, setIndividualColumns] = useState(
    individualReportClientColumns
  );

  const onePageReportColumns = [
    {
      header: "referralComission",
      name: (
        <div>
          <div>C Name</div>
          <div>M+F+RC</div>
        </div>
      ),
    },
    { header: "clientShare", name: "C Net P/L" },
    { header: "referalShare", name: "Rf Net P/L" },
    { header: "masterProfitloss", name: "Total P/L" },
  ];

  const handleIndividualReport = (name) => {
    setShowClientTable(false);
    if (name === "clients") {
      setColumns(clientColumns);
      setData(clientData);
      setReportReferralDataStatus(false);
    } else if (name === "refferrels") {
      setColumns(individualReportReferralColumns);
      setData(individualReportReferralData);
    } else if (name === "ulSharePl") {
      setColumns(individualReportULShareColumn);
      setData(individualReportULShareData);
      setReportReferralDataStatus(false);
    } else if (name === "platformCommPl") {
      setColumns(individualReportPlatformCommColumns);
      setData(individualReportPlatformCommData);
      setReportReferralDataStatus(false);
    }
  };
  const IndividualPLReportsColumns = [
    { header: "clients", name: "Clients", onClick: handleIndividualReport },
    {
      header: "refferrels",
      name: "Refferals",
      onClick: handleIndividualReport,
    },
    {
      header: "ulSharePl",
      name: "UL Share P/L",
      onClick: handleIndividualReport,
    },
    {
      header: "platformCommPl",
      name: "Platform comm P/L",
      onClick: handleIndividualReport,
    },
  ];
  const onePageReportdata1 =
    onePageReportData?.length > 0 &&
    onePageReportData?.map((report) => {
      const totalAmountAfterCommission =
        parseFloat(report?.amount || 0) +
        parseFloat(report?.clientComission || 0);
      const clienPL =
        parseFloat(report?.amount || 0) +
        parseFloat(report?.clientShare || 0) +
        parseFloat(report?.clientComission || 0);
      const rfNet =
        parseFloat(report?.referalShare || 0) +
        parseFloat(report?.referralComission || 0);
      return {
        referralComission: (
          <div
            className="client-name-role-container mb-5 mt-5"
            onClick={() => {
              getIndividualPLReport(report?.client_id);
              setShowClientTable(true);
              setInduvisualClientName(report?.client_name);
            }}
          >
            <div>{report?.client_name}</div>
            <div
              className={
                totalAmountAfterCommission >= 0 ? "green-clr" : "red-clr"
              }
            >
              {totalAmountAfterCommission
                ? totalAmountAfterCommission?.toFixed(2)
                : 0}
            </div>
          </div>
        ),
        clientShare: (
          <div className={clienPL >= 0 ? "green-clr" : "red-clr"}>
            {clienPL ? clienPL?.toFixed(2) : 0}
          </div>
        ),
        referalShare: (
          <div className={rfNet >= 0 ? "green-clr" : "red-clr"}>
            {rfNet ? rfNet?.toFixed(2) : 0}
          </div>
        ),
        masterProfitloss:
          (
            <div
              className={
                report?.totalLossOrProfit >= 0 ? "green-clr" : "red-clr"
              }
            >
              {report?.totalLossOrProfit
                ? report?.totalLossOrProfit?.toFixed(2)
                : 0}
            </div>
          ) || 0,
      };
    });
  const handleindividualPLModalStatus = () => {
    setActive(false);
  };
  const handleOnePageReport = () => {
    setActive(true);
  };

  const onePageIndidvidualPLReportButtons = (
    <div>
      <button
        className={`common-active-css ${
          active ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleOnePageReport()}
      >
        One Page Report
      </button>
      <button
        className={`common-active-css ${
          active ? "inactive-css" : "active-css"
        }`}
        onClick={() => handleindividualPLModalStatus()}
      >
        Individual P/L Report
      </button>
      <div className="hr-line"></div>
    </div>
  );

  const handleCloseTable = () => {
    setShowClientTable(false);
  };
  const individualClientNameData = (
    <div>
      <button
        className={`common-active-css inactive-css font-12 individual-referral-name
  `}
      >
        {clientName} &nbsp; - &nbsp;&nbsp;
        <span
          className={netPLInduvisualClient >= 0 ? "approved-color" : "red-clr"}
        >
          {netPLInduvisualClient ? netPLInduvisualClient?.toFixed(2) : 0}
        </span>
      </button>
      <IonIcon
        icon={closeOutline}
        className="close-outline-icon"
        onClick={() => handleCloseTable()}
      />
      <div className="hr-line"></div>
    </div>
  );

  const individualReferralNameData = (
    <div>
      <button
        className={`common-active-css inactive-css font-12 individual-referral-name
  `}
      >
        Ref - {refName}
      </button>
      <IonIcon
        icon={closeOutline}
        className="close-outline-icon"
        onClick={() => handleCloseTable()}
      />
      <div className="hr-line"></div>
    </div>
  );
  const invidiualReportReferralHeading = (
    <div>
      <button
        className={`common-active-css inactive-css font-12 individual-referral-name
`}
      >
        Client - {refClientName} Match Wise Rf P/L
      </button>
      <IonIcon
        icon={closeOutline}
        className="close-outline-icon"
        onClick={() => handleCloseTable()}
      />
      <div className="hr-line"></div>
    </div>
  );

  const getOnePageReportData = async () => {
    await call(GET_ONEPAGE_REPORT, { register_id })
      .then((res) => {
        setOnePageReportData(res?.data?.data?.client_object);
      })
      .catch((err) => console.log(err));
  };

  const getIndividualPLReport = async (clientId) => {
    await call(GET_COMPLETED_MATCHES_BY_CLEINT, {
      register_id,
      account_role,
      client_id: clientId,
    })
      .then((res) => {
        setIndividualClientData(res?.data?.data);
        setInduvisualClientStatus((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const getRefferalData = async () => {
    await call(GET_REFFERAL_DATA, { register_id })
      .then((res) => {
        setRefData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getCompleteMatches = async () => {
    await call(GET_FINANCIAL_STATEMENT_BY_DATE, {
      register_id,
      account_role,
    })
      .then((res) => {
        setCompleteMatchesData(
          res?.data?.data?.filter((item) => item?.match_declared === "Y")
        );
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getOnePageReportData();
    getCompleteMatches();
    getRefferalData();
  }, []);
  
  useEffect(() => {
    setIndividualPLReportsButtons(individualClientNameData);
  }, [clientName, netPLInduvisualClient]);

  useEffect(() => {
    setIndividualPLReportsButtons(individualReferralNameData);
  }, [refId]);

  useEffect(() => {
    setInduvisualClientRef(invidiualReportReferralHeading);
  }, [refClientId, refClientName]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <MeetingsHeader heading="One Page Report" />
      {/* {onePageIndidvidualPLReportButtons} */}
      <div className="offline-page-container mt-10">
        <Table
          columns={active ? onePageReportColumns : IndividualPLReportsColumns}
          data={active ? onePageReportdata1 : [""]}
          buttons={onePageIndidvidualPLReportButtons}
        />
        {active ? (
          <div>
            {/* <div className="scroll-onepage">
              <Table
                columns={
                  active ? onePageReportColumns : IndividualPLReportsColumns
                }
                data={active ? onePageReportdata1 : [""]}
              />
            </div>
            <table className="w-100 account-summary-main-container mt-5">
              <tr>
                <th>Total</th>
                <th>5000</th>
                <th>6000</th>
                <th>6000</th>
              </tr>
            </table> */}
            <MatchFancyRLComm
              data={individualClientData}
              induvisualClientStatus={induvisualClientStatus}
              clientName={induvisualClientName}
            />
          </div>
        ) : (
          <div>
            <IndividualPLReport
              individualPLReportButtons={IndividualPLReportsButtons}
              columns={columns || clientColumns}
              data={data || clientData}
              individualReportClientData={
                clientId ? individualReportClientData : individualRefferralData
              }
              individualColumns={individualColumns}
              showClientTable={showClientTable}
              invidiualReportReferralData={invidiualReportReferralData}
              invidiualReportReferralColumns={invidiualReportReferralColumns}
              invidiualReportReferralHeading={induvisualClientRef}
              reportReferralDataStatus={reportReferralDataStatus}
              setIndivisualMatchReportData={setIndivisualMatchReportData}
              setClientsDataForRefferal={setClientsDataForRefferal}
              clientId={clientId}
              refId={refId}
              refClientId={refClientId}
            />
          </div>
        )}
      </div>
    </IonPage>
  );
};
export default OnePageReport;
