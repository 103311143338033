import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { ImArrowDown2, ImArrowUp2 } from "react-icons/im";

function TeamIntrested() {
  const INTRESTED_TEAM_DETAILS = [
    {
      id: 1,
      sNo: 1,
      date: "14-07-2023",
      time: "15:52:00 PM",
      name: "Srinivas3456",
      role: "S M + 20",
    },
    {
      id: 2,
      sNo: 2,
      date: "14-07-2023",
      time: "15:52:00 PM",
      name: "Srinivas3456",
      role: "S M + 20",
    },
    {
      id: 2,
      sNo: 3,
      date: "14-07-2023",
      time: "15:52:00 PM",
      name: "Srinivas3456",
      role: "S M + 20",
    },
  ];
  return (
    <IonGrid>
      <IonRow className="mt-10">
        <IonCol size="6">
          <div className="flex-center w-70 custom-box-shadow font-14 br-15 p-5">
            Name & Role
          </div>
        </IonCol>
        <IonCol size="6">
          <div className="flex-center w-70 custom-box-shadow font-14 br-15 p-5">
            Interested
          </div>
        </IonCol>
      </IonRow>
      <div className="custom-box-shadow br-10 p-5 mt-10">
        <IonRow>
          <IonCol size="3">
            <div className="font-12">Srinivash</div>
          </IonCol>
          <IonCol size="4.5">
            <div className="flex-center button-box-shadow font-12 p-5 br-5">
              Users = 5200 <ImArrowDown2 className="tour-arrow-icon ml-5" />
            </div>
          </IonCol>
          <IonCol size="4.5">
            <div className="flex-center button-box-shadow font-12 p-5 br-5">
              Admins = 5200 <ImArrowUp2 className="tour-arrow-icon ml-5" />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="flex-space-between">
          <IonCol size="4">
            <div className="w-70 flex-center custom-box-shadow br-5 p-5 font-12">
              Sub Admin
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="font-12 flex-center">Ad/Users 5000</div>
          </IonCol>
        </IonRow>
      </div>
      <div className="custom-box-shadow br-10 p-5 mt-10">
        <IonRow>
          <IonCol size="4">
            <div className="w-50 flex-center custom-box-shadow p-5 br-15 font-12">
              S.No
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="flex-center custom-box-shadow p-5 br-15 font-12">
              Date & Time
            </div>
          </IonCol>
          <IonCol size="4">
            <div className="flex-center custom-box-shadow p-5 br-15 font-12">
              Name & Role
            </div>
          </IonCol>
        </IonRow>
        {INTRESTED_TEAM_DETAILS?.map((item, index) => (
          <div key={index}>
            <IonRow className="mt-10">
              <IonCol size="4">
                <div className="w-50 flex-center font-12">{item.sNo}</div>
              </IonCol>
              <IonCol size="4">
                <div className="flex-center flex-column font-12">
                  <div>{item.date}</div>
                  <div>{item.time}</div>
                </div>
              </IonCol>
              <IonCol size="4">
                <div className="flex-center flex-column font-12">
                  <div>{item.name}</div>
                  <div>{item.role}</div>
                </div>
              </IonCol>
            </IonRow>
            <hr className="hr-line2 mt-5 mb-5" />
          </div>
        ))}
      </div>
    </IonGrid>
  );
}

export default TeamIntrested;
