import { IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import Table from "../../common/Table";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { GET_SETTLEMENT_HISTORY } from "../../config/endpoints";
import { call } from "../../config/axios";

function SettlementStatement() {
  // Updated function name
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");

  const [settlementHistory, setSettlementHistory] = useState([]);

  const settlementStatementColumns = [
    {
      header: "client_name",
      name: "C Name",
    },
    {
      header: "till_day_balance",
      name: "Till Day Bal",
    },
    {
      header: "settled_amount",
      name: "Settled Amount",
    },
    {
      header: "pending_amount",
      name: "Balance",
    },
  ];
  const totalTillDayBalance =
    settlementHistory &&
    settlementHistory?.length > 0 &&
    settlementHistory?.reduce(
      (acc, obj) => acc + (+obj?.till_day_balance || 0),
      0
    );
  const totalSettleAmount =
    settlementHistory &&
    settlementHistory?.length > 0 &&
    settlementHistory?.reduce(
      (acc, obj) => acc + (+obj?.settled_amount || 0),
      0
    );
  const totalPendingAmount =
    settlementHistory &&
    settlementHistory?.length > 0 &&
    settlementHistory?.reduce(
      (acc, obj) => acc + (+obj?.pending_amount || 0),
      0
    );

  const settlementStatementData =
    settlementHistory.length > 0 &&
    settlementHistory?.map((settlementData) => ({
      client_name: (
        <div className="settlemt-statement-client-data">
          <div>{settlementData?.client_name}</div>
          <div>Client</div>
          <div>Phonepe</div>
          <div>{settlementData?.date}</div>
          <div>{settlementData?.time}</div>
        </div>
      ),
      till_day_balance: (
        <div
          className={
            settlementData?.till_day_balance >= 0 ? "approved-color" : "red-clr"
          }
        >
          {settlementData?.till_day_balance
            ? settlementData?.till_day_balance?.toFixed(2)
            : 0}
        </div>
      ),
      settled_amount: (
        <div
          className={
            settlementData?.settled_amount >= 0 ? "approved-color" : "red-clr"
          }
        >
          {-settlementData?.settled_amount
            ? settlementData?.settled_amount.toFixed(2)
            : 0}
        </div>
      ),
      pending_amount: (
        <div
          className={
            settlementData?.pending_amount >= 0 ? "approved-color" : "red-clr"
          }
        >
          {settlementData?.pending_amount
            ? settlementData?.pending_amount.toFixed(2)
            : 0}
        </div>
      ),
    }));

  const getSettlementStatement = async () => {
    await call(GET_SETTLEMENT_HISTORY, {
      register_id,
    })
      .then((res) => {
        if (res?.data?.statusCode == 200) {
          setSettlementHistory(res?.data?.data?.Items);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  useEffect(() => {
    getSettlementStatement();
  }, []);

  return (
    <IonPage>
      <MeetingsHeader heading="Settlement-Statement" />
      <div className="offline-page-container mt-10">
        <div className="settlement-table">
          <Table
            columns={settlementStatementColumns}
            data={settlementStatementData}
          />
        </div>
        <table className="w-100 account-summary-main-container mt-5">
          <tfoot>
            <tr>
              <th>Total</th>
              <th className={totalTillDayBalance > 0 ? "clr-green" : "clr-red"}>
                {totalTillDayBalance || 0}
              </th>
              <th className={totalSettleAmount > 0 ? "clr-green" : "clr-red"}>
                {totalSettleAmount || 0}
              </th>
              <th className={totalPendingAmount > 0 ? "clr-green" : "clr-red"}>
                {totalPendingAmount || 0}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </IonPage>
  );
}

export default SettlementStatement;
