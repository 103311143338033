import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";

function MatchCommissionPopup(props) {
  const { handleMatchCommissionPopupClose, data, totalMatchResultData } = props;
  const MATCH_COMMISSION_DATA = [
    {
      name: "Srinivas2346",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulShare: "- -",
    },
    {
      name: "Srinivas2346",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulShare: "- -",
    },
    {
      name: "Srinivas2346",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulShare: "- -",
    },
    {
      name: "Srinivas2346",
      clientComm: 10000000.0,
      rfComm: 10000000.0,
      ulShare: "- -",
    },
  ];
  return (
    <IonGrid className="fancy-commission-popup-pos meetings-container w-75 match-share-popup">
      <div className="custom-box-shadow  fancy-meetings-popups-div">
        <div
          className="flex-flex-end fancy-close-icon"
          onClick={() => handleMatchCommissionPopupClose()}
        >
          <RxCross2 />
        </div>
        <IonRow className="flex-space-between w-100 pr-5 pl-5">
          <IonCol
            size="4"
            className="br-15 custom-box-shadow flex-center flex-column font-10"
          >
            <div>Name</div>
            {/* <div>Client Comm</div> */}
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            C Comm
          </IonCol>
          <IonCol
            size="3"
            className="br-15 custom-box-shadow flex-center  font-10"
          >
            Rf Comm
          </IonCol>
        </IonRow>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {data?.map((client, index) => (
            <div key={index} className="flex-row">
              <IonRow className="flex-space-between w-100 align-end mt-5 pl-10 pr-10">
                <IonCol size="3" className="meetings-time font-2">
                  <div>{client?.client}</div>
                  {/* <div
                    className={
                      client?.clientComm >= 0 ? "green-clr" : "red-clr"
                    }
                  >
                    {client?.clientComm?.toFixed(2)}
                  </div> */}
                </IonCol>
                <IonCol
                  size="3"
                  className={
                    client?.clientComm >= 0
                      ? "green-clr font-12 w-25 text-center"
                      : "red-clr font-12 w-25 text-center"
                  }
                >
                  {client?.clientComm ? client?.clientComm?.toFixed(2) : 0}
                </IonCol>
                <IonCol
                  size="3"
                  className={
                    client?.rfComm >= 0
                      ? "green-clr font-12 w-25 text-center"
                      : "red-clr font-12 w-25 text-center"
                  }
                >
                  {client?.rfComm ? client?.rfComm?.toFixed(2) : 0}
                </IonCol>
              </IonRow>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
        </div>
        <IonRow className="flex-space-between align-end mt-5 pl-10 pr-10 fancy-total-row">
          <IonCol size="4" className="meetings-time font-2">
            <div className="font-14 fw-600">Total</div>
          </IonCol>
          <IonCol
            size="3.5"
            className={
              totalMatchResultData?.clientComm >= 0
                ? "green-clr font-12 w-25 text-center"
                : "red-clr font-12 w-25 text-center"
            }
          >
            <div
              className={
                totalMatchResultData?.clientComm >= 0 ? "green-clr" : "red-clr"
              }
            >
              {totalMatchResultData?.clientComm ? totalMatchResultData?.clientComm?.toFixed(2) : 0}
            </div>
          </IonCol>
          <IonCol
            size="3"
            className={
              totalMatchResultData?.rfComm >= 0
                ? "green-clr font-12 w-25"
                : "red-clr font-12 w-25"
            }
          >
            {totalMatchResultData?.rfComm ? totalMatchResultData?.rfComm?.toFixed(2) : 0}
          </IonCol>
        </IonRow>
        {/* <IonRow className="flex-space-between align-end mt-5 pl-10 pr-10 fancy-total-row">
          <IonCol size="4"></IonCol>
          <IonCol size="4" className="meetings-time font-2">
            <div className="font-14 fw-600">Total</div>
            <div
              className={
                totalMatchResultData?.clientComm >= 0 ? "green-clr" : "red-clr"
              }
            >
              {totalMatchResultData?.clientComm?.toFixed(2)}
            </div>
          </IonCol>
          <IonCol
            size="4"
            className={
              totalMatchResultData?.rfComm >= 0
                ? "green-clr font-12 w-25 text-center"
                : "red-clr font-12 w-25 text-center"
            }
          >
            {totalMatchResultData?.rfComm?.toFixed(2)}
          </IonCol>
         
        </IonRow> */}
      </div>
    </IonGrid>
  );
}

export default MatchCommissionPopup;
