import { IonModal, useIonToast } from "@ionic/react";
import { Images } from "../../images";
import { FaCalendarDays } from "react-icons/fa6";
import { GoArrowSwitch } from "react-icons/go";
import EditPopup from "../popups/EditPopup";
import { useEffect, useState } from "react";
import {
  DELETE_RECORDING,
  GET_ALL_MEETINGS,
  GET_RECORDING_PUBLIC_URL,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";
import { RxCross1 } from "react-icons/rx";

function CallHistory() {
  const [history, setHistoryData] = useState([]);
  const [recordingData, setRecordingData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [delectFileInfo, setDelectFileInfo] = useState("");
  const [present] = useIonToast();

  const register_id = localStorage.getItem("register_id");

  const getAllHistory = async () => {
    await call(GET_ALL_MEETINGS, { register_id })
      .then((res) => {
        setHistoryData(res?.data?.data?.reverse());
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllHistory();
  }, []);
  const formatDuration = (seconds) => {
    const hours = isNaN(Math.floor(seconds / 3600))
      ? 0
      : Math.floor(seconds / 3600);
    const minutes = isNaN(Math.floor((seconds % 3600) / 60))
      ? 0
      : Math.floor((seconds % 3600) / 60);
    const secondsLeft = isNaN(seconds % 60) ? 0 : seconds % 60;

    return `${hours}:${minutes}:${secondsLeft}`;
  };

  const callHisoryData = history
    ?.filter((obj) => {
      const objDate = moment(obj.date);
      const isWithinDateRange =
        (!startDate || objDate.isSameOrAfter(moment(startDate))) &&
        (!endDate || objDate.isSameOrBefore(moment(endDate)));
      return obj?.p_id === register_id && isWithinDateRange;
    })
    ?.sort((a, b) => b?.created_time_stamp - a?.created_time_stamp)
    ?.map((obj) => {
      const duration = obj.recording_stopped_time - obj.recording_started_time;
      const formattedDuration = formatDuration(duration);
      const filename = obj.recoding_taskId + "_" + obj.meeting_id;
      return {
        date: moment(obj.date).format("DD-MM-YYYY"),
        time: obj.time,
        meetingTitle: (
          <div>
            <div>{obj?.event_name}</div>
            <div>{obj.match_name}</div>
            <div>{obj?.meeting_type}</div>
          </div>
        ),
        estimatedTime: formattedDuration || 0,
        fileUrl: obj.fileUrl,
        filename: filename,
      };
    });

  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };

  const handleOpenDeletePopup = (value) => {
    setDeletePopup(!deletePopup);
    setDelectFileInfo({
      fileUrl: value.fileUrl?.[0],
      meeting_id: value.meeting_id,
    });
  };

  const onPlayBtnClick = async (filename) => {
    await call(GET_RECORDING_PUBLIC_URL, { filename: filename })
      .then((res) => {
        setRecordingData(res?.data?.body);
      })
      .catch((err) => console.log(err));
  };

  const onDeleteClick = async () => {
    const { meeting_id, fileUrl } = delectFileInfo;
    await call(DELETE_RECORDING, { fileUrl, register_id, meeting_id })
      .then((res) => {
        if (res.data.statusCode === 200) {
          presentToast({ message: res.data.message, color: "success" });
          getAllHistory();
          setDelectFileInfo("");
        } else {
          presentToast({ message: "Something went wrong" });
          setDelectFileInfo("");
        }
      })
      .catch((err) => console.log(err));
    setDelectFileInfo("");
  };

  console.log(history, "===>history");

  return (
    <div className="main-container p-10">
      <div className="flex-row flex relative-position">
        <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow h-40 p-20 mr-5">
          <input
            required
            type="date"
            placeholder="Start Date"
            className="flex-center w-80 match-input"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <FaCalendarDays className="font-size-20 pink-clr" />
        </div>
        <GoArrowSwitch className="double-arrow-icon-match left-absolute-position" />
        <div className="management-div w-vw-40 font-12 flex-center custom-box-shadow  h-40 p-20 ml-5">
          <input
            required
            type="date"
            placeholder="End Date"
            className="flex-center w-80 match-input"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <FaCalendarDays className="font-size-20 pink-clr" />
        </div>
      </div>
      <div>
        <div className="flex-space-between mt-20 font-12">
          <div className="meetings-button custom-box-shadow flex-center w-30 mr-5">
            Start Date&Time
          </div>
          <div className="meetings-button custom-box-shadow flex-center w-30 mr-5">
            Meeting Title
          </div>
          <div className="meetings-button custom-box-shadow flex-center w-20 mr-5">
            Duration
          </div>
          <div className="meetings-button custom-box-shadow flex-center w-20">
            Price
          </div>
        </div>
        <div className="scroll-call-history">
          {callHisoryData?.map((value, index) => (
            <div
              className="flex-space-between custom-box-shadow meetings-details-div mt-10"
              key={index}
            >
              <div className="flex-space-around flex-column w-20">
                <div className="flex-space-evenly flex-column custom-box-shadow meetings-date-time-div w-100 ml-10 mt-10">
                  <div className="meetings-date">{value.date}</div>
                  <div className="meetings-time">{value.time}</div>
                </div>
                <div className="flex-space-around flex-row w-100 mt-5 mb-10 ml-5">
                  <div className="flex-center play-delete-div">
                    <img
                      className="play-delete-icon"
                      src={Images.PlayIcon}
                      alt="Play"
                      onClick={() => onPlayBtnClick(value.filename)}
                    />
                  </div>
                  <div
                    className="flex-center play-delete-div"
                    onClick={() => handleOpenDeletePopup(value)}
                  >
                    <img
                      className="play-delete-icon"
                      src={Images.DeleteIcon}
                      alt="Delete"
                    />
                  </div>
                </div>
              </div>

              <div className="meetings-time ml-30 mr-10 w-30 padding-tb font-12">
                {value.meetingTitle}
              </div>
              <div className="custom-box-shadow meetings-date meetings-time-text font-12 clr-text">
                {value.estimatedTime}
              </div>

              <div className="flex-column flex-space-between play-btn-div w-20">
                <div className="font-12 mt-40">{value.price}</div>
                <div className="font-12 w-100 complete-text flex-center">
                  {value?.fileUrl?.length ? "Complete" : "Expired"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {recordingData && (
        <IonModal className="statememt-popup-main w-100" isOpen={true}>
          <center>
            <div
              className="w-100 flex-flex-end p-5"
              onClick={() => setRecordingData("")}
            >
              <RxCross1 />
            </div>
            <video height="300" width={"100%"} controls autoPlay>
              <source src={recordingData} type="video/ogg" />
            </video>
          </center>
        </IonModal>
      )}
      <EditPopup
        openPopup={deletePopup}
        setclosePopup={() => {
          setDeletePopup(!deletePopup);
          setDelectFileInfo("");
        }}
        setSelectedId={() => setDelectFileInfo("")}
        deletedId={true}
        deletedApi={onDeleteClick}
        displayData={"Are You Sure You Want To delete This Data"}
      />
    </div>
  );
}

export default CallHistory;
