import React,{useEffect, useState, useRef} from "react";
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { setOngoingMeeting } from "../../redux/actions/dataActions";

import { call } from "../../config/axios";
import {
  GET_ALL_CLIENTS,
  GET_USER_INFO,
  GET_LIVE_MEETING,
  SIGNAL_RECORDING,
  GET_ADMIN_PACKAGES,
} from "../../config/endpoints";
import "./styles.css";
import { Images } from "../../images";
import { setLoading } from "../../redux/actions";
import {
  setMeetingStartStatus,
  setUserJoinClick,
} from "../../redux/actions/dataActions";
import { FaMicrophone, FaVideo,FaVideoSlash,FaMicrophoneSlash } from "react-icons/fa";

const Join = ({id, UserJoinClick}) => {
  const { meetingId, creatorId } = useParams();
    const history = useHistory();
     const dispatch = useDispatch();
    const inputref = useRef(null);
   const [micOn, setMicOn] = useState(true);
   const [camOn, setCamOn] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [adminSubscription, setAdminSubscription] = useState([]);
   const [profileImage, setProfileImage] = useState("");
    const [inputValue, setInputValue] = useState("");
   const [liveMeeting, setLiveMeeting] = useState({});
   const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";

  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const register_id = localStorage.getItem("register_id");

    const getLiveMeetingData = async () => {
      const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
      return await call(GET_LIVE_MEETING, {
        creator_id: creator_id,
        meeting_id: id,
      })
        .then((res) => {
            console.log("res",res)
          setLiveMeeting(res.data?.data?.Items?.[0]);
        })
        .catch((err) => {
          throw err;
        });
    };

    const getAdminSubscription = async () => {
      return await call(GET_ADMIN_PACKAGES, {
        register_id: register_id,
      })
        .then((res) => {
            console.log("getAdminSubscription", res);
            const subscriptions = res?.data?.data?.flatMap(item => item?.subscriptions?.flatMap(sub => sub.Items));
            setAdminSubscription(subscriptions);
            console.log("subscriptions", subscriptions);
        })
        .catch((err) => {
          throw err;
        });
    
  };
    useEffect(() => {

        if(isAdminCreated){
           getAdminSubscription();
        }
       
        getLiveMeetingData();
        
    }, [id]);

    const getProfile = async (id) => {
    try {
      const res = await call(GET_USER_INFO, { register_id });
      const userProfileData = res?.data?.data[0];
        console.log('userProfileData',userProfileData)
        setProfileImage(userProfileData?.profile_image || "");
    } catch (err) {
      console.log(err);
    }
  };
    
  useEffect(() => {
    getProfile(register_id);
  }, [register_id]);


    const handleOpenJoinPopup = async () => {
        console.log('data',liveMeeting)
        console.log('adminSubscription',adminSubscription)
      const package_data = adminSubscription &&
       adminSubscription?.length > 0 &&
        adminSubscription
          ?.filter((item) => liveMeeting?.package_id === item?.package_id);
          let duration = package_data[0]?.package_limits?.duration;
          let [hoursStr, minutesStr] = duration?.split(":") || [];
          if (hoursStr?.includes('hr')) {
            hoursStr = hoursStr.replace(' hr', '');
            minutesStr = '00';
          }
          const hours = parseInt(hoursStr);
          const minutes = parseInt(minutesStr);
          const totalMinutes = hours * 60 + minutes;
          console.log('package_data',package_data)
    if (totalMinutes < 0) {
      toast.error("Insuffient package hrs, Please upgarde hrs!");
      return;
    }
    
    if (package_data[0]) {
      const currentTime = new Date();
      const startTimeStr = currentTime.toISOString();
      localStorage.setItem('startTime', startTimeStr);
       localStorage.setItem("meeting_id", liveMeeting?.meeting_id);
      localStorage.setItem("totalMinutes", totalMinutes);
      localStorage.setItem("isAdminMeeting", true);
      
           console.log(inputref.current.value);
           let obj = {
                meeting_id: id,
                user_name: inputref.current.value,
                type: "join_meeting",
                user_id: localStorage.getItem("register_id"),
                creator_id: liveMeeting?.p_id,
                meeting_type: liveMeeting?.meeting_type,
                camera: camOn,
                micro: micOn,
                videoType : liveMeeting.video_call_type

           }
              dispatch(setOngoingMeeting(obj));
                history.push(`/meeting/${id}`);
                UserJoinClick(true);
           
           
    } else {
      toast.error("you don't have any subscription, Please upgarde !")
    }
  };
      
     

    return (
        <>
           <div className="join-container">
            <div className="img-join">
                <img src="../../assets/we_2_call_logo.png" alt="join" />
            </div>
                <div className="join-box">
                        <img src={profileImage || "../../assets/calling_profile_image.png"}  alt="" />
                        <div className="name-box">
                            <h3>{first_name} {last_name}</h3>
                            <p>inviting for video call...</p>
                        </div>
                        <div className="cam-micro">
                            <p> 

                              {liveMeeting?.video_call_type === "audio-video" ?
                              (
                                <>
                                {camOn ? <></> : <span>Camera is Off...</span>}
                                </>
                              ):(
                                <></>
                              )}
                                
                                {micOn ? <></> : <span>Microphone is Off...</span>}
                            </p>
                        </div>
                        <div className="mic-cam-icon">
                          {liveMeeting?.video_call_type === "audio-video" ?
                              (
                                <>
                                <div className={`box-mic ${camOn ? 'box-green' : 'box-red'}`}
                                 onClick={() => setCamOn(!camOn)}>
                                       {camOn ? <FaVideo /> : <FaVideoSlash />} 
                                </div>
                                </>
                              ):(
                                <></>
                              )}
                             
                            <div className={`box-mic ${micOn ? 'box-green' : 'box-red'}`}
                            onClick={
                                ()=> setMicOn(!micOn)
                            }>
                                  {micOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
                            </div>
                           
                        </div>
                        <div className="input-but">
                            <input 
                            type="text" 
                            ref={inputref}
                            onChange={(e) => setInputValue(e.target.value)} 
                            placeholder='Enter your name'/>
                            <button 
                            className='btn btn-join' 
                            disabled={inputValue === ""}  
                            onClick={()=> handleOpenJoinPopup()}>Join</button>
                        </div>
                </div>
           </div>
        </>

    );
}

export default Join;
