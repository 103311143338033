import React from "react";
import Table from "../../common/Table";
import { IonCol, IonRow } from "@ionic/react";

const ProfitLoss = ({puchaseSales}) => {
  const purchaseReport = [
    {
      name: "Buy Amt",
      price: 10000,
    },
    {
      name: "Sale Amt",
      price: <div className="clr-orng">150000</div>,
    },
    {
      name: "Net P/L",
      price: <div className="clr-green">50000</div>,
    },
  ];

  const columns = [
    {
      header: "channel_pkg",
      name: "Channel & Pkg",
    },
    { header: "purchase", name: "Purchase" },
    { header: "dis_t_amt", name: "Dis & T Amt" },
    { header: "p_amt_bal", name: "P Amt & Bal " },
  ];

  const userDetailsProfitLoss = [
    {
      channel_pkg: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">Live Stream </div>
          <div>30 days</div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>25</div>
          <div>Amt:100000</div>
        </div>
      ),
      dis_t_amt: (
        <div className="settlemt-statement-client-data">
          <div>Dis:20000</div>
          <div>T Amt :80000</div>
        </div>
      ),
      p_amt_bal: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P Amt: <span className="clr-orng">80000</span> </div>
          <div >Bal:0.00 </div>
        </div>
      ),
    },
    {
      channel_pkg: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">Live Stream </div>
          <div>90 days</div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>25</div>
          <div>Amt:100000</div>
        </div>
      ),
      dis_t_amt: (
        <div className="settlemt-statement-client-data">
          <div>Dis:20000</div>
          <div>T Amt :80000</div>
        </div>
      ),
      p_amt_bal: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P Amt: <span className="clr-orng">80000</span> </div>
          <div >Bal:0.00 </div>
        </div>
      ),
    },
   
    {
      channel_pkg: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">Line </div>
          <div>30 days</div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>25</div>
          <div>Amt:100000</div>
        </div>
      ),
      dis_t_amt: (
        <div className="settlemt-statement-client-data">
          <div>Dis:20000</div>
          <div>T Amt :80000</div>
        </div>
      ),
      p_amt_bal: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P Amt: <span className="clr-orng">80000</span> </div>
          <div >Bal:0.00 </div>
        </div>
      ),
    },
    {
      channel_pkg: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">Line </div>
          <div>30 days</div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>25</div>
          <div>Amt:100000</div>
        </div>
      ),
      dis_t_amt: (
        <div className="settlemt-statement-client-data">
          <div>Dis:20000</div>
          <div>T Amt :80000</div>
        </div>
      ),
      p_amt_bal: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P Amt: <span className="clr-orng">80000</span> </div>
          <div >Bal:0.00 </div>
        </div>
      ),
    },
    {
      channel_pkg: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">Line </div>
          <div>30 days</div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>25</div>
          <div>Amt:100000</div>
        </div>
      ),
      dis_t_amt: (
        <div className="settlemt-statement-client-data">
          <div>Dis:20000</div>
          <div>T Amt :80000</div>
        </div>
      ),
      p_amt_bal: (
        <div className="settlemt-statement-client-data">
          <div className="clr-white">P Amt: <span className="clr-orng">80000</span> </div>
          <div >Bal:0.00 </div>
        </div>
      ),
    },

  ];

  return (
    <>
    {puchaseSales==="Purchase" ? <div className="m-10">Purchase and Report</div>:<div className="m-10">Sales</div>}
      
      <div className="purchase-container br-10 d-flex mt-5 w-100 purchase-report">
        {purchaseReport.map((item, index) => (
          <div className="purchase-price-container br-10 m-5  ">
            <div className="text-center mt-5 pt-5">{item.name}</div>
            <div className="text-center mt-5 pt-5">{item.price}</div>
          </div>
        ))}
      </div>
      <Table columns={columns} data={userDetailsProfitLoss} />
      <div className="w-100 account-summary-main-container mt-5">
        <div>
        <IonRow className="w-100">
            <IonCol size="4">
             Amt <span className="p-3 action-btn action-btn-shadow br-10">800000</span>
            </IonCol>
            <IonCol size="4">
             Dis <span className="p-3 action-btn action-btn-shadow br-10">800000</span>
            </IonCol>
            <IonCol size="4">
             T Amt <span className=" action-btn action-btn-shadow br-10">800000</span>
            </IonCol>
           
          </IonRow>
          <IonRow className="w-100 mt-10">
            <IonCol size="6"> 
             P Amt <span className=" p-3 clr-orng action-btn action-btn-shadow br-10">80000</span>
            </IonCol>
            <IonCol size="6">
             Bal <span className="p-3 clr-red action-btn action-btn-shadow br-10">0.00</span>
             </IonCol>
           
          </IonRow>
          
        </div>
      </div>
    </>
  );
};

export default ProfitLoss;
