import { IonCol, IonGrid, IonRow, IonText, useIonToast } from "@ionic/react";
import { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { call } from "../../config/axios";
import { setRegisterUserInfo } from "../../redux/actions/commonActions";
import { ACCOUNT_REGISTERATION, UPDATE_PROFILE } from "../../config/endpoints";

function AdminCreation(props) {
  const {
    typeOfAdd,
    setRegistationPopup,
    setIsUserCreated,
    selectedUser,
    editStatus,
    setEditStatus,
    setSelectedUser,
  } = props;

  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [isProcessing, setIsProcessing] = useState(false);
  const [err, setErr] = useState("");
  const [inputData, setInputData] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const [isPackageSelect, setIsPackageSelect] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [showPackageDropdown, setShowPackageDropdown] = useState(false);
  const [activeIndex, setActiveIndex] = useState({
    password: true,
    confirm_password: true,
  });

  const [present] = useIonToast();
  const dispatch = useDispatch();

  const sportNameList = ["Trial"];

  const onChange = (e) => {
    let inputValue = e.target.value;
    if (e.target.name === "ul_share" && inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2);
    }
    setErr("");
    setInputData({
      ...inputData,
      [e.target.name]: e.target.type === "number" ? +inputValue : inputValue,
    });
  };
  const handlePasswordVisible = (obj) => {
    setActiveIndex((prev) => ({ ...prev, [obj]: !prev[obj] }));
  };
  const handleEyeicon = () => {
    setIsVisible((prev) => !prev);
  };
  const handlePackageDropdown = () => {
    setShowPackageDropdown((prev) => !prev);
  };
  const handleSelectedPackage = (value) => {
    if (editStatus === true) {
      setIsPackageSelect("Trial");
    }
    setIsPackageSelect(value);
    setShowPackageDropdown(false);
  };
  const handleUserRoles = () => {
    setUserRole((prev) => !prev);
  };
  const handleSelectUserRole = (item) => {
    setSelectedUserRole(item);
    setInputData({ ...inputData, account_role: item.value });
    setUserRole((prev) => !prev);
  };
  const handleSubmitUserCreation = async () => {
    if (
      !(
        inputData?.first_name &&
        inputData?.user_name &&
        inputData?.creator_password
      )
    ) {
      return setErr("Please enter required fields");
    }
    if (inputData?.password !== inputData?.confirm_password) {
      return setErr(`password doesn't match`);
    }
    if (+inputData?.share + +inputData?.ul_share > 100) {
      return setErr("Invalid shares");
    }
    const trailPack = {
      package_id: "8a147698-4f2b-42b6-a409-f56ad6065002",
      package_limits: {
        duration: "unlimited",
        members: 10,
        no_of_meetings: "5",
      },
    };
    const packInfo = isPackageSelect ? { trailPack } : {};
    setErr("");
    setIsProcessing(true);
    await call(ACCOUNT_REGISTERATION, {
      account_role: "client",
      ...inputData,
      ...packInfo,
      creator_id: register_id,
      creator_role: account_role,
      share: 100 - +inputData["ul_share"],
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.status === 201) {
          presentToast({ message: res.data.message, color: "success" });
          setRegistationPopup(true);
          dispatch(
            setRegisterUserInfo({
              role: inputData?.account_role
                ? inputData?.account_role
                : "client",
              user_name: inputData?.user_name,
              password: inputData?.password,
            })
          );
          setInputData({ creator_id: register_id, creator_role: account_role });
          setIsUserCreated((prev) => !prev);
        } else {
          presentToast({ message: res.data.message });
          setErr(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setErr(err?.message ? err?.message : `something wen't wrong`);
        console.log(err);
      });
  };
  const handleUserUpdate = async () => {
    if (
      !(
        inputData?.first_name &&
        inputData?.user_name &&
        inputData?.creator_password
      )
    ) {
      return setErr("Please enter required fields");
    }
    if (inputData?.password !== inputData?.confirm_password) {
      return setErr(`password doesn't match`);
    }
    if (!editStatus && +inputData?.share + +inputData?.ul_share > 100) {
      return setErr("Invalid shares");
    }
    const trailPack = {
      package_id: "8a147698-4f2b-42b6-a409-f56ad6065002",
      package_limits: {
        duration: "unlimited",
        members: 10,
        no_of_meetings: "5",
      },
    };
    const packInfo = trailPack ? { trailPack } : {};
    setErr("");
    setIsProcessing(true);
    await call(UPDATE_PROFILE, {
      ...inputData,
      ...packInfo,
      creator_id: register_id,
      creator_role: account_role,
      share: 100 - +inputData["ul_share"],
      account_role: selectedUser?.account_role,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.statusCode === 200) {
          setEditStatus(false);
          setSelectedUser("");
          presentToast({ message: res.data.message, color: "success" });
          setRegistationPopup(true);
          dispatch(
            setRegisterUserInfo({
              role: inputData?.account_role
                ? inputData?.account_role
                : "client",
              user_name: inputData?.user_name,
              password: inputData?.password,
            })
          );
          setInputData({ creator_id: register_id, creator_role: account_role });
          setIsUserCreated((prev) => !prev);
        } else {
          presentToast({ message: res.data.message });
          setErr(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setErr(err?.message ? err?.message : `something wen't wrong`);
        console.log(err);
      });
  };
  const admininputs = [
    { size: "12", name: "first_name", placeholder: "Name" },
    { size: "6", name: "user_name", placeholder: "User Id" },
  ];
  const passwordCretion = [
    { name: "password", placeholder: "Password" },
    { name: "confirm_password", placeholder: "Confirm Password" },
  ];

  const sharePercentage = [
    { percent: "%", share: "Share" },
    { percent: "%", share: "My Share" },
  ];
  let userRoles = [
    { label: "Super Admin", value: "superadmin" },
    { label: "Admin", value: "admin" },
    { label: "Sub Admin", value: "subadmin" },
    { label: "Super Master", value: "supermaster" },
    { label: "Master", value: "master" },
    { label: "Agent", value: "agent" },
  ];
  const finIndex = userRoles.findIndex(
    (obj) => obj.value === localStorage.getItem("account_role")
  );
  userRoles =
    finIndex > -1 ? userRoles.slice(finIndex + 1, userRoles.length) : [];
  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };

  useEffect(() => {
    if (editStatus && selectedUser) {
      setInputData({ ...inputData, ...selectedUser });
    }
  }, [editStatus, selectedUser]);

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        {admininputs?.map((value, index) => {
          return (
            <IonCol key={`create_${index}`} size={value.size}>
              <div className="series-input flex-center custom-box-shadow">
                <input
                  value={inputData[value.name] || ""}
                  name={value.name}
                  onChange={onChange}
                  className="flex-center w-100 match-input"
                  placeholder={value.placeholder}
                ></input>
              </div>
            </IonCol>
          );
        })}
        <IonCol
          size="6"
          className={typeOfAdd === "Add Users" ? "disabled-div" : "h-45px"}
        >
          <div
            className="series-input flex-space-between custom-box-shadow"
            onClick={editStatus === true ? null : () => handleUserRoles()}
          >
            <div className="flex-center w-80 match-input pl-10">
              {selectedUser.account_role
                ? selectedUser.account_role
                : selectedUserRole.label
                ? selectedUserRole.label
                : "Role"}
            </div>
            <RiArrowDropDownLine className="dropdown-icon" />
          </div>
          {userRole && (
            <div className="select-team-dropdown h-150px">
              {userRoles?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="h-40 flex-center mb-5"
                    onClick={() => handleSelectUserRole(item)}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          )}
        </IonCol>
        {passwordCretion.map((obj, index) => {
          return (
            <IonCol key={index} size="6">
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  type={activeIndex[obj.name] ? "password" : "text"}
                  placeholder={obj.placeholder}
                  name={obj.name}
                  onChange={onChange}
                  value={inputData[obj.name] || ""}
                ></input>
                {activeIndex[obj.name] ? (
                  <AiFillEyeInvisible
                    className="dropdown-icon"
                    onClick={() => handlePasswordVisible(obj.name)}
                  />
                ) : (
                  <AiFillEye
                    className="dropdown-icon"
                    onClick={() => handlePasswordVisible(obj.name)}
                  />
                )}
              </div>
            </IonCol>
          );
        })}
        {typeOfAdd !== "Add Users" && (
          <>
            <IonCol size="12" className="flex-space-between">
              {sharePercentage?.map((obj, index) => {
                return (
                  <div
                    key={index}
                    className="upcoming-table-heading flex-center custom-box-shadow"
                  >
                    {obj.share}
                    <span className="persentage-div ml-10">{obj?.percent}</span>
                  </div>
                );
              })}
              <div className="upcoming-table-heading flex-center custom-box-shadow">
                Pl Comm
                <span className="persentage-div ml-10">1%</span>
              </div>
            </IonCol>

            <IonCol size="6">
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  placeholder="share"
                  name="share"
                  type="number"
                  value={100 - +inputData["ul_share"] || 100}
                  disabled
                ></input>
              </div>
            </IonCol>
            <IonCol size="6">
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  placeholder="Ul Share"
                  name="ul_share"
                  type="number"
                  value={inputData["ul_share"] || ""}
                  onChange={onChange}
                ></input>
              </div>
            </IonCol>
          </>
        )}

        {typeOfAdd === "Add Admins" ? (
          <IonRow className="w-100">
            <IonCol>
              <div
                className="series-input flex-space-between custom-box-shadow w-100"
                onClick={
                  editStatus === true ? null : () => handlePackageDropdown()
                }
              >
                <div className="pl-10">
                  {editStatus === true
                    ? "Trial"
                    : isPackageSelect
                    ? isPackageSelect
                    : "Package"}
                </div>
                <RiArrowDropDownLine className="dropdown-icon" />
              </div>
              {showPackageDropdown && (
                <div className="select-team-dropdown w-50 position-right font-12 h-150px">
                  {sportNameList.map((value, index) => (
                    <div
                      key={index}
                      className="h-40 flex-center mb-5"
                      onClick={() => handleSelectedPackage(value)}
                    >
                      {value}
                    </div>
                  ))}
                </div>
              )}
            </IonCol>
            <IonCol>
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  placeholder="Package Discount"
                  name="package_discount"
                  type="number"
                  value={inputData["package_discount"] || ""}
                  onChange={onChange}
                ></input>
              </div>
            </IonCol>
          </IonRow>
        ) : (
          ""
        )}
        <IonCol size="12">
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-92 match-input"
              placeholder="Enter Password"
              onChange={onChange}
              name="creator_password"
              value={inputData["creator_password"] || ""}
              type={isVisible ? "text" : "password"}
            ></input>
            {isVisible ? (
              <AiFillEye
                className="dropdown-icon"
                onClick={() => handleEyeicon()}
              />
            ) : (
              <AiFillEyeInvisible
                className="dropdown-icon"
                onClick={() => handleEyeicon()}
              />
            )}
          </div>
        </IonCol>
        {err && (
          <IonText className="ion-text-center" color="danger">
            {err}
          </IonText>
        )}
        <IonCol size="12">
          <button
            type="submit"
            className="submit-button w-100 mb-10"
            disabled={isProcessing}
            onClick={() =>
              editStatus ? handleUserUpdate() : handleSubmitUserCreation()
            }
          >
            {isProcessing ? "Processing..." : editStatus ? "Update" : "Add"}
          </button>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default AdminCreation;
