import { useState } from "react";
import Table from "../../common/Table";
import { FaCalendarAlt } from "react-icons/fa";
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";
import { Images } from "../../images";


const Stock = ({ purchaseClick }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  const columns = [
    {
      header: "package",
      name: "Package ",
    },
    { header: "purchase", name: "Purchase" },
    { header: "used", name: "Used" },
    { header: "available", name: "Available" },
  ];
  const columnsownuses = [
    {
      header: "package",
      name: "Package ",
    },
    { header: "purchase", name: "Purchase" },
    { header: "used", name: "Used" },
    { header: "available", name: "Available" },
    { header: "exp", name: "Exp Date" },
  ];

  const userDetailsProfitLoss = [
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.Coin}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>30 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
    },
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.SilverMedal}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>90 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
    },
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.GoldMedal}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>180 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
    },
  ];
  const userDetailsProfitLossOwnUses = [
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.Coin}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>30 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
      exp: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">- </div>
        </div>
      ),
    },
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.SilverMedal}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>90 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
      exp: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">- </div>
        </div>
      ),
    },
    {
      package: (
        <div className="settlemt-statement-client-data d-flex">
          <img
            src={Images.GoldMedal}
            alt="Goldmedalicon"
            className="packageicon"
          />
          <div>180 Days </div>
        </div>
      ),
      purchase: (
        <div className="settlemt-statement-client-data">
          <div>10</div>
        </div>
      ),
      used: (
        <div className="settlemt-statement-client-data">
          <span className="clr-orng">5</span>
        </div>
      ),
      available: (
        <div className="settlemt-statement-client-data">
          <div className="clr-green">5 </div>
        </div>
      ),
      exp: (
        <div className="settlemt-statement-client-data">
          <div className="clr-orng">30-12-24 </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-10 font-weight-600">
        <span>Live Streaming Stock</span>
      </div>

      {purchaseClick === false && (
        <>
          <div className="sales-container br-10 d-flex w-100">
            <div className="flex-space-between date-input w-50 m-5  p-10 ">
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="date-none w-100"
              />
              <FaCalendarAlt className="calendar-icon" />
            </div>
            <MdOutlineSwapHorizontalCircle className="swap-icon" />
            <div className="flex-space-between date-input m-5  w-50 p-10 ">
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="date-none w-100"
              />
              <FaCalendarAlt className="calendar-icon" />
            </div>
          </div>
        </>
      )}

      <div className="scrolling-settlement">
        <Table columns={columns} data={userDetailsProfitLoss} />
      </div>
      <div className="p-10 font-weight-600">
        <span>Live Streaming own uses</span>
      </div>
      <div className="scrolling-settlement">
        <Table columns={columnsownuses} data={userDetailsProfitLossOwnUses} />
      </div>
     
    </>
  );
};

export default Stock;
