import Header from "../../components/Header";
import HomeContent from "./HomeContent";
import Footer from "../../components/Footer";
import "./styles.css";

function HomePage() {
  return (
    <div>
      <Header />
      <HomeContent />
      <Footer />
    </div>
  );
}

export default HomePage;
