import "./styles.css";
import { IonCol, IonHeader, IonImg, IonRow } from "@ionic/react";
import { useHistory } from "react-router";
import { Images } from "../../images";
import { BiSolidChevronLeft } from "react-icons/bi";
import { GET_ALL_CLIENTS } from "../../config/endpoints";
import { useState, useEffect } from "react";
import { call } from "../../config/axios";

function ChatLists() {
  const creator_id = localStorage.getItem("creator_id");
  const creator_name =
    localStorage.getItem("creator_name") === "undefined"
      ? "Company"
      : localStorage.getItem("creator_name");

  const history = useHistory();
  const [clientsData, setClientsData] = useState([]);

  const handleUserClick = async (registerId, name) => {
    history.push(`/chat/${registerId}/${name}`);
  };

  const handleAdminClick = async () => {
    history.push(`/chat/${creator_id}/${creator_name}`);
  };

  const getAllUserData = async () => {
    await call(GET_ALL_CLIENTS, {
      register_id: localStorage.getItem("register_id"),
      account_role: localStorage.getItem("account_role"),
    })
      .then((res) => {
        setClientsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  // const chatsDetails = clientsData?.map((item) => ({
  //   register_id: item.register_id,
  //   image: item.profile_image,
  //   name: item?.user_name,
  // }));

  return (
    <div className="chat-main-container">
      <div>
        <IonHeader className="user-outer-div">
          <div className="flex-jc-c">
            <BiSolidChevronLeft
              className="custom-ionicon-container custom-back-icon"
              src={Images.BackArrow}
              onClick={() => history.push("/")}
            />
            <div className="meetings-heading ml-5">Chats Contacts</div>
          </div>
        </IonHeader>
        <IonRow>
          <IonCol>
            <div className="flex-column-chat white-text font-10">
              <div className="hr-line" />
              <h3 className="white-text">Upper Level</h3>
              <div className="hr-line" />
              <div className="d-flex align-center p-5">
                <IonImg
                  className="custom-meeting-image chat-list-image"
                  src={Images.ViratImage}
                />
                <div className="ml-10">
                  <div
                    className="font-16 yellow-clr"
                    onClick={() => handleAdminClick()}
                  >
                    <h5>{creator_name}</h5>
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div className="flex-column-chat white-text font-10">
              <div className="hr-line" />
              <h3 className="white-text">Users List</h3>
              <div className="hr-line" />
              <div className="h-50vh vertical-scroll">
                {clientsData &&
                  clientsData?.length > 0 &&
                  clientsData?.map((item, index) => (
                    <div key={index}>
                      <IonRow className="mb-5 mt-5">
                        <IonCol size="10">
                          <div
                            className="flex-column-chat white-text font-10"
                            onClick={() =>
                              handleUserClick(item?.register_id, item?.user_name)
                            }
                          >
                            <div className="d-flex align-center">
                              <img
                                className="custom-meeting-image chat-list-image"
                                src={item?.profile_image}
                                alt=""
                              />
                              <div className="ml-10">
                                <div className="font-16 mb-5 yellow-clr">
                                  {item?.user_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                      <div className="hr-line" />
                    </div>
                  ))}
              </div>
            </div>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
}

export default ChatLists;
