import React, { useEffect, useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import Table from "../../common/Table";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";
import MatchStatementlist from "./MatchStatementlist";
import FancyFirstStatement from "./FancyFirstStatement";
import FancySecondStatement from "./FancySecondStatement";
import FancyProfitLossDropdown from "./FancyProfitLossDropdown";
import moment from "moment";
import { useParams } from "react-router";
import { call } from "../../config/axios";
import {
  GET_ACCOUNT_MATCHES_DATA,
  GET_MATCH_ENTRY_DETAILS,
  GET_STATEMENT_BY_MATCH_ID,
} from "../../config/endpoints";

const StatementPopUp = () => {
  const { id, match, date, winTeam } = useParams();
  const [activeStatus, setActiveStatus] = useState("client");
  const [fancyInningsStatus, setFancyInningsStatus] = useState(false);
  const [clientMatchStatus, setClientMatchStatus] = useState(false);
  const [statementMatchData, setStatemenMatchtData] = useState([]);
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const account_role = localStorage?.getItem("account_role");
  const [isProcessing, setIsProcessing] = useState(false);
  const [onePageData, setOnePageData] = useState([]);
  const ulShare = localStorage.getItem("ul_share");
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");

  const clientFinancialStatementColumns = [
    {
      header: "masterProfitLoss",
      name: "Name Match P/L",
    },
    {
      header: "fancyProfitLoss",
      name: "Fancy P/L",
    },
    {
      header: "fancyReferralComm",
      name: "C.Comm(M+F) F-Roll-Com",
    },
    {
      header: "amount",
      name: "M+F+C=C-P/L",
    },
  ];
  const referralFinancialStatementColumns = [
    {
      header: "masterProfitLoss",
      name: "Name Match P/L",
    },
    {
      header: "fancyProfitLoss",
      name: "Fancy P/L",
    },
    {
      header: "fancyReferralComm",
      name: "RefCom(M+F) F-Roll-Com",
    },
    {
      header: "amount",
      name: "Referal M+F+C",
    },
  ];
  const handleFancyInnings = (clientID, clientName) => {
    setFancyInningsStatus((prev) => !prev);
    setClientMatchStatus(false);
    setSelectedClientId(clientID);
    setSelectedClientName(clientName);
  };
  const handleClientMatch = (clientID, clientName) => {
    setClientMatchStatus(true);
    setFancyInningsStatus(false);
    setSelectedClientId(clientID);
    setSelectedClientName(clientName);
  };
  let clientPL = 0,
    refPL = 0,
    matchPL = 0;

  const clientFinancialStatementData =
    onePageData &&
    onePageData?.length > 0 &&
    onePageData?.map((report) => {
      clientPL = onePageData.reduce(
        (acc, obj) => acc + (+obj?.clientNet || 0),
        0
      );
      matchPL = onePageData.reduce(
        (acc, obj) => acc + (+obj?.totalLossOrProfit || 0),
        0
      );
      const amount = report?.matchEntryResult?.amount;
      return {
        masterProfitLoss: (
          <div
            className="settlemt-statement-client-data"
            onClick={() =>
              handleClientMatch(report?.client_id, report?.client_name)
            }
          >
            <div>
              {report?.client_name}
              <IonIcon
                icon={arrowDownOutline}
                slot="end"
                className="down-arrow-icon"
              />
            </div>
            <div className={`${amount >= 0 ? "green-clr" : "red-clr"}`}>
              {amount}
            </div>
            Share
            <div
              className={`${
                report?.clientShare >= 0 ? "green-clr" : "red-clr"
              }`}
            >
              {report?.clientShare}
            </div>
          </div>
        ),
        fancyProfitLoss: (
          <div className="flex-center">
            <div
              className={
                report?.fancyEntryResult?.amount >= 0 ? "green-clr" : "red-clr"
              }
            >
              {report?.fancyEntryResult?.amount}
            </div>
            {/* <IonIcon
              icon={arrowDownOutline}
              slot="end"
              className="down-arrow-icon"
            /> */}
          </div>
        ),
        fancyReferralComm: (
          <div
            className={`${
              report?.clientComission >= 0 ? "green-clr" : "red-clr"
            }`}
          >
            {report?.clientComission}
          </div>
        ),
        amount: (
          <div
            className={`${report?.clientNet >= 0 ? "green-clr" : "red-clr"}`}
          >
            {report?.clientNet}
          </div>
        ),
      };
    });

  const referralFinancialStatementAllData =
    onePageData &&
    onePageData?.length > 0 &&
    onePageData?.map((report) => {
      const amount = report?.matchEntryResult?.amount;
      refPL = onePageData.reduce(
        (acc, obj) => acc + (+obj?.referalNet || 0),
        0
      );
      return {
        masterProfitLoss: (
          <div
            className="settlemt-statement-client-data"
            onClick={() =>
              handleClientMatch(report?.client_id, report?.client_name)
            }
          >
            <div>
              {report?.client_name}
              <IonIcon
                icon={arrowDownOutline}
                slot="end"
                className="down-arrow-icon"
              />
            </div>
            <div className={`${amount >= 0 ? "green-clr" : "red-clr"}`}>
              {amount}
            </div>
            Rf Share
            <div
              className={`${
                report?.referalShare >= 0 ? "green-clr" : "red-clr"
              }`}
            >
              {report?.referalShare}
            </div>
          </div>
        ),
        fancyProfitLoss: (
          <div
            className="flex-center"
            onClick={() =>
              handleFancyInnings(report?.client_id, report?.client_name)
            }
          >
            <div
              className={
                report?.fancyEntryResult?.amount >= 0 ? "green-clr" : "red-clr"
              }
            >
              {report?.fancyEntryResult?.amount}
            </div>
            <IonIcon
              icon={arrowDownOutline}
              slot="end"
              className="down-arrow-icon"
            />
          </div>
        ),
        fancyReferralComm: (
          <div
            className={`${
              report?.referralComission >= 0 ? "green-clr" : "red-clr"
            }`}
          >
            {report?.referralComission}
          </div>
        ),
        amount: (
          <div
            className={`${report?.referalNet >= 0 ? "green-clr" : "red-clr"}`}
          >
            {report?.referalNet ? report?.referalNet.toFixed(2) : 0}
          </div>
        ),
      };
    });

  const clientHeadingButtons = (
    <div className="flex">
      <div className="common-active-css inactive-css font-12 p-2">
        Match:
        <div>IND vs SL</div>
      </div>
      <div className="common-active-css inactive-css font-12">
        Date:
        <div>{moment(+date).format("DD-MM-YYYY")}</div>
      </div>
      <div
        className={`common-active-css font-12 flex-center ${
          activeStatus === "client" ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleClientData("client")}
      >
        Client PL
      </div>
      <div
        className={`common-active-css font-12 flex-center ${
          activeStatus === "referral" ? "active-css" : "inactive-css"
        }`}
        onClick={() => handleRFData("referral")}
      >
        Rf P/L
      </div>
    </div>
  );

  const [statementColumns, setStatementColumns] = useState(
    clientFinancialStatementColumns
  );

  const handleRFData = (referral) => {
    setStatementColumns(referralFinancialStatementColumns);
    setActiveStatus(referral);
    setFancyInningsStatus(false);
    setClientMatchStatus(false);
  };
  const handleClientData = (client) => {
    setStatementColumns(clientFinancialStatementColumns);
    setActiveStatus(client);
    setClientMatchStatus(false);
    setFancyInningsStatus(false);
  };

  const getStatementByMatchIdData = async () => {
    setIsProcessing(true);
    await call(GET_STATEMENT_BY_MATCH_ID, {
      register_id,
      registered_match_id: id,
    })
      .then((res) => {
        setIsProcessing(false);
        setOnePageData(res?.data?.data?.client_object);
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getStatementByMatchIdData();
  }, []);

  return (
    <div style={{ overflowY: "auto" }}>
      <MeetingsHeader heading="Statement" />
      <div className="offline-page-container mt-10 mb-10">
        <Table
          columns={statementColumns}
          data={
            activeStatus === "client"
              ? clientFinancialStatementData
              : referralFinancialStatementAllData
          }
          buttons={clientHeadingButtons}
        />
        <div>
          <div className="account-summary-main-container mt-10">
            <div className="flex flex-row clr-white font-13  mt-5 p-10 flex-space-between w-100">
              <div className="flex-column">
                <div className="flex mt-5">
                  <div>C Net P/L =</div>
                  <div
                    className={`${
                      clientPL >= 0 ? "clr-green ml-15" : "red-clr ml-15"
                    }`}
                  >
                    {clientPL}
                  </div>
                </div>
                <div className="flex mt-5">
                  <div>Ref Net P/L =</div>
                  <div
                    className={`${
                      refPL >= 0 ? "clr-green ml-15" : "red-clr ml-15"
                    }`}
                  >
                    {refPL ? refPL.toFixed(2) : 0}
                  </div>
                </div>
                <div className="flex mt-5">
                  <div>Match P/L =</div>
                  <div
                    className={`${
                      matchPL >= 0 ? "clr-green ml-15" : "red-clr ml-15"
                    }`}
                  >
                    {matchPL ? matchPL.toFixed(2) : 0}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex mt-5">
                  <div>
                    UL Share <span className="clr-green ml-5">{ulShare}%</span>
                    =
                  </div>
                  <div
                    className={`${
                      matchPL >= 0 ? "clr-red ml-10" : "clr-green ml-10"
                    }`}
                  >
                    {matchPL >= 0
                      ? -(matchPL * ulShare) / 100
                      : (matchPL * ulShare) / 100}
                  </div>
                </div>
                <div className="flex mt-20">
                  <div>Match Net P/L =</div>
                  <div
                    className={`${
                      matchPL - (matchPL * ulShare) / 100 >= 0
                        ? "clr-green ml-10"
                        : "clr-red ml-10"
                    }`}
                  >
                    {matchPL - (matchPL * ulShare) / 100
                      ? (matchPL - (matchPL * ulShare) / 100).toFixed(2)
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {clientMatchStatus && (
          <>
            <div className="mb-10 mt-10">
              <MatchStatementlist
                selectedClientName={selectedClientName}
                selectedClientId={selectedClientId}
                registerMatchId={id}
                winTeam={winTeam}
                matchDate={date}
              />
            </div>
            <div className="mb-10 mt-10">
              <FancyFirstStatement
                selectedClientName={selectedClientName}
                selectedClientId={selectedClientId}
                registerMatchId={id}
                winTeam={winTeam}
                matchDate={date}
              />
            </div>
            <div className="mb-10">
              <FancySecondStatement
                selectedClientName={selectedClientName}
                selectedClientId={selectedClientId}
                registerMatchId={id}
                winTeam={winTeam}
                matchDate={date}
              />
            </div>
          </>
        )}

        {fancyInningsStatus && (
          <>
            {/* <div className="mb-10 mt-10">
              <FancyProfitLossDropdown />
            </div> */}
            <div className="mb-10 mt-10">
              <FancyFirstStatement
                selectedClientName={selectedClientName}
                selectedClientId={selectedClientId}
                registerMatchId={id}
                winTeam={winTeam}
                matchDate={date}
              />
            </div>
            <div className="mb-10">
              <FancySecondStatement
                selectedClientName={selectedClientName}
                selectedClientId={selectedClientId}
                registerMatchId={id}
                winTeam={winTeam}
                matchDate={date}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StatementPopUp;
