import React, { useEffect, useState } from "react";
import { IonCol, IonGrid, IonImg, IonRow, IonHeader } from "@ionic/react";
import "./styles.css";
import PackagesContainer from "./PackagesContainer";
import GoldPackages from "./GoldPackages";
import UpgradePagePopup from "./UpgradePagePopup";
import AddPaymentPopup from "./AddPaymentPopup";
import { Images } from "../../images";
import { useHistory } from "react-router";
import ThankYouPopup from "./ThankYouPopup";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import { GET_ALL_PACKAGES } from "../../config/endpoints";
import { call } from "../../config/axios";
import PackageHourContainer from "./PackageHourContainer";
import GoldHourPackgaes from "./GoldHourPackgaes";

function TopupHoursAdding(props) {
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectPackagesDropdown, setSelectPackagesDropdown] = useState("");
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);
  const [upgradePaymentPopup, setUpgradePaymentPopup] = useState(false);
  const [allPackages, setAllPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState({});

  const handleUpgradePopup = () => {
    setShowUpgradePopup((prev) => !prev);
  };
  const [showAddPaymentPopupOpen, setShowAddPaymentPopupOpen] = useState(false);
  const handleAddPaymentPopup = () => {
    setShowAddPaymentPopupOpen((prev) => !prev);
  };
  const [checked, setChecked] = useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const getAllPackages = async () => {
    await call(GET_ALL_PACKAGES,{ package_type: true })
      .then((res) => {
        if (res.data.status === 200) {
          const response = res.data.data;
          setAllPackages(response);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllPackages();
  }, []);
  const {adminSubscription=[]} =props
  return (
    <div className="match-container">
      <div>
        <IonGrid className="pb-0 my-4">
          <IonRow className="d-flex">
            <IonCol size="6" className="d-flex">
              <div className="package-container">
                <div className="we-2-call-description">
                  <div>Special Package</div>
                  <div className="bold-text we-2-call-text">For New Admins</div>
                  <div className="flex-space-between mt-10 w-90">
                    <div className="flex-column">
                      <div className="font-10">Monthly</div>
                      <div className="font-10">5999/-</div>
                    </div>
                    <div className="flex-column">
                      <div className="font-10">Yearly</div>
                      <div className="font-10">60000/-</div>
                    </div>
                  </div>
                  <button className="custom-view-details-button w-80 font-10 mt-5 mb-10">
                    <b>View Details</b>
                  </button>
                </div>
                <div className="flex-column">
                  <div className="off-icon flex-center flex-column">
                    <div style={{ fontSize: "8px" }}>FLAT</div>
                    <div style={{ fontSize: "12px" }}>
                      <b>10%</b>
                    </div>
                    <div style={{ fontSize: "8px" }}>Off</div>
                  </div>
                  <IonImg
                    className="discount-img"
                    src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
                  />
                  <IonImg
                    className="giftbox-img"
                    src={process.env.PUBLIC_URL + "./assets/gift_box.png"}
                  />
                </div>
              </div>
            </IonCol>
            <IonCol size="6" className="d-flex">
              <div className="package-container-second">
                <div className="we-2-call-description">
                  <div>Special Package</div>
                  <div className="bold-text we-2-call-text">For New Admins</div>
                  <div className="flex-space-between mt-10 w-90">
                    <div className="flex-column">
                      <div className="font-10">Monthly</div>
                      <div className="font-10">5999/-</div>
                    </div>
                    <div className="flex-column">
                      <div className="font-10">Yearly</div>
                      <div className="font-10">60000/-</div>
                    </div>
                  </div>
                  <button className="custom-view-details-button w-80 font-10 mt-5 mb-10">
                    <b>View Details</b>
                  </button>
                </div>
                <div className="flex-column">
                  <div className="off-icon flex-center flex-column">
                    <div style={{ fontSize: "8px" }}>FLAT</div>
                    <div style={{ fontSize: "12px" }}>
                      <b>10%</b>
                    </div>
                    <div style={{ fontSize: "8px" }}>Off</div>
                  </div>
                  <IonImg
                    className="discount-img"
                    src={process.env.PUBLIC_URL + "./assets/discount_02.png"}
                  />
                  <IonImg
                    className="giftbox-img"
                    src={process.env.PUBLIC_URL + "./assets/gift_box.png"}
                  />
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="flex-row mt-5">
          <div className="package-inactive-buttons custom-box-shadow packages-button flex-start font-14 h-40 p-10 fw-600">
            Packages Hours
          </div>
        </div>
        <PackageHourContainer
          checked={checked}
          allPackages={allPackages}
          handleUpgradePopup={handleUpgradePopup}
          handleAddPaymentPopup={handleAddPaymentPopup}
          setSelectedImage={setSelectedImage}
          setSelectPackagesDropdown={setSelectPackagesDropdown}
          setSelectedPackages={setSelectedPackages}
          adminSubscription={adminSubscription}
        />
        <GoldHourPackgaes
          checked={checked}
          handleUpgradePopup={handleUpgradePopup}
          setSelectedImage={setSelectedImage}
          allPackages={allPackages}
          setSelectPackagesDropdown={setSelectPackagesDropdown}
          setSelectedPackages={setSelectedPackages}
          adminSubscription={adminSubscription}
        />
        <UpgradePagePopup
          showUpgradePopup={showUpgradePopup}
          hoursPack={"hoursPack"}
          setShowUpgradePopup={setShowUpgradePopup}
          selectedImage={selectedImage}
          allPackages={allPackages}
          selectedPackages={selectedPackages}
          setSelectedImage={setSelectedImage}
          selectPackagesDropdown={selectPackagesDropdown}
          setSelectPackagesDropdown={setSelectPackagesDropdown}
          setShowThankYouPopup={setShowThankYouPopup}
          setUpgradePaymentPopup={setUpgradePaymentPopup}
        />
        <ThankYouPopup
          showThankYouPopup={showThankYouPopup}
          setShowThankYouPopup={setShowThankYouPopup}
        />
        <AddPaymentPopup
          showAddPaymentPopupOpen={showAddPaymentPopupOpen}
          setShowAddPaymentPopupOpen={setShowAddPaymentPopupOpen}
          handleAddPaymentPopup={handleAddPaymentPopup}
          showThankYouPopup={showThankYouPopup}
          setShowThankYouPopup={setShowThankYouPopup}
        />
        <FancyThankYouPopup
          openPopup={upgradePaymentPopup}
          setclosePopup={setUpgradePaymentPopup}
          displayData={"Upgraded Your Ticket Successfully"}
        />
      </div>
    </div>
  );
}

export default TopupHoursAdding;
