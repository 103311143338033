import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { call } from "../../config/axios";
import { GET_ALL_MATCHES } from "../../config/endpoints";
import moment from "moment";
import { PiPencilLine } from "react-icons/pi";
import EditMatchPopUp from "./EditMatchPopUp";

function UrCreatingMatches({
  status,
  setMatchDetails,
  setMatchEditStatus,
  setMatchEditSuccess,
}) {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [liveMatchesData, setLiveMatchesData] = useState([]);
  const [upcomingMatchesData, setUpcomingMatchesData] = useState([]);
  const [todayMatchesData, setTodayMatchesData] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [liveMatch, setLiveMatch] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const matchesStatus = ["Live", "Today", "UpComing"];
  const tableHeading = [
    {
      size: "5",
      headerName: "Series Name",
    },
    {
      size: "5",
      headerName: "Match Name",
    },
    { headerName: "Edit", size: "2" },
  ];

  const handleMatchStatus = (index) => {
    setActiveIndex(index);
    index === 0 ? setLiveMatch(true) : setLiveMatch(false);
  };
  const getAllMatches = () => {
    call(GET_ALL_MATCHES, {
      register_id,
      account_role,
    })
      .then((res) => {
        const result = res?.data?.data;
        const currentTime = new Date().getTime();
        const thirtyMinutesLater = currentTime + 30 * 60 * 1000;
        const todayStart = new Date().setHours(0, 0, 0, 0);
        const todayEnd = new Date().setHours(23, 59, 59, 999);

        const allMatches = [
          ...(result?.todaysMatches || []),
          ...(result?.upCommingMatches || []),
        ];

        allMatches.forEach((match) => {
          console.log("Match Timestamp:", new Date(match.givenTimeStamp));
        });

        let liveUnDeclareMatches = result?.liveMatches?.filter(
          (i) => i.match_declared !== "Y"
        );

        liveUnDeclareMatches.forEach((match) => {
          console.log("Live Match Timestamp:", new Date(match.givenTimeStamp));
        });

        const todayMatches = allMatches.filter((match) => {
          const matchTimestamp = new Date(match.givenTimeStamp).getTime();
          return (
            matchTimestamp >= todayStart &&
            matchTimestamp <= todayEnd &&
            matchTimestamp > thirtyMinutesLater &&
            match.match_declared === "Y"
          );
        });

        const upcomingMatches = allMatches.filter((match) => {
          const matchTimestamp = new Date(match.givenTimeStamp).getTime();
          return (
            matchTimestamp > todayEnd ||
            (matchTimestamp <= thirtyMinutesLater &&
              match.match_declared === "Y")
          );
        });

        // Move matches starting within the next 30 minutes to liveUnDeclareMatches
        const soonToBeLiveMatches = allMatches.filter((match) => {
          const matchTimestamp = new Date(match.givenTimeStamp).getTime();
          return (
            matchTimestamp > currentTime &&
            matchTimestamp <= thirtyMinutesLater &&
            match.match_declared === "Y"
          );
        });

        // Merge soonToBeLiveMatches with liveUnDeclareMatches
        liveUnDeclareMatches = [
          ...liveUnDeclareMatches,
          ...soonToBeLiveMatches,
        ];

        const todayLiveMatches = liveUnDeclareMatches.filter((match) => {
          const matchTimestamp = new Date(match.givenTimeStamp).getTime();
          return matchTimestamp >= todayStart && matchTimestamp <= todayEnd;
        });

        const upcomingLiveMatches = liveUnDeclareMatches.filter((match) => {
          const matchTimestamp = new Date(match.givenTimeStamp).getTime();
          return matchTimestamp > todayEnd;
        });

        // Remove matches in todayLiveMatches from liveUnDeclareMatches
        const todayLiveMatchIds = todayLiveMatches.map(
          (match) => match.match_id
        );
        liveUnDeclareMatches = liveUnDeclareMatches.filter(
          (match) => !todayLiveMatchIds.includes(match.match_id)
        );

        // Remove soonToBeLiveMatches from todayMatches
        const soonToBeLiveMatchIds = soonToBeLiveMatches.map(
          (match) => match.match_id
        );
        const finalTodayMatches = todayMatches
          .filter((match) => !soonToBeLiveMatchIds.includes(match.match_id))
          .concat(todayLiveMatches);

        const finalUpcomingMatches = [
          ...upcomingMatches,
          ...upcomingLiveMatches,
        ];

        setLiveMatchesData(liveUnDeclareMatches);
        setTodayMatchesData(finalTodayMatches);
        setUpcomingMatchesData(finalUpcomingMatches);
      })
      .catch((err) => console.log(err));
  };

  const getData = (i) => {
    switch (i) {
      case 0:
        return liveMatchesData || [];
      case 1:
        return todayMatchesData || [];
      case 2:
        return upcomingMatchesData || [];
      default:
        return liveMatchesData || [];
    }
  };
  useEffect(() => {
    getAllMatches();
  }, [status]);

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        <IonCol size="12" className="flex-space-between ">
          <div className="connect-device-header custom-box-shadow">
            Your Matches
          </div>
          {matchesStatus?.map((value, index) => (
            <div
              key={index}
              className={
                activeIndex === index
                  ? "connect-device-seemore custom-box-shadow clr-green"
                  : "connect-device-seemore custom-box-shadow"
              }
              onClick={() => handleMatchStatus(index)}
            >
              {value}
            </div>
          ))}
        </IonCol>
        <div className="w-100 upcoming-table flex-space-between">
          {tableHeading?.map((value, index) => {
            return (
              <IonCol key={index} size={value.size}>
                <div className="upcoming-table-heading flex-center custom-box-shadow">
                  {value?.headerName}
                </div>
              </IonCol>
            );
          })}
        </div>
        <div className="table-rows-scroll w-100">
          {(getData(activeIndex) ? getData(activeIndex) : [])?.map(
            (match, i) => {
              return (
                <div
                  className="w-100 d-flex justify-center flex-center border-bottom-white"
                  key={i}
                >
                  {[
                    {
                      size: "5",
                      content: (
                        <div className="flex-column w-100">
                          <div className="d-flex flex-row">
                            <div>
                              {liveMatch && (
                                <GoDotFill className="clr-green font-size-20" />
                              )}
                            </div>
                            <div className="d-flex flex-column">
                              <div> {match?.sport_name}</div>
                              <div>{match?.game_object?.match_type}</div>
                              <div> {match?.match_place}</div>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      size: "5",
                      content: (
                        <div className="flex">
                          {match?.match_name} - ({match?.gender})
                          <br />
                          {moment(match?.matchTimeStamp).format("DD-MM-YYYY")}
                          <br />
                          {moment(match?.matchTimeStamp).format("hh:mm:ss A")}
                        </div>
                      ),
                    },
                  ]?.map((value, index) => {
                    return (
                      <IonCol
                        className="table-content-text"
                        size={value?.size}
                        key={index}
                      >
                        <div>{value?.content}</div>
                      </IonCol>
                    );
                  })}
                  <IonCol
                    size="2"
                    className="flex-center"
                    onClick={() => {
                      setPopUp(true);
                      setMatchData(match);
                      setMatchEditSuccess(true);
                    }}
                  >
                    <PiPencilLine className="clock-icon" />
                  </IonCol>
                </div>
              );
            }
          )}
        </div>
      </IonRow>
      <EditMatchPopUp
        matchData={matchData}
        setMatchDetails={setMatchDetails}
        popUp={popUp}
        setPopUp={setPopUp}
        setMatchEditStatus={setMatchEditStatus}
      />
    </IonGrid>
  );
}

export default UrCreatingMatches;
