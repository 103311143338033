import React, { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import "./styles.css";
import DownlevelTransaction from "./DownlevelTransaction";
import UpgradeYourPackages from "../add-admins/UpgradeYourPackages";
import PackageTransaction from "./PackageTransaction";

function PackageUpgrade(props) {
  const { showUpgradePackage } = props;
  const packageList = ["Upgrade Your Package", "Package Transaction"];
  const [activeIndex, setActiveIndex] = useState(0);
  const handlePackageButton = (value) => {
    setActiveIndex(value);
  };
  return (
    <div>
      {showUpgradePackage === true ? null : (
        <MeetingsHeader heading={"Package Upgrade/Accounts"} />
      )}
      <div className="package-upgrade-container p-5">
        <div className="flex-space-around flex-row mb-10">
          {packageList.map((data, index) => {
            return (
              <div
                key={index}
                className={
                  activeIndex === index
                    ? "package-active-buttons button-box-shadow flex-center font-12 ml-5 mr-5 flex-center flex-column"
                    : "package-inactive-buttons button-box-shadow flex-center font-12 ml-5 mr-5  flex-center flex-column "
                }
                onClick={() => handlePackageButton(index)}
              >
                {data}
              </div>
            );
          })}
        </div>
        {activeIndex === 0 && <UpgradeYourPackages />}
        {activeIndex === 1 && <PackageTransaction type="subscription"/>}
        {/* {activeIndex === 2 && <DownlevelTransaction />} */}
      </div>
    </div>
  );
}

export default PackageUpgrade;
