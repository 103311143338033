import { isLoggedIn } from "../utils/helpers";
import { keepTheme } from "../theme/themes";
import { useEffect, useState } from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const Routes = () => {
  useEffect(() => {
    keepTheme();
  }, []);


  return <div>
    {isLoggedIn() ? <PrivateRoutes /> : <PublicRoutes />}
  </div>;
};

export default Routes;
