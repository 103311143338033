import React, { useEffect, useState } from "react";
import { GET_FANCY_ENTRY_DATA } from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";

function FancyFirstStatement({
  selectedClientId,
  registerMatchId,
  winTeam,
  selectedClientName,
  matchDate,
}) {
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const account_role = localStorage?.getItem("account_role");
  const [userFancyEntrys, setUserFancyEntrys] = useState([]);

  const tableMatchRowData = [
    {
      title: "Bet Place",
    },
    {
      title: "Name",
    },
    {
      title: "Amount",
    },
    {
      title: "P/L",
    },
  ];

  // totalPL = userMatchEntrys && userMatchEntrys?.length > 0  && userMatchEntrys.reduce(
  //   (acc, obj) => acc + (+obj?.teamObj[winTeam] || 0),
  //   0
  // );
  let totalBetsAmount = 0,
    totalPL = 0;

  const fancyFirstInnings =
    userFancyEntrys &&
    userFancyEntrys?.length > 0 &&
    userFancyEntrys?.map((fancy) => {
      totalPL =
        userFancyEntrys &&
        userFancyEntrys?.length > 0 &&
        userFancyEntrys.reduce(
          (acc, obj) =>
            acc +
            ((obj?.fancy_status === "Y" ? -+obj?.amount : +obj?.amount) || 0),
          0
        );
      return {
        betPlace: (
          <div>
            S no-{`${fancy?.s_no}/${fancy?.old_s_no}`}
            <br />
            {fancy?.over} over
            <br />
            {fancy?.runs}-{fancy?.yN}
            <br />
            {fancy?.team} <br />
            {moment(fancy?.matchTimeStamp).format("DD-MM-YYYY")}
            <br />
            {moment(fancy?.matchTimeStamp).format("hh:mm:ss A")}
            <br />
            <span style={{ color: "#22ff0b" }}>
              
              Result-{fancy?.scored_runs}
            </span>
          </div>
        ),
        name: fancy?.client_name,
        amount: fancy?.amount,
        PL: fancy?.fancy_status === "Y" ? -fancy?.amount : fancy?.amount,
      };
    });

  const getFancyEntryDetails = async () => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: registerMatchId,
      register_id,
      client_id: selectedClientId,
    })
      .then((res) => {
        let results = res?.data?.data?.Items;
        setUserFancyEntrys(
          results?.filter((i) => i.innings === "1" || i.innings === 1)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFancyEntryDetails();
  }, [selectedClientId]);

  return (
    <div className="custom-box-shadow m-none overflow-none meetings-details-div mt-10">
      <div className="flex-row flex-space-between p-10 mt-10">
        <div className=" custom-box-shadow upcoming-meetings-div flex-start  font-16 w-60 p-10">
          Fancy 1st Innings
        </div>
        <div className="seeall-button h-30p w-30 p-5 flex-center custom-box-shadow w-25 font-10">
          {selectedClientName}
        </div>
      </div>
      <div className="hr-line mt-5" />
      <div className="d-flex ">
        {tableMatchRowData.map((matchData, index) => (
          <div key={index} className="flex-center mt-5 w-100 pr-5 pl-5">
            <div className="match-history-button flex-center custom-box-shadow font-10">
              {matchData.title}
            </div>
          </div>
        ))}
      </div>
      <div className="hr-line mt-10"></div>
      <div className="upcoming-meet-scroll ml-10 mr-5">
        {fancyFirstInnings &&
          fancyFirstInnings?.length > 0 &&
          fancyFirstInnings?.map((fancyonedata, index) => (
            <div key={index} className="flex-row">
              <div className="flex-space-between mt-5 pl-10 pr-10">
                <div className="meetings-time w-25 font-2 padding-tb">
                  {fancyonedata?.betPlace}
                </div>
                <div className="font-12 w-15">{fancyonedata?.name}</div>
                <div className="font-12 w-20">{fancyonedata?.amount}</div>
                <div
                  className={
                    fancyonedata?.PL >= 0
                      ? "font-12 approved-color"
                      : "font-12 red-clr"
                  }
                >
                  {fancyonedata?.PL}
                </div>
              </div>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
      </div>
      <div className="flex-space-between flex-row total-count-container">
        <div className="font-14">Total</div>
        <div className={`${totalPL >= 0 ? "approved-color" : "red-clr"}`}>
          {totalPL}
        </div>
      </div>
    </div>
  );
}

export default FancyFirstStatement;
