import { IonCol, IonModal, IonRow } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";

function PackageViewPopup(props) {
  const {
    showPackageViewPopup,
    setShowPackageViewPopup,
    transactionData = {},
  } = props;
  const handlePackageViewPopupClose = () => {
    setShowPackageViewPopup(false);
  };
  const packageViewData = [
    {
      title: "Pkgs",
    },
    {
      title: "Pur",
    },
    {
      title: "Price",
    },
    {
      title: "Dis",
    },
    {
      title: "Rtn Pkg",
    },
    {
      title: "Rtn Hrs",
    },
  ];

  console.log(transactionData, "===>transactionData");

  const packageData = transactionData?.requested_packages?.map((obj) => {
    return {
      packages: obj?.package_name,
      purchase: obj?.no_of_packages,
      price: obj?.cost,
      discount: obj?.discount,
      returnpkg: obj?.return_packages || 0,
      returnhrs: obj?.return_hrs || 0,
    };
  });
  const returnPackageList = transactionData?.summary?.return_package_list?.map(
    (obj) => {
      return {
        packages: obj?.package_name,
        purchase: obj?.no_of_packages,
        price: obj?.no_of_packages * obj.cost,
        returnpkg: 0,
        returnhrs: obj?.return_hrs || 0,
      };
    }
  );
  const totalNoReturnPackages =
    transactionData?.summary?.return_package_list?.reduce(
      (acc, obj) => acc + (obj?.selected_no_of_packages || 0),
      0
    );
  const totalNoReturnPackagesCost =
    transactionData?.summary?.return_package_list?.reduce(
      (acc, obj) => acc + (obj?.cost * obj?.selected_no_of_packages || 0),
      0
    );

  return (
    <div className="border-colour">
      <IonModal
        className="package-popup-main p-0"
        isOpen={showPackageViewPopup}
      >
        <div
          className="w-100 flex-flex-end p-5"
          onClick={handlePackageViewPopupClose}
        >
          <RxCross1 />
        </div>
        <div>
          <div className="d-flex ">
            {packageViewData?.length > 0 &&
              packageViewData?.map((matchData, index) => (
                <div key={index} className="flex-center mt-5 pr-5 pl-5">
                  <div className="match-history-button flex-center custom-box-shadow font-10">
                    {matchData.title}
                  </div>
                </div>
              ))}
          </div>
          <div className="hr-line mt-10"></div>
          <div className="ml-10 mr-5">
            {packageData?.length > 0 &&
              packageData?.map((packagedata, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5 w-100">
                    <div className="meetings-time w-30 flex-center  font-2 padding-tb">
                      {packagedata.packages}
                    </div>
                    <div className="font-10 flex-center  w-30">
                      {packagedata.purchase}
                    </div>
                    <div className="font-10 flex-center w-30">
                      {packagedata.price}
                    </div>
                    <div className="font-10 flex-center w-30">
                      {packagedata.discount} %
                    </div>
                    <div className="font-size-10 flex-center clr-text  w-30">
                      {packagedata.returnpkg}
                    </div>
                    <div className="font-size-10 clr-text flex-center  w-30">
                      {packagedata.returnhrs}
                    </div>
                  </div>
                  <div className="hr-line mt-5"></div>
                </div>
              ))}
            {returnPackageList?.length > 0 &&
              returnPackageList?.map((packagedata, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5 w-100">
                    <div className="meetings-time w-30 flex-center  font-2 padding-tb">
                      {packagedata.packages}
                    </div>
                    <div className="font-10 flex-center  w-30">
                      {packagedata.purchase}
                    </div>
                    <div className="font-10 flex-center w-30">
                      {packagedata.price}
                    </div>
                    <div className="font-size-10 flex-center clr-text  w-30">
                      {packagedata.returnpkg}
                    </div>

                    <div className="font-size-10 clr-text flex-center  w-30">
                      {packagedata.returnhrs}
                    </div>
                  </div>
                  <div className="hr-line mt-5"></div>
                </div>
              ))}
          </div>
          <div className="upgrade-popup-box-container custom-box-shadow w-100 p- h-auto font-12">
            <IonRow>
              <IonCol size="6">
                Total Pkgs :{transactionData?.requested_packages?.length}=
                {transactionData?.packageAmt}
              </IonCol>
              <IonCol size="6">
                Rtn Pkgs :{totalNoReturnPackages} = {totalNoReturnPackagesCost}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">Discount :0</IonCol>
              <IonCol size="6">Spl Dis : 0</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                From: {transactionData?.summary?.requester_name}
              </IonCol>
              <IonCol size="6">
                Paid Amt : {transactionData?.summary?.final_package_cost}
              </IonCol>
            </IonRow>
          </div>
        </div>
      </IonModal>
    </div>
  );
}

export default PackageViewPopup;
