import React, { useEffect, useState } from "react";
import { GET_MATCH_ENTRY_DETAILS } from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";

function MatchStatementlist({
  selectedClientId,
  registerMatchId,
  winTeam,
  selectedClientName,
  matchDate,
}) {
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const account_role = localStorage?.getItem("account_role");
  const [userMatchEntrys, setUserMatchEntrys] = useState([]);

  const tableMatchRowData = [
    {
      title: "Bet Place",
    },
    {
      title: "Name",
    },
    {
      title: "Amount",
    },
    {
      title: "P/L",
    },
  ];
  let totalBetsAmount = 0,
    totalPL = 0;
  totalPL =
    userMatchEntrys &&
    userMatchEntrys?.length > 0 &&
    userMatchEntrys.reduce(
      (acc, obj) => acc + (+obj?.teamObj[winTeam] || 0),
      0
    );
  const matchDetails =
    userMatchEntrys &&
    userMatchEntrys?.length > 0 &&
    userMatchEntrys?.map((match) => {
      return {
        betPlace: (
          <div>
            S no-{`${match?.s_no}/${match?.old_s_no}`}
            <br />
            Rate- {match?.rate}
            <br />
            {match?.team}- {match?.pe}
            <br />
            {moment(match?.matchTimeStamp).format("DD-MM-YYYY")}
            <br />
            {moment(match?.matchTimeStamp).format("hh:mm:ss A")}
            <br />
            <span style={{ color: "#22ff0b" }}>Win-{winTeam}</span>
          </div>
        ),
        name: match?.client_name,
        amount: match?.amount,
        PL: match?.teamObj[winTeam],
      };
    });
  const getMatchEntryDetails = async () => {
    await call(GET_MATCH_ENTRY_DETAILS, {
      registered_match_id: registerMatchId,
      register_id,
      client_id: selectedClientId,
    })
      .then((res) => {
        setUserMatchEntrys(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMatchEntryDetails();
  }, [selectedClientId]);

  return (
    <div className="custom-box-shadow m-none overflow-none meetings-details-div">
      <div className="seeall-button h-30p w-45 p-5 flex-center custom-box-shadow w-40 font-12 mt-5 ml-10">
        Client Name : {selectedClientName}
      </div>
      <div className="flex-row flex-space-between p-10">
        <div className=" custom-box-shadow upcoming-meetings-div flex-start font-16 w-60 p-10">
          Match
        </div>
        <div className="seeall-button h-30p w-30 p-5 w-30 p-5 flex-center custom-box-shadow font-10">
          Date : {moment(+matchDate).format("DD-MM-YYYY")}
        </div>
      </div>
      <div className="hr-line mt-5" />
      <div className="d-flex ">
        {tableMatchRowData.map((matchData, index) => (
          <div key={index} className="flex-center mt-5 w-100 pr-5 pl-5">
            <div className="match-history-button flex-center custom-box-shadow font-10">
              {matchData?.title}
            </div>
          </div>
        ))}
      </div>
      <div className="hr-line mt-10 "></div>
      <div className="upcoming-meet-scroll  ml-10 mr-5">
        {matchDetails &&
          matchDetails?.length > 0 &&
          matchDetails?.map((matchdata, index) => (
            <div key={index} className="flex-row">
              <div className="flex-space-between mt-5 pl-10 pr-10">
                <div className="meetings-time w-25 font-2 padding-tb">
                  {matchdata?.betPlace}
                </div>
                <div className="font-12 w-15">{matchdata?.name}</div>
                <div className="font-12 w-20 approved-color">
                  {matchdata?.amount}
                </div>
                <div
                  className={`${
                    matchdata?.PL >= 0
                      ? "font-12 approved-color"
                      : "font-12 red-clr"
                  }`}
                >
                  {matchdata?.PL}
                </div>
              </div>
              <div className="hr-line mt-5"></div>
            </div>
          ))}
      </div>
      <div className="flex-space-between flex-row total-count-container">
        <div className="font-14">Total</div>
        <div className={`${totalPL?.PL >= 0 ? "approved-color" : "red-clr"}`}>
          {totalPL}
        </div>
      </div>
    </div>
  );
}

export default MatchStatementlist;
