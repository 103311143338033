import { BiChevronDown } from "react-icons/bi";
import MatchSharePopup from "../matchpopups/MatchSharePopup";
import { useState } from "react";
import MatchCommissionPopup from "../matchpopups/MatchCommissionPopup";

function MatchPositionTable(props) {
  const { matchPositionData, team1, team2, win, teamName } = props;
  const [matchSharePopupOpen, setMatchSharePopupOpen] = useState(false);
  const [matchCommissionPopupOpen, setMatchCommissionPopupOpen] =
    useState(false);
  const handleMatchSharePopupOpen = () => {
    setMatchSharePopupOpen(!matchSharePopupOpen);
  };
  const handleMatchSharePopupClose = () => {
    setMatchSharePopupOpen(false);
  };
  const handleMatchCommissionPopupOpen = () => {
    setMatchCommissionPopupOpen(!matchCommissionPopupOpen);
  };
  const handleMatchCommissionPopupClose = () => {
    setMatchCommissionPopupOpen(false);
  };
  const data =
    matchPositionData?.length > 0 &&
    matchPositionData
      ?.filter((item) => item.record_status === "active")
      ?.map(
        ({ client_name, teamObj, amount, team, ClientDetails, pe }, index) => {
          let resultTeam =
            team1 === "draw"
              ? parseFloat(teamObj["draw"])
              : team1 === win
              ? parseFloat(teamObj[win])
              : parseFloat(teamObj[team1]);

          return {
            key: index,
            resultTeam: resultTeam,
            client: client_name,
            matchBet: parseFloat(amount),
            clientCommConst: ClientDetails?.clientComm,
            ulCommConst: ClientDetails?.ulComm,
            rfCommConst: ClientDetails?.refComm,
            clientShareConst: ClientDetails?.clientShare,
            ulShareConst: ClientDetails?.ulShare,
            rfShareConst: ClientDetails?.refShare,
          };
        }
      );

  const mergedData =
    data?.length > 0 &&
    data?.reduce((accumulator, item) => {
      const clientName = item.client;

      if (!accumulator[clientName]) {
        accumulator[clientName] = { ...item };
      } else {
        accumulator[clientName].matchBet += item.matchBet;
        accumulator[clientName].resultTeam += item.resultTeam;
      }

      return accumulator;
    }, {});

  // Convert the mergedData object into an array (if needed)
  const mergedDataArray = Object.values(mergedData);
  mergedDataArray.map((item) => {
    item.clientComm =
      item.resultTeam > 0
        ? parseFloat(
            (-1 * (item.resultTeam * parseFloat(item?.clientCommConst))) / 100
          )
        : 0;
    item.rfComm =
      item.resultTeam > 0
        ? parseFloat(
            (-1 * (item.resultTeam * parseFloat(item?.rfCommConst))) / 100
          )
        : 0;
    item.afterComm =
      item.resultTeam + (parseFloat(item.clientComm) + parseFloat(item.rfComm));
    item.clientShare = parseFloat(
      (-1 *
        ((item.resultTeam + parseFloat(item.clientComm)) *
          parseFloat(item?.clientShareConst))) /
        100
    );
    item.rfShare = parseFloat(
      (-1 *
        ((item?.resultTeam + parseFloat(item.rfComm)) *
          parseFloat(item?.rfShareConst))) /
        100
    );
    item.afterShare =
      item?.resultTeam +
      (parseFloat(item?.clientShare) + parseFloat(item?.rfShare));
    item.clientNet =
      item?.resultTeam +
      (parseFloat(item.clientComm) + parseFloat(item.clientShare));
    item.rfNet = parseFloat(item?.rfComm) + parseFloat(item?.rfShare);
    item.ULShare = parseFloat(
      (-1 * ((item.clientNet + item.rfNet) * parseFloat(item.ulShareConst))) /
        100
    );
    item.ulNet = parseFloat(item.ULShare);
    item.masterProfitLoss =
      item?.resultTeam +
      (parseFloat(item.rfNet) +
        parseFloat(item?.clientNet) +
        parseFloat(item.ulNet));
    item.urPosition =
      item.resultTeam + (item.clientComm + item.clientShare + item.rfNet);
    item.ulComm =
      item?.resultTeam > 0
        ? parseFloat(
            (-1 *
              ((item?.resultTeam + item.clientNet) *
                parseFloat(item.ulCommConst))) /
              100
          )
        : 0;
  });

  let totalMatchResultData = {};
  if (mergedDataArray?.length > 0) {
    totalMatchResultData = {
      matchBet: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.matchBet || 0),
        0
      ),
      clientComm: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.clientComm || 0),
        0
      ),
      rfComm: mergedDataArray.reduce((acc, obj) => acc + (obj.rfComm || 0), 0),
      afterComm: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.afterComm || 0),
        0
      ),
      clientShare: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.clientShare || 0),
        0
      ),
      rfShare: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.rfShare || 0),
        0
      ),
      afterShare: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.afterShare || 0),
        0
      ),
      clientNet: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.clientNet || 0),
        0
      ),
      rfNet: mergedDataArray.reduce((acc, obj) => acc + (obj.rfNet || 0), 0),
      ulNet: mergedDataArray.reduce((acc, obj) => acc + (obj.ulNet || 0), 0),
      resultTeam: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.resultTeam || 0),
        0
      ),
      ULShare: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.ULShare || 0),
        0
      ),
      ulComm: mergedDataArray.reduce((acc, obj) => acc + (obj.ulComm || 0), 0),
      urPosition: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.urPosition || 0),
        0
      ),
      masterProfitLoss: mergedDataArray.reduce(
        (acc, obj) => acc + (obj.masterProfitLoss || 0),
        0
      ),
    };
  }
  return (
    <div className="meetings-container w-92 mt-2rem">
      <div className="custom-box-shadow  fancy-meetings-details-div">
        <div className="flex-row flex-space-between">
          <div className=" custom-box-shadow fancy-upcoming-meetings-div font-14 w-60 p-10 mar-5">
            <b>Match</b> Position
            {teamName === "Draw" ? (
              <span className="font-12 ml-5">DRAW</span>
            ) : (
              <span className="font-12 ml-5">{win ? team1 : team2}</span>
            )}
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handleMatchSharePopupOpen(win ? team1 : team2)}
          >
            Shares <BiChevronDown className="fancy-down-arrow" />
          </div>
          <div
            className="fancy-match-history-button custom-box-shadow flex-center  font-10"
            onClick={() => handleMatchCommissionPopupOpen(win ? team1 : team2)}
          >
            Comm’s <BiChevronDown className="fancy-down-arrow" />
          </div>
        </div>
        <div className="hr-line mt-5"></div>
        <div className="flex-space-between mt-5 w-100 pr-5 pl-5">
          <div className="fancy-match-history-button custom-box-shadow flex-center flex-column font-10">
            <div>Name</div>
            <div>Gross P/L</div>
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            C - Position
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Rf - Position
          </div>
          <div className="fancy-match-history-button custom-box-shadow flex-center  font-10">
            Urs Position
          </div>
        </div>
        <div className="hr-line mt-10"></div>
        <div className="fancy-upcoming-meet-scroll">
          {mergedDataArray?.length > 0 &&
            mergedDataArray?.map((client, index) => (
              <div key={index} className="flex-row p-5">
                <div className="flex-space-between align-end mt-5 pl-10">
                  <div className="meetings-time w-25 font-2">
                    <div>{client?.client}</div>
                    <div
                      className={
                        client?.resultTeam >= 0 ? "green-clr" : "red-clr"
                      }
                    >
                      {client?.resultTeam ? client?.resultTeam?.toFixed(2) : 0}
                    </div>
                  </div>
                  <div
                    className={
                      client?.clientNet >= 0
                        ? "font-12 w-20 green-clr"
                        : "font-12 w-20 red-clr"
                    }
                  >
                    {client?.clientNet ? client?.clientNet?.toFixed(2) : 0}
                  </div>
                  <div
                    className={
                      client?.rfNet >= 0
                        ? "font-12 w-20 green-clr"
                        : "font-12 w-20 red-clr"
                    }
                  >
                    {client?.rfNet ? client?.rfNet?.toFixed(2) : 0}
                  </div>
                  <div
                    className={
                      client?.urPosition >= 0
                        ? "font-12 w-20 green-clr"
                        : "font-12 w-20 red-clr"
                    }
                  >
                    {client?.urPosition ? client?.urPosition?.toFixed(2) : 0}
                  </div>
                </div>
                <div className="hr-line mt-5"></div>
              </div>
            ))}
        </div>
        <div className="flex-space-between align-end mt-5 pl-10 fancy-total-row">
          <div className="meetings-time w-25 font-2">
            <div className="font-14 fw-600">Total</div>
            <div
              className={
                totalMatchResultData?.resultTeam >= 0 ? "green-clr" : "red-clr"
              }
            >
              {totalMatchResultData?.resultTeam
                ? totalMatchResultData?.resultTeam?.toFixed(2)
                : 0}
            </div>
          </div>
          <div
            className={
              totalMatchResultData?.clientNet >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalMatchResultData?.clientNet
              ? totalMatchResultData?.clientNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalMatchResultData?.rfNet >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalMatchResultData?.rfNet
              ? totalMatchResultData?.rfNet?.toFixed(2)
              : 0}
          </div>
          <div
            className={
              totalMatchResultData?.urPosition >= 0
                ? "green-clr font-12 w-20"
                : "red-clr font-12 w-20"
            }
          >
            {totalMatchResultData?.urPosition
              ? totalMatchResultData?.urPosition?.toFixed(2)
              : 0}
          </div>
        </div>
        <div className="flex-space-between w-100">
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14 fw-600">UL Position</div>
            <div
              className={
                totalMatchResultData?.ulNet >= 0
                  ? "green-clr font-12 text-center"
                  : "red-clr font-12 text-center"
              }
            >
              {totalMatchResultData?.ulNet
                ? totalMatchResultData?.ulNet?.toFixed(2)
                : 0}
            </div>
          </div>
          <div className="flex-space-between fancy-total-row w-50">
            <div className="font-14 fw-600">Urs Position</div>
            <div
              className={
                totalMatchResultData?.urPosition >= 0
                  ? "green-clr font-12"
                  : "red-clr font-12"
              }
            >
              {totalMatchResultData?.urPosition + totalMatchResultData?.ulNet
                ? (
                    totalMatchResultData?.urPosition +
                    totalMatchResultData?.ulNet
                  )?.toFixed(2)
                : 0}
            </div>
          </div>
        </div>
      </div>
      {matchSharePopupOpen && (
        <MatchSharePopup
          data={mergedDataArray}
          totalMatchResultData={totalMatchResultData}
          handleMatchSharePopupClose={handleMatchSharePopupClose}
        />
      )}
      {matchCommissionPopupOpen && (
        <MatchCommissionPopup
          data={mergedDataArray}
          totalMatchResultData={totalMatchResultData}
          handleMatchCommissionPopupClose={handleMatchCommissionPopupClose}
        />
      )}
    </div>
  );
}

export default MatchPositionTable;
