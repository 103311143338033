import React, { useEffect, useState } from "react";
import { IonApp, setupIonicReact, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";
import Routes from "./routes/Routes";
import "./App.css";
import { SIGNAL_RECORDING } from "../src/config/endpoints";
import { call } from "../src/config/axios";

setupIonicReact();
let title: any = localStorage?.getItem("account_role");
document.title = title?.charAt(0)?.toUpperCase() + title?.slice(1) || "App";

const App: React.FC = () => {
  useEffect(() => {
    redirectToAppropriateView();
    calculatetime();
    const intervalId = setInterval(() => {
        calculatetime();
    }, 60000); 
    return () => clearInterval(intervalId);
  }, []);


  const calculatetime = () => {

  const totalMinutesStr = localStorage.getItem('totalMinutes');
  if (totalMinutesStr) {
    const totalMinutes = parseInt(totalMinutesStr);
    const startTimeStr = localStorage.getItem('startTime');
    if (startTimeStr) {
      const startTime = new Date(startTimeStr).getTime();
      const currentTime = new Date().getTime();
      const elapsedTimeMs = currentTime - startTime;
      const elapsedTimeMinutes = elapsedTimeMs / (1000 * 60);
      let remainingMinutes = totalMinutes - elapsedTimeMinutes;
      remainingMinutes = Math.max(0, remainingMinutes);

      localStorage.setItem('minutesRemaining', remainingMinutes.toString());

      if (remainingMinutes <= 0) {
        
         const stopRecordParamsObj = {
           meeting_id: localStorage.getItem("meeting_id"),
          type: "stopRecord",
          register_id: localStorage.getItem("register_id"),
          userType: "admin",
          remainingTime: 0,
        };
        localStorage.removeItem("isAdminMeeting");
        localStorage.removeItem("totalMinutes");
        localStorage.removeItem("startTime");
           localStorage.removeItem("meeting_id");
        localStorage.removeItem("minutesRemaining");
        const isAdminCreated = localStorage.getItem("isAdminCreated");
        if (isAdminCreated) {
           call(SIGNAL_RECORDING, stopRecordParamsObj)
              .then((res) => {
                console.log(res,'api response')
                return res.data;
              })
              .catch((err) => {
                throw err;
              });
        }
        const removeItem = localStorage.getItem("joinAsaUser");
        if (removeItem) {
          localStorage.removeItem("joinAsaUser");
        }
        localStorage.removeItem("isAllUserAccessStatus");
        localStorage.removeItem("isAdminCreated");
      
        window.close();
        console.log('Time is utilized');
      } else {
        const hours = Math.floor(remainingMinutes / 60);
        const minutes = Math.floor(remainingMinutes % 60);
        console.log(`Remaining time: ${hours} hours and ${minutes} minutes`);
      }
    }
  }
};

 

  const redirectToAppropriateView = () => {
    if (isPlatform("mobile")) {
    } else {
      window.location.href = "https://admin.we2call.com";
    }
  };
  return (
    <>
      {isPlatform("mobile") ? (
        <IonApp>
          <IonReactRouter>
            <Routes />
          </IonReactRouter>
        </IonApp>
      ) : null}
    </>
  );
};

export default App;
