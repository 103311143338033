import { useState } from "react";
const Table = (props) => {
  const { columns, data, buttons } = props;
  const [activeColumn, setActiveColumn] = useState("clients");
  const toggleActiveColumn = (header) => {
    setActiveColumn(header);
  };

  return (
    <div className="account-summary-main-container mt-5">
      {buttons}
      <table className="account-settlement-summary account-settlement-summary-data fixed-table">
        <thead>
          <tr>
            {columns?.map(({ header, name, onClick }, index) => (
              <th
                key={header}
                onClick={() => {
                  onClick && toggleActiveColumn(header);
                  onClick && onClick(header);
                }}
              >
                <div
                  className={`custom-box-shadow ${
                    activeColumn === header ? "active-css" : ""
                  }`}
                >
                  {name}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0
            ? data?.map((rowData, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    rowData.onClick &&
                      rowData.onClick(rowData.onClick(rowData.client_name));
                  }}
                >
                  {columns?.map(({ header }) => (
                    <td key={header}>{rowData[header]}</td>
                  ))}
                </tr>
              ))
            : data && (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
