import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import {
  CREATE_MATCH_ENTRY,
  GET_OFFLINE_CLIENTS,
  UPDATE_MATCH_ENTRY,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import { IonText } from "@ionic/react";
import Select from "react-select";
import MatchEntryCreatePopup from "./MatchEntryCreatePopup";

function MatchEntryInputs({
  selectedMatch,
  registered_match_id,
  selectedMatchEntry,
  setStatus,
  getMatchPositionData,
}) {
  const register_id = localStorage?.getItem("register_id");
  const account_role = localStorage?.getItem("account_role");
  const [error, setError] = useState("");
  const [enterTeamOpen, setEnterTeamOpen] = useState(false);
  const [enterTeamHeading, setEnterTeamHeading] = useState("");
  const [yesOrNoOpen, setYesOrNoOpen] = useState(false);
  const [yesOrNoHeading, setYesOrNoHeading] = useState("");
  const [matchSubmitPopup, setMatchSubmitPopup] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const toggleTeamOpen = () => {
    setEnterTeamOpen(!enterTeamOpen);
    setYesOrNoOpen(false);
  };
  const toggleYesOrNoOpen = () => {
    setYesOrNoOpen(!yesOrNoOpen);
    setEnterTeamOpen(false);
  };
  const handleEnterTeamHeading = (content) => {
    setEnterTeamHeading(content);
    setEnterTeamOpen(false);
    setYesOrNoOpen(false);
  };
  const handleYesOrNoHeading = (content) => {
    setYesOrNoHeading(content);
    setEnterTeamOpen(false);
    setYesOrNoOpen(false);
  };

  const resetFields = () => {
    setRate("");
    setAmount("");
    setEnterTeamHeading("");
    setYesOrNoHeading("P");
  };

  const handleMatchSubmitPopup = async () => {
    if (
      !amount ||
      !rate ||
      !yesOrNoHeading ||
      !enterTeamHeading ||
      !selectedOptions?.label
    ) {
      return setError("Please enter required fields");
    }
    setIsProcessing(true);
    setError("");
    await call(CREATE_MATCH_ENTRY, {
      ...selectedMatch,
      client_id: selectedOptions?.value,
      client_name: selectedOptions?.label,
      amount: +amount,
      rate: "1." + rate,
      pe: yesOrNoHeading,
      registered_match_id: registered_match_id,
      account_role,
      team: enterTeamHeading,
      loginUserId: register_id,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.statusCode === 200) {
          setStatus((prev) => !prev);
          setMatchSubmitPopup(true);
          getMatchPositionData();
          resetFields();
          setTimeout(() => {
            setMatchSubmitPopup(false);
          }, 1000);
          setError("");
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wen't wrong`);
        console.log(err);
      });
  };

  const handleMatchEntryUpdate = async () => {
    if (
      !amount ||
      !rate ||
      !yesOrNoHeading ||
      !enterTeamHeading ||
      !selectedOptions?.label
    ) {
      return setError("Please enter required fields");
    }
    setIsProcessing(true);
    setError("");
    await call(UPDATE_MATCH_ENTRY, {
      register_id,
      client_id: selectedOptions?.value,
      client_name: selectedOptions?.label,
      amount: amount,
      rate: "1." + rate,
      pe: yesOrNoHeading,
      registered_match_id,
      account_role,
      team: enterTeamHeading,
      match_entry_id: selectedMatchEntry?.match_entry_id,
      loginUserId: register_id,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.statusCode === 200) {
          setStatus((prev) => !prev);
          setMatchSubmitPopup(true);
          getMatchPositionData();
          setTimeout(() => {
            setMatchSubmitPopup(false);
          }, 1000);
          resetFields();
        } else {
          setError(`something wen't wrong`);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wen't wrong`);
        console.log(err);
      });
  };

  const getAllClientsData = async () => {
    await call(GET_OFFLINE_CLIENTS, {
      register_id,
      account_role,
    })
      .then((res) => {
        if (res.status === 200) {
          let temp = res?.data?.data;
          setExistingUsers(temp);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllClientsData();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedMatchEntry) {
      setSelectedOptions({
        label: selectedMatchEntry?.client_name,
        value: selectedMatchEntry?.client_id,
      });
      setEnterTeamHeading(selectedMatchEntry?.team);
      setYesOrNoHeading(selectedMatchEntry?.pe);
      setRate(selectedMatchEntry?.rate?.substring(2));
      setAmount(selectedMatchEntry?.amount);
    }
  }, [selectedMatchEntry]);

  const [selectedOptions, setSelectedOptions] = useState();
  const optionList = existingUsers
    ?.filter((item) => item?.active == true || item?.active == "true")
    ?.map((item) => {
      return {
        value: item?.client_id,
        label: item?.client_name,
      };
    });
  const handleSelect = (data) => {
    setSelectedOptions(data);
  };

  console.log(existingUsers, "existingUsers");

  const handleInputChange = (e) => {
    if (e.target.value.length <= 4) {
      let value = e.target.value;
      if (value?.startsWith("1.")) {
        setRate(value?.substring(2));
      } else {
        setRate(value);
      }
    }
  };

  return (
    <div>
      <div className="flex-space-between">
        <input
          className="fancy-inputs custom-box-shadow w-48 pl-10"
          placeholder="1."
          type="text"
          name="rate"
          id="rate"
          value={"1." + rate}
          onChange={(e) => handleInputChange(e)}
          maxLength={4}
        />
        <div
          className="align-center fancy-inputs custom-box-shadow w-48 pl-10 pt-5 flex-column"
          onClick={() => toggleTeamOpen()}
        >
          <div className="flex-space-between w-100">
            {enterTeamHeading || <div>Enter Team</div>}
            <div>
              <BiChevronDown className="fancy-down-arrow" />
            </div>
          </div>
          {enterTeamOpen ? (
            <div className="w-100 flex-space-around fancy-teams-div custom-box-shadow fancy-team-margin">
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center"
                onClick={() => handleEnterTeamHeading(selectedMatch?.team1)}
              >
                {selectedMatch?.team1}
              </div>
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleEnterTeamHeading(selectedMatch?.team2)}
              >
                {selectedMatch?.team2}
              </div>
              {selectedMatch?.game_object?.match_type === "testMatch" && (
                <div
                  className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                  onClick={() => handleEnterTeamHeading("DRAW")}
                >
                  DRAW
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex-space-between mt-10">
        <input
          className="fancy-inputs custom-box-shadow w-48 pl-10"
          placeholder="Amount"
          type="number"
          name="amount"
          id="amount"
          value={amount || ""}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div
          className="align-center fancy-inputs custom-box-shadow w-48 pl-10 pt-5 flex-column"
          onClick={() => toggleYesOrNoOpen()}
        >
          <div
            className={
              yesOrNoHeading === "P"
                ? "clr-green flex-space-between w-100"
                : yesOrNoHeading === "E"
                ? "clr-pink flex-space-between w-100"
                : "flex-space-between w-100"
            }
          >
            {yesOrNoHeading || <div>P/E</div>}
            <div>
              <BiChevronDown className="fancy-down-arrow" />
            </div>
          </div>
          {yesOrNoOpen ? (
            <div className="w-100 flex-space-around fancy-teams-div custom-box-shadow fancy-team-margin">
              <div
                className="clr-green w-100 match-enterteam custom-box-shadow flex-center"
                onClick={() => handleYesOrNoHeading("P")}
              >
                P
              </div>
              <div
                className="clr-pink w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleYesOrNoHeading("E")}
              >
                E
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <Select
          className="w-100 mt-10 client-name-select"
          classNamePrefix="react-select"
          options={optionList}
          placeholder="Select Client Name..."
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
        />
      </div>
      <button
        className="mt-10 fancy-inputs button-box-shadow flex-center w-100"
        onClick={() =>
          Object.keys(selectedMatchEntry).length === 0
            ? handleMatchSubmitPopup()
            : handleMatchEntryUpdate()
        }
        disabled={!selectedMatch?.match_id || isProcessing}
      >
        {isProcessing
          ? "Processing..."
          : Object.keys(selectedMatchEntry).length === 0
          ? "Submit"
          : "Update"}
      </button>
      {error && (
        <IonText color="danger" className="ion-text-center">
          {error}
        </IonText>
      )}
      <MatchEntryCreatePopup
        openPopup={matchSubmitPopup}
        setclosePopup={setMatchSubmitPopup}
        displayData={
          Object.keys(selectedMatchEntry).length === 0
            ? "Match entry added"
            : "Macth entry updated"
        }
      />
    </div>
  );
}

export default MatchEntryInputs;
