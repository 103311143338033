import React, { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { IonCol } from "@ionic/react";
import { useHistory } from "react-router";
function ViewUsersAdmins() {
  const viewUsersData = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];
  const accountRole = localStorage.getItem()
  const tableRowData = [
    {
      title: "User Name",
    },
    {
      title: "Packages",
    },
    {
      title: "Action",
    },
  ];
  const history = useHistory();
  const [blockUnblock, setBlockUnblock] = useState("B");
  const handleBlockUnblock = () => {
    blockUnblock === "B" ? setBlockUnblock("UB") : setBlockUnblock("B");
  };
  return (
    <div>
      <MeetingsHeader />
      <div className="main-container">
        <div className="flex-start flex-row mt-10 ml-20 mb-10">
          <div className="d-flex font-16 mr-5 fw-600">
            Srinivash
            <div className="ml-5" style={{ color: "#ffae00" }}>
              SM
            </div>
          </div>
          <MdOutlineArrowForwardIos className="font-16" />

          <div className="d-flex font-16 mr-5 fw-600">
            Sai
            <div className="ml-5" style={{ color: "#007eff" }}>
              
              M
            </div>
          </div>
        </div>
        {accountRole !=="agent" && <div className="custom-box-shadow  meetings-details-div mt-10">
          <div className="d-flex ">
            {tableRowData.map((rowData, index) => (
              <div key={index} className="flex-center mt-5 w-100">
                <div className="match-history-button flex-center custom-box-shadow font-10">
                  {rowData.title}
                </div>
              </div>
            ))}
          </div>
          <div className="hr-line mt-10"></div>
          <div className="upcoming-meet-scroll ml-10 mr-5 h-50-prcnt">
            <div className="meet-scroll">
              {viewUsersData.map((data, index) => (
                <div key={index} className="flex-row">
                  <div className="flex-space-between mt-5">
                    <div className="w-25 flex-center flex-column">
                      <div
                        className="meetings-time padding-tb"
                        onClick={() => history.push("/add-view-users-admins")}
                      >
                        Jayantha
                      </div>
                      <div className="flex-center flex-row">
                        <div className="meetings-time padding-tb mr-5">
                          Agent
                        </div>
                        <GoDotFill
                          className={
                            index === 3 ||
                            index === 5 ||
                            index === 6 ||
                            index === 8 ||
                            index === 9 ||
                            index === 11 ||
                            index === 14 ||
                            index === 17
                              ? "font-size-14 green-clr"
                              : "font-size-14 red-clr"
                          }
                        />
                      </div>
                    </div>
                    <div className="font-12 w-10">10</div>
                    <div className="d-flex flex-space-between">
                      <div className="custom-box-shadow p-5 bt-cls">CP</div>
                      <span className="ml-10 custom-box-shadow p-5 br-10">
                        Edit
                      </span>
                      <div className="mt-5">
                        <div
                          className={`
                          ${blockUnblock === "B" ? "clr-red" : "clr-green"}
                            ml-10 custom-box-shadow p-5 bt-cls`}
                          onClick={() => handleBlockUnblock(index)}
                        >
                          {blockUnblock}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hr-line mt-5"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-space-between flex-row total-count-container h-30p w-100">
            <div className="flex-space-between flex-row h-30p w-40">
              <div className="font-14">Total</div>
              <div className="match-history-button flex-center custom-box-shadow font-10 h-20p">
                10000000.00
              </div>
            </div>
            <div className="flex-space-around flex-row h-30p w-40">
              <div className="font-14">Balance</div>
              <div className="match-history-button flex-center custom-box-shadow font-10 h-20p ml-10">
                10000000.00
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default ViewUsersAdmins;
