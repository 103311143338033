import React from "react";
import MeetingsHeader from "./MeetingsHeader";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Images } from "../../images";

function CallStatement() {
  const callHisoryData = [
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      meetingTitle: (
        <div>
          <div>
            Newzelend vs South Africa Test Series Newzelend Onquard Stadium
          </div>
          <div>20-05-2023</div>
          <div>10:30 AM</div>
        </div>
      ),
      estimatedTime: "4h 10m",
      price: 5000,
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      meetingTitle: (
        <div>
          <div>
            Newzelend vs South Africa Test Series Newzelend Onquard Stadium
          </div>
          <div>20-05-2023</div>
          <div>10:30 AM</div>
        </div>
      ),
      estimatedTime: "4h 10m",
      price: 5000,
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      meetingTitle: (
        <div>
          <div>
            Newzelend vs South Africa Test Series Newzelend Onquard Stadium
          </div>
          <div>20-05-2023</div>
          <div>10:30 AM</div>
        </div>
      ),
      estimatedTime: "4h 10m",
      price: 5000,
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      meetingTitle: (
        <div>
          <div>
            Newzelend vs South Africa Test Series Newzelend Onquard Stadium
          </div>
          <div>20-05-2023</div>
          <div>10:30 AM</div>
        </div>
      ),
      estimatedTime: "4h 10m",
      price: 5000,
    },
    {
      date: "20-06-2023",
      time: "10:30:00 PM",
      meetingTitle: (
        <div>
          <div>
            Newzelend vs South Africa Test Series Newzelend Onquard Stadium
          </div>
          <div>20-05-2023</div>
          <div>10:30 AM</div>
        </div>
      ),
      estimatedTime: "4h 10m",
      price: 5000,
    },
  ];
  return (
    <>
      <h5 className="clr-white text-center">Call Statement</h5>
      <div className="main-container p-5 pb-10">
        <div className="flex-space-between flex-column custom-box-shadow  meetings-details-div mt-10">
          <div className="flex-space-between flex-row custom-box-shadow settelment-time-div w-90 mt-10 p-10">
            <div className="settelment-text">
              Settelment Information Charges
            </div>
            <div className="meetings-date fw-600">100/Hours</div>
          </div>
          <div className="flex-space-evenly flex-row w-95">
            <div className="flex-space-evenly flex-column custom-box-shadow align-start pl-10 total-duration-amount-div">
              <div className="meetings-time fw-500">Total Duration</div>
              <div className="meetings-date fw-600">159h 59m 32s</div>
            </div>
            <div className="flex-space-evenly flex-column custom-box-shadow align-start pl-10 total-duration-amount-div">
              <div className="meetings-time fw-500">Total Amount</div>
              <div className="meetings-date fw-600">15970</div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex-space-between mt-20 mr-10 ml-5">
            <div className="match-history-button custom-box-shadow flex-center flex-column">
              Start <div>Date & Time</div>
            </div>
            <div className="match-history-button custom-box-shadow flex-center mr-20">
              Meeting Title
            </div>
            <div className="match-history-button custom-box-shadow flex-center">
              Duration
            </div>
            <div className="match-history-button custom-box-shadow flex-center">
              Price
            </div>
          </div>
          <div className="scroll-call-history">
            {callHisoryData?.map((value, index) => (
              <div
                className="flex-space-between custom-box-shadow meetings-details-div mt-10"
                key={index}
              >
                <div className="flex-column flex-space-around w-20">
                  <div className="flex-space-evenly flex-column custom-box-shadow meetings-date-time-div font-10 w-100 ml-10 mt-5">
                    <div className="meetings-date">{value.date}</div>
                    <div className="meetings-time">{value.time}</div>
                  </div>
                  <div className="flex-center play-delete-div mt-5 mb-5">
                    <img
                      className="play-delete-icon"
                      src={Images.PlayIcon}
                      alt="Play"
                    />
                  </div>
                </div>
                <div className="meetings-time w-30 padding-tb font-12 ml-10">
                  {value.meetingTitle}
                </div>
                <div className="custom-box-shadow meetings-date meetings-time-text">
                  {value.estimatedTime}
                </div>

                <div className="flex-column flex-space-between play-btn-div">
                  <div className="font-12 mt-40">{value.price}</div>
                  <div className="font-12 complete-text flex-center w-100">
                    Complete
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex-flex-end mt-20">
            <MdOutlineArrowBackIosNew className="custom-ionicon-container" />
            <div className="custom-ionicon-container flex-center p-3">1</div>
            <MdOutlineArrowForwardIos className="custom-ionicon-container" />
          </div>
        </div>
      </div>
    </>
  );
}

export default CallStatement;
