import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import TicketUpgradePopup from "./TicketUpgradePopup";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import PackageViewPopup from "./PackageViewPopup";
import {
  APPROVE_REJECT_FOR_SUBSCRIPTION,
  BULK_PACKAGE_APPROVE_REJECT,
  GET_ALL_ADMIN_PACKAGE_REQUEST,
  GET_ALL_REASONS,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import moment from "moment";
import { useIonToast } from "@ionic/react";
import MeetingsHeader from "../meetings/MeetingsHeader";

function AdminTicketPurchaseData(props) {
  const register_id = localStorage.getItem("register_id");

  const [showTicketUpgradePopup, setShowTicketUpgradePopup] = useState(false);
  const [requestedPackages, setRequestedPackages] = useState([]);
  const [rejectionList, setRejectionList] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [present] = useIonToast();
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState("");

  const presentToast = ({ message, color = "danger" }) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: color,
    });
  };

  const handleTicketUpgradePopup = (itm) => {
    setShowTicketUpgradePopup(true);
    setTransactionData(itm);
  };
  const [showPackageViewPopup, setShowPackageViewPopup] = useState(false);
  const handlePackageViewPopup = () => {
    setShowPackageViewPopup(true);
  };

  const [successfulPopup, setSuccessfulPopup] = useState(false);
  const UserDisplayData = "Submitted Ticket Successfully";

  const handleSuccessfullPopup = async (
    transaction_id,
    type,
    status,
    reason
  ) => {
    if (status === "Reject" && !reason) {
      presentToast({ message: "Please select Reason" });
      return;
    }

    console.log(type);
    const url = type
      ? APPROVE_REJECT_FOR_SUBSCRIPTION
      : BULK_PACKAGE_APPROVE_REJECT;
    setIsProcessing(true);
    setStatus(status);
    await call(url, {
      register_id,
      transaction_id,
      status,
      reason,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setIsProcessing(false);
          setStatus("");
          if (res.data.status == 403) {
            presentToast({ message: res.data.message });
            return;
          } else {
            reason && setShowTicketUpgradePopup(false);
            if (status === "Approved") setSuccessfulPopup(true);
            setStatus("");
            getAllPackageRequests();
          }
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setStatus("");
        console.log(err);
      });
  };
  const getAllPackageRequests = async () => {
    await call(GET_ALL_ADMIN_PACKAGE_REQUEST, { register_id })
      .then((res) => {
        const resp = res?.data?.data
          .map((item) => {
            const dateTimeString = `${item.created_date} ${item.created_time}`;
            const timestamp = new Date(dateTimeString).getTime();
            item.timestamp = timestamp;
            return item;
          })
          ?.sort((a, b) => b.timestamp - a.timestamp);
        setRequestedPackages(resp);
      })
      .catch((err) => console.log(err));
  };

  const getAllReasons = async () => {
    await call(GET_ALL_REASONS, { p_id: "REJECT-REASON" })
      .then((res) => {
        const resp = res?.data?.data;
        setRejectionList(resp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllPackageRequests();
    getAllReasons();
  }, []);

  const downTransactionData = requestedPackages?.map((obj) => {
    return {
      ...obj,
      user: obj?.summary?.requester_name,
      date: moment(obj.created_date).format("DD-MM-YYYY"),
      time: obj?.created_time,
      packageAmt: obj?.summary?.total_packages_cost,
      txrID: obj?.transaction_id,
      price: obj?.summary?.final_package_cost,
    };
  });

  return (
    <div>
      <MeetingsHeader heading={"Package Sale Tickets"} />
      <div className="p-10 mt-10">
        <div className="flex-space-between">
          <div className="meetings-button custom-box-shadow flex-center">
            Date & Time
          </div>
          <div className="meetings-button custom-box-shadow flex-center">
            Trx ID
          </div>
          <div className="meetings-button custom-box-shadow flex-center">
            Pay Amount
          </div>
          <div className="meetings-button custom-box-shadow flex-center">
            Status
          </div>
        </div>
      </div>
      <div className="sale-ticket">
        {console.log(downTransactionData)}
        {downTransactionData?.map((value, index) => (
          <div
            className="flex-space-between custom-box-shadow meetings-details-div mt-10"
            key={index}
          >
            <div className="flex-space-around flex-column w-20">
              <div className="font-12 ml-5 mt-5">{value.user}</div>
              <div className="flex-space-evenly flex-column custom-box-shadow meetings-date-time-div w-100  mt-10">
                <div className="font-10">{value.date}</div>
                <div className="font-10">{value.time}</div>
              </div>
            </div>
            <div className="w-30 padding-tb font-12">
              <div className="mb-10">{value.txrID} </div>
              <div className="mt-20">
                {value?.type === "subscription" ? "Upgrade" : "Bulk"} Package :
                {value.packageAmt}
              </div>
            </div>
            <div className="custom-box-shadow flex-end meetings-time-text font-12 mt-50">
              {value.price}
            </div>
            <div className="flex-column flex-space-around play-btn-div w-20">
              <AiFillEye
                className="IonIcon-eye custom-box-shadow"
                alt="Play"
                onClick={() => handleTicketUpgradePopup(value)}
              />
              <div
                className="flex-center new-button fw-600 w-70"
                onClick={() => handleTicketUpgradePopup(value)}
              >
                {value.status == "pending" ? "NEW" : value.status}
              </div>
            </div>
          </div>
        ))}
      </div>
      <TicketUpgradePopup
        handlePackageViewPopup={handlePackageViewPopup}
        handleSuccessfullPopup={handleSuccessfullPopup}
        showTicketUpgradePopup={showTicketUpgradePopup}
        transactionData={transactionData}
        setShowTicketUpgradePopup={setShowTicketUpgradePopup}
        isProcessing={isProcessing}
        rejectionList={rejectionList}
        status={status}
      />
      <FancyThankYouPopup
        openPopup={successfulPopup}
        setclosePopup={setSuccessfulPopup}
        displayData={UserDisplayData}
        setShowTicketUpgradePopup={setShowTicketUpgradePopup}
      />
      <PackageViewPopup
        transactionData={transactionData}
        showPackageViewPopup={showPackageViewPopup}
        setShowPackageViewPopup={setShowPackageViewPopup}
      />
    </div>
  );
}

export default AdminTicketPurchaseData;
