import {ZegoExpressEngine} from 'zego-express-engine-webrtc'
import { Zeego_server_url, Zeego_AppId} from "../../config/config_local";
import moment from "moment";
import React,{useEffect, useState, useRef} from "react";
import { useHistory } from "react-router";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { setUplevelMeeting } from "../../redux/actions/dataActions";
import toast from 'react-hot-toast';

import { call } from "../../config/axios";
import {
  GET_ALL_CLIENTS,
  GET_USER_INFO,
  GET_LIVE_MEETING,
  SIGNAL_RECORDING,
  GET_ADMIN_PACKAGES,
} from "../../config/endpoints";
import "./styles.css";
import { FaMicrophone, FaVideo,FaVideoSlash,FaMicrophoneSlash } from "react-icons/fa";

const Join = ({id, UserJoinClick}) => {
  const { meetingId, creatorId } = useParams();
  const dispatch = useDispatch();
    const history = useHistory();
    const inputref = useRef(null);
   const [micOn, setMicOn] = useState(true);
   const [camOn, setCamOn] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [adminSubscription, setAdminSubscription] = useState([]);
   const [profileImage, setProfileImage] = useState("");
    const [inputValue, setInputValue] = useState("");
   const [liveMeeting, setLiveMeeting] = useState({});
   const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";

  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const register_id = localStorage.getItem("register_id");

    const getLiveMeetingData = async () => {
      const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
      return await call(GET_LIVE_MEETING, {
        creator_id: creator_id,
        meeting_id: id,
      })
        .then((res) => {
            console.log("res",res)
          setLiveMeeting(res.data?.data?.Items?.[0]);
        })
        .catch((err) => {
          throw err;
        });
    };

    const getAdminSubscription = async () => {
      return await call(GET_ADMIN_PACKAGES, {
        register_id: register_id,
      })
        .then((res) => {
            console.log("getAdminSubscription", res);
            const subscriptions = res?.data?.data?.flatMap(item => item?.subscriptions?.flatMap(sub => sub.Items));
            setAdminSubscription(subscriptions);
            console.log("subscriptions", subscriptions);
        })
        .catch((err) => {
          throw err;
        });
    
  };
    useEffect(() => {

        if(isAdminCreated){
           getAdminSubscription();
        }
       
        getLiveMeetingData();
        
    }, [id]);

    const getProfile = async (id) => {
    try {
      const res = await call(GET_USER_INFO, { register_id });
      const userProfileData = res?.data?.data[0];
        console.log('userProfileData',userProfileData)
        setProfileImage(userProfileData?.profile_image || "");
    } catch (err) {
      console.log(err);
    }
  };
    
  useEffect(() => {
    getProfile(register_id);
  }, [register_id]);




    const handleOpenJoinPopup = async () => {
      let obj = {
        meeting_id: id,
        user_name: inputref.current.value,
        type: "join_meeting",
        user_id: localStorage.getItem("register_id")+'uplevel',
        creator_id: liveMeeting?.p_id,
        meeting_type: liveMeeting?.meeting_type,
        camera: camOn,
        micro: micOn,
        videoType : liveMeeting.video_call_type

      }
      dispatch(setUplevelMeeting(obj));
      history.push(`/upmeetings/${id}`);
                UserJoinClick(true);
    
  };
      
     

    return (
        <>
           <div className="join-container">
            <div className="img-join">
                <img src="../../assets/we_2_call_logo.png" alt="join" />
            </div>
                <div className="join-box">
                        <img src={profileImage || "../../assets/calling_profile_image.png"}  alt="" />
                        <div className="name-box">
                            <h3>{first_name} {last_name}</h3>
                            <p>inviting for video call...</p>
                        </div>
                        <div className="cam-micro">
                            <p> 

                              {liveMeeting?.video_call_type === "audio-video" ?
                              (
                                <>
                                {camOn ? <></> : <span>Camera is Off...</span>}
                                </>
                              ):(
                                <></>
                              )}
                                
                                {micOn ? <></> : <span>Microphone is Off...</span>}
                            </p>
                        </div>
                        <div className="mic-cam-icon">
                          {liveMeeting?.video_call_type === "audio-video" ?
                              (
                                <>
                                <div className={`box-mic ${camOn ? 'box-green' : 'box-red'}`}
                                 onClick={() => setCamOn(!camOn)}>
                                       {camOn ? <FaVideo /> : <FaVideoSlash />} 
                                </div>
                                </>
                              ):(
                                <></>
                              )}
                             
                            <div className={`box-mic ${micOn ? 'box-green' : 'box-red'}`}
                            onClick={
                                ()=> setMicOn(!micOn)
                            }>
                                  {micOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
                            </div>
                           
                        </div>
                        <div className="input-but">
                            <input 
                            type="text" 
                            ref={inputref}
                            onChange={(e) => setInputValue(e.target.value)} 
                            placeholder='Enter your name'/>
                            <button 
                            className='btn btn-join' 
                            disabled={inputValue === ""}  
                            onClick={()=> handleOpenJoinPopup()}>Join</button>
                        </div>
                </div>
           </div>
        </>

    );
}

export default Join;
