import React from "react";
import Table from "../../../common/Table";
import moment from "moment";

const AdminOnePageReportIndividual = ({
  data,
  ulShare,
  userName,
  userRole,
}) => {
  const matchWiseULPlatformComColumns = [
    { header: "series_name", name: "Series" },
    { header: "team_name", name: "Team Name" },
    { header: "admin_pl", name: "Admin P/L" },
    { header: "urs_pl", name: "Urs P/L" },
  ];
  const adminMatchWisePlformData =
    data &&
    data?.length &&
    data?.map((match) => {
      return {
        series_name: match?.series_name,
        match_place: match?.match_place,
        date: moment(match?.matchTimeStamp).format("DD-MM-YYYY"),
        time: moment(match?.matchTimeStamp).format("hh:mm:ss A"),
        team: `${match?.team1} vs ${match?.team2}`,
        win_team: match?.winTeam,
        admin_pl: match?.totalAmount?.totalLossOrProfit || 0,
        urs_pl: (+match?.totalAmount?.totalLossOrProfit * +ulShare) / 100,
      };
    });
  const matchWiseULPlatformComData =
    adminMatchWisePlformData &&
    adminMatchWisePlformData?.length > 0 &&
    adminMatchWisePlformData?.map((adminUldata) => ({
      series_name: (
        <div className="mb-10 text-align-left">
          <div>{adminUldata?.series_name}</div>
          <div>{adminUldata?.match_place}</div>
          <div>{adminUldata?.date}</div>
          <div>{adminUldata?.time}</div>
          <div>{adminUldata?.team}</div>
        </div>
      ),
      team_name: (
        <div>
          <div>{adminUldata?.team}</div>
          <div>
            <span>Win - </span>
            <span className="clr-green">{adminUldata?.win_team}</span>
          </div>
        </div>
      ),
      admin_pl: (
        <div
          className={`${+adminUldata?.admin_pl >= 0 ? "green-clr" : "red-clr"}`}
        >
          {adminUldata?.admin_pl ? adminUldata?.admin_pl?.toFixed(2) : 0}
        </div>
      ),
      urs_pl: (
        <div
          className={`${+adminUldata?.urs_pl >= 0 ? "green-clr" : "red-clr"}`}
        >
          {adminUldata?.urs_pl ? adminUldata?.urs_pl?.toFixed(2) : 0}
        </div>
      ),
    }));
  const matchWiseULPlatformHeadings = (
    <div className="flex-space-between">
      <button className="common-active-css inactive-css">
        Match Wise Share P/L
      </button>
      <button className="common-active-css inactive-css">
        {`${userName}-${userRole}`}
      </button>
    </div>
  );
  return (
    <div className="mt-10">
      <Table
        columns={matchWiseULPlatformComColumns}
        data={matchWiseULPlatformComData}
        buttons={matchWiseULPlatformHeadings}
      />
    </div>
  );
};

export default AdminOnePageReportIndividual;
