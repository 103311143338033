import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import MatchDeclarePopup from "../matchpopups/MatchDeclarePopup";
import { MATCH_DECLARATION } from "../../config/endpoints";
import { call } from "../../config/axios";
import { IonText } from "@ionic/react";
import MatchDeclareSuccessPopup from "./MatchDeclareSuccessPopup";
import AfterConfirmDeclarePopup from "./AfterConfirmDeclarePopup";
import MatchEntryCreatePopup from "./MatchEntryCreatePopup";
function MatchResultInputs({
  registered_match_id,
  selectedMatch,
  selectedMatchEntry,
  setAfterDeclare,
}) {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [isProcessing, setIsProcessing] = useState(false);
  const [winTeamOpen, setWinTeamOpen] = useState(false);
  const [winTeamHeading, setWinTeamHeading] = useState("");
  const [resultTypeOpen, setResultTypeOpen] = useState(false);
  const [resultTypeHeading, setResultTypeHeading] = useState("");
  const [confirmDeclaration, setConfirmDeclaration] = useState(false);
  const [afterConfirm, setAfterConfirm] = useState(false);

  const [error, setError] = useState("");

  const toggleWinTeamOpen = () => {
    setWinTeamOpen(!winTeamOpen);
    setResultTypeOpen(false);
  };
  const toggleResultTypeOpen = () => {
    setResultTypeOpen(!resultTypeOpen);
    setWinTeamOpen(false);
  };
  const handleWinTeamHeading = (content) => {
    setWinTeamHeading(content);
    setWinTeamOpen(false);
    setResultTypeOpen(false);
  };
  const handleResultTypeHeading = (content) => {
    setResultTypeHeading(content);
    setWinTeamOpen(false);
    setResultTypeOpen(false);
  };
  const handleConfirmDeclaration = () => {
    if (!winTeamHeading) {
      return setError("Please enter required fields");
    }
    setConfirmDeclaration(true);
  };

  const handleMatchDeclarePopupOpen = async () => {
    setConfirmDeclaration(false);
    setIsProcessing(true);
    setAfterConfirm(true);
    setError("");
    await call(MATCH_DECLARATION, {
      registered_match_id: registered_match_id,
      register_id,
      account_role,
      sport_name: selectedMatch?.sport_name,
      teamName: winTeamHeading,
      declarestatus: resultTypeHeading,
      series_name: selectedMatch?.series_name,
      creator_id_platform: creator_id,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.statusCode === 200) {
          setConfirmDeclaration(false);
          setTimeout(() => {
            setAfterConfirm(false);
          }, 2000);
          setError(res?.data?.message);
          setTimeout(() => {
            setError("");
          }, 4000);
        } else {
          setConfirmDeclaration(false);
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(`something wen't wrong`);
        console.log(err);
      });
  };

  return (
    <div>
      <div className="flex-space-between">
        <div className="align-center fancy-inputs custom-box-shadow w-48 pl-10 pt-5 flex-column">
          <div className="flex-space-between w-100 pt-5">
            <div>{selectedMatch?.sport_name || "Sports Name"}</div>
          </div>
        </div>
        <div className="align-center fancy-inputs custom-box-shadow w-48 pl-10">
          {selectedMatch?.series_name || "Series Name"}
        </div>
      </div>
      <div className="flex-space-between mt-10">
        <div
          className="align-center fancy-inputs custom-box-shadow w-48 pl-10 pt-5 flex-column"
          onClick={() => toggleWinTeamOpen()}
        >
          <div className="flex-space-between w-100">
            {winTeamHeading || <div>Win Team</div>}
            <div>
              <BiChevronDown className="fancy-down-arrow" />
            </div>
          </div>
          {winTeamOpen ? (
            <div className="w-100 flex-space-around fancy-teams-div custom-box-shadow fancy-team-margin">
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center"
                onClick={() => {
                  setError("");
                  handleWinTeamHeading(selectedMatch?.team1);
                }}
              >
                {selectedMatch?.team1}
              </div>
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleWinTeamHeading(selectedMatch?.team2)}
              >
                {selectedMatch?.team2}
              </div>
              {selectedMatch?.game_object?.match_type === "testMatch" && (
                <div
                  className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                  onClick={() => handleWinTeamHeading("draw")}
                >
                  DRAW
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div
          className="align-center fancy-inputs custom-box-shadow w-48 pl-10 pt-5 flex-column"
          onClick={() => toggleResultTypeOpen()}
        >
          <div className="flex-space-between w-100">
            {resultTypeHeading || <div>Result Type</div>}
            <div>
              <BiChevronDown className="fancy-down-arrow" />
            </div>
          </div>
          {resultTypeOpen ? (
            <div className="w-100 flex-space-around fancy-teams-div custom-box-shadow fancy-team-margin">
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center"
                onClick={() => handleResultTypeHeading("result")}
              >
                Result
              </div>
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleResultTypeHeading("cancel")}
              >
                Cancel
              </div>
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleResultTypeHeading("abandon")}
              >
                Abandoned
              </div>
              <div
                className="w-100 match-enterteam custom-box-shadow flex-center mt-5"
                onClick={() => handleResultTypeHeading("draw")}
              >
                Draw
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <button
        className="mt-10 fancy-inputs button-box-shadow flex-center w-100"
        onClick={() => {
          handleConfirmDeclaration();
        }}
        disabled={isProcessing}
      >
        {isProcessing ? "Declaring..." : "Result Declaration"}
      </button>
      {error && <div className="error-msg">{error}</div>}
      {confirmDeclaration && (
        <MatchDeclareSuccessPopup
          winTeamHeading={winTeamHeading}
          confirmDeclaration={confirmDeclaration}
          setConfirmDeclaration={setConfirmDeclaration}
          handleMatchDeclarePopupOpen={handleMatchDeclarePopupOpen}
        />
      )}
      {afterConfirm && (
        <MatchEntryCreatePopup
          openPopup={afterConfirm}
          setclosePopup={setAfterConfirm}
          isProcessing={isProcessing}
          error={error}
          displayData={
            isProcessing ? "Declaring..." : "Match declared successfully"
          }
        />
      )}
    </div>
  );
}

export default MatchResultInputs;
