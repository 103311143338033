import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Images } from "../../images";
import { FiPercent } from "react-icons/fi";
import { useEffect, useState } from "react";
import { call } from "../../config/axios";
import {
  CREATE_OFFLINE_CLIENT,
  GET_ALL_CLIENTS,
  GET_REFFERAL_DATA,
  UPDATE_OFFLINE_CLIENT,
} from "../../config/endpoints";
import { FaChevronDown } from "react-icons/fa";
import FancyThankYouPopup from "../fancypopups/FancyThankYouPopup";
import CreateReferralPopup from "../popups/CreateReferralPopup";
import CreateRefSuccessPopUp from "../popups/CreateRefSuccessPopUp";
import CreateUserSuccessPopup from "./CreateUserSuccessPopup";
function UserCreation({
  existingClients,
  clientDetails,
  editStatus,
  setEditStatus,
  setAddClientStatus,
}) {
  let register_id = localStorage?.getItem("register_id");
  let creator_id = localStorage?.getItem("creator_id");
  let account_role = localStorage?.getItem("account_role");
  const [clientData, setClientData] = useState({});
  const [allClients, setAllClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [refferalData, setRefferalData] = useState([]);
  const [selectedRefferal, setSelectedRefferal] = useState({});
  const [showClientTypeDropdoen, setShowClientTypeDropdoen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [depositOrwidthdraw, setDepositOrwidthdraw] = useState("");
  const [depositeDropdown, setDepositeDropdown] = useState(false);
  const [clientTypeSelected, setClientTypeSelected] = useState({});
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [referalDropDown, setRefferalDropDown] = useState(false);
  const [userCreationSubmitPopup, setUserCreationSubmitPopup] = useState(false);
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [createReferralPopup, setCreateReferralPopup] = useState(false);
  const [createClientStatus, setCreateClientStatus] = useState(false);
  const [refStatus, setRefStatus] = useState(false);

  const handleReferralPopupOpen = () => {
    setCreateReferralPopup(!createReferralPopup);
  };

  const handleReferralPopupClose = () => {
    setCreateReferralPopup(false);
  };
  const handleSelelctDropdown = () => {
    setShowClientTypeDropdoen((prev) => !prev);
  };
  const handleClientSelectedDropdown = () => {
    setShowClientDropdown((prev) => !prev);
  };
  const handleReferralDropDown = () => {
    setRefferalDropDown((prev) => !prev);
  };
  const handleClientSelect = (user_id, user_name) => {
    setSelectedValue(user_name);
    setSelectedClient(user_id);
    setShowClientTypeDropdoen(false);
  };

  const handleDepositeWidthdraw = (type) => {
    setDepositOrwidthdraw(type?.label);
    setDepositeDropdown(false);
    setClientData({ ...clientData, deposit_type: type?.value });
  };

  const handleDepositeDropdown = () => {
    setDepositeDropdown((prev) => !prev);
  };

  const handleChange = (e) => {
    setError("");
    let inputValue = e.target.value;
    if (e.target.name === "client_risk_limit") {
      inputValue = inputValue.slice(0, 9);
    } else if (e.target.type === "number" && inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2);
    }
    setClientData({
      ...clientData,
      [e.target.name]:
        e.target.type === "number" ? parseInt(inputValue) : inputValue,
    });
  };

  const handleSubmit = async () => {
    if (!clientData?.alias_name || !selectedValue) {
      return setError("Please enter required fields");
    }
    let clientPayload = {
      ...clientData,
      existing_user_id: selectedClient,
      register_id: register_id,
      client_type: clientTypeSelected?.value,
      client_name: selectedValue,
      referral_name: selectedRefferal?.referral_name,
      referal_id: selectedRefferal?.refferal_id,
      master_share:
        localStorage.getItem("share") ||
        localStorage.getItem("share") === undefined
          ? 0
          : localStorage.getItem("share"),
      ul_share:
        localStorage.getItem("ul_share") ||
        localStorage.getItem("ul_share") === undefined
          ? 0
          : localStorage.getItem("ul_share"),
    };
    setIsProcessing(true);
    setError("");
    await call(
      editStatus ? UPDATE_OFFLINE_CLIENT : CREATE_OFFLINE_CLIENT,
      clientPayload
    )
      .then((res) => {
        setIsProcessing(false);

        if (res?.data?.statusCode === 200) {
          setAddClientStatus((prev) => !prev);
          setUserCreationSubmitPopup(true);
          setTimeout(() => {
            setUserCreationSubmitPopup(false);
          }, 1000);
          setEditStatus(false);
          handleReset();
        } else {
          setError(
            res?.data?.message ? res?.data?.message : `something wen't wrong`
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
        setError(err?.message ? err?.message : `something wen't wrong`);
      });
  };
  const handleReset = () => {
    setSelectedClient({});
    setSelectedRefferal({});
    setSelectedValue("");
    setDepositOrwidthdraw("");
    setClientData({});
  };
  const getAllClients = async () => {
    await call(GET_ALL_CLIENTS, { register_id, account_role })
      .then((res) => {
        setAllClients(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getRefferalData = async () => {
    await call(GET_REFFERAL_DATA, { register_id })
      .then((res) => {
        setRefferalData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setClientData(clientDetails);
    setSelectedClient(clientDetails?.existing_user_id);
    setSelectedValue(clientDetails?.client_name);
    setClientTypeSelected(
      clientSelection?.filter((i) => i.value === clientDetails?.client_type)[0]
    );
    setSelectedRefferal({
      referral_name: clientDetails?.referral_name,
      refferal_id: clientDetails?.refferal_id,
    });
    setDepositOrwidthdraw(
      depositeWidthdraw[clientDetails?.deposit_type]?.label
    );
    setClientData({
      ...clientDetails,
      deposit_type: depositeWidthdraw[clientDetails?.deposit_type]?.value,
    });
  }, [clientDetails]);

  const clientEntrys = [
    {
      place: "Enter Alias Name",
      size: "12",
      name: "alias_name",
      id: "alias_name",
      type: "text",
      value: clientData?.alias_name || "",
    },
    {
      place: "Share",
      size: "4",
      name: "client_share",
      id: "client_share",
      type: "number",
      value: clientData?.client_share,
    },
    {
      place: "Fancy Role Comm",
      size: "4",
      name: "fancy_comm",
      id: "fancy_comm",
      type: "number",
      value: clientData?.fancy_comm,
    },
    {
      place: "Mat+Fa-comm",
      size: "4",
      name: "match_race_comm",
      id: "match_race_comm",
      type: "number",
      value: clientData?.match_race_comm,
    },
  ];
  const shares = [
    {
      title: "UL Comm",
      name: "ul_comm",
      id: "ul_comm",
      value:
        localStorage.getItem("ul_comm") ||
        localStorage.getItem("ul_comm") === undefined
          ? 0
          : localStorage.getItem("ul_comm"),
    },
    {
      title: "UL Share",
      name: "ul_share",
      id: "ul_share",
      value:
        localStorage.getItem("ul_share") === undefined
          ? 0
          : localStorage.getItem("ul_share"),
    },
    {
      title: "Owner Share",
      name: "owner_share",
      id: "owner_share",
      value: localStorage.getItem("share"),
    },
  ];
  const RFshares = [
    {
      name: "referral_share",
      placeholder: "Rf Share",
      id: "referral_share",
      type: "number",
      value: clientData?.referral_share || "",
    },
    {
      placeholder: "Rf Fan role Comm",
      name: "fancy_refferal_comm",
      id: "fancy_refferal_comm",
      type: "number",
      value: clientData?.fancy_refferal_comm || "",
    },
    {
      placeholder: "Rf M+F Comm",
      id: "referral_comm",
      name: "referral_comm",
      type: "number",
      value: clientData?.referral_comm || "",
    },
  ];
  const depositeWidthdraw = [
    {
      label: "Deposited",
      value: 0,
    },
    {
      label: "Credited",
      value: 1,
    },
  ];
  const clientSelection = [
    { name: "Regulor", value: 0 },
    { name: "Book", value: 1 },
    { name: "Agent", value: 2 },
    { name: "Dami", value: 3 },
  ];

  useEffect(() => {
    getAllClients();
  }, []);

  useEffect(() => {
    getRefferalData();
  }, [createClientStatus]);

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        <IonCol size="12" className="flex-space-between ">
          <div className="connect-device-header custom-box-shadow m-5">
            User Management/Creation
          </div>
        </IonCol>
        <div className="upcoming-table w-100 flex-space-between">
          {shares?.map((share, index) => {
            return (
              <IonCol key={index}>
                <div className="font-12 mb-5">{share?.title}</div>
                <div className="series-input flex-center custom-box-shadow">
                  <input
                    className="flex-center w-80 match-input"
                    placeholder={share?.title}
                    value={share?.value || 0}
                    id={share?.id}
                    name={share?.id}
                    disabled
                  ></input>
                  <FiPercent
                    className="clock-icon"
                    // onClick={() => handleTeamsDropdown()}
                  />
                </div>
              </IonCol>
            );
          })}
        </div>
        <div className="w-100 flex-space-between">
          <IonCol>
            <div className="upcoming-table-heading custom-box-shadow">
              Select Client
            </div>
          </IonCol>
          <IonCol size="3">
            <div
              className="upcoming-table-heading flex-center custom-box-shadow"
              onClick={() => handleClientSelectedDropdown()}
            >
              {clientTypeSelected?.name || "Select"}
              <FaChevronDown />
            </div>
            {showClientDropdown && (
              <div className="select-team-dropdown w-100 position-right font-12 mt-5">
                {clientSelection?.map((client, index) => (
                  <div
                    key={index}
                    className="h-40 flex-center mb-5 "
                    onClick={() => {
                      setClientTypeSelected(client);
                      setClientData({
                        ...clientData,
                        client_type: client?.value,
                      });
                      handleClientSelectedDropdown();
                    }}
                  >
                    {client?.name}
                  </div>
                ))}
              </div>
            )}
          </IonCol>
        </div>
        <IonCol size="12" className="h-50">
          <div
            className="series-input flex-space-between custom-box-shadow"
            onClick={() => handleSelelctDropdown()}
          >
            <div className="pl-10">
              {selectedValue ? selectedValue : "Select"}
            </div>
            <RiArrowDropDownLine className="dropdown-icon" />
          </div>
          {showClientTypeDropdoen && (
            <div className="select-team-dropdown w-50 position-right font-12 h-150px">
              {allClients?.length > 0 &&
                allClients
                  ?.filter(
                    (obj) =>
                      !existingClients.some(
                        (o) => o.existing_user_id === obj.register_id
                      )
                  )
                  ?.map(({ register_id, user_name, account_role }, index) => (
                    <div
                      key={index}
                      className="h-40 flex-center mb-5 "
                      onClick={() => {
                        setClientData({
                          ...clientData,
                          account_role: account_role,
                        });
                        handleClientSelect(register_id, user_name);
                      }}
                    >
                      {user_name} - {account_role}
                    </div>
                  ))}
            </div>
          )}
        </IonCol>
        {clientEntrys?.map((item, index) => {
          return (
            <IonCol key={index} size={item?.size}>
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-100 match-input"
                  placeholder={item?.place || ""}
                  name={item?.name || ""}
                  id={item?.id || ""}
                  type={item?.type}
                  value={clientData[item?.name] || ""}
                  onChange={(e) => handleChange(e)}
                ></input>
                {index !== 0 ? <FiPercent className="clock-icon" /> : ""}
              </div>
            </IonCol>
          );
        })}
        <div className="w-100 flex-space-between">
          <IonCol size="4">
            <div className="upcoming-table-heading flex-center custom-box-shadow">
              Select Referral
            </div>
          </IonCol>
          <IonCol size="5">
            <div
              className="create-referral flex-center button-box-shadow"
              onClick={() => handleReferralPopupOpen()}
            >
              Create New Referral
            </div>
          </IonCol>
        </div>
        <IonCol size="12">
          <div
            className="series-input flex-space-between custom-box-shadow"
            onClick={() => handleReferralDropDown()}
          >
            <div>{selectedRefferal?.referral_name || "Select"}</div>
            <RiArrowDropDownLine className="dropdown-icon" />
          </div>
          {referalDropDown && (
            <div className="select-team-dropdown w-50 position-right font-12">
              {refferalData?.length > 0 &&
                refferalData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="h-40 flex-center mb-5"
                      onClick={() => {
                        setSelectedRefferal(item);
                        handleReferralDropDown();
                      }}
                    >
                      {item.referral_name}
                    </div>
                  );
                })}
            </div>
          )}
        </IonCol>
        {RFshares?.map((item, index) => {
          return (
            <IonCol key={index} size="4">
              <div className="series-input flex-center custom-box-shadow">
                <input
                  className="flex-center w-80 match-input"
                  placeholder={item?.placeholder || ""}
                  name={item?.name || ""}
                  id={item?.id || ""}
                  type={item?.type}
                  maxLength="2"
                  value={clientData[item?.name] || ""}
                  onChange={(e) => handleChange(e)}
                ></input>
                <FiPercent
                  className="clock-icon"
                  // onClick={() => handleTeamsDropdown()}
                />
              </div>
            </IonCol>
          );
        })}
        <div className="w-100 flex-space-between">
          <IonCol size="6">
            <div className="upcoming-table-heading flex-center custom-box-shadow">
              Deposit/Credit
            </div>
          </IonCol>
          <IonCol size="6">
            <div className="upcoming-table-heading flex-center custom-box-shadow">
              Location
            </div>
          </IonCol>
        </div>
        <IonCol size="6" className="h-50">
          <div className="series-input flex-center custom-box-shadow">
            <div
              className="flex-center w-80 match-input "
              onClick={() => handleDepositeDropdown()}
            >
              {depositOrwidthdraw ? depositOrwidthdraw : "Select"}
            </div>
            <RiArrowDropDownLine className="dropdown-icon" />
          </div>
          {depositeDropdown && (
            <div className="select-team-dropdown ">
              {depositeWidthdraw?.map((type, index) => (
                <div
                  key={index}
                  className="h-40 flex-center mb-5"
                  onClick={() => handleDepositeWidthdraw(type)}
                >
                  {type?.label}
                </div>
              ))}
            </div>
          )}
        </IonCol>
        <IonCol size="6">
          <div className="series-input flex-center custom-box-shadow">
            <input
              className="flex-center w-80 match-input"
              placeholder="Enter Location"
              name="location"
              id="location"
              type="text"
              value={clientData["location"] || ""}
              onChange={(e) => handleChange(e)}
            ></input>
            <img
              className="play-delete-icon"
              alt="play"
              src={Images.MatchPlace}
            ></img>
          </div>
        </IonCol>
        <IonCol size="12">
          <input
            className="flex-center w-100 series-input"
            placeholder="Match Risk Limit"
            name="client_risk_limit"
            id="client_risk_limit"
            type="number"
            value={clientData["client_risk_limit"] || ""}
            onChange={(e) => handleChange(e)}
          ></input>
        </IonCol>
        <IonCol size="12">
          <button
            type="submit"
            className="submit-button w-100 mb-10"
            disabled={isProcessing}
            onClick={() => handleSubmit()}
          >
            {isProcessing ? "Processing..." : editStatus ? "Update" : "Submit"}
          </button>
          {error && (
            <IonText color="danger" className="ion-text-center">
              {error}
            </IonText>
          )}
        </IonCol>
      </IonRow>
      <CreateUserSuccessPopup
        openPopup={userCreationSubmitPopup}
        setclosePopup={setUserCreationSubmitPopup}
        displayData="user details added successfully"
      />
      <CreateRefSuccessPopUp
        refStatus={refStatus}
        setRefStatus={setRefStatus}
        handleReferralPopupClose={handleReferralPopupClose}
        setCreateClientStatus={setCreateClientStatus}
      />
      <CreateReferralPopup
        createReferralPopup={createReferralPopup}
        handleReferralPopupClose={handleReferralPopupClose}
        setCreateClientStatus={setCreateClientStatus}
        refStatus={refStatus}
        setRefStatus={setRefStatus}
      />
    </IonGrid>
  );
}

export default UserCreation;
