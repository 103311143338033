import { IonImg, IonTitle, IonIcon, IonModal, IonText } from "@ionic/react";
import moment from "moment";
import { closeOutline } from "ionicons/icons";
import { Images } from "../../../images";
import { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { call } from "../../../config/axios";
import { SET_ADMIN_OFFLINE_PAYMENT } from "../../../config/endpoints";

function AdminPaymentPopup(props) {
  const {
    paymentPopupOpen,
    setPaymentPopupOpen,
    selectedUser,
    totalAmount,
    pendinAmount,
    setPaymentSuccessPopUp,
    setSuccess,
  } = props;

  const register_id = localStorage?.getItem("register_id");
  const [showPaymentType, setShowPaymentType] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [settlementObj, setSettlementObj] = useState({
    settled_platform_amount: 0,
  });
  const paymentTypes = [
    { name: "PhonePe", value: "Phonepay" },
    { name: "G Pay", value: "G pay" },
  ];

  const handleCloseModal = () => {
    setPaymentPopupOpen(false);
  };

  const onPaymentType = () => {
    setShowPaymentType((prev) => !prev);
  };

  const handlePaymentType = (type) => {
    setPaymentType(type);
  };
  const onInputChange = (e) => {
    setSettlementObj({
      ...settlementObj,
      [e.target.name]: Number(e.target.value),
    });
  };

  const onSubmitBtnClick = async () => {
    setIsProcessing(true);
    await call(SET_ADMIN_OFFLINE_PAYMENT, {
      client_id: selectedUser?.client_id,
      referral_name: selectedUser?.referral_name,
      client_name: selectedUser?.client_name,
      register_id,
      payment_type: paymentType?.value,
      totalAmount: totalAmount?.toFixed(2),
      ...settlementObj,
    })
      ?.then((res) => {
        setIsProcessing(false);
        if (res?.data?.error === "true") {
          setError(res?.data?.message);
        } else {
          setPaymentPopupOpen(false);
          setSuccess((prev) => !prev);
          setPaymentSuccessPopUp(true);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        setError(err);
        console.log(err);
      });
  };
  return (
    <IonModal
      isOpen={paymentPopupOpen}
      backdropDismiss={true}
      className="payment-settlement-modal p-0 payment-settlement-container custom-box-shadow"
    >
      <IonIcon
        icon={closeOutline}
        slot="middle"
        className="close-icon"
        onClick={() => handleCloseModal()}
      />

      <div className="flex-center mb-10">
        <IonImg className="logoImage" src={Images.handIllus} alt="hand-illus" />
      </div>
      <IonTitle className="payment-settlement-heading mb-10">
        Payment Settlement
      </IonTitle>
      <div className="flex-space-evenly w-100 mb-10">
        <button className="account-summary-headings custom-box-shadow w-40 font-10">
          Date: {moment().format("DD-MM-YYYY")}
        </button>
        <button className="account-summary-headings custom-box-shadow w-40 font-10">
          Time: {moment().format("hh:mm:ss A")}
        </button>
      </div>

      <div
        placeholder="Client Name"
        name="client_id"
        className="payment-settlement-client-selection d-flex custom-box-shadow mb-10 pl-10"
      >
        {selectedUser?.client_name || ""}
      </div>
      <div className="flex-space-between  w-100 mb-10">
        <button className="account-summary-headings custom-box-shadow w-48 h-40 border-radius-10 text-align-left pl-10">
          {totalAmount ? totalAmount?.toFixed(2) : 0}
        </button>
        <button className="account-summary-headings custom-box-shadow w-48 h-40 border-radius-10 text-align-left pl-10">
          {+pendinAmount -
            (pendinAmount > 0
              ? settlementObj?.settled_platform_amount || 0
              : -1 * (settlementObj?.settled_platform_amount || 0))}
        </button>
      </div>
      <div className="flex-space-between w-100 mb-10">
        <div
          className="payment-settlement-client-selection custom-box-shadow w-48 h-40 flex-space-between position-relative"
          name="payment_type"
          //   value={settlementObj?.payment_type}
          onClick={() => onPaymentType()}
        >
          {paymentType?.name || "Select..."}
          {showPaymentType && (
            <div className="admin-reports-dropdown dropdown-position w-100 h-100 font-12">
              {paymentTypes.map((type, index) => {
                return (
                  <div
                    key={index}
                    className={`h-40 flex-center mb-5`}
                    onClick={() => handlePaymentType(type)}
                  >
                    {type?.name}
                  </div>
                );
              })}
            </div>
          )}
          <RiArrowDropDownLine className="dropdown-icon" />
        </div>
        <input
          className="custom-box-shadow h-40 br-10 font-12 w-48 enter-amount-input"
          type="number"
          id="settled_platform_amount"
          placeholder="Enter Amount"
          name="settled_platform_amount"
          defaultValue={settlementObj?.settled_platform_amount}
          onChange={(e) => {
            onInputChange(e);
          }}
        />
      </div>
      <button
        className="account-summary-title-container clr-green w-100 h-40 mt-20"
        onClick={() => onSubmitBtnClick()}
      >
        {isProcessing ? "Processing..." : "Submit"}
      </button>
    </IonModal>
  );
}

export default AdminPaymentPopup;
