import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useEffect, useState } from "react";
import { FaTrophy } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLiveMatch } from "../../redux/actions/commonActions";

function MatchesScroll({ data, setSelectedMatch, selectedMatch }) {
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeButton, setActiveButton] = useState(0);

  const selectedLiveMatch = useSelector(
    (state) => state?.common?.selected_match
  );

  const handleSelectedMatch = (match) => {
    setSelectedMatch(match);
    dispatch(setSelectedLiveMatch(match));
  };

  const scrollLeft = () => {
    const container = document.getElementById("scroll-container-btn");
    container.scrollTo({
      left: scrollPosition - 100,
      behavior: "smooth",
    });
    setScrollPosition(scrollPosition - 100);
  };
  const scrollRight = () => {
    const container = document.getElementById("scroll-container-btn");
    container.scrollTo({
      left: scrollPosition + 100,
      behavior: "smooth",
    });
    setScrollPosition(scrollPosition + 100);
  };

  return (
    <div className="align-center match-scroll-div match-containter">
      <div className="flex-center arrow-icon" onClick={scrollLeft}>
        <IonIcon icon={chevronBackOutline} />
      </div>
      <div
        className="align-center scroll-container-btn"
        id="scroll-container-btn"
        style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
      >
        {data &&
          data?.length &&
          data?.map((match, index) => (
            <div
              key={index}
              className="flex-center match-div font-12"
              onClick={() => handleSelectedMatch(match)}
            >
              <div className="flex-center trophy-icon">
                <FaTrophy />
              </div>
              <div
                className={
                  match?.match_id === selectedLiveMatch?.match_id
                    ? "clr-green"
                    : ""
                }
                onClick={() => setActiveButton(index)}
              >
                {match?.match_name}
              </div>
            </div>
          ))}
      </div>
      <div className="flex-center arrow-icon" onClick={scrollRight}>
        <IonIcon icon={chevronForwardOutline} />
      </div>
    </div>
  );
}

export default MatchesScroll;
