export const SET_LOADING = "SET_LOADING";
export const SET_REGISTER_USER_INFO = "SET_REGISTER_USER_INFO";
export const SELECTED_PACKAGES = "SELECTED_PACKAGES";
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";
export const SET_MEETING_IS_STARTED = "SET_MEETING_IS_STARTED";
export const SET_MINIMIZE = "SET_MINIMIZE"
export const SET_MINIMIZE_USER = "SET_MINIMIZE_USER"
export const SET_SELECTED_MATCH = "SET_SELECTED_MATCH"
export const SET_USER_JOIN_CLICK = "SET_USER_JOIN_CLICK"
export const SET_ONGOING_MEETING = "SET_ONGOING_MEETING"
export const REMOVE_ONGOING_MEETING = "REMOVE_ONGOING_MEETING"
export const SET_UPLEVEL_MEETING = "SET_UPLEVEL_MEETING"
export const REMOVE_UPLEVEL_MEETING = "REMOVE_UPLEVEL_MEETING"

