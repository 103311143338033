import React, { useState } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdModeEditOutline } from "react-icons/md";
import { GoDotFill } from "react-icons/go";

function AddViewUsersAdmins() {
  const accountRole = localStorage.getItem("account_role");
  const viewUsersData = [
    {
      user: "Sai-Offline-",
      role: "Agent",
      status: "online",
      packages: "01",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      user: "Sai-Offline-",
      role: "Agent",
      status: "online",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      user: "Sai-Offline-",
      role: "Agent",
      status: "offline",
      packages: "01",
      button1: "CP",
      button2: "Edit",
      button3: "UB",
    },
    {
      user: "Sai-Offline-",
      role: "Agent",
      status: "offline",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "UB",
    },
    {
      user: "Sai-Offline-",
      role: "-Agent",
      status: "online",
      packages: "01",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      user: "Sai-Offline-",
      role: "User",
      status: "online",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      user: "Sai-Offline-",
      role: "User",
      status: "online",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      status: "online",

      user: "Sai-Offline-",
      role: "User",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      status: "online",

      user: "Sai-Offline-",
      role: "User",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      status: "online",

      user: "Sai-Offline",
      role: "User",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
    {
      status: "online",
      user: "Sai-Offline",
      role: "User",
      packages: "10",
      button1: "CP",
      button2: "Edit",
      button3: "B",
    },
  ];
  const tableRowData = [
    {
      title: "User Name",
    },
    {
      title: "Action",
    },
  ];
  const [blockUnblock, setBlockUnblock] = useState("B");
  const handleBlockUnblock = () => {
    blockUnblock === "B" ? setBlockUnblock("UB") : setBlockUnblock("B");
  };
  return (
    <div>
      
      <MeetingsHeader />
      <div className="main-container">
        <div className="flex-start flex-row mt-10 ml-20 mb-10">
          <div className="d-flex font-16 mr-5 fw-600">
            Srinivash
            <div className="ml-5" style={{ color: "#ffae00" }}>
              SM
            </div>
          </div>
          <MdOutlineArrowForwardIos className="font-16" />
          <div className="d-flex font-16 mr-5 fw-600">
            Sai
            <div className="ml-5" style={{ color: "#007eff" }}>
              M
            </div>
          </div>
          <MdOutlineArrowForwardIos className="font-16" />
          <div className="d-flex font-16 mr-5 fw-600">
            Jayanta
            <div className="ml-5" style={{ color: "#6aebda" }}>
              AG
            </div>
          </div>
        </div>
        {
          <div className="custom-box-shadow  meetings-details-div mt-10">
            <div className="d-flex flex-space-between w-100">
              {tableRowData.map((rowData, index) => (
                <div key={index} className="flex-space-between mt-5">
                  <div className="match-history-button flex-center custom-box-shadow font-10 ml-10 mr-30">
                    {rowData.title}
                  </div>
                </div>
              ))}
            </div>
            <div className="hr-line mt-10"></div>
            <div className="upcoming-meet-scroll ml-10 mr-5 h-50-prcnt">
              <div className="meet-scroll">
                {viewUsersData.map((data, index) => (
                  <div key={index} className="flex-row">
                    <div className="flex-space-between mt-5">
                      <div className="w-25 flex-center flex-column">
                        <div className="meetings-time padding-tb">
                          Sai-Offline-
                        </div>
                        <div className="flex-center flex-row">
                          <div className="meetings-time padding-tb mr-5">
                            User
                          </div>
                          <GoDotFill
                            className={
                              index === 3 ||
                              index === 5 ||
                              index === 6 ||
                              index === 8 ||
                              index === 9 ||
                              index === 11 ||
                              index === 14 ||
                              index === 17
                                ? "font-size-14 green-clr"
                                : "font-size-14 red-clr"
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-space-between w-35 mr-20">
                        <div className="custom-box-shadow p-5 w-30p flex-center h-30p br-10 font-12">
                          CP
                        </div>
                        <span className="custom-box-shadow p-5 w-30p flex-center h-30p br-10 ml-10 font-12">
                          Edit
                        </span>
                        <div className="mt-5">
                          <div
                            className={`
                          ${blockUnblock === "B" ? "clr-red" : "clr-green"}
                            ml-10 custom-box-shadow p-5 w-30p flex-center h-30p br-10 font-12`}
                            onClick={() => handleBlockUnblock(index)}
                          >
                            {blockUnblock}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hr-line mt-5"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default AddViewUsersAdmins;
