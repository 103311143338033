import { IonModal } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { call } from "../../config/axios";
import { GET_ADMIN_PACKAGES } from "../../config/endpoints";

function UserwisePackagesPopup(props) {
  const { setOpenUserwisePopup, openUserwisePopup } = props;
  const register_id = localStorage.getItem("register_id");
  const PackagesHeadings = [
    { size: "3.5", name: "Packages" },
    { size: "3.5", name: "Purchase" },
    { size: "2.5", name: "Used" },
    { size: "2", name: "Available" },
  ];
  const PackagesTypes = [
    {
      packageType: "Standard12",
      purchase: "10",
      used: "6",
      available: "4",
    },
    { packageType: "Silver", purchase: "5", used: "3", available: "2" },
    { packageType: "Gold", purchase: "15", used: "10", available: "5" },
    { packageType: "Diamond", purchase: "4", used: "3", available: "1" },
    { packageType: "Vip", purchase: "7", used: "3", available: "4" },
  ];
  const [showPurchasePackages, setShowPurchasePackages] = useState(false);
  const [packageName, setPackageName] = useState("");
  const handleShowPurchasePackages = (item) => {
    setShowPurchasePackages(true);
    setPackageName(item);
  };
  const handleShowClosePackages = () => {
    setShowPurchasePackages(false);
  };
  const handleCloseUserwisePopup = () => {
    setOpenUserwisePopup(false);
  };
  const getAdminPackages = async () => {
    await call(GET_ADMIN_PACKAGES, { register_id })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAdminPackages();
  }, []);

  return (
    <IonModal isOpen={openUserwisePopup} className="user-wise-popup">
      <IonGrid>
        <IonRow className="w-100">
          <div className="flex-space-between mb-10 w-100">
            <div className="upcoming-table-heading custom-box-shadow flex-center">
              Srinivas-SM
            </div>
            <IoMdClose
              className="reports-heading"
              onClick={() => handleCloseUserwisePopup()}
            />
          </div>
          <div className="h-45px w-100 upcoming-table pt-5">
            <div className="w-100 d-flex">
              {PackagesHeadings.map((item, index) => (
                <IonCol key={index} size={item.size}>
                  <div className="upcoming-table-heading custom-box-shadow flex-center">
                    {item.name}
                  </div>
                </IonCol>
              ))}
            </div>
            {showPurchasePackages && (
              <div className="series-input custom-box-shadow w-70 month-report">
                <IoCloseCircle
                  className="close-pop-icon"
                  onClick={() => handleShowClosePackages()}
                />
                <div className="flex-space-around w-100 ">
                  <div className="upcoming-table-heading custom-box-shadow flex-center">
                    monthly
                  </div>
                  <div className="upcoming-table-heading custom-box-shadow flex-center">
                    yearly
                  </div>
                </div>
                <div className="flex-space-around w-90 mt-20 ml-10 font-16">
                  <div>6</div>
                  <div>{packageName}</div>
                  <div>8</div>
                </div>
              </div>
            )}
          </div>
          {PackagesTypes.map((item, index) => (
            <div key={index} className="w-100 upcoming-table d-flex">
              <IonCol
                size="3.5"
                className="table-content-text flex-space-between"
                onClick={() => handleShowPurchasePackages(item.packageType)}
              >
                {item.packageType}
              </IonCol>
              <IonCol
                size="3"
                className="flex-center table-content-text"
                onClick={() => handleShowPurchasePackages(item.packageType)}
              >
                {item.purchase}
              </IonCol>
              <IonCol
                size="2.5"
                className="flex-center table-content-text clr-orng"
                onClick={() => handleShowPurchasePackages(item.packageType)}
              >
                {item.used}
              </IonCol>
              <IonCol
                size="3"
                className="flex-center table-content-text clr-green"
                onClick={() => handleShowPurchasePackages(item.packageType)}
              >
                {item.available}
              </IonCol>
            </div>
          ))}
        </IonRow>
        <div className="w-100 flex-space-between mt-10 upcoming-table p-5">
          <div className="d-flex">
            Total Paid
            <div className="upcoming-table-heading custom-box-shadow flex-center ml-10">
              10000
            </div>
          </div>
          <div className="d-flex">
            Balance
            <div className="upcoming-table-heading custom-box-shadow flex-center ml-10">
              1000000
            </div>
          </div>
        </div>
      </IonGrid>
    </IonModal>
  );
}

export default UserwisePackagesPopup;
