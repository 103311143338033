import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { Images } from "../../images";

function EditPopup(props) {
  const {
    openPopup,
    setclosePopup,
    displayData,
    setSelectedMatchEntry=()=>{},
    data,
    deletedId,
    deleteApi,
    setSelectedId,
  } = props;
  const handleFancyThankyouPopupClose = (isEdited) => {
    setclosePopup(isEdited);
    setSelectedMatchEntry("");
  };
  const handleConfirm = () => {
    setSelectedMatchEntry && setSelectedMatchEntry(data);
    deletedId && deleteApi();
    handleFancyThankyouPopupClose(true);
  };
  const handleCancel = () => {
    setSelectedMatchEntry && setSelectedMatchEntry("");
    deletedId && setSelectedId("");
    handleFancyThankyouPopupClose();
  };
  return (
    <IonModal className="statememt-popup-main" isOpen={openPopup}>
      <center>
        <div
          className="w-100 flex-flex-end p-5"
          onClick={() => handleFancyThankyouPopupClose()}
        >
          <RxCross1 />
        </div>
        <div>
          <img
            className="fancy-declare-image"
            src={Images.ThumbsUpImage}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-75 fancy-declare-text mt-10">{displayData}</div>
        <div className="flex-center w-100">
          <div
            className="mt-15 flex-space-around w-100"
            onClick={() => handleConfirm()}
          >
            <button className="w-80 button-box-shadow fancy-declare-button green-clr">
              YES
            </button>
          </div>
          <div
            className="mt-15 flex-space-around w-100"
            onClick={() => handleCancel()}
          >
            <button className="w-80 button-box-shadow fancy-declare-button red-clr">
              NO
            </button>
          </div>
        </div>
      </center>
    </IonModal>
  );
}

export default EditPopup;
