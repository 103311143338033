import { IonCol, IonModal, IonRow } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";

function SelectPackageType(props) {
  const { selectPackagePopup, setSelectPackagePopup } = props;
  const handlePopupClose = () => {
    setSelectPackagePopup(false);
  };
  return (
    <IonModal className="statememt-popup-main" isOpen={selectPackagePopup}>
      <div
        className="w-100 flex-flex-end p-5"
        onClick={() => handlePopupClose()}
      >
        <RxCross1 />
      </div>
      <center>
        <h2>Select Package</h2>
      </center>
      <div className="bb-1">
        <IonRow className="w-100">
          <IonCol size="4">Pack</IonCol>
          <IonCol size="4">Expire</IonCol>
          <IonCol size="3">Balance</IonCol>
          <IonCol size="1"></IonCol>
        </IonRow>
      </div>
      <div className="bb-1">
        <IonRow className="w-100 mb-5 mt-5">
          <IonCol size="4">Standred</IonCol>
          <IonCol size="4">24/08/24</IonCol>
          <IonCol size="3">40 hrs</IonCol>
          <IonCol size="1">
            <input type="checkbox"></input>
          </IonCol>
        </IonRow>
      </div>
      <div className="bb-1">
        <IonRow className="w-100 mb-5 mt-5">
          <IonCol size="4">Silver</IonCol>
          <IonCol size="4">24/08/24</IonCol>
          <IonCol size="3">40 hrs</IonCol>
          <IonCol size="1">
            <input type="checkbox"></input>
          </IonCol>
        </IonRow>
      </div>
      <div className="mt-15 flex-space-around w-100">
        <button className="w-40 button-box-shadow fancy-declare-button green-clr fw-600">
          Submit
        </button>
      </div>
    </IonModal>
  );
}

export default SelectPackageType;
