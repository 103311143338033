import { Images } from "../../images";
import MeetingsHeader from "../meetings/MeetingsHeader";
import { PRIVACY_POLICY } from "../../config/endpoints";
import { call } from "../../config/axios";
import { useEffect, useState } from "react";

function PrivacyPolicy() {
  const [privacyPolicyData, setPrivacyPolicyData] = useState("");
  const getPrivacyPolicy = async () => {
    await call(PRIVACY_POLICY, {
      register_id: "company",
      website_name: "www.we2call.com",
    })
      .then((res) => {
        setPrivacyPolicyData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <div>
      <MeetingsHeader heading={"Privacy Policy"} />
      <div className="main-container p-10">
        <div className="align-center flex-column custom-box-shadow br-15 h-75vh p-10 mt-15">
          <div className="flex-center mt-15">
            <img
              className="w-65"
              src={Images.PrivacyPolicyImg}
              alt="Privacy-Policy"
            />
          </div>
          <div className="font-16 mt-15 fw-600">
            Privacy Policy and Terms & Condition
          </div>
          {privacyPolicyData &&
            privacyPolicyData?.map((policy, index) => (
              <div className="font-10 mt-15 conditions-text" key={index}>
                {policy?.active === true && policy?.policy_description}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
