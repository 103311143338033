import { useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { GoDotFill } from "react-icons/go";
import { call } from "../../config/axios";
import { GET_ALL_MATCHES } from "../../config/endpoints";
import moment from "moment";

function ScheduleMatches({ status }) {
  const account_role = localStorage?.getItem("account_role");
  const [liveMatchesData, setLiveMatchesData] = useState([]);
  const [upcomingMatchesData, setUpcomingMatchesData] = useState([]);
  const [todayMatchesData, setTodayMatchesData] = useState([]);

  const getAllMatches = () => {
    call(GET_ALL_MATCHES, {
      register_id: "company",
      account_role,
    })
      .then((res) => {
        const result = res?.data?.data;
        setLiveMatchesData(result?.liveMatches);
        setTodayMatchesData(result?.todaysMatches);
        setUpcomingMatchesData(result?.upCommingMatches);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllMatches();
  }, []);

  const tableHeading = [
    {
      size: "6",
      headerName: "Series Name",
    },

    {
      size: "6",
      headerName: "Match Name",
    },
  ];
  const [liveSelected, setLiveSelected] = useState(true);
  const tableContent =
    liveMatchesData &&
    liveMatchesData?.length > 0 &&
    liveMatchesData?.map((match) => {
      return {
        size: "5",
        content: (
          <div>
            <div className="d-flex">
              {match?.sport_name}
              {liveSelected && <GoDotFill className="clr-green font-size-20" />}
            </div>
            {match?.series_name}
            <br />
            {match?.match_place}
          </div>
        ),
        matchName: (
          <div>
            {match?.match_name} - ({match?.gender})
            <br />
            {moment(match?.matchTimeStamp).format("DD-MM-YYYY")}
            <br />
            {moment(match?.matchTimeStamp).format("hh:mm:ss A")}
          </div>
        ),
      };
    });

  return (
    <IonGrid>
      <IonRow className="connect-device mrl-10">
        <IonCol size="12" className="flex-space-between ">
          <div className="connect-device-header custom-box-shadow">
            Scheduled Matches
          </div>
        </IonCol>
        <div className="w-100 upcoming-table d-flex">
          {tableHeading.map((value, index) => {
            return (
              <IonCol key={index} size={value.size}>
                <div className="upcoming-table-heading flex-center custom-box-shadow">
                  {value.headerName}
                </div>
              </IonCol>
            );
          })}
        </div>
        <div className="table-rows-scroll w-100">
          {tableContent &&
            tableContent?.length > 0 &&
            tableContent?.map((value, index) => {
              return (
                <IonRow key={index}>
                  <IonCol
                    className={
                      index <= 3 ? "table-content-text" : "table-content-join"
                    }
                    size="6"
                  >
                    <div>{value.content}</div>
                  </IonCol>
                  <IonCol
                    className={
                      index <= 3 ? "table-content-text" : "table-content-join"
                    }
                    size="6"
                    key={index}
                  >
                    <div>{value?.matchName}</div>
                  </IonCol>
                </IonRow>
              );
            })}
        </div>
      </IonRow>
    </IonGrid>
  );
}

export default ScheduleMatches;
