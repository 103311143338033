import React from "react";
import { IonImg, IonIcon, IonModal } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { Images } from "../../images";
const SuccessfulSubmit = ({ showModal, setShowModal,setShowSettledPaymentModal }) => {
  const handleOkClick = () => {
    setShowModal(false);
    setShowSettledPaymentModal(false)
  };
  return (
    <IonModal
      isOpen={showModal}
      backdropDismiss={true}
      className="settled-payment-modal"
    >
      <div className="settled-payment-container custom-box-shadow">
        <IonIcon
          icon={closeOutline}
          className="close-icon"
          onClick={() => handleOkClick()}
        />

        <div className="flex-center mb-10">
          <IonImg
            className="logoImage w-80px"
            src={Images.success}
            alt="hand-illus"
          />
        </div>
        <h1 className="payment-settlement-heading mb-20">{`Successfully Submitted`}</h1>
        <div className="flex-space-between  w-100 mb-10 mt-20">
          <button
            className="settled-payment-btn yes-btn w-100 h-40"
            onClick={() => handleOkClick()}
          >
            {`Okay`}
          </button>
        </div>
      </div>
    </IonModal>
  );
};

export default SuccessfulSubmit;
