import { useState, useEffect } from "react";
import MeetingsHeader from "../meetings/MeetingsHeader";
import AdminScoreBoard from "./AdminScoreBoard";
import LiveStreaming from "./LiveStreaming";
import Line from "./Line";
import JoinList from "./JoinList";
import VideoCallHeader from "./VideoCallHeader";
import { GET_LIVE_MEETING } from "../../config/endpoints";
import { call } from "../../config/axios";
import MatchPosition from "./MatchPosition";
import VideoCallJoining from "./VideoCallJoining";
import { useDispatch, useSelector } from "react-redux";
import { setMinimize } from "../../redux/actions/dataActions";
import { useHistory } from "react-router";

function CallMeetings(props) {
  const isMinimize = useSelector((State) => State?.data?.isMinimize);
  const [liveMeeting, setLiveMeeting] = useState({});
  const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const onUserJoinFlag = useSelector((State) => State.data);
  const history = useHistory();
  const [joinList, setJoinList] = useState(false);
  const [scoreBoard, setScoreBoard] = useState(false);
  const [liveStream, setLiveStream] = useState(false);
  const [line, setLine] = useState(false);
  const [matchDetails, setMatchDetails] = useState(false);
  const dispatch = useDispatch();
  const handleJoinListOpen = () => {
    setJoinList(!joinList);
    setScoreBoard(false);
    setLiveStream(false);
     setLine(false);
    setMatchDetails(false);
  };
  const handleScoreBoardOpen = () => {
    setScoreBoard(!scoreBoard);
    setJoinList(false);
     setLiveStream(false);
     setLine(false);
    setMatchDetails(false);
  };
  const handleLineOpen = () =>{
    setLine(!line);
    setJoinList(false);
    setScoreBoard(false);
    setLiveStream(false);
    setMatchDetails(false);
  }
  const handleLiveStremOpen = () =>{
      setLiveStream(!liveStream);
    setJoinList(false);
    setScoreBoard(false);
    setLine(false);
    setMatchDetails(false);
  }
  const handleMatchDetailsOpen = () => {
    setMatchDetails(!matchDetails);
    setJoinList(false);
    setScoreBoard(false);
  };
  const onMinimizeClick = () => {
    onUserJoinFlag && dispatch(setMinimize(true));
  };
  const onBackClick = () => {
    onMinimizeClick();
    history.push("/");
  };
  const handleRemove = (id) => {
    props.removeUserFromMeeting(id);
  };

  const getLiveMeetingData = async () => {
    const creator_id = isAdminCreated
      ? localStorage.getItem("register_id")
      : localStorage.getItem("creator_id");
    return await call(GET_LIVE_MEETING, {
      creator_id: creator_id,
      meeting_id: props.meetingId,
    })
      .then((res) => {
        console.log("res", res);
        setLiveMeeting(res.data?.data?.Items?.[0]);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getLiveMeetingData();
  }, [props.meetingId]);

  if (isMinimize) return null;
  return (
    <div className="call-meeting-head">
      {/* <VideoCallJoining /> */}
      <VideoCallHeader
        joinList={joinList}
        scoreBoard={scoreBoard}
        matchDetails={matchDetails}
        handleMatchDetailsOpen={handleMatchDetailsOpen}
        handleJoinListOpen={handleJoinListOpen}
        handleScoreBoardOpen={handleScoreBoardOpen}
        handleLineOpen={handleLineOpen}
        handleLiveStremOpen={handleLiveStremOpen}
        liveMeeting={liveMeeting}
        onBackClick={onBackClick}
      />
      {joinList && (
        <JoinList
          isAdminCreated={props.isAdminCreated}
          userData={props.userData}
          removeUserFromMeeting={handleRemove}
          meetingId={props.meetingId}
          muteusers={props.muteusers}
        />
      )}
      {scoreBoard && <AdminScoreBoard />}
      {liveStream && <LiveStreaming />}
      {line && <Line />}
      {/* <VideoCallJoining /> */}
      {/* <MatchPosition /> */}
    </div>
  );
}

export default CallMeetings;
