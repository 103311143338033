import { IonCol, IonGrid, IonRow, IonHeader } from "@ionic/react";
import {useState} from "react"
import { BiSolidChevronLeft } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import moment from "moment";
import Select from "react-select";

const professionalUser = [ 
    { value: 1, label: "Live Streaming" },  
    { value: 2, label: "Line" },  
] 

function VideoCallHeader(props) {
  const {
    handleJoinListOpen,
    handleScoreBoardOpen,
    handleLiveStremOpen,
    handleLineOpen,
    matchDetails,
    handleMatchDetailsOpen,
    joinList,
    scoreBoard,
    liveMeeting = {},
    onBackClick = {},
  } = props;
  const [activeIndex, setActiveIndex] = useState(1);
    const buttons = professionalUser;


   const handleselect = (value) =>{
      setActiveIndex(value)
      if(value === 1){
        handleLiveStremOpen()
      }else{
        handleLineOpen()
      }
    }
  return (
    <IonHeader className="user-outer-div">
      {console.log("liveMeeting", liveMeeting)}
      <div
        className={` ${props.liveMeeting ? "meeting-header" : "flex-center"}`}
      >
        <BiSolidChevronLeft
          className="custom-box-shadow custom-back-icon-score"
          onClick={() => onBackClick()}
        />
        <div className="prof-meeting-score">
          <div className="flex-column">
            <div
              className="h-2rem custom-box-shadow p-8 br-10 font-14 flex-space-between ml-5"
              onClick={handleMatchDetailsOpen}
            >
              <div className="flex-80 white-space">
                {liveMeeting?.match_name}
              </div>
              <div>
                {matchDetails ? (
                  <FaChevronUp className="flex font-18 fw-600" />
                ) : (
                  <FaChevronDown className="flex font-18 fw-600" />
                )}
              </div>
            </div>
            {matchDetails && (
              <div className="custom-box-shadow p-8 br-10 font-14 mt-5">
                {moment(liveMeeting?.date, "M/D/YYYY").format("D-MM-YY")}
                <br />
                {liveMeeting?.time}
                <br />
                  <Select
                    className="w-100 react-select-container cursor-pointer select-admin"
                    
                    classNamePrefix="react-select"
                    placeholder="Select"
                    options={buttons}
                    value={buttons[activeIndex]}
                    onChange={(e) => handleselect(e.value)}
                    isSearchable={true}
                  />
              </div>
            )}
          </div>
          <div
            className="h-2rem custom-box-shadow p-8 br-10 font-14 flex-space-between ml-5"
            onClick={handleScoreBoardOpen}
          >
            <div className="flex-80 white-space">Score Board</div>
            <div>
              {scoreBoard ? (
                <FaChevronUp className="flex font-18 fw-600" />
              ) : (
                <FaChevronDown className="flex font-18 fw-600" />
              )}
            </div>
          </div>
          <div
            className="h-2rem custom-box-shadow p-8 br-10 font-14 flex-space-between"
            onClick={handleJoinListOpen}
          >
            <div className="flex-80">Join</div>
            <div>
              {joinList ? (
                <FaChevronUp className="flex font-18 fw-600" />
              ) : (
                <FaChevronDown className="flex font-18 fw-600" />
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className="horizantal-line" />
    </IonHeader>
    // <div className="w-100 custom-box-shadow br-10 mt-5 p-5 vc-top-header">
    //     <BiSolidChevronLeft className="header-icons custom-back-icon"  onClick={() => onBackClick()} />
    //   <IonGrid>
    //     <IonRow>
    //       <IonCol size="12">
    //         <div className="custom-box-shadow p-5 br-10 font-14 text-center">
    //           {liveMeeting?.date} - {liveMeeting?.match_name}
    //         </div>
    //       </IonCol>
    //     </IonRow>
    //     <IonRow>
    //       <IonCol size="6" onClick={handleScoreBoardOpen}>
    //         <div className="custom-box-shadow p-8 br-10 font-14 flex-space-between">
    //           <div>Score Board</div>
    //           <div>
    //             {scoreBoard ? (
    //               <FaChevronUp className="flex font-18 fw-600" />
    //             ) : (
    //               <FaChevronDown className="flex font-18 fw-600" />
    //             )}
    //           </div>
    //         </div>
    //       </IonCol>
    //       <IonCol size="6" onClick={handleJoinListOpen}>
    //         <div className="custom-box-shadow p-8 br-10 font-14 flex-space-between">
    //           <div>Join</div>
    //           <div>
    //             {joinList ? (
    //               <FaChevronUp className="flex font-18 fw-600" />
    //             ) : (
    //               <FaChevronDown className="flex font-18 fw-600" />
    //             )}
    //           </div>
    //         </div>
    //       </IonCol>
    //     </IonRow>
    //   </IonGrid>
    // </div>
  );
}

export default VideoCallHeader;
